import {atomWithSuspenseQuery} from "jotai-tanstack-query";
import axiosApiInstance from "../api/axiosClient";
import {atomWithHash} from "jotai-location";
import dayjs from "dayjs";

export const revenueCostNetAtom = atomWithSuspenseQuery<API.RevenueCostNet[]>((get) => ({
    queryKey: ['reporting', 'revenue_cost_net', get(fetchParamsAtom)],
    queryFn: async ({queryKey}) => {
        const fetchParams = get(fetchParamsAtom);
        const response = await axiosApiInstance.get(`/api/reporting/revenue_cost_net`, {params: fetchParams});
        return response.data;
    },
}));

export const fetchParamsAtom = atomWithHash<Partial<{start_date: string; end_date: string}>>('reportingParams', {
    // last 3 months
    start_date: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
    end_date: dayjs().endOf('month').format('YYYY-MM-DD'),
});

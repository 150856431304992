import * as React from 'react';
import {useRef, useState} from 'react';
import {ActionType, ModalForm, ProList} from "@ant-design/pro-components";
import axiosApiInstance from "../../api/axiosClient";
import {UserAuth} from "../../context/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {Button, message, Progress, Tooltip, Upload, UploadFile} from "antd";
import {DeleteOutlined, InboxOutlined, ReloadOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import Avatar from "antd/es/avatar/avatar";
import FormData from 'form-data';
import {auth} from "../../config/firebase";
import axios from "axios";

type Props = {};


const ProjectDocuments = (props: Props) => {
    const [filesData, setFilesData] = useState<API.ProjectFile[] | undefined>(undefined);
    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);
    const [uploading, setUploading] = useState<boolean>(false);
    const actionRef = useRef<ActionType>();

    //maybe use for editing permissions
    const {can} = UserAuth();

    const routerParams = useParams();
    const navigate = useNavigate();

    // useEffect(() => {
    //     axiosApiInstance.get(`/api/projects/${routerParams.projectId}/files`)
    // }, [])

    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

    const handleFileChange = (fileList: UploadFile<any>[]) => {
        setFileList(fileList);
    };

    const handleUpload = async () => {
        if (!fileList) {
            message.error('Please select a file to upload.');
            return;
        }

        setUploading(true);

        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('files', file.originFileObj!, file.name);
        });

        const boundary = `----${Date.now()}`;

        const access_token = await auth.currentUser?.getIdToken();

        axios.post(process.env.REACT_APP_API! + `/api/projects/${routerParams.projectId}/files`, formData,
            {
                headers: {
                    "Accept": "*/*",
                    // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                    Authorization: `Bearer ${access_token}`
                },
                onUploadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent

                    function roundNum(number: number, number2: number) {
                        return Math.round((number + Number.EPSILON) * (10 ** number2)) / (10 ** number2)
                    }

                    const percent = roundNum((loaded / total!) * 100, 1)
                    console.log('progressEvent', progressEvent, percent);
                    setUploadProgress(percent)
                }

            }
        )
            .then((response) => {
                setUploading(false);
                message.success('File uploaded successfully.');
                setFileList([]);
                setUploadProgress(undefined);
                actionRef?.current?.reload();
            })
            .catch((error) => {
                setUploading(false);
                setUploadProgress(undefined)
                message.error('File upload failed.');
                console.error(error);
            });
    };


    return (
        <div>
            {/*<ProCard bordered>*/}
            <ProList<API.ProjectFile>
                className={'Documents'}
                cardBordered
                // itemCardProps={{
                //     ghost: false,
                // }}
                toolBarRender={() => {
                    return [
                        // <Link to="new" state={{background: location}}>
                        //     <Button
                        //         icon={<PlusOutlined/>}
                        //         key="new"
                        //         type="primary"
                        //     >
                        //         New
                        //     </Button>
                        // </Link>,
                        <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                            actionRef?.current?.reload()
                        }}/>
                    ];
                }}
                // search={{}}
                search={false}
                actionRef={actionRef}
                // search={{filterType: "light"}}
                rowKey="id"
                onDataSourceChange={setFilesData}
                headerTitle={'Project Documents'}
                request={async (params = {}) =>
                    // axiosApiInstance.post<API.Project[]>('/api/projects/', params)
                {
                    // console.log(params)
                    return axiosApiInstance.get<API.ProjectFile[]>(`/api/projects/${routerParams.projectId}/files`)
                }
                }
                pagination={{
                    pageSize: 5,
                    hideOnSinglePage: true
                }}

                // tableStyle={{display:"flex"}}
                // onSubmit={params => console.log(params)}
                // itemHeaderRender={(item, index, defaultDom) => }
                // header={'HEADER'}
                metas={{
                    title: {
                        dataIndex: 'name',
                        // valueType:'text',
                        // fieldProps:{style:{flexBasis:'content'}},
                        // fieldProps: {style: {width: 0}},
                        // formItemProps:{style:{flexBasis:'content'}},
                        // formItemProps: {style: {width: 0}},
                        title: 'Name',
                        // render: (dom, entity) => <Link to={'' + entity.id}>{dom}</Link>
                        render: (dom, entity) => <a href={entity.web_view_link} target={'_blank'}>{dom}</a>
                    },
                    avatar: {
                        dataIndex: 'icon_link',
                        // fieldProps: {shape: "circle"}
                        render: (dom, entity) => <Avatar src={entity.icon_link} shape={"square"} size={24}/>
                        // search: false,
                        // render: () => <Row justify={"center"} align="middle" style={{width: 36, height: 48}}>
                        //     <Col>
                        //         <ProjectTwoTone style={{fontSize: 24}}/>
                        //     </Col>
                        // </Row>
                    },
                    description: {
                        dataIndex: 'created_time',
                        render: (dom, entity) => `Uploaded at: ${dayjs.utc(entity.created_time).format('DD/MM/YYYY HH:mm')}`
                    },
                    actions: {
                        render: (dom, entity) => {
                            // return <a key="delete">Delete</a>;
                            // return <a key="delete"> <DeleteProject listRef={actionRef}/> </a>;
                            // return <DeleteProjects projectIds={[entity.id!]} label={'Delete'}/>;
                            return <ModalForm
                                title="Delete File"
                                trigger={
                                    <Tooltip title={'Delete'}>
                                        <Button type={"text"} danger icon={<DeleteOutlined/>}/>
                                    </Tooltip>
                                }
                                modalProps={{
                                    onCancel: () => console.log('run'),
                                    destroyOnClose: true,
                                    okText: 'Delete',
                                    cancelText: 'Cancel',
                                    // afterClose: () => actionRef?.current?.reload(),
                                }}
                                width={400}
                                submitter={{submitButtonProps: {danger: true}}}

                                onFinish={async (values) => {
                                    return axiosApiInstance.delete('/api/projects/files/' + entity.id)
                                        .then(res => {
                                            actionRef?.current?.reload()
                                            return true
                                        }).catch(reason => false)
                                }}
                            >
                                Are you sure?
                            </ModalForm>
                        },
                    },


                }}
            />
            {/*</ProCard>*/}

            <br/>
            <Upload.Dragger
                onChange={(info) => handleFileChange(info.fileList)}
                fileList={fileList}
                beforeUpload={() => false} // Prevent auto-upload
                multiple={true}
            >
                {/*<Button>Select File</Button>*/}
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or multiple file upload.
                </p>
            </Upload.Dragger>
            {fileList.length > 0 &&
                <>
                    <br/>
                    <Button
                        disabled={fileList.length === 0 || uploadProgress === 100}
                        loading={uploading}
                        type="primary" onClick={handleUpload}>Upload</Button>
                    {uploadProgress !== undefined && uploadProgress !== 0 && <Progress percent={uploadProgress}/>}
                </>}
        </div>
    );
};

export default ProjectDocuments;
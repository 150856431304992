import * as React from 'react';
import {Navigate, useParams} from "react-router-dom";

type Props = {};
const RedirectProjectIndexToProjectInfo = (props: Props) => {
    const routerParams = useParams();
    // return <Navigate to={`/wbs/${routerParams.wbsId}/versions/${routerParams.wbsVersionId}`} replace/>;
    return <Navigate to={`/projects/${routerParams.projectId}/info`} replace/>;
};

export default RedirectProjectIndexToProjectInfo;
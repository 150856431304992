import axiosApiInstance from '../../api/axiosClient';

const changeOpportunityCustomer = async (id: number, data: any) => {
    return axiosApiInstance
        .post<API.Opportunity>(`/api/sales/opportunities/${id}/change_customer`, data)
        .then(() => true)
        .catch((e) => false);
};

export default changeOpportunityCustomer;

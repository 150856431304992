import axiosApiInstance from '../../api/axiosClient';
import axios, { AxiosResponse } from 'axios';
import { notification } from 'antd';

const convertOpportunityToCompound = async (id: number) => {
    return axiosApiInstance
        .post<API.Opportunity>(`/api/sales/opportunities/${id}/covert_to_compound`)
        .then(() => true)
        .catch((e) => false);
};

export default convertOpportunityToCompound;

import {Button, Popover, Progress, Space, Statistic, Tag, Tooltip, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useSalesContext} from '../../context/SalesContextProvider';
import {ProCard, ProList} from "@ant-design/pro-components";
import {
    BlockOutlined,
    CalendarOutlined,
    DashboardOutlined,
    DeleteOutlined,
    EditOutlined,
    EuroOutlined,
    FolderOutlined,
    ForwardOutlined
} from "@ant-design/icons";
import shortenNameWithRegex from "../../util/name_shortener";
import './OpportunityCard.css'
import currencyFormatter from "../../util/currency_formatter";
import dayjs from "dayjs";

const {Divider} = ProCard;


type Props = {
    target: API.Opportunity;
    controlled_by_compound?: boolean;
};

const SMALL_SPAN = 4;
const BIG_SPAN = 4 // 8;
const ITEM_MIN_WIDTH = 635;

const colStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export const DelayedTooltip = ({children, title}: any) => {
    return (
        <Tooltip title={title} mouseEnterDelay={1}>
            {children}
        </Tooltip>
    );
};

const innerCardPaddingBlock = 4
const innerCardPaddingInlineStart = 16
const innerCardPaddingInlineEnd = 4

/**
 * Render item του opportunity list για το scroll layout
 * @param param0
 * @returns
 */
const OpportunityCard = ({target, controlled_by_compound}: Props) => {
    const location = useLocation();
    const {isCurrent, isLoading} = useSalesContext();
    const [hovering, setHovering] = useState(false);
    // const [hovering, setHovering] = useState(true);
    const {opportunitiesUnfiltered,} = useSalesContext();
    const [purchaseOrders, setPurchaseOrders] = useState<API.Opportunity[]>(
        // opportunities.filter(value => value.parent_id == target.id)
        []
    )

    useEffect(() => {
        const POs = opportunitiesUnfiltered.filter(value => value.parent_id == target.id);
        // console.log('opportunities', opportunities)
        // console.log('target', target.id)
        // console.log('POs', POs)
        // console.log('HEERE')
        setPurchaseOrders(POs)
    }, [opportunitiesUnfiltered])
    // const navigate = useNavigate();
    // useEffect(() => {
    //     console.log(target)
    // }, [])


    const links = [
        (!target.is_compound && !controlled_by_compound) && (
            <Link
                key="advance"
                to={{
                    pathname: `advance/${target.id}`,
                    search: location.search,
                }}
                state={{background: location}}
            >
                <Tooltip title={'Advance'} placement={'bottom'}>
                    <Button
                        type="link"
                        disabled={!isCurrent || target.opportunity_phase.completion_percentage === 0}
                        icon={<ForwardOutlined/>}
                    />
                </Tooltip>
            </Link>
        ),
        (!target.is_compound && !controlled_by_compound) && (
            <Link
                key="case"
                to={`/projects/by_pretty_id/${target.project_pretty_id?.replaceAll('/', '_')}`}
            >
                <Tooltip title={'Case'} placement={'bottom'}>
                    <Button type="link" icon={<FolderOutlined/>}/>
                </Tooltip>
            </Link>
        ),
        (!target.is_compound && controlled_by_compound) && (
            <Link
                key="advance_compound"
                to={{
                    pathname: `../advance/${target.id}`,
                    search: location.search,
                }}
                state={{background: location, from_path: location.pathname}}
            >
                <Tooltip title={'Advance'} placement={'bottom'}>
                    <Button
                        type="link"
                        disabled={!isCurrent || target.opportunity_phase.completion_percentage === 0}
                        icon={<ForwardOutlined/>}
                    />
                </Tooltip>
            </Link>
        ),
        (!target.is_compound && controlled_by_compound) && (
            <Link
                key="case_compound"
                to={`/projects/by_pretty_id/${target.project_pretty_id?.replaceAll('/', '_')}`}
            >
                <Tooltip title={'Case'} placement={'bottom'}>
                    <Button type="link" icon={<FolderOutlined/>}/>
                </Tooltip>
            </Link>
        ),
        <Link
            key="edit"
            to={{
                pathname: `/sales/list/${target.id}/edit`,
                search: location.search,
            }}
            state={{
                // background: location,
                from_path: location.pathname
            }}
        >
            <Tooltip title={'Edit'} placement={'bottom'}>
                <Button type="link" icon={<EditOutlined/>}/>
            </Tooltip>
        </Link>,
        (!target.is_compound && controlled_by_compound) && (
            <Link
                key="delete_compound"
                to={{
                    pathname: `../delete/${target.id}`,
                    search: location.search,
                }}
                state={{background: location, from_path: location.pathname}}
            >
                <Tooltip title={'Delete'} placement={'bottom'}>
                    <Button type="link" danger icon={<DeleteOutlined/>}/>
                </Tooltip>
            </Link>
        ),
        (!controlled_by_compound) && (
            <Link
                key="delete"
                to={{
                    pathname: `delete/${target.id}`,
                    search: location.search,
                }}
                state={{background: location}}
            >
                <Tooltip title={'Delete'} placement={'bottom'}>
                    <Button type="link" danger icon={<DeleteOutlined/>}/>
                </Tooltip>
            </Link>
        ),
    ].filter((link) => link);

    // console.log(links)

    return (
        <div className='opportunity-card'
             key={target.id}
             style={{margin: 8}}
            // style={{margin: 8, width: '100%'}}
        >
            {!controlled_by_compound ?
                <Popover
                    // className={'opportunity-card-popover'}
                    // style={{width: 'fit-content'}}
                    overlayInnerStyle={{
                        padding: 0,
                        // paddingInline: 0, paddingBlock: 0
                    }}
                    // style={{paddingInline: 0, paddingBlock: 0}}
                    placement={'right'}
                    // open={hovering && target.is_compound}
                    // open
                    overlayStyle={{display: !target.is_compound ? 'none' : 'auto'}}
                    content={() => {
                        // if (!target.is_compound) {
                        //     return false
                        // }
                        // put the purchaseOrders value in a prolist inside the content
                        return (<ProList
                            // tableStyle={{
                            // width: 500,
                            // paddingInline: 0, paddingBlock: 0}}
                            // cardProps={{style: {padding: 0,
                            //         // paddingInline: 0, paddingBlock: 0
                            //     }}}
                            // style={{paddingInline: 0, paddingBlock: 0}}
                            // itemCardProps={{style: {padding: 0, paddingInline: 0, paddingBlock: 0}}}
                            // style={{width: 500}}
                            // tableStyle={{padding: 0}}
                            pagination={{defaultPageSize: 6, hideOnSinglePage: true}}
                            dataSource={purchaseOrders}
                            rowKey="id"
                            metas={{
                                // title: {
                                //     dataIndex: 'title',
                                //     render: (dom, entity) => {
                                //         return (
                                //             ``
                                //         )
                                //     }
                                // },
                                actions: {
                                    render: (dom, entity) => {
                                        return [
                                            (!entity.is_compound && !controlled_by_compound) && (
                                                <Link
                                                    key="advance"
                                                    to={{
                                                        pathname: `advance/${entity.id}`,
                                                        search: location.search,
                                                    }}
                                                    state={{background: location}}
                                                >
                                                    <Tooltip title={'Advance'} placement={'bottom'}>
                                                        <Button
                                                            type="link"
                                                            disabled={!isCurrent || entity.opportunity_phase.completion_percentage === 0}
                                                            icon={<ForwardOutlined/>}
                                                        />
                                                    </Tooltip>
                                                </Link>
                                            ),
                                            (!entity.is_compound && !controlled_by_compound) && (
                                                <Link
                                                    key="case"
                                                    to={`/projects/by_pretty_id/${entity.project_pretty_id?.replaceAll('/', '_')}`}
                                                >
                                                    <Tooltip title={'Case'} placement={'bottom'}>
                                                        <Button type="link" icon={<FolderOutlined/>}/>
                                                    </Tooltip>
                                                </Link>
                                            ),
                                            (!entity.is_compound && controlled_by_compound) && (
                                                <Link
                                                    key="advance_compound"
                                                    to={{
                                                        pathname: `../advance/${entity.id}`,
                                                        search: location.search,
                                                    }}
                                                    state={{background: location, from_path: location.pathname}}
                                                >
                                                    <Tooltip title={'Advance'} placement={'bottom'}>
                                                        <Button
                                                            type="link"
                                                            disabled={!isCurrent || entity.opportunity_phase.completion_percentage === 0}
                                                            icon={<ForwardOutlined/>}
                                                        />
                                                    </Tooltip>
                                                </Link>
                                            ),
                                            (!entity.is_compound && controlled_by_compound) && (
                                                <Link
                                                    key="case_compound"
                                                    to={`/projects/by_pretty_id/${entity.project_pretty_id?.replaceAll('/', '_')}`}
                                                >
                                                    <Tooltip title={'Case'} placement={'bottom'}>
                                                        <Button type="link" icon={<FolderOutlined/>}/>
                                                    </Tooltip>
                                                </Link>
                                            ),
                                            (!entity.is_compound && controlled_by_compound) && (
                                                <Link
                                                    key="delete_compound"
                                                    to={{
                                                        pathname: `../delete/${entity.id}`,
                                                        search: location.search,
                                                    }}
                                                    state={{background: location, from_path: location.pathname}}
                                                >
                                                    <Tooltip title={'Delete'} placement={'bottom'}>
                                                        <Button type="link" danger icon={<DeleteOutlined/>}/>
                                                    </Tooltip>
                                                </Link>
                                            ),
                                            (!controlled_by_compound) && (
                                                <Link
                                                    key="delete"
                                                    to={{
                                                        pathname: `delete/${entity.id}`,
                                                        search: location.search,
                                                    }}
                                                    state={{background: location}}
                                                >
                                                    <Tooltip title={'Delete'} placement={'bottom'}>
                                                        <Button type="link" danger icon={<DeleteOutlined/>}/>
                                                    </Tooltip>
                                                </Link>
                                            ),
                                        ].filter((link) => link)
                                    }
                                },
                                content: {
                                    render: (dom, entity) => {
                                        return (
                                            <Link
                                                reloadDocument={!location.pathname.includes('list')}
                                                to={{
                                                    pathname: controlled_by_compound ? `../${entity.id}` : `${entity.id}`,
                                                    search: location.search
                                                }}
                                            >
                                                <ProCard
                                                    gutter={12}
                                                    ghost>
                                                    <ProCard
                                                        style={{
                                                            width: 'max-content',
                                                            // padding: '0 12px'
                                                        }}
                                                        ghost
                                                        // layout="center"
                                                        // bordered
                                                    >
                                                        <Space>
                                                            <CalendarOutlined/>
                                                            {entity.expected_po_date !== '0001-01-01T00:00:00Z'
                                                                ? new Date(entity.expected_po_date!).toLocaleDateString('el')
                                                                : 'No Date'}
                                                        </Space>
                                                    </ProCard>
                                                    <ProCard
                                                        style={{
                                                            width: 'max-content',
                                                            // padding: '0 12px'
                                                        }}
                                                        ghost
                                                        // layout="center"
                                                        // bordered
                                                    >
                                                        <Space>
                                                            <EuroOutlined/>
                                                            {currencyFormatter.format(entity.proposed_value_in_euro!)}
                                                        </Space>


                                                    </ProCard>
                                                    <ProCard
                                                        style={{
                                                            width: 'max-content',
                                                            // padding: '0 12px'
                                                        }}
                                                        ghost
                                                        // layout="center"
                                                        // bordered
                                                    >
                                                        <Space>
                                                            {/*<div/>*/}
                                                            <Tooltip title={entity.opportunity_phase.name}>
                                                                <Progress
                                                                    style={{
                                                                        width: 100,
                                                                    }}
                                                                    status={
                                                                        entity.opportunity_phase.completion_percentage === 0
                                                                            ? 'exception'
                                                                            : undefined
                                                                    }
                                                                    percent={entity.opportunity_phase.completion_percentage * 100}
                                                                />
                                                            </Tooltip>
                                                        </Space>

                                                    </ProCard>
                                                </ProCard>
                                            </Link>
                                        )
                                    }
                                }
                            }}
                        />)
                    }}

                >
                    <Link
                        reloadDocument={!location.pathname.includes('list')}
                        to={{
                            pathname: controlled_by_compound ? `../${target.id}` : `${target.id}`,
                            search: location.search
                        }}
                    >
                        <ProCard
                            title={
                                <Space direction={"vertical"} size={0}
                                    // style={{width: 'calc(100% - 32px)'}}
                                       style={{width: '100%'}}
                                    // style={{width: '80%'}}
                                    // style={{paddingInlineEnd: 8}}
                                >
                                    <Tooltip title={target.title} placement={'topLeft'}>
                                        <Typography.Paragraph ellipsis={{
                                            rows: 1,
                                            // tooltip: true
                                        }}
                                            // style={{width: '100%'}}
                                                              style={{marginBottom: 4}}
                                            // style={{width: '90%'}}
                                        >
                                            {target.title}
                                        </Typography.Paragraph>
                                    </Tooltip>
                                    {/*<span*/}
                                    {/*    // smoothly change display from none to block*/}
                                    {/*    style={{*/}
                                    {/*        opacity: hovering ? 1 : 0,*/}
                                    {/*        // height: hovering ? 'auto' : 0, overflow: 'hidden',*/}
                                    {/*        // visibility: hovering ? 'visible' : 'hidden',*/}
                                    {/*        // transform: hovering ? 'scaleY(1)' : 'scaleY(0)',*/}
                                    {/*        // transformOrigin: 'top',*/}
                                    {/*        // transition: 'opacity 0.3s, transform 0.3s, height 0.3s, visibility 0.3s',*/}
                                    {/*        transition: 'opacity 0.2s',*/}
                                    {/*        // margin: 0,*/}
                                    {/*        // padding: 0*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    <Space>

                                        <Tag
                                            style={{
                                                opacity: hovering ? 1 : 0,
                                                transition: 'opacity 0.2s',
                                            }}
                                            color='blue'>
                                            {target.project_pretty_id}
                                        </Tag>
                                        {/*</span>*/}
                                        <Tag
                                            style={{
                                                opacity: hovering ? 1 : 0,
                                                transition: 'opacity 0.2s',
                                            }}
                                            color='default'>
                                            Created: {dayjs(target.created_at).format('DD/MM/YYYY')}
                                        </Tag>
                                    </Space>
                                </Space>
                                // <Row justify='space-around' align='middle'>
                                //     {/*<Col span={18}>*/}
                                //     {/*</Col>*/}
                                //     {/*<Col span={6}>*/}
                                //         {/*<Space>*/}
                                //             {/*{target.is_compound && <BlockOutlined/>}*/}
                                //             <BlockOutlined/>
                                //         {/*</Space>*/}
                                //     {/*</Col>*/}
                                // </Row>
                            }
                            extra={
                                // align this to the top
                                //     target.is_compound && <BlockOutlined/>
                                <Space>
                                    {/*{target.is_compound && <BlockOutlined/>}*/}
                                    <BlockOutlined
                                        style={{opacity: target.is_compound ? 1 : 0, color: '#4096ff', fontSize: 20}}/>
                                </Space>

                            }
                            // subTitle={
                            //     <Tag color='blue'>
                            //         {target.project_pretty_id}
                            //     </Tag>
                            // }
                            hoverable
                            bordered
                            onMouseEnter={() => setHovering(true)}
                            onMouseLeave={() => setHovering(false)}
                            // headStyle={{display: "flex", justifyContent: 'space-between', width: '100%', flexDirection:'row'}}
                            headStyle={{
                                alignItems: 'start',
                                // paddingInlineEnd: 0, width: '90%'
                            }}
                            bodyStyle={{padding: '4px 8px'}}
                            actions={links}
                        >
                            <ProCard.Group direction={'row'}
                                // split={'vertical'}
                            >
                                <ProCard colSpan={12}
                                    // layout={'center'}
                                    // bodyStyle={{padding: 0}}
                                         bodyStyle={{
                                             paddingBlock: innerCardPaddingBlock,
                                             paddingInlineStart: innerCardPaddingInlineStart,
                                             paddingInlineEnd: innerCardPaddingInlineEnd
                                         }}
                                >
                                    <Statistic title="Deal Size" valueRender={() =>
                                        <Typography.Paragraph
                                            strong
                                        >
                                            {currencyFormatter.format(target.proposed_value_in_euro!)}
                                        </Typography.Paragraph>
                                    }/>
                                    <Tooltip title={'Weighted Value'} placement={'bottom'}>
                                        <Tag
                                            // style={{
                                            //     opacity: hovering ? 1 : 0,
                                            //     transition: 'opacity 0.2s',
                                            // }}
                                        ><DashboardOutlined/> {currencyFormatter.format(target.po_value_in_euro!)}</Tag>
                                    </Tooltip>
                                </ProCard>
                                {/*<Divider type={'vertical'}/>*/}
                                <ProCard colSpan={12}
                                         bodyStyle={{
                                             paddingBlock: innerCardPaddingBlock,
                                             paddingInlineStart: innerCardPaddingInlineStart,
                                             paddingInlineEnd: innerCardPaddingInlineEnd
                                         }}
                                >
                                    {target.is_compound ?
                                        <>
                                            <Statistic title="Total POs" valueRender={() =>
                                                <Typography.Paragraph
                                                    strong
                                                >
                                                    {purchaseOrders.length}
                                                </Typography.Paragraph>
                                            }/>
                                            <Progress style={{opacity: 0,}}/>
                                        </>
                                        :
                                        <>
                                            <Statistic title="Expected P.O. Date"
                                                       valueRender={
                                                           () => <Typography.Paragraph
                                                               strong
                                                           >
                                                               {target.expected_po_date !== '0001-01-01T00:00:00Z'
                                                                   ? new Date(target.expected_po_date!).toLocaleDateString('el')
                                                                   : 'No Date'}
                                                           </Typography.Paragraph>
                                                       }
                                            />
                                            <Tooltip
                                                title={target.opportunity_phase.status.completed ? 'Closure Date: ' + dayjs(target.closure_date).format('DD/MM/YYYY') : undefined}
                                                placement={'top'}>
                                                <Tooltip title={target.opportunity_phase.name} placement={'bottom'}>
                                                    <Progress
                                                        // style={{
                                                        //     opacity: hovering ? 1 : 0,
                                                        //     transition: 'opacity 0.2s',
                                                        // }}
                                                        status={
                                                            target.opportunity_phase.completion_percentage === 0
                                                                ? 'exception'
                                                                : undefined
                                                        }
                                                        percent={target.opportunity_phase.completion_percentage * 100}
                                                    />
                                                </Tooltip>
                                            </Tooltip>
                                        </>
                                    }
                                </ProCard>
                            </ProCard.Group>
                            <ProCard.Group direction={'row'}>
                                <ProCard colSpan={12}
                                         bodyStyle={{
                                             paddingBlock: innerCardPaddingBlock,
                                             paddingInlineStart: innerCardPaddingInlineStart,
                                             paddingInlineEnd: innerCardPaddingInlineEnd
                                         }}
                                    // title={<Typography.Paragraph ellipsis={{rows: 1, tooltip: true}}>
                                    //     {target.customer?.name || target.external_buyer?.name}
                                    // </Typography.Paragraph>}
                                    // subTitle={
                                    //     <Typography.Paragraph ellipsis={{rows: 1, tooltip: true}}>
                                    //         {target.customer?.name || target.external_buyer?.name}
                                    //     </Typography.Paragraph>
                                    // }
                                >

                                    <Statistic title="Customer"
                                               valueRender={() =>
                                                   <Tooltip
                                                       title={target.customer?.name || target.external_buyer?.name || target.accounting_customer?.name}
                                                       placement={'bottomLeft'}>
                                                       <Typography.Paragraph
                                                           // style={{width: 80}}
                                                           // ellipsis={{tooltip: true}}
                                                           strong
                                                       >
                                                           {/*{target.customer?.name || target.external_buyer?.name}*/}
                                                           {shortenNameWithRegex(target.customer?.name || target.external_buyer?.name || target.accounting_customer?.name)}
                                                       </Typography.Paragraph>
                                                   </Tooltip>
                                               }

                                    />
                                </ProCard>
                                <ProCard colSpan={12}
                                         bodyStyle={{
                                             paddingBlock: innerCardPaddingBlock,
                                             paddingInlineStart: innerCardPaddingInlineStart,
                                             paddingInlineEnd: innerCardPaddingInlineEnd
                                         }}
                                >
                                    <Statistic title="Manager"
                                               valueRender={() =>
                                                   <Tooltip title={target.employee.name} placement={'bottomLeft'}>
                                                       <Typography.Paragraph
                                                           // style={{width: 80}}
                                                           // ellipsis={{tooltip: true}}
                                                           strong
                                                       >
                                                           {/*{target.employee.name}*/}
                                                           {shortenNameWithRegex(target.employee.name)}
                                                       </Typography.Paragraph>
                                                   </Tooltip>
                                               }
                                    />
                                </ProCard>
                            </ProCard.Group>
                        </ProCard>

                    </Link>

                </Popover>

                :
                <Link
                    reloadDocument={!location.pathname.includes('list')}
                    to={{
                        pathname: controlled_by_compound ? `../${target.id}` : `${target.id}`,
                        search: location.search
                    }}
                >
                    <ProCard
                        className={'opportunity-card-controlled'}
                        gutter={12}
                        ghost>
                        <ProCard
                            style={{
                                width: 'max-content',
                                // padding: '0 12px'
                            }}
                            ghost
                            // layout="center"
                            // bordered
                        >
                            <Space>
                                <CalendarOutlined/>
                                {target.expected_po_date !== '0001-01-01T00:00:00Z'
                                    ? new Date(target.expected_po_date!).toLocaleDateString('el')
                                    : 'No Date'}
                            </Space>
                        </ProCard>
                        <ProCard
                            style={{
                                width: 'max-content',
                                // padding: '0 12px'
                            }}
                            ghost
                            // layout="center"
                            // bordered
                        >
                            <Space>
                                <EuroOutlined/>
                                {currencyFormatter.format(target.proposed_value_in_euro!)}
                            </Space>


                        </ProCard>
                        <ProCard
                            style={{
                                width: 'max-content',
                                // padding: '0 12px'
                            }}
                            ghost
                            // layout="center"
                            // bordered
                        >
                            <Space>
                                {/*<div/>*/}
                                <Tooltip title={target.opportunity_phase.name}>
                                    <Progress
                                        style={{
                                            width: 100,
                                        }}
                                        status={
                                            target.opportunity_phase.completion_percentage === 0
                                                ? 'exception'
                                                : undefined
                                        }
                                        percent={target.opportunity_phase.completion_percentage * 100}
                                    />
                                </Tooltip>
                            </Space>

                        </ProCard>

                        <ProCard
                            style={{
                                width: 'max-content',
                                // padding: '0 12px'
                            }}
                            ghost
                            // layout="center"
                            // bordered
                        >
                            <Space>
                                {links.map((link, index) =>
                                    link
                                )}
                            </Space>

                        </ProCard>
                    </ProCard>
                </Link>

            }
        </div>
    )
};

export default OpportunityCard;

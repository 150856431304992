import axiosApiInstance from '../../api/axiosClient';

type InvoicesIn = API.LoggedInvoiceIncoming[];

const getInvoicesIncoming = async (params?: any) => {
    return axiosApiInstance
        .get<InvoicesIn>('/api/invoices/incoming/', {params: params,})
        .then(({data}) => data)
        .catch((e) => {
            throw e;
        });
};

export default getInvoicesIncoming;

import * as React from 'react';
import {useEffect} from 'react';
import {PageContainer} from "@ant-design/pro-layout";
import {useNavigate, useParams} from "react-router-dom";
import axiosApiInstance from "../../api/axiosClient";

type Props = {};

export const ViewCaseByPrettyId = (props: Props) => {
    const {pretty_id} = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        console.log(pretty_id)
        axiosApiInstance.get<API.Project>(`/api/projects/by_pretty_id/${pretty_id}`)
            .then(({data}) => {
                if (!data) {
                    navigate(`/projects`);
                    return;
                }
                console.log(data);
                navigate(`/projects/${data.id}`, {replace: true});
            })
            .catch((e) => {
                console.log(e);
                navigate(`/projects`);
            });

    }, []);
    return (
        <PageContainer loading={true}/>
    );
};

export default ViewCaseByPrettyId;
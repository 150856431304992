import React, {useCallback, useContext, useEffect, useState} from 'react';
import getOpportunityTypeOptions from '../util/sales/getOpportunityTypeOptions';
import getCustomerOptions from '../util/sales/getCustomerOptions';
import getEmployeeOptions from '../util/sales/getEmployeeOptions';
import getOpportunityPhases from '../util/sales/getOpportunityPhases';
import {notification} from 'antd';
import {auth} from "../config/firebase";
import getBuyerTypes from "../util/sales/getBuyerTypes";
import getExternalBuyers from "../util/sales/getExternalBuyers";
import getAccountingCustomerOptions from "../util/sales/getAccountingCustomerOptions";

type SalesAuxDataContextType = {
    isLoading: boolean;
    opportunityTypes: API.OpportunityType[];
    customers: API.Customer[];
    accountingCustomers: API.AccountingCustomer[];
    employees: API.Employee[];
    opportunityPhases: Array<API.OpportunityPhase>;
    buyerTypes: Array<API.BuyerType>;
    externalBuyers: Array<API.ExternalBuyer>;
    updateExternal: () => Promise<void>;
};

type Props = {
    children: JSX.Element;
};

const SalesAuxDataContext = React.createContext<SalesAuxDataContextType>({
    isLoading: true,
    opportunityTypes: [],
    customers: [], // to be replaced by jotai atom
    accountingCustomers: [],  // to be replaced by jotai atom
    employees: [],  // to be replaced by jotai atom
    opportunityPhases: [],
    buyerTypes: [],  // to be replaced by jotai atom
    externalBuyers: [],  // to be replaced by jotai atom
    updateExternal: () => Promise.resolve(),

});

//|-|--|---|----|-----|------|-------|--------|---------|----------|-----------|-----------|----------|
/**
 * This is the sales context that preloads auxiliary data necessary for the sales branch of the application to function,
 * except for the opportunities.
 *
 * @property {boolean} isLoading - Determines whether context is currently loading data
 * @property {API.OpportunityPhase[]} opportunityPhases - Opportunity phases loaded from database
 * @property {API.OpportunityType[]} opportunityTypes - Opportunity types loaded from database
 * @property {API.Customer[]} customers - Customers loaded from database
 * @property {API.Employee[]} employees - Employees loaded from database
 *
 * The context requires a React Component as children param to wrap around it:
 *
 * @param children React component to render in context
 * @returns context with loaded data
 */
const SalesAuxDataContextProvider = ({children}: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    // selected opportunities is either a copy of current or historical.
    const [opportunityPhases, setOpportunityPhases] = useState<API.OpportunityPhase[]>([]);
    const [opportunityTypes, setOpportunityTypes] = useState<API.OpportunityType[]>([]);
    const [customers, setCustomers] = useState<any[]>([]);
    const [accountingCustomers, setAccountingCustomers] = useState<any[]>([]);
    const [employees, setEmployees] = useState<any[]>([]);
    const [buyerTypes, setBuyerTypes] = useState<any[]>([]);
    const [externalBuyers, setExternalBuyers] = useState<any[]>([]);
    const [compoundOppModalTarget, setCompoundOppModalTarget] = useState<API.Opportunity | undefined>(undefined);
    const [compoundOppModalVisible, setCompoundOppModalVisible] = useState<boolean>(false);

    /**
     * this function is called when SalesAuxDataContext is mounted, and initializes the states that keep track of data
     */
    const bootstrap = useCallback(async () => {
        if (auth.currentUser === null) {
            // wait for user to be logged in
            await new Promise((resolve) => {
                    const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                            unsubscribe();
                            resolve(user);
                        }
                    });
                }
            );
        }
        setIsLoading(true);

        Promise.all([
            getOpportunityTypeOptions(),
            getCustomerOptions(),
            getAccountingCustomerOptions(),
            getEmployeeOptions(),
            getOpportunityPhases(),
            getBuyerTypes(),
            getExternalBuyers(),
        ])
            .then((values) => {
                const [types, customers, accountingCustomers, employees, phases, buyerTypes, externalBuyers] = values;
                setOpportunityTypes(types);
                setCustomers(customers);
                console.log('accountingCustomers: ', accountingCustomers)
                setAccountingCustomers(accountingCustomers)
                setEmployees(employees);
                setOpportunityPhases(phases);
                console.log('buyertypes: ', buyerTypes);
                setBuyerTypes(buyerTypes);
                setExternalBuyers(externalBuyers);
                // console.log('customers: ', customers, 'employees: ', employees, 'phases: ', phases, 'buyerTypes: ', buyerTypes, 'externalBuyers: ', externalBuyers);
            })
            .catch((e) => {
                // notification.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []); // useCallback hook: declare function on mount only

    /**
     * Call bootstrap function on mount
     */
    useEffect(() => {
        bootstrap();
    }, [bootstrap]); // on mount

    const updateExternal = useCallback(async () => {
        setIsLoading(true)
        await getExternalBuyers()
            .then((val) => setExternalBuyers(val))
            .catch((e) => console.error(e))
            .finally(() => setIsLoading(false))
    }, []); // useCallback hook: declare function on mount only

    return (
        <SalesAuxDataContext.Provider
            value={{
                isLoading,
                opportunityTypes,
                customers,
                accountingCustomers,
                employees,
                opportunityPhases,
                buyerTypes,
                externalBuyers,
                updateExternal,
            }}>
            {children}
        </SalesAuxDataContext.Provider>
    );
};

/**
 * This hook provides the sales context, and exposes the following data to the user:
 *
 * @property {boolean} isLoading - Determines whether context is currently loading data
 * @property {API.OpportunityType[]} opportunityTypes - Opportunity types loaded from database
 * @property {API.Customer[]} customers - Customers (LTD) loaded from database
 * @property {API.AccountingCustomer[]} accountingCustomers - Customers (IKE) loaded from database
 * @property {API.Employee[]} employees - Employees loaded from database
 * @property {API.OpportunityPhase[]} opportunityPhases - Opportunity phases loaded from database
 * @property {API.BuyerType[]} buyerTypes - Buyer types loaded from database
 * @property {API.ExternalBuyer[]} externalBuyers - External buyers loaded from database
 *
 * @returns {SalesAuxDataContextType} context data
 */
export const useSalesAuxDataContext = () => {
    return useContext(SalesAuxDataContext);
};

export default SalesAuxDataContextProvider;

import {atomWithHash} from "jotai-location";
import axiosApiInstance from "../api/axiosClient";
import {atomWithSuspenseQuery} from "jotai-tanstack-query";
import {ListGridType} from "antd/es/list";
import {atom} from "jotai";
import dayjs from "dayjs";

export const brandsCashFlowStatusAtom = atomWithSuspenseQuery<API.BrandsCashFlowStatus>((get) => ({
    queryKey: ['projects', 'cashflow', get(fetchParamsAtom)],
    queryFn: async ({queryKey}) => {
        const fetchParams = get(fetchParamsAtom);
        const response = await axiosApiInstance.get(`/api/projects/cashflow`, {params: fetchParams});
        return response.data;
    },
}));

export const projectsAtom = atomWithSuspenseQuery<API.Project[]>((get) => ({
    queryKey: ['projects', get(fetchParamsAtom)],
    queryFn: async ({queryKey}) => {
        // console.log('projectsAtom')
        const fetchParams = get(fetchParamsAtom);
        const response = await axiosApiInstance.get(`/api/projects/`, {params: fetchParams});
        // console.log('projectsAtom response.data', response.data?.length)
        return response.data;
    },
}));

export const projectsUnfilteredAtom = atomWithSuspenseQuery<API.Project[]>((get) => ({
    queryKey: ['projects', {}],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/projects/`);
        return response.data;
    },
}));

export const projectsOnExecutionUnfilteredAtom = atomWithSuspenseQuery<API.Project[]>((get) => ({
    queryKey: ['projects', 'onexecution'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/projects/onexecution`);
        return response.data;
    },
}));

// export const projectsAtom = atomWithQuery<API.Project[]>((get) => ({
//     queryKey: ['projects'],
//     queryFn: async ({queryKey}) => {
//         console.log('projectsAtom')
//         const fetchParams = get(fetchParamsAtom);
//         console.log('step 1')
//         const response = await axiosApiInstance.get(`/api/projects/`, {params: fetchParams});
//         console.log('step 2')
//         console.log('projectsAtom response.data', response.data?.length)
//         return response.data;
//     },
//     select: (data) => {
//         console.log('projectsAtom select', data?.length)
//         const orderBy = get(orderByAtom);
//         console.log('orderBy', orderBy)
//         return data?.map((project) => {
//             return {...project, title: orderBy+project.title}
//         } )
//     }
// }));

// export const projectsAtom = atomWithCache(async (get) => {
//         const fetchParams = get(fetchParamsAtom);
//         const response = await axiosApiInstance.get(`/api/projects/`, {params: fetchParams});
//         return response.data;
// });

// export const projectsAtom = atom<Promise<API.Project[]>>(async (get) => {
//     const fetchParams = get(fetchParamsAtom);
//     const response = await axiosApiInstance.get(`/api/projects/`, {params: fetchParams});
//     console.log('step 1')
//     return response.data;
// });

export const allProjectTagsAtom = atomWithSuspenseQuery<API.ProjectTag[]>((get) => ({
    queryKey: ['projects', 'tags'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/projects/tags`);
        return response.data;
    },
}));

export const selectedProjectTagIdsAtom = atomWithHash('selectedProjectTagIds', [] as number[]);

export const enum LayoutEnum {
    Grid = 'Grid',
    List = 'List'
}

export const layoutAtom = atomWithHash<LayoutEnum>('layout', LayoutEnum.Grid);

export const gridAtom = atom<ListGridType | undefined>((get) => {
    const layout = get(layoutAtom);
    return layout === LayoutEnum.Grid ? {xxl: 5, xl: 4, lg: 3, md: 2, sm: 1, xs: 1, gutter: 16} : undefined;
});

export const enum ProjectRangeEnum {
    LastWeek = 'last-week',
    LastMonth = 'last-month',
}

type FetchParams = API.Project & {
    range: ProjectRangeEnum;
    status: string;
    // tagIds: number[];
} & any

export const fetchParamsAtom = atomWithHash<Partial<FetchParams>>('casesSearchParams', {
    created_at_start: dayjs().startOf('year').format('YYYY-MM-DD'),
    created_at_end: dayjs().endOf('year').format('YYYY-MM-DD')
});

export const projectsAfterFilterAtom = atom(async (get) => {
    const {data: projects} = await get(projectsAtom);
    // const projects = await get(projectsAtom);
    // console.log('step 2', projects.length)
    const fetchParams = get(fetchParamsAtom);
    const selectedProjectTagIds = get(selectedProjectTagIdsAtom);
    const f = projects?.filter((project: API.Project) => {

        if (fetchParams?.range) {
            // console.log('fetchParams?.range', fetchParams?.range)
            let rangeStart, rangeEnd
            if (fetchParams?.range == ProjectRangeEnum.LastWeek) {
                rangeStart = dayjs.utc().subtract(1, 'week')
                rangeEnd = dayjs.utc()
            }
            if (fetchParams?.range == ProjectRangeEnum.LastMonth) {
                rangeStart = dayjs.utc().subtract(1, 'month')
                rangeEnd = dayjs.utc()
            }
            if (!dayjs.utc(project.created_at).isBetween(rangeStart, rangeEnd))
                return false
        }

        if (fetchParams?.status) {
            // rowIncluded = project.status == project.status;
            if (project.status != fetchParams?.status) {
                // console.log('fetchParams?.status',project.status, fetchParams?.status)
                return false
            }
        }

        // if (selectedProjectTagIds.length > 0) {
        //     // console.log('selectedProjectTagIds', selectedProjectTagIds)
        //     // rowIncluded = project.project_tags?.some((tag) => selectedProjectTagIds.includes(tag.id));
        //     if (!project.project_tags?.some((tag) => selectedProjectTagIds.includes(tag.id)))
        //         return false
        // }

        return true
    });
    // console.log('projectsAfterFilterAtom', f?.length)
    return f;
});

export const orderByAtom = atomWithHash<string>('orderBy', '-created_at');

export const projectsAfterFilterSortAtom = atom(async (get) => {
    // console.log('step 3')
    const projects = await get(projectsAfterFilterAtom);
    // console.log('step 3', projects?.length)
    const orderBy = get(orderByAtom);
    const s = projects?.sort((a, b) => {
        if (orderBy) {
            if (orderBy == '-created_at') {
                return dayjs.utc(b.created_at).diff(dayjs.utc(a.created_at))
            }
            if (orderBy == 'created_at') {
                return dayjs.utc(a.created_at).diff(dayjs.utc(b.created_at))
            }
            if (orderBy == '-execution_cost') {
                return b.active_execution_cost - a.active_execution_cost
            }
            if (orderBy == 'execution_cost') {
                return a.active_execution_cost - b.active_execution_cost
            }
        }
        return 0
    });

    // console.log('projectsAfterFilterSortAtom', s?.length)
    return s;
    // const res = await get(projectsAtom);
    // // console.log({projects})
    // return []
});

export const projectsAfterFilterSortSearchAtom = atom(async (get) => {
    const projects = await get(projectsAfterFilterSortAtom);
    const filterSearch = get(filterSearchAtom);
    if (!filterSearch) return projects;
    const s = projects?.filter((project) => {
        if (filterSearch) {
            return project.title?.toLowerCase().includes(filterSearch.toLowerCase())
                || project.description?.toLowerCase().includes(filterSearch.toLowerCase())
                || project.project_pretty_id?.toLowerCase().includes(filterSearch.toLowerCase())
                || project.customer?.name?.toLowerCase().includes(filterSearch.toLowerCase())
                || project.accounting_customer?.name?.toLowerCase().includes(filterSearch.toLowerCase())
                || project.external_buyer?.name?.toLowerCase().includes(filterSearch.toLowerCase())
                || project.employee?.name?.toLowerCase().includes(filterSearch.toLowerCase())
        }
        return true
    });
    return s;
});


export const orderByEnum = {
    '-created_at': 'Created At (Newest First)',
    'created_at': 'Created At (Oldest First)',
    '-execution_cost': 'Execution Cost (Highest First)',
    'execution_cost': 'Execution Cost (Lowest First)',
};

export const customersAtom = atomWithSuspenseQuery<API.Customer[]>((get) => ({
    queryKey: ['customers'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/customers/`);
        return response.data;
    },
}));

export const accountingCustomersAtom = atomWithSuspenseQuery<API.AccountingCustomer[]>((get) => ({
    queryKey: ['accounting_customers'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/accounting_customers/`);
        return response.data;
    },
}));

export const employeesAtom = atomWithSuspenseQuery<API.Employee[]>((get) => ({
    queryKey: ['employees'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/employees/`, {params: {is_active: true}});
        return response.data;
    },
}));

export const externalBuyersAtom = atomWithSuspenseQuery<API.ExternalBuyer[]>((get) => ({
    queryKey: ['external_buyers'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/buyers/`);
        return response.data;
    },
}));

export const externalSuppliersAtom = atomWithSuspenseQuery<API.ExternalSupplier[]>((get) => ({
    queryKey: ['external_suppliers'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/suppliers/`);
        return response.data;
    },
}));

export const buyerTypesAtom = atomWithSuspenseQuery<API.BuyerType[]>((get) => ({
    queryKey: ['buyers/types'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/buyers/types`);
        return response.data;
    },
}));

export const supplierTypesAtom = atomWithSuspenseQuery<API.SupplierType[]>((get) => ({
    queryKey: ['suppliers/types'],
    queryFn: async ({queryKey}) => {
        const response = await axiosApiInstance.get(`/api/suppliers/types`);
        return response.data;
    },
}));


export const filterSearchAtom = atomWithHash<string | undefined>('filterSearch', undefined);

// +Unified customer search implementation
export const combinedCustomersOptionsAtom = atom(async (get) => {
    const {data: customers} = await get(customersAtom);
    const {data: accountingCustomers} = await get(accountingCustomersAtom);
    const {data: externalBuyers} = await get(externalBuyersAtom);

    let options: any[] = [];
    if (customers) {
        options = [...options,
            {
                label: 'LTD',
                options: customers.map((customer) => ({
                    // label: customer.name,
                    label: `${customer.name} (LTD${customer.id})`,
                    // value: customer.id,
                    value: JSON.stringify({buyer_type_id: 1, customer_id: customer.id})
                }))
            }
        ];
    }
    if (accountingCustomers) {
        options = [...options,
            {
                label: 'IKE',
                options: accountingCustomers.map((customer) => ({
                    // label: customer.name,
                    label: `${customer.name} (IKE${customer.id})`,
                    // value: customer.id,
                    value: JSON.stringify({buyer_type_id: 2, accounting_customer_id: customer.id})
                }))
            }
        ];
    }
    if (externalBuyers) {
        options = [...options,
            {
                label: 'External Buyer',
                options: externalBuyers.map((customer) => ({
                    // label: customer.name,
                    label: `${customer.name} (EXT${customer.id})`,
                    // value: customer.id,
                    value: JSON.stringify({buyer_type_id: 3, external_buyer_id: customer.id})
                }))
            }
        ];
    }

    return options;
});

export const combinedSuppliersOptionsAtom = atom(async (get) => {
    const {data: customers} = await get(customersAtom);
    const {data: accountingCustomers} = await get(accountingCustomersAtom);
    const {data: externalSuppliers} = await get(externalSuppliersAtom);

    let options: any[] = [];
    if (customers) {
        options = [...options,
            {
                label: 'LTD',
                options: customers.map((customer) => ({
                    // label: customer.name,
                    label: `${customer.name} (LTD${customer.id})`,
                    // value: customer.id,
                    value: JSON.stringify({supplier_type_id: 1, supplier_customer_id: customer.id})
                }))
            }
        ];
    }
    if (accountingCustomers) {
        options = [...options,
            {
                label: 'IKE',
                options: accountingCustomers.map((customer) => ({
                    // label: customer.name,
                    label: `${customer.name} (IKE${customer.id})`,
                    // value: customer.id,
                    value: JSON.stringify({supplier_type_id: 2, supplier_accounting_customer_id: customer.id})
                }))
            }
        ];
    }
    if (externalSuppliers) {
        options = [...options,
            {
                label: 'External',
                options: externalSuppliers.map((customer) => ({
                    // label: customer.name,
                    label: `${customer.name} (EXT${customer.id})`,
                    // value: customer.id,
                    value: JSON.stringify({supplier_type_id: 3, supplier_external_id: customer.id})
                }))
            }
        ];
    }

    return options;
});
import {ActionType, ProDescriptions} from '@ant-design/pro-components';
import * as React from 'react';
import {useContext, useRef} from 'react';
import {GlobalStateContext} from "../../context/GlobalContext";
import {Button, Typography} from "antd";
// import moment, {Moment} from "moment";
import {useParams} from "react-router-dom";
import axiosApiInstance from "../../api/axiosClient";
import dayjs, {Dayjs} from 'dayjs';
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

type Props = {
    day: Dayjs
    own_material_id?: number
    cost_id: number
    cost_type: string
    start_date: string
    hours?: number
    project_id?: number
};

export const LogDeliverableMaterialUtilization = (props: Props) => {
    const {materialsUtilizationTableRef, materialsUtilizationGlobalTableRef} = useContext(GlobalStateContext);
    const routerParams = useParams();
    const ref = useRef<ActionType>();

    // if (props.cost_id==325)
    // console.log(
    //     props.week,
    //     // props.week.utcOffset(0, true),
    //     props.start_date,
    //     moment(props.start_date),
    //     moment(props.start_date).startOf('isoWeek'),
    //     props.week.isBefore(moment(props.start_date).startOf('isoWeek'))
    // )

    return (
        <>
            {!props.day.isBefore(dayjs.utc(props.start_date)) &&
            <ProDescriptions
                actionRef={ref}
                dataSource={{
                    hours: props.hours,
                }}
                editable={{
                    type: "multiple",
                    onSave: async (key, record) => {
                        // console.log('saved', key, record, record.hours)
                        ref.current?.startEditable('hours')
                        let params = {
                            own_material_id: props.own_material_id,
                            execution_own_material_cost_id: props.cost_id,
                            // date: props.day.utcOffset(0, true).format()
                            date: props.day.format()
                        };
                        return axiosApiInstance.post(`/api/projects/${props.project_id}/material_utilization`, {
                            ...record,
                            ...params,
                        })
                            .then(res => {
                                // console.log(materialsUtilizationTableRef)
                                materialsUtilizationTableRef?.current?.reload()
                                materialsUtilizationGlobalTableRef?.current?.reload()
                                return true
                            }).catch(reason => false)
                    },
                    // actionRender: (row, config) => []
                }}
                columns={[
                    {
                        valueType: 'digit',
                        fieldProps: {
                            min: 0,
                            max: 24,
                            precision: 0,
                            defaultValue: 0,
                            formatter: (value: any) => `${value}hr`,
                            parser: (value: any) => value!.replace('hr', ''),
                        },
                        dataIndex: 'hours',
                        contentStyle: {justifyContent: 'center'},
                        render: (dom, entity) => {
                            return entity.hours !== undefined ?
                                <Typography.Text>{entity.hours}hr</Typography.Text> :
                                <Button onClick={() => ref.current?.startEditable('hours')}
                                        type={"dashed"}>Log</Button>
                        },
                        // editable: ref.current?.isEditable
                        editable: props.hours == undefined ? false : () => true
                    },

                ]}
            />
            }
        </>
    );
};

export default LogDeliverableMaterialUtilization
import * as React from 'react';
import {ModalForm} from "@ant-design/pro-components";
import OpportunityInfoLayout from "./OpportunityInfoLayout";
import {useNavigate, useParams} from "react-router-dom";
import {EditOutlined, MoreOutlined, PartitionOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useSalesContext} from "../../context/SalesContextProvider";

type Props = {};

export const OpportunityInfoModal = (props: Props) => {
    const routeParams = useParams<{ id: string }>();
    const navigate = useNavigate();
    const location = window.location; // can be used to get search params

    const {opportunitiesUnfiltered: opportunities} = useSalesContext();
    const {id: opportunity_id} = useParams();
    const target = opportunities.find((opp) => opportunity_id == `${opp.id}`);

    return (
        <ModalForm
            // title="Opportunity Info"
            modalProps={{
                // onCancel: () => setCompoundOppModalVisible!(false),
                // onCancel: () => navigate(`/sales/list`),
                onCancel: () => navigate({pathname: `/sales/list`, search: location.search}),
                // okButtonProps: {style: {display: 'none'}},
                cancelText: "Close",
                // style: {top: 40},
                okText: 'Details',

            }}
            // open={compoundOppModalVisible}
            open={true}
            // submitter={{submitButtonProps: {style: {display: 'none'}}}}
            submitter={{
                submitButtonProps: {icon: <MoreOutlined/>},
                onSubmit: () => navigate({pathname: `/sales/${routeParams.id}`}, {state: {background: location}}),
                render: (_, dom) =>

                    [
                        <Button key={'edit'} type={'text'} icon={<EditOutlined/>}
                                onClick={() => navigate(`/sales/list/${routeParams.id}/edit`)}>Edit</Button>,
                        <Button key={'to_compound'} type={'text'} icon={<PartitionOutlined/>}
                                disabled={!!target?.parent_id || target?.is_compound}
                                onClick={() => navigate({
                                    pathname: `/sales/list/to_compound/${routeParams.id}`,
                                    search: location.search,
                                }, {state: {from_path: location.pathname}})}>Convert to
                            Compound</Button>,
                        <Button key={'change_customer'} type={'text'} icon={<UserSwitchOutlined/>}
                                disabled={!!target?.parent_id}
                                onClick={() => navigate({
                                    pathname: `/sales/list/change_customer/${routeParams.id}`,
                                    search: location.search,
                                }, {state: {from_path: location.pathname}})}>Change Customer</Button>,
                        // ...dom
                        dom[1],
                        dom[0]
                    ],
            }}
        >
            {/*{compoundOppModalTarget && <OpportunityInfoLayout target={{id: +routeParams.id!}}/>}*/}
            <OpportunityInfoLayout
                // target={{id: +routeParams.id!}}
            />

        </ModalForm>

    );
};

export default OpportunityInfoModal;
import axiosApiInstance from '../../api/axiosClient';
import axios from 'axios';
import {notification} from 'antd';


const createNewExternalBuyer = async (values: any) => {
    console.log('values passed to createNewOpportunity(): ', values);
    try {
        console.log('object being passed to axios api: ', values);
        const {status} = await axiosApiInstance.post('/api/buyers/', values);
        return true;
    } catch (e: any) {
        if (!axios.isAxiosError(e)) {
            notification.error({message: e.message || 'Unexpected error'});
        }
        return false;
    }
};

export default createNewExternalBuyer;

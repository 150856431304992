import XLSX from 'sheetjs-style-v2';
import dayjs from 'dayjs';

/**
 * sheetjs library documentation: https://docs.sheetjs.com/
 */

const buildAndDownloadXLSX = (data: Array<any>) => {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {dateNF: 'dd/mm/yyyy', cellDates: true});
    const wb: XLSX.WorkBook = {Sheets: {Brands: ws}, SheetNames: ['Brands']};
    XLSX.writeFile(wb, `Brands_${dayjs().format('DD-MM-YYYY_HH-mm-ss')}.xlsx`, {
        bookType: 'xlsx',
        type: 'array'
    });
};

/**
 * get current opportunity data, then map it's values to an array of json objects and save them as excel using the
 * sheetsjs library and the buildAndDownloadXLSX helper function
 */
const exportToExcel = (opportunities: Array<API.Opportunity>) => {
    const EPOCH = '0001-01-01T00:00:00Z';

    buildAndDownloadXLSX(
        opportunities.map((o) => ({
            UID: o.project_pretty_id || o.project_pretty_id,
            Title: o.title,
            Description: o.description,
            Manager: o.employee.name,
            Type: o.is_compound ? 'Compound' : !!o.parent_id ? 'Child' : 'Single',
            'Parent UID': opportunities.find((p) => p.id === o.parent_id)?.project_pretty_id || '',
            'Customer Type': o.buyer_type.name,
            // CustomerID: o.buyer_type_id == 1 ? o.customer_id : o.buyer_type_id == 2 ? o.external_buyer_id : o.buyer_type_id == 3 ? o.accounting_customer_id : '',
            'Customer Name': o.buyer_type_id == 1 ? o.customer?.name : o.buyer_type_id == 3 ? o.external_buyer?.name : o.buyer_type_id == 3 ? o.accounting_customer?.name : '',
            'Unweighted Value': o.proposed_value_in_euro,
            'Weighted Value': o.po_value_in_euro,
            Phase: o.opportunity_phase.name,
            'Completion Percentage': o.opportunity_phase.completion_percentage,
            'Expected PO Date': dayjs(o.expected_po_date).toDate(),
            'Closure Date': o.closure_date === EPOCH ? '' : dayjs(o.closure_date).toDate(),
            // 'Implementation Start':
            //     o.expected_po_date === EPOCH ? '' : dayjs(o.expected_po_date).toDate(),
            // 'Implementation End':
            //     o.expected_closure_date === EPOCH ? '' : dayjs(o.expected_closure_date).toDate()
        }))
    )

};

export default exportToExcel;

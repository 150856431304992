import * as React from 'react';
import {useEffect, useState} from 'react';
import {ProTable, ProColumns} from "@ant-design/pro-table";
import {useSalesContext} from "../../context/SalesContextProvider";
import {LightFilter, ProCard, ProFormDatePicker, ProList} from "@ant-design/pro-components";
import dayjs, {Dayjs} from "dayjs";
import countFormatter from "../../util/count_formatter";
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";
import {Popover, Progress, Space, Tooltip, Typography} from "antd";
import {Link, useLocation} from "react-router-dom";
import {BuildOutlined, CalendarOutlined, DashboardOutlined, EuroOutlined} from "@ant-design/icons";
import {blue} from '@ant-design/colors';
import shortenNameWithRegex from "../../util/name_shortener";
import './SalesValidationTable.css'

type Props = {};

type OpportunitiesSummaryDatum = {
    month?: string;
    [phaseKey: string]: any;
    totalUnweightedValue: number;
    totalWeightedValue: number;
    totalNumberOfOpportunities: number;
    opportunity_ids?: number[];
};

const SalesValidationTable = (props: Props) => {
// this component will be used to display the summary table on the sales page
    // on the top, it will display a year picker
    // below that, it will display a table with the following columns:
    // 1. month
    // 2. total unweighted value
    // 3. total weighted value
    // 4. total number of opportunitiesFilteredOnly

    const {opportunitiesFilteredOnly} = useSalesContext()
    const {opportunityPhases} = useSalesAuxDataContext()

    const [year, setYear] = useState<Dayjs | null>(dayjs());
    const [columns, setColumns] = useState<ProColumns<any>[]>([]);
    const location = useLocation();

    useEffect(() => {

        let columns: ProColumns<any>[] = [
            {
                title: 'Month',
                dataIndex: 'month',
                key: 'month',
                width: 100,
                fixed: 'left',
                sorter: (a: any, b: any) => dayjs(a.month, 'YYYY-MM').isBefore(dayjs(b.month, 'YYYY-MM')) ? -1 : 1,
                render: (text: any, record: any) => {
                    return <span>{dayjs(record.month, 'YYYY-MM').format('MMMM YYYY')}</span>
                },
                align: 'center',
                defaultSortOrder: "ascend"
            },
        ]

        opportunityPhases.forEach((opportunityPhase) => {
            columns.push({
                title: opportunityPhase.name,
                dataIndex: `phase${opportunityPhase.id}`,
                // dataIndex: 'opportunityPhaseKey',
                // key: opportunityPhase.id,
                width: 100,
                // sorter: (a: any, b: any) => a.[`phase${opportunityPhase.id}`] - b[`phase${opportunityPhase.id}`],
                render: (text: any, record: any) => {
                    if (!record[`phase${opportunityPhase.id}`]) {
                        return <span>-</span>
                    }
                    let res = JSON.parse(record[`phase${opportunityPhase.id}`]) as Partial<OpportunitiesSummaryDatum>
                    return (
                        <div className='sales-validation-box'
                             style={{margin: 8}}
                            // style={{margin: 8, width: '100%'}}
                        >
                            <Popover
                                overlayInnerStyle={{
                                    padding: 0,
                                }}
                                // open={record.month === dayjs('2023-01').format('YYYY-MM')}
                                placement={'right'}
                                content={() => {
                                    return (<ProList
                                        pagination={{defaultPageSize: 6, hideOnSinglePage: true}}
                                        dataSource={opportunitiesFilteredOnly.filter((opportunity) => {
                                            return res.opportunity_ids?.includes(opportunity.id)
                                        })}
                                        rowKey="id"
                                        className={'sales-validation-popover-list'}
                                        metas={{
                                            // actions: {
                                            //     render: (dom, entity) => {
                                            //         return [
                                            //             (!entity.is_compound && !controlled_by_compound) && (
                                            //                 <Link
                                            //                     key="advance"
                                            //                     to={{
                                            //                         pathname: `advance/${entity.id}`,
                                            //                         search: location.search,
                                            //                     }}
                                            //                     state={{background: location}}
                                            //                 >
                                            //                     <Tooltip title={'Advance'} placement={'bottom'}>
                                            //                         <Button
                                            //                             type="link"
                                            //                             disabled={!isCurrent || entity.opportunity_phase.completion_percentage === 0}
                                            //                             icon={<ForwardOutlined/>}
                                            //                         />
                                            //                     </Tooltip>
                                            //                 </Link>
                                            //             ),
                                            //             (!entity.is_compound && !controlled_by_compound) && (
                                            //                 <Link
                                            //                     key="case"
                                            //                     to={`/projects/by_pretty_id/${entity.project_pretty_id?.replaceAll('/', '_')}`}
                                            //                 >
                                            //                     <Tooltip title={'Case'} placement={'bottom'}>
                                            //                         <Button type="link" icon={<FolderOutlined/>}/>
                                            //                     </Tooltip>
                                            //                 </Link>
                                            //             ),
                                            //             (!entity.is_compound && controlled_by_compound) && (
                                            //                 <Link
                                            //                     key="advance_compound"
                                            //                     to={{
                                            //                         pathname: `../advance/${entity.id}`,
                                            //                         search: location.search,
                                            //                     }}
                                            //                     state={{background: location, from_path: location.pathname}}
                                            //                 >
                                            //                     <Tooltip title={'Advance'} placement={'bottom'}>
                                            //                         <Button
                                            //                             type="link"
                                            //                             disabled={!isCurrent || entity.opportunity_phase.completion_percentage === 0}
                                            //                             icon={<ForwardOutlined/>}
                                            //                         />
                                            //                     </Tooltip>
                                            //                 </Link>
                                            //             ),
                                            //             (!entity.is_compound && controlled_by_compound) && (
                                            //                 <Link
                                            //                     key="case_compound"
                                            //                     to={`/projects/by_pretty_id/${entity.project_pretty_id?.replaceAll('/', '_')}`}
                                            //                 >
                                            //                     <Tooltip title={'Case'} placement={'bottom'}>
                                            //                         <Button type="link" icon={<FolderOutlined/>}/>
                                            //                     </Tooltip>
                                            //                 </Link>
                                            //             ),
                                            //             (!entity.is_compound && controlled_by_compound) && (
                                            //                 <Link
                                            //                     key="delete_compound"
                                            //                     to={{
                                            //                         pathname: `../delete/${entity.id}`,
                                            //                         search: location.search,
                                            //                     }}
                                            //                     state={{background: location, from_path: location.pathname}}
                                            //                 >
                                            //                     <Tooltip title={'Delete'} placement={'bottom'}>
                                            //                         <Button type="link" danger icon={<DeleteOutlined/>}/>
                                            //                     </Tooltip>
                                            //                 </Link>
                                            //             ),
                                            //             (!controlled_by_compound) && (
                                            //                 <Link
                                            //                     key="delete"
                                            //                     to={{
                                            //                         pathname: `delete/${entity.id}`,
                                            //                         search: location.search,
                                            //                     }}
                                            //                     state={{background: location}}
                                            //                 >
                                            //                     <Tooltip title={'Delete'} placement={'bottom'}>
                                            //                         <Button type="link" danger icon={<DeleteOutlined/>}/>
                                            //                     </Tooltip>
                                            //                 </Link>
                                            //             ),
                                            //         ].filter((link) => link)
                                            //     }
                                            // },
                                            // title: {
                                            //     // dataIndex: 'title',
                                            //     render: (dom, entity) => {
                                            //         return (
                                            //             `Title`
                                            //         )
                                            //     }
                                            // },
                                            content: {
                                                render: (dom, entity) => {
                                                    return (
                                                        <Link
                                                            to={{
                                                                pathname: `../list/${entity.id}`,
                                                                search: location.search
                                                            }}
                                                        >
                                                            <ProCard
                                                                gutter={12}
                                                                ghost
                                                                style={{justifyContent: 'start'}}
                                                                title={
                                                                    <Typography.Paragraph
                                                                        ellipsis={{rows: 1, tooltip: true}}
                                                                        style={{marginBottom: 4, fontSize: 13}}
                                                                    >
                                                                        {shortenNameWithRegex(entity.customer?.name || entity.external_buyer?.name)} - {entity.title}
                                                                    </Typography.Paragraph>
                                                                }
                                                            >
                                                                <ProCard
                                                                    style={{
                                                                        width: 'max-content',
                                                                    }}
                                                                    ghost
                                                                >
                                                                    <Space>
                                                                        <Tooltip
                                                                            title={'Expected PO Date'}><CalendarOutlined/></Tooltip>
                                                                        {entity.expected_po_date !== '0001-01-01T00:00:00Z'
                                                                            ? new Date(entity.expected_po_date!).toLocaleDateString('el')
                                                                            : 'No Date'}
                                                                    </Space>
                                                                </ProCard>
                                                                <ProCard
                                                                    style={{
                                                                        width: 'max-content',
                                                                        // padding: '0 12px'
                                                                    }}
                                                                    ghost
                                                                >
                                                                    <Space>
                                                                        <Tooltip
                                                                            title={'Unweighted'}><EuroOutlined/></Tooltip>
                                                                        {countFormatter.format(entity.proposed_value_in_euro!)}
                                                                    </Space>


                                                                </ProCard>
                                                                <ProCard
                                                                    style={{
                                                                        width: 'max-content',
                                                                        // padding: '0 12px'
                                                                    }}
                                                                    ghost
                                                                >
                                                                    <Space>
                                                                        <Tooltip title={'Weighted'}><DashboardOutlined/></Tooltip>
                                                                        {countFormatter.format(entity.po_value_in_euro!)}
                                                                    </Space>
                                                                </ProCard>
                                                                <ProCard
                                                                    style={{
                                                                        width: 'max-content',
                                                                        // padding: '0 12px'
                                                                    }}
                                                                    ghost
                                                                >
                                                                    <Space>
                                                                        {/*<div/>*/}
                                                                        <Tooltip title={entity.opportunity_phase.name}>
                                                                            <Progress
                                                                                style={{
                                                                                    width: 100,
                                                                                }}
                                                                                status={
                                                                                    entity.opportunity_phase.completion_percentage === 0
                                                                                        ? 'exception'
                                                                                        : undefined
                                                                                }
                                                                                percent={entity.opportunity_phase.completion_percentage * 100}
                                                                            />
                                                                        </Tooltip>
                                                                    </Space>

                                                                </ProCard>
                                                                {entity.parent_id &&
                                                                    <ProCard
                                                                        style={{
                                                                            width: 'max-content',
                                                                            // padding: '0 12px'
                                                                        }}
                                                                        ghost
                                                                    >
                                                                        <Space>
                                                                            <Tooltip
                                                                                title={'Part of Compound Opportunity'}>
                                                                                <BuildOutlined
                                                                                    style={{color: blue.primary}}
                                                                                />
                                                                            </Tooltip>
                                                                        </Space>
                                                                    </ProCard>
                                                                }
                                                            </ProCard>
                                                        </Link>
                                                    )
                                                }
                                            }
                                        }}
                                    />)
                                }}

                            >
                                <Space direction={"vertical"} size={0}>
                                    <Space>
                                        <Tooltip title={'Unweighted'}><EuroOutlined/></Tooltip>
                                        <Typography.Text strong
                                                         style={{fontSize: 13}}>
                                            {countFormatter.format(res.totalUnweightedValue!)}
                                        </Typography.Text>
                                    </Space>
                                    <Space>
                                        <Tooltip title={'Weighted'}><DashboardOutlined/></Tooltip>
                                        <Typography.Text strong
                                                         type={res.totalUnweightedValue! > res.totalWeightedValue! && dayjs(record.month, 'YYYY-MM').utc().isBefore(dayjs().utc()) ? 'warning' : undefined}
                                                         style={{fontSize: 13}}>
                                            {countFormatter.format(res.totalWeightedValue!)}
                                        </Typography.Text>
                                    </Space>
                                    {/*<Divider/>*/}
                                    <Typography.Text style={{fontSize: 11}}
                                                     type={"secondary"}>{countFormatter.format(res.totalNumberOfOpportunities!)} opportunities</Typography.Text>
                                    {/*<Typography.Text strong style={{fontSize: 11}} type={"secondary"}>{res.opportunity_ids!.toString()}</Typography.Text>*/}
                                </Space>
                            </Popover>
                        </div>
                    )
                },
                search: false,
                align: 'right'
            })
        })

        console.log(columns, year)

        setColumns(columns)

    }, [year]);


    const [data, setData] = useState<OpportunitiesSummaryDatum[]>([]);

    useEffect(() => {
        // the data will be an array of opportunitiesFilteredOnly of the selected year, grouped by month and opportunity phase
        // for each month/phase, we will calculate the total unweighted value, total weighted value, and total number of opportunitiesFilteredOnly
        const groupedOpportunities = opportunitiesFilteredOnly.filter((opportunity) => !opportunity.is_compound).reduce((result: {
            [date: string]: { [phase: string]: OpportunitiesSummaryDatum }
        }, opportunity) => {

            const closureDate = dayjs(opportunity.closure_date);
            const expectedPoDate = dayjs(opportunity.expected_po_date);

            // "forecast to complete"
            if (!opportunity.opportunity_phase.status.completed && expectedPoDate.year() < year!.year()) {
                return result;
            }
            // else ("up-to-date" forecast)
            const fiscalMonthForOpportunity = opportunity.opportunity_phase.status.completed ? closureDate : expectedPoDate;
            // console.log(fiscalMonthForOpportunity.format('YYYY-MM'));
            // if (fiscalMonthForOpportunity.year() == 1) {
            // }
            const dateKey = fiscalMonthForOpportunity.format('YYYY-MM');
            const phaseKey = `phase${opportunity.opportunity_phase_id}`;
            if (!result[dateKey]) {
                result[dateKey] = {
                    [phaseKey]: {
                        totalNumberOfOpportunities: 1,
                        totalUnweightedValue: opportunity.proposed_value_in_euro!,
                        totalWeightedValue: opportunity.po_value_in_euro!,
                        opportunity_ids: [opportunity.id]
                    }
                }
            } else {

                if (!result[dateKey][phaseKey]) {
                    result[dateKey][phaseKey] = {
                        totalNumberOfOpportunities: 1,
                        totalUnweightedValue: opportunity.proposed_value_in_euro!,
                        totalWeightedValue: opportunity.po_value_in_euro!,
                        opportunity_ids: [opportunity.id]
                    }

                } else {
                    result[dateKey][phaseKey].totalNumberOfOpportunities += 1;
                    result[dateKey][phaseKey].totalUnweightedValue += opportunity.proposed_value_in_euro!;
                    result[dateKey][phaseKey].totalWeightedValue += opportunity.po_value_in_euro!;
                    result[dateKey][phaseKey].opportunity_ids!.push(opportunity.id);
                }
            }

            return result;
        }, {});

        console.log({groupedOpportunities})

        // const data = Object.values(groupedOpportunities);
        console.log(Object.entries(groupedOpportunities))
        // flatten the object
        let data: OpportunitiesSummaryDatum[] = [];
        Object.entries(groupedOpportunities).map((groupedOpportunity) => {
            let datum: OpportunitiesSummaryDatum = {
                month: groupedOpportunity[0],
                totalNumberOfOpportunities: 0,
                totalUnweightedValue: 0,
                totalWeightedValue: 0,
                opportunity_ids: []
            }
            Object.entries(groupedOpportunity[1]).map((opportunity) => {
                // console.log({opportunity})
                datum[opportunity[0]] = JSON.stringify(opportunity[1])
            })
            data.push(datum)
        })
        console.log({data})

        data.sort((a, b) => {
            return dayjs(a.month, 'YYYY-MM').isBefore(dayjs(b.month, 'YYYY-MM')) ? -1 : 1;
        })

        if (!year) {
            setData(data);
        } else {
            setData(data.filter((opportunity) => {
                return dayjs(opportunity.month, 'YYYY-MM').year() === year?.year();
            }))
        }


    }, [opportunitiesFilteredOnly, columns])

    return (
        <div>
            {/* table */}
            <ProTable
                columns={columns}
                dataSource={data}
                pagination={false}
                search={false}
                options={false}
                // toolBarRender={false}
                rowKey="id"
                tableStyle={{width: '100%'}}
                size="small"
                scroll={{x: 'max-content'}}
                style={{marginTop: 16}}
                // search={{}}
                toolbar={{
                    style: {
                        display: 'flex',
                        alignItems: 'start',
                    },
                    filter: (
                        <LightFilter>
                            <ProFormDatePicker.Year
                                name="year"
                                label="Year"
                                fieldProps={{
                                    style: {
                                        width: '100%',
                                    },
                                    value: year,
                                    onChange: (value) => {
                                        if (value)
                                            setYear(value);
                                    }
                                }}
                                placeholder="Select year"
                                help={'Select fiscal year'}
                                allowClear={false}
                            />
                        </LightFilter>
                    ),
                }}
                summary={(pageData) => {

                    let mapPhaseKeyToTotals: {
                        [string: string]: {
                            unweightedValue: number, weightedValue: number, numberOfOpportunities: number,
                            totalForecastToCompleteUnweightedValue: number,
                            totalForecastToCompleteWeightedValue: number,
                            totalForecastToCompleteNumberOfOpportunities: number
                        }
                    } = {};

                    opportunityPhases.forEach((opportunityPhase) => {
                        mapPhaseKeyToTotals[`phase${opportunityPhase.id}`] = {
                            unweightedValue: 0,
                            weightedValue: 0,
                            numberOfOpportunities: 0,
                            totalForecastToCompleteUnweightedValue: 0,
                            totalForecastToCompleteWeightedValue: 0,
                            totalForecastToCompleteNumberOfOpportunities: 0
                        }
                    })
                    // console.log({mapPhaseKeyToTotals})
                    console.log({pageData})
                    pageData.forEach((pageDatum) => {
                            // console.log({rest})
                            opportunityPhases.forEach((opportunityPhase) => {
                                const phaseKey = `phase${opportunityPhase.id}`;
                                if (!pageDatum[phaseKey]) {
                                    return;
                                }
                                const phaseValue = JSON.parse(pageDatum[phaseKey]) as Partial<OpportunitiesSummaryDatum>;

                                // console.log({phaseValue})
                                mapPhaseKeyToTotals[phaseKey].unweightedValue += phaseValue.totalUnweightedValue || 0;
                                mapPhaseKeyToTotals[phaseKey].weightedValue += phaseValue.totalWeightedValue || 0;
                                mapPhaseKeyToTotals[phaseKey].numberOfOpportunities += phaseValue.totalNumberOfOpportunities || 0;
                            })
                        }
                    )


                    opportunitiesFilteredOnly.filter((opportunity) => !opportunity.is_compound).forEach((opportunity) => {
                        const expectedPoDate = dayjs(opportunity.expected_po_date);
                        // "forecast to complete"
                        if (!opportunity.opportunity_phase.status.completed && expectedPoDate.year() < year!.year()) {
                            // console.log('forecast to complete', opportunity)
                            const phaseKey = `phase${opportunity.opportunity_phase_id}`;
                            mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteUnweightedValue += opportunity.proposed_value_in_euro!;
                            mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteWeightedValue += opportunity.po_value_in_euro!;
                            mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteNumberOfOpportunities += 1;
                        }
                    })


                    return (
                        <>
                            <ProTable.Summary.Row>
                                <ProTable.Summary.Cell index={0} align={"center"}>Forecast To
                                    Complete</ProTable.Summary.Cell>
                                {opportunityPhases.map((opportunityPhase, index) => {
                                        const phaseKey = `phase${opportunityPhase.id}`;
                                        return (
                                            <ProTable.Summary.Cell
                                                index={index + 1}
                                                align={"right"}>
                                                <Space direction={"vertical"} size={0}>
                                                    {/*{countFormatter.format(mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteNumberOfOpportunities)}*/}
                                                    {/*{countFormatter.format(mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteUnweightedValue)}*/}
                                                    {/*{countFormatter.format(mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteWeightedValue)}*/}
                                                    <Space>
                                                        <Tooltip title={'Unweighted'}><EuroOutlined/></Tooltip>
                                                        <Typography.Text
                                                            // strong
                                                            style={{fontSize: 13}}>
                                                            {countFormatter.format(mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteUnweightedValue)}
                                                        </Typography.Text>
                                                    </Space>
                                                    <Space>
                                                        <Tooltip title={'Weighted'}><DashboardOutlined/></Tooltip>
                                                        <Typography.Text
                                                            // strong
                                                            style={{fontSize: 13}}>
                                                            {countFormatter.format(mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteWeightedValue)}
                                                        </Typography.Text>
                                                    </Space>
                                                    <Typography.Text style={{fontSize: 11}}
                                                                     type={"secondary"}>{countFormatter.format(mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteNumberOfOpportunities)} opportunities</Typography.Text>
                                                </Space>
                                            </ProTable.Summary.Cell>
                                        )
                                    }
                                )}
                            </ProTable.Summary.Row>
                            <ProTable.Summary.Row>
                                <ProTable.Summary.Cell index={0} align={"center"}>Total</ProTable.Summary.Cell>
                                {opportunityPhases.map((opportunityPhase, index) => {
                                        const phaseKey = `phase${opportunityPhase.id}`;
                                        return (
                                            <ProTable.Summary.Cell
                                                index={index + 1}
                                                align={"right"}>
                                                <Space direction={"vertical"} size={0}>
                                                    {/*{countFormatter.format(mapPhaseKeyToTotals[phaseKey].numberOfOpportunities + mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteNumberOfOpportunities)}*/}
                                                    {/*{countFormatter.format(mapPhaseKeyToTotals[phaseKey].unweightedValue + mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteUnweightedValue)}*/}
                                                    {/*{countFormatter.format(mapPhaseKeyToTotals[phaseKey].weightedValue + mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteWeightedValue)}*/}
                                                    <Space>
                                                        <Tooltip
                                                            title={'Unweighted'}><EuroOutlined/></Tooltip>
                                                        <Typography.Text
                                                            // strong
                                                            style={{fontSize: 13}}>
                                                            {countFormatter.format(mapPhaseKeyToTotals[phaseKey].unweightedValue + mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteUnweightedValue)}
                                                        </Typography.Text>
                                                    </Space>
                                                    <Space>
                                                        <Tooltip title={'Weighted'}><DashboardOutlined/></Tooltip>
                                                        <Typography.Text
                                                            // strong
                                                            style={{fontSize: 13}}>
                                                            {countFormatter.format(mapPhaseKeyToTotals[phaseKey].weightedValue + mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteWeightedValue)}
                                                        </Typography.Text>
                                                    </Space>
                                                    <Typography.Text style={{fontSize: 11}}
                                                                     type={"secondary"}>{countFormatter.format(mapPhaseKeyToTotals[phaseKey].numberOfOpportunities + mapPhaseKeyToTotals[phaseKey].totalForecastToCompleteNumberOfOpportunities)} opportunities</Typography.Text>
                                                </Space>
                                            </ProTable.Summary.Cell>
                                        )
                                    }
                                )}
                            </ProTable.Summary.Row>
                        </>
                    );
                }}

                // the search bar must contain only one year picker

            />


        </div>
    );
};

export default SalesValidationTable;
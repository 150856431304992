import {Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {useSalesContext} from '../../context/SalesContextProvider';
import {Link, useLocation} from 'react-router-dom';

const NewOpportunityButton = () => {
    const {isCurrent, isLoading} = useSalesContext();
    const location = useLocation();
    return (
        <Link to={{pathname: './new', search: location.search}} state={{background: location,}}>
            <Button
                // type='dashed'
                type='primary'
                size='middle'
                disabled={!isCurrent}
                loading={isLoading}
                // style={{ width: '100%', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                style={{marginRight: 4}}
                // icon={<PlusSquareOutlined />}>
                icon={<PlusOutlined/>}>
                New
                {/*Opportunity*/}
            </Button>
        </Link>
    );
};

export default NewOpportunityButton;

import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormMoney,
    ProFormSelect,
    ProFormText
} from "@ant-design/pro-components";
import {Form} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import axiosApiInstance from "../../../../api/axiosClient";
import {GlobalStateContext} from "../../../../context/GlobalContext";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

type Props = {};

const NewInvoiceIncomingGlobal = (props: Props) => {
    const [form] = Form.useForm<API.LoggedInvoiceIncoming>();
    const navigate = useNavigate();
    const routerParams = useParams();
    // const {executionDeliverablesListRef, executionWBSVersionRef} = useContext(GlobalStateContext);
    const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const {invoicesIncomingGlobalListRef, taxCategories} = useContext(GlobalStateContext);
    const [projectsData, setProjectsData] = useState<API.Project[] | undefined>(undefined);
    // const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(undefined);

    const projectValue = Form.useWatch('project_id', form);

    const fetchWbsVersionData = async () => {
        try {
            const response = await axiosApiInstance.get<API.ExecutionWBSVersion>(`/api/projects/${projectValue}/structures/executions/active`)
            console.log(response.data)
            setWbsVersionData(response.data);
        } catch (e) {
            console.log('catch error')
            setWbsVersionData(undefined)
        }
    };

    const fetchProjectsData = async () => {
        try {
            // setLoading(true)
            const response = await axiosApiInstance.get<API.Project[]>(`/api/projects/onexecution`)
            console.log(response.data)
            setProjectsData(response.data);
            // setLoading(false)

            if (response.data.length > 0) {
                // setSelectedProjectId(response.data[0].id)
                form.setFieldsValue({project_id: response.data[0].id})
            }
        } catch (e) {
            console.log('catch error')
            setProjectsData(undefined)
            // setLoading(false)
        }
    };

    useEffect(() => {
        // fetchProjectsData().then(fetchWbsVersionData);
        fetchProjectsData();
    }, []);

    useEffect(() => {
        // console.log({projectValue})
        if (projectValue)
            fetchWbsVersionData();
    }, [projectValue]);

    return (
        <ModalForm<API.LoggedInvoiceIncoming>
            title="Create a new Invoice"
            open={true}
            form={form}
            autoFocusFirstInput
            // width={"100%"}
            modalProps={{
                destroyOnClose: true,
                okText: 'Create',
                onCancel: () => navigate(`/invoices/incoming`),
                style: {top: 30},
            }}
            // submitTimeout={2000}
            onFinish={(values) => {
                // values.execution_wbs_version_id = +routerParams.executionWbsVersionId!
                console.log({values});
                // return Promise.resolve()
                return axiosApiInstance.post<API.LoggedInvoiceIncoming>('/api/invoices/incoming/', values)
                    .then(res => {
                        invoicesIncomingGlobalListRef?.current?.reload()
                        navigate(`/invoices/incoming`)
                        return true
                    }).catch(reason => false)
            }}
            validateMessages={{required: "${label} is required!"}}
            // initialValues={{
            //     dateRange: []
            // }}
        >
            <ProFormDigit
                name="id"
                hidden={true}
            />

            <ProFormGroup label={'Basic Information'}>
                <ProFormSelect
                    label={'Project'}
                    name={'project_id'}
                    width={'lg'}
                    rules={[{required: true}]}
                    placeholder={'Select Project'}
                    options={projectsData?.map(value => ({
                        label: `${value.title} (${value.project_pretty_id})`,
                        value: value.id
                    }))}
                    // style={{width: 350}} placeholder={'Select Project'}
                    /*fieldProps={{
                        value: selectedProjectId,
                        onSelect: (value: number) => {
                            setSelectedProjectId(value)
                        }
                    }}*/
                    // initialValue={selectedProjectId}
                    // onSelect={(value: number) => {
                    //     setSelectedProjectId(value)
                    // }}

                />
                <ProFormGroup>

                    <ProFormText
                        width="sm"
                        name="invoice_number"
                        label="Invoice Number"
                        rules={[{required: true}]}
                    />
                    <ProFormDatePicker name={"date"} label={"Date Issued"}
                                       rules={[{required: true}]}
                                       normalize={(value) => {
                                           return value.toISOString()
                                           // return new Date(value).toISOString()
                                       }}
                    />
                    <ProFormDatePicker name={"due_date"} label={"Due Date"}
                                       rules={[{required: true}]}
                                       normalize={(value) => {
                                           return value.toISOString()
                                           // return new Date(value).toISOString()
                                       }}
                                       fieldProps={{
                                           presets: [
                                               {label: 'In 14 days', value: dayjs.utc().add(14, 'd').startOf('d')},
                                               {label: 'In 30 days', value: dayjs.utc().add(30, 'd').startOf('d')},
                                               {label: 'In 60 days', value: dayjs.utc().add(60, 'd').startOf('d')},
                                               {label: 'In 90 days', value: dayjs.utc().add(90, 'd').startOf('d')},
                                           ]
                                       }}
                    />

                </ProFormGroup>

                <ProFormGroup>


                    <ProFormMoney
                        name="cost"
                        label={'Cost'}
                        customSymbol={'€'}
                        fieldProps={{
                            numberFormatOptions: {currency: 'EUR'}
                        }}
                        rules={[{required: true}]}
                        width={"xs"}
                    />

                    <ProFormSelect
                        options={taxCategories?.map(i => ({label: i.label, value: i.id}))}
                        width="xs"
                        name="tax_category_id"
                        label="Tax category"
                        // formItemProps={{}}
                        fieldProps={{
                            onSelect: (tax_category_id: number | undefined) => {
                                // console.log('SELECT')
                                form.setFieldValue(['tax_percentage'], taxCategories?.find(value => value.id == tax_category_id)?.tax_percentage)
                                form.setFieldValue(['tax_category'], taxCategories?.find(value => value.id == tax_category_id))
                            }
                        }}
                        rules={[{required: true}]}
                    />

                    <ProFormItem
                        name="tax_percentage"
                        hidden={true}
                    />

                    <ProFormItem
                        name="tax_category"
                        hidden={true}
                    />

                    {/*<ProFormMoney*/}
                    {/*    name="cost_incl_vat"*/}
                    {/*    label={'Cost Incl. VAT'}*/}
                    {/*    customSymbol={'€'}*/}
                    {/*    fieldProps={{*/}
                    {/*        numberFormatOptions: {currency: 'EUR'}*/}
                    {/*    }}*/}
                    {/*    rules={[{required: true}]}*/}
                    {/*    width={"xs"}*/}
                    {/*/>*/}

                    <ProFormDependency
                        name={[['cost'], ['tax_percentage']]}>
                        {({cost, tax_percentage}) => {
                            // console.log("cost", effort, hourly_rate)
                            form.setFieldValue('cost_incl_vat', (cost || 0) + (cost || 0) * (tax_percentage || 0))

                            return (
                                <ProFormMoney
                                    name="cost_incl_vat"
                                    label={'Cost Incl. VAT'}
                                    customSymbol={'€'}
                                    readonly
                                    fieldProps={{
                                        numberFormatOptions: {currency: 'EUR'}
                                    }}
                                    width={"xs"}
                                />
                            );
                        }}
                    </ProFormDependency>

                    {/*<ProFormRadio.Group*/}
                    {/*    valueEnum={{*/}
                    {/*        YES: 'Yes',*/}
                    {/*        NO: 'No',*/}
                    {/*        PARTIALLY: 'Partially',*/}
                    {/*    }}*/}
                    {/*    radioType={"button"}*/}
                    {/*    width="xl"*/}
                    {/*    name="paid"*/}
                    {/*    label="Paid"*/}
                    {/*    readonly*/}
                    {/*    rules={[{required: true}]}*/}
                    {/*/>*/}

                </ProFormGroup>
            </ProFormGroup>

            <ProFormGroup label={'Related Cost Center'}>
                <ProFormSelect
                    valueEnum={{
                        _execution_third_party_time_costs: 'Time',
                        _execution_third_party_material_costs: 'Material',
                    }}
                    width={"sm"}
                    name="cost_type"
                    label="Cost Type"
                    rules={[{required: true}]}
                    fieldProps={{onSelect: () => form.setFieldValue(['cost_id'], undefined)}}
                />

                <ProFormDependency name={[['cost_type']]}>
                    {({cost_type}) => {
                        if (cost_type === '_execution_third_party_time_costs') {
                            let options: any = []
                            wbsVersionData?.execution_deliverables?.map((d) => {
                                d.third_party_time_costs?.map((c) => {
                                    options.push({
                                        value: c.id,
                                        // value: JSON.stringify({deliverable_id: d.id, cost_id: c.id}),
                                        label: `${d.name} → ${c.description}`
                                    })
                                })
                            })
                            // console.log(wbsVersionData?.execution_deliverables)
                            return <ProFormSelect
                                options={options}
                                width={"md"}
                                name="cost_id"
                                label="Cost Center" // task
                                rules={[{required: true}]}
                                fieldProps={{
                                    onSelect: (cost_id: number) => {
                                        let costMeta: API.ThirdPartyCost
                                        // console.log(form.getFieldValue('cost_type'))
                                        if (form.getFieldValue('cost_type') === '_execution_third_party_time_costs') {
                                            wbsVersionData?.execution_deliverables?.map((d) => {
                                                d.third_party_time_costs?.map((c) => {
                                                    if (c.id == cost_id) {
                                                        costMeta = c
                                                        return
                                                    }
                                                })
                                            })
                                        } else {
                                            wbsVersionData?.execution_deliverables?.map((d) => {
                                                d.third_party_material_costs?.map((c) => {
                                                    if (c.id == cost_id) {
                                                        costMeta = c
                                                        return
                                                    }
                                                })
                                            })
                                        }
                                        // console.log(costMeta!)
                                        form.setFieldValue(['supplier_type_id'], costMeta!.supplier_type_id)
                                        if (costMeta!.supplier_type_id === 1) {
                                            form.setFieldValue(['supplier_customer_id'], costMeta!.supplier_customer_id)

                                            form.setFieldValue(['supplier_external_id'], null)
                                            form.setFieldValue(['external_supplier'], null)
                                        } else {
                                            form.setFieldValue(['supplier_external_id'], costMeta!.supplier_external_id)

                                            form.setFieldValue(['supplier_customer_id'], null)
                                            form.setFieldValue(['customer'], null)
                                        }
                                    }
                                }}
                            />
                        }
                        let options: any = []
                        wbsVersionData?.execution_deliverables?.map((d) => {
                            d.third_party_material_costs?.map((c) => {
                                options.push({
                                    value: c.id,
                                    // value: JSON.stringify({deliverable_id: d.id, cost_id: c.id}),
                                    label: `${d.name} → ${c.description}`
                                })
                            })
                        })
                        return <ProFormSelect
                            options={options}
                            width={"md"}
                            name="cost_id"
                            label="Cost Center" // task
                            rules={[{required: true}]}
                            fieldProps={{
                                onSelect: (cost_id: number) => {
                                    let costMeta: API.ThirdPartyCost
                                    // console.log(form.getFieldValue('cost_type'))
                                    if (form.getFieldValue('cost_type') === '_execution_third_party_time_costs') {
                                        wbsVersionData?.execution_deliverables?.map((d) => {
                                            d.third_party_time_costs?.map((c) => {
                                                if (c.id == cost_id) {
                                                    costMeta = c
                                                    return
                                                }
                                            })
                                        })
                                    } else {
                                        wbsVersionData?.execution_deliverables?.map((d) => {
                                            d.third_party_material_costs?.map((c) => {
                                                if (c.id == cost_id) {
                                                    costMeta = c
                                                    return
                                                }
                                            })
                                        })
                                    }
                                    // console.log(costMeta!)
                                    if (costMeta!.supplier_type_id === 1) {
                                        form.setFieldValue(['supplier_customer_id'], costMeta!.supplier_customer_id)

                                        form.setFieldValue(['supplier_external_id'], null)
                                        form.setFieldValue(['external_supplier'], null)
                                    } else {
                                        form.setFieldValue(['supplier_external_id'], costMeta!.supplier_external_id)

                                        form.setFieldValue(['supplier_customer_id'], null)
                                        form.setFieldValue(['customer'], null)
                                    }
                                }
                            }}
                        />
                    }}
                </ProFormDependency>

            </ProFormGroup>

            <ProFormGroup label={'Supplier'}>

                <ProFormSelect
                    request={() =>
                        axiosApiInstance.get<API.SupplierType[]>('/api/suppliers/types')
                            .then(res => {
                                return res.data.map(i => ({label: i.name, value: i.id}))
                            })
                    }
                    width={"sm"}
                    name="supplier_type_id"
                    label="Supplier Type"
                    rules={[{required: true}]}
                />

                <ProFormDependency name={[['supplier_type_id']]}>
                    {({supplier_type_id}) => {

                        // let costMeta: API.ThirdPartyCost
                        // if (form.getFieldValue('cost_type') === '_execution_third_party_time_costs') {
                        //     wbsVersionData?.execution_deliverables?.map((d) => {
                        //         d.third_party_time_costs?.map((c) => {
                        //             if (c.id == cost_id) {
                        //                 costMeta = c
                        //                 return
                        //             }
                        //         })
                        //     })
                        // } else {
                        //     wbsVersionData?.execution_deliverables?.map((d) => {
                        //         d.third_party_material_costs?.map((c) => {
                        //             if (c.id == cost_id) {
                        //                 costMeta = c
                        //                 return
                        //             }
                        //         })
                        //     })
                        // }

                        if (supplier_type_id === 1) {
                            form.setFieldValue(['supplier_external_id'], null)
                            form.setFieldValue(['external_supplier'], null)
                            return <ProFormSelect
                                request={() =>
                                    axiosApiInstance.get<API.Customer[]>('/api/customers/')
                                        .then(res => {
                                            return res.data.map(i => ({
                                                label: `${i.name} [${i.id}]`,
                                                value: i.id
                                            }))
                                        })
                                }
                                width={"md"}
                                name="supplier_customer_id"
                                label="Customer"
                                rules={[{required: true}]}
                                fieldProps={{
                                    showSearch: true, filterOption: (input, option) =>
                                        (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
                                }}
                            />
                        }
                        form.setFieldValue(['supplier_customer_id'], null)
                        form.setFieldValue(['customer'], null)
                        return (
                            <ProFormSelect
                                request={() =>
                                    axiosApiInstance.get<API.ExternalSupplier[]>('/api/suppliers/')
                                        .then(res => {
                                            // console.log(res.data)
                                            return res.data.map(i => ({label: i.name, value: i.id}))
                                        })
                                }
                                width="md"
                                name="supplier_external_id"
                                label="External Supplier"
                                rules={[{required: true}]}
                            />
                        );
                    }}
                </ProFormDependency>

            </ProFormGroup>

            {/*<ProFormText*/}
            {/*    name="description"*/}
            {/*    label={'Description'}*/}
            {/*/>*/}

            <ProFormGroup label={'Other'}>
                <ProFormText
                    width="xl"
                    name="file_url"
                    label="File URL"
                    // rules={[{required: true}]}
                />
            </ProFormGroup>

        </ModalForm>
    );
};

export default NewInvoiceIncomingGlobal;
import {useAtomValue} from "jotai/index";
import {projectsAfterFilterSortSearchAtom} from "../../store/projects";
import dayjs from "dayjs";
import currencyFormatter from "../../util/currency_formatter";
import XLSX from "sheetjs-style-v2";
import {Button, Tooltip} from "antd";
import * as React from "react";
import {FileExcelOutlined} from "@ant-design/icons";

export const ExportToExcel = () => {
    const projectsAfterFilterSortSearch = useAtomValue(projectsAfterFilterSortSearchAtom)

    // based on the columns of the table export the data to excel
    const buildAndDownloadXLSX = () => {
        let title: string = 'Cases_' + dayjs().format('YYYYMMDD') + '.xlsx'
        let data = projectsAfterFilterSortSearch.map((item, index) => {
            return {
                'Case ID': item.project_pretty_id,
                Title: item.title,
                'Customer type': item.buyer_type_id == 1 ? 'Existing (LTD)' : item.buyer_type_id == 2 ? 'Existing (IKE)' : 'External',
                'Customer ID': item.customer_id == null ? item.external_buyer_id == null ? item.accounting_customer_id : item.external_buyer_id : item.customer_id,
                'Customer name': item.customer_id != null ? item.customer.name : item.external_buyer_id != null ?
                    item.external_buyer.name : item.accounting_customer.name,
                Manager: item.employee.name,
                Status: item.status,
                'Exec. Cost': item.active_execution_cost,
                'Exec. Price': item.active_execution_price,
                'Exec. Margin': item.active_execution_price - item.active_execution_cost,
                'Created At': new Date(item.created_at).toLocaleDateString('el'),
                Tags: item.project_tags.map((tag) => tag.tag).join(';')
            }
        })

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {dateNF: 'dd/mm/yyyy', cellDates: true});
        const wb: XLSX.WorkBook = {Sheets: {Report: ws}, SheetNames: ['Report']};
        XLSX.writeFile(wb, title, {
            bookType: 'xlsx',
            type: 'array'
        });
    };

    return (
        <Tooltip title={'Export to Excel'}>
            <Button
                size={'large'}
                type={'dashed'}
                icon={<FileExcelOutlined style={{color: '#575757'}}/>}
                onClick={() => {
                    buildAndDownloadXLSX();
                }}
            />
        </Tooltip>
    );
}

export default ExportToExcel;
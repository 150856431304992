import {Input} from 'antd';
import React from 'react';
import {useSalesContext} from '../../context/SalesContextProvider';
import {useSearchParams} from 'react-router-dom';

const {Search} = Input;

type Props = {};

const SearchBar = () => {
    const {searchFilter, setSearchFilter} = useSalesContext();
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <Search
            placeholder='Search...'
            defaultValue={searchFilter}
            allowClear
            style={{width: 200}}
            onSearch={(value: string, event) => {
                event?.currentTarget.blur()
                // setSearchFilter(value);
                searchParams.set('search', value);
                setSearchParams(searchParams);
            }}
            // onChange={(event) => {
            //     if (event.target.value === '') {
            //         event?.currentTarget.blur()
            //     }
            // }}
        />
    );
};

const NameSearcher = (props: Props) => {
    return (
        // <Popover trigger='hover' placement='bottom' content={<SearchBar />}>
        //     <Button type='text' icon={<SearchOutlined />} />
        // </Popover>
        <SearchBar/>
    );
};

export default NameSearcher;

import axiosApiInstance from '../../api/axiosClient';

type InvoicesOut = API.LoggedInvoiceOutgoing[];

const getInvoicesOutgoing = async (params?: any) => {
    return axiosApiInstance
        .get<InvoicesOut>('/api/invoices/outgoing/', {params: params,})
        .then(({data}) => data)
        .catch((e) => {
            throw e;
        });
};

export default getInvoicesOutgoing;

import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {PageContainer} from "@ant-design/pro-layout";
import {ProDescriptions, ProList} from "@ant-design/pro-components";
import {Button, Col, Divider, Dropdown, Row, Space, Statistic, Typography} from "antd";
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import axiosApiInstance from "../../api/axiosClient";
import {DownOutlined, EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {GlobalStateContext} from "../../context/GlobalContext";
import DeleteProposedDeliverable from "../../components/wbs/proposals/DeleteProposedDeliverable";
import currencyFormatter from "../../util/currency_formatter";

type Props = {};
const ProposedWBSVersion = (props: Props) => {
    const [wbsVersionData, setWbsVersionData] = useState<API.ProposedWBSVersion | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const routerParams = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {proposedDeliverablesListRef} = useContext(GlobalStateContext);
    const {proposedWBSVersionRef} = useContext(GlobalStateContext);

    const fetchWbsVersionData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.ProposedWBSVersion>('/api/proposals/' + routerParams.proposedWbsVersionId)
            // console.log(response.data)
            setWbsVersionData(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setWbsVersionData(undefined)
            setLoading(false)
        }
    };

    useEffect(() => {
        // if (location.pathname.includes('newdeliverable'))
        //     return
        fetchWbsVersionData();
    }, []);

    return (
        <PageContainer
            title={wbsVersionData ? wbsVersionData.pretty_id : ""}
            // title={wbsVersionData?.pretty_id}
            // loading={loading} // too much
            content={
                <Row>
                    <ProDescriptions
                        actionRef={proposedWBSVersionRef}
                        loading={loading}
                        editable={{
                            onSave: (key, record, originRow, newLineConfig) => {
                                // console.log(key,record,originRow,newLineConfig)
                                console.log({[key as string]: record[key as keyof API.ProposedWBSVersion]})
                                return axiosApiInstance.put('/api/proposals/' + routerParams.proposedWbsVersionId, {[key as string]: record[key as keyof API.ProposedWBSVersion]})
                                    .then(fetchWbsVersionData)
                            }
                        }}
                        dataSource={wbsVersionData}
                        column={{sm: 2, xs: 1}}
                        style={{maxWidth: 1200}}
                        // column={2}
                        // style={{marginBlockEnd: -16}}
                        // formProps={{
                        // onValuesChange: (e, f) => console.log(f),
                        // onFinish:values => console.log(values),
                        // onSubmitCapture:event => console.log(event)
                        // }}
                        columns={[
                            {
                                title: 'Version no',
                                dataIndex: 'wbs_version_no',
                                editable: false,
                            },
                            {
                                title: 'Total Duration (days)',
                                dataIndex: 'total_estimated_duration_days',
                                // valueType: 'digit',
                                editable: false,
                                // render: (text, record) => currencyFormatter.format(text as number),
                            },
                            {
                                title: 'Total Cost',
                                dataIndex: 'total_cost',
                                // valueType: 'digit',
                                editable: false,
                                render: (text, record) => currencyFormatter.format(text as number),
                            },
                            {
                                title: 'Total Price',
                                dataIndex: 'total_price',
                                // valueType: 'digit',
                                editable: false,
                                render: (text, record) => currencyFormatter.format(text as number),
                            },
                            {
                                title: 'Created At',
                                dataIndex: 'created_at',
                                valueType: 'date',
                                editable: false,
                                render: (text, record) => new Date(record.created_at).toLocaleString('el'),
                            },
                        ]}
                    />
                </Row>
            }
        >

            <ProList<API.ProposedDeliverable & { actions: any }>
                toolBarRender={() => [
                    // <Link to="newdeliverable" state={{background: location}}>
                    //     <Button
                    //         icon={<PlusOutlined/>}
                    //         // key="new"
                    //         type="primary"
                    //     >
                    //         New
                    //     </Button>
                    // </Link>,

                    <Dropdown.Button
                        type="primary"
                        key="new"
                        icon={<DownOutlined/>}
                        // loading={loadings[1]}
                        onClick={() => navigate('newdeliverable', {state: {background: location}})}
                        menu={{
                            items: [
                                {
                                    label: 'From template',
                                    key: '1',
                                    onClick: () => navigate('newdeliverable/fromtemplate', {state: {background: location}})
                                },
                            ]
                        }}
                        // onClick={() => enterLoading(1)}
                    >
                        <PlusOutlined/> New
                    </Dropdown.Button>,

                    <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                        proposedDeliverablesListRef?.current?.reload()
                    }}/>
                ]}
                ghost
                itemCardProps={{
                    // bodyStyle:{padding:"16px 32px 16px 32px"},
                    bodyStyle: {paddingLeft: 32, width: 330},
                    // headStyle: {paddingLeft: 0}
                }}
                pagination={{
                    defaultPageSize: 8,
                    showSizeChanger: false,
                }}
                showActions="always"
                // rowSelection={{}}
                rowSelection={false}
                grid={{gutter: 16}}
                onItem={(record: any) => {
                    return {
                        onClick: () => {
                            navigate(`deliverables/${record.id}`)
                        },
                    };
                }}
                metas={{
                    title: {
                        dataIndex: 'name',
                        render: (dom, entity) => {
                            return `Deliverable ${entity.name}`
                            // return <Row justify={"start"}>Deliverable {entity.name}</Row>
                        },
                    },
                    subTitle: {
                        // dataIndex: 'description'
                        // dataIndex: 'description'
                        render: (dom, entity) => `${entity.estimated_duration_days} days`
                    },
                    // type: {},
                    // avatar: {},
                    content: {
                        render: (dom, entity) => {
                            let otc = 0
                            let omc = 0
                            let tptc = 0
                            let tpmc = 0
                            entity.own_time_costs?.map(value => {
                                otc += value.cost
                            })
                            entity.own_material_costs?.map(value => {
                                omc += value.cost
                            })
                            entity.third_party_time_costs?.map(value => {
                                tptc += value.cost
                            })
                            entity.third_party_material_costs?.map(value => {
                                tpmc += value.cost
                            })
                            return <>
                                {/*<Row>*/}
                                <Space direction={"vertical"} size={"small"}>

                                    <div style={{display: "flex", justifyContent: "space-between"}}><Typography.Text
                                        style={{marginRight: 10}}>Own time cost: €{otc}</Typography.Text>
                                        <Typography.Text
                                            type={"secondary"}>{entity.own_time_costs?.length || '0'} item(s)</Typography.Text>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}><Typography.Text
                                        style={{marginRight: 10}}>Own materials cost: €{omc} </Typography.Text>
                                        <Typography.Text
                                            type={"secondary"}>{entity.own_material_costs?.length || '0'} item(s)</Typography.Text>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}><Typography.Text
                                        style={{marginRight: 10}}>3P time cost: €{tptc} </Typography.Text>
                                        <Typography.Text
                                            type={"secondary"}>{entity.third_party_time_costs?.length || '0'} item(s)</Typography.Text>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}><Typography.Text
                                        style={{marginRight: 10}}>3P materials cost: €{tpmc} </Typography.Text>
                                        <Typography.Text
                                            type={"secondary"}>{entity.third_party_material_costs?.length || '0'} item(s)</Typography.Text>
                                    </div>

                                    <Divider style={{marginTop: 8, marginBottom: 8}}/>
                                    <Row gutter={8} justify={"space-evenly"}>
                                        <Col span={12}>
                                            <Statistic title="Total Cost" value={entity.total_cost} precision={2}
                                                       prefix={'€'}
                                                       valueStyle={{fontSize: 18}}/>
                                        </Col>
                                        {/*<Divider type={'vertical'}/>*/}
                                        <Col span={12}>
                                            <Statistic title="Total Price" value={entity.total_price} precision={2}
                                                       prefix={'€'}
                                                       valueStyle={{fontSize: 18}}/>
                                        </Col>
                                    </Row>
                                </Space>
                                {/*</Row>*/}
                            </>
                        }
                    },
                    type: {},
                    avatar: {},
                    actions: {cardActionProps: "actions"}
                }}
                // onDataSourceChange={dataSource => log}
                actionRef={proposedDeliverablesListRef}
                headerTitle="Deliverables"
                request={params => {
                    return axiosApiInstance.get<API.ProposedDeliverable[]>('/api/proposals/' + routerParams.proposedWbsVersionId + '/deliverables')
                        .then(value => {
                            return {
                                data: value.data.map((i) => ({
                                    ...i,
                                    actions: [
                                        <DeleteProposedDeliverable id={i.id}/>,
                                        // <EditOutlined key="edit"/>,
                                        <Link to={`deliverables/${i.id}/edit`}> <EditOutlined
                                            key="edit"/> </Link>,
                                        // <EllipsisOutlined key="ellipsis"/>,
                                    ],
                                })),
                                success: true,
                                total: value.data.length
                            }
                        })
                        .catch(reason => {
                            // console.log(reason)
                            return {success: false}
                        })
                }}
                onRequestError={e => console.log(e)}
                // onDataSourceChange={dataSource => console.log(dataSource)}
                // onLoad={dataSource => console.log(dataSource)}
            />

            <Outlet/>

        </PageContainer>
    );
};

export default ProposedWBSVersion
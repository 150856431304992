import axiosApiInstance from '../../api/axiosClient';

type Types = API.OpportunityType[];

const getOpportunityTypeOptions = async () => {
    return axiosApiInstance
        .get<Types>('/api/sales/types')
        .then((res) => res.data)
        .catch((e) => {
            throw e;
        });
};

export default getOpportunityTypeOptions;

import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import './ExecutionWBSVersions.css'
import {GlobalStateContext} from "../../context/GlobalContext";
import axiosApiInstance from "../../api/axiosClient";
import {Link, Outlet, useParams} from "react-router-dom";
import {ProList} from "@ant-design/pro-components";
import {Button, Col, Dropdown, Menu, Row, Space, Tag, Typography} from "antd";
import {BuildTwoTone, DownOutlined, ReloadOutlined} from "@ant-design/icons";
import DeleteExecutionWBSVersion from "../wbs/executions/DeleteExecutionWBSVersion";
import currencyFormatter from "../../util/currency_formatter";
// import moment from "moment";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

type Props = {};


const ExecutionWBSVersions = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    // const actionRef = useRef<ActionType>();
    // const {wbsVersionsListRef} = useContext(GlobalStateContext);
    const {executionWbsVersionsListRef} = useContext(GlobalStateContext);
    const [proposals, setProposals] = useState<API.ProposedWBSVersion[] | undefined>(undefined);
    const routerParams = useParams();

    const createExecutionFromProposal = async (proposalId: number) => {
        try {
            setLoading(true)
            await axiosApiInstance.post<API.ExecutionWBSVersion>(`/api/executions/fromproposal/${proposalId}`)
            setLoading(false)
            executionWbsVersionsListRef?.current?.reload()
        } catch (e) {
            console.log('catch error')
            setLoading(false)
        }
    }

    const copyExecution = async (wbsVersion: API.ExecutionWBSVersion) => {
        try {
            setLoading(true)
            await axiosApiInstance.post<API.ExecutionWBSVersion>(`/api/executions/${wbsVersion.id}/copy`)
            setLoading(false)
            executionWbsVersionsListRef?.current?.reload()
        } catch (e) {
            console.log('catch error')
            setLoading(false)
        }
    }

    const getProposals = async () => {
        try {
            // setLoading(true)
            const res = await axiosApiInstance.get<API.ProposedWBSVersion[]>(`/api/projects/${routerParams.projectId}/structures/proposals`)
            setProposals(res.data)
        } catch (e) {
            console.log('catch error')
            setProposals(undefined)
        }
    }

    const setActiveExecution = async (executionId: number) => {
        try {
            setLoading(true)
            await axiosApiInstance.post<API.ExecutionWBSVersion>(`/api/executions/${executionId}/setactive`)
            setLoading(false)
            executionWbsVersionsListRef?.current?.reload()
        } catch (e) {
            console.log('catch error')
            setLoading(false)
        }
    }


    useEffect(() => {
        getProposals()
    }, []);

    const contentItemWidth = 120
    return (
        <div>

            {/* show wbs versions */}
            {/*<ProCard>*/}
            <ProList<API.ExecutionWBSVersion>
                className={'ExecutionWBSVersions'}
                cardBordered
                pagination={{
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    // hideOnSinglePage: true
                    size: "small"
                }}
                headerTitle={'Execution Structures'}
                actionRef={executionWbsVersionsListRef}
                // search={{filterType: "light"}}
                toolBarRender={() => {
                    return [
                        <Dropdown overlay={<Menu
                            key={'create-execution'}
                            items={[
                                {
                                    label: 'From Scratch',
                                    key: 'scratch',
                                    onClick: async (e) => {
                                        await axiosApiInstance.post<API.ExecutionWBSVersion>(`/api/projects/${routerParams.projectId}/structures/execution`)
                                        executionWbsVersionsListRef?.current?.reload()
                                    }
                                },
                                {
                                    label: 'From Proposal',
                                    key: 'PR',
                                    children: proposals?.map(value => {
                                        return {
                                            key: `PR-${value.id}`, label: value.pretty_id, onClick: async (e) => {
                                                await createExecutionFromProposal(value.id!)
                                            },
                                        }
                                    })

                                },
                            ]}
                        />}>
                            <Button type={"primary"} onClick={(e) => e.preventDefault()}>
                                <Space>
                                    New
                                    <DownOutlined/>
                                </Space>
                            </Button>
                        </Dropdown>,

                        <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                            executionWbsVersionsListRef?.current?.reset!()
                            // actionRef.current?.reset!()
                        }}/>
                    ];
                }}
                // showActions={"hover"}
                tooltip="A work breakdown structure is subject to explicit versioning."
                metas={{
                    title: {
                        search: false,
                        // render: (dom, entity) => <Link
                        //     to={`/projects/${routerParams.projectId}/executions/${entity.id}`}>Version {entity.wbs_version_no}</Link>
                        // render:(dom, entity) => (`Version ${entity.wbs_version_no}`)
                        render: (dom, entity) => entity.is_active ? <Link
                                to={`/projects/${routerParams.projectId}/executions/${entity.id}`}>Version {entity.wbs_version_no}</Link> :
                            <Typography.Text strong style={{
                                color: '#919191',
                                cursor: "default",
                            }}>Version {entity.wbs_version_no}</Typography.Text>
                    },
                    subTitle: {
                        search: false,
                        render: (dom, entity) => (
                            <>
                                <Space>
                                    <Tag color="blue">{entity.execution_deliverables?.length || 'No'} deliverables</Tag>
                                    {entity.is_active && <Tag color={"green"}>Active</Tag>}
                                    {/*{entity.status?.title && <Tag color="red">{entity.status.title}</Tag>}*/}
                                    {/*{new Date(item.created_at).toLocaleDateString('el')}*/}
                                </Space>
                            </>)
                    }
                    ,
                    // type: {},
                    description: {
                        search: false,
                        dataIndex: 'pretty_id',
                        title: 'Pretty ID',
                        valueType: 'text'
                        // search: false,
                    },
                    avatar: {
                        // dataIndex: 'avatar',
                        search: false,
                        render: () => <Row justify={"center"} align="middle" style={{width: 36, height: 48}}>
                            <Col>
                                <BuildTwoTone style={{fontSize: 24}}/>
                            </Col>
                        </Row>
                        // render: () => <BuildTwoTone style={{fontSize: 24}}/>
                    },
                    content: {
                        search: false,
                        render: (dom, entity) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: "wrap",
                                        rowGap: 16,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: contentItemWidth
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Total Cost'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{currencyFormatter.format(entity.total_cost)}</Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: contentItemWidth
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Total Price'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{currencyFormatter.format(entity.total_price)}</Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: contentItemWidth
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Gross Margin'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{Math.round((entity.gross_margin || 0) * 100) / 100}%</Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: contentItemWidth
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Gross Profit'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{currencyFormatter.format(entity.gross_profit || 0)}</Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: contentItemWidth
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Earliest Start'}</Typography.Text>
                                            <Typography.Text type={"secondary"}>
                                                {dayjs.utc(entity.earliest_start_date).isSame(dayjs.utc("0001-01-01T00:00:00Z")) ?
                                                    '-' : dayjs.utc(entity.earliest_start_date).format('DD/MM/YYYY')}
                                            </Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: contentItemWidth
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Latest End'}</Typography.Text>
                                            <Typography.Text type={"secondary"}>
                                                {dayjs.utc(entity.latest_end_date).isSame(dayjs.utc("0001-01-01T00:00:00Z")) ?
                                                    '-' : dayjs.utc(entity.latest_end_date).format('DD/MM/YYYY')}
                                            </Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: contentItemWidth
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Duration (days)'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{entity.duration_days}</Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            // width:contentItemWidth,
                                            width: '80px',
                                            // width: '200px',
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Created At'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{new Date(entity.created_at).toLocaleDateString('el')}</Typography.Text>
                                        </Space>
                                    </div>
                                </div>
                            )
                        },
                    },
                    actions: {
                        render: (dom, entity) => {
                            return <Space>
                                <DeleteExecutionWBSVersion id={entity.id}/>
                                <Button type={"link"} onClick={() => copyExecution(entity)}>Make a Copy</Button>
                                <Button type={"link"} onClick={() => setActiveExecution(entity.id!)}>Set as
                                    Active</Button>
                            </Space>
                        },
                    },
                }}
                request={() => axiosApiInstance.get<API.ExecutionWBSVersion[]>(`/api/projects/${routerParams.projectId}/structures/executions`)}
                // onDataSourceChange={dataSource => console.log(dataSource)}
            />
            {/*</ProCard>*/}

            <Outlet/>

        </div>
    );
};

export default ExecutionWBSVersions;
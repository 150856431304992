import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormMoney,
    ProFormSelect,
    ProFormText,
    ProFormTreeSelect
} from "@ant-design/pro-components";
import {Form, Skeleton} from "antd";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axiosApiInstance from "../../../../api/axiosClient";
import {GlobalStateContext} from "../../../../context/GlobalContext";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

type Props = {};

const NewInvoiceOutgoingGlobal = (props: Props) => {
    const [form] = Form.useForm<API.LoggedInvoiceOutgoing>();
    const navigate = useNavigate();
    const routerParams = useParams();
    // const {executionDeliverablesListRef, executionWBSVersionRef} = useContext(GlobalStateContext);
    // const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const {invoicesOutgoingGlobalListRef, taxCategories} = useContext(GlobalStateContext);
    const [loading, setLoading] = useState<boolean>(false);
    // const [invoiceData, setInvoiceData] = useState<API.LoggedInvoiceOutgoing | undefined>(undefined);
    const [projectsData, setProjectsData] = useState<API.Project[] | undefined>(undefined);
    const [costCentersData, setCostCentersData] = useState<any>([]);

    const projectValue = Form.useWatch('project_id', form);

    const fetchProjectsData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.Project[]>(`/api/projects/onexecution`)
            console.log(response.data)
            setProjectsData(response.data);
            setLoading(false)

            if (response.data.length > 0) {
                // setSelectedProjectId(response.data[0].id)
                form.setFieldsValue({project_id: response.data[0].id})
            }
        } catch (e) {
            console.log('catch error')
            setProjectsData(undefined)
            setLoading(false)
        }
    };

    useEffect(() => {
        // fetchProjectsData().then(fetchWbsVersionData);
        if (projectValue)
            fetchCostCenters();
    }, [projectValue]);

    useEffect(() => {
        fetchProjectsData();
    }, []);

    const fetchCostCenters = async () => {
        try {
            const res = await axiosApiInstance.get(`/api/projects/${projectValue}/tree`)
            setCostCentersData(res.data)
        } catch (err) {
            console.log(err)
        }
    }

    const location = useLocation();
    const dataFromRouter = location.state?.data;
    if (dataFromRouter) {
        form.setFieldsValue(dataFromRouter)
    }
    const plannedInvoiceId = location.state?.plannedInvoiceId;


    return (
        <ModalForm<API.LoggedInvoiceOutgoing>
            title="Create a new Invoice"
            open={true}
            form={form}
            autoFocusFirstInput
            // width={"100%"}
            modalProps={{
                destroyOnClose: true,
                okText: 'Create',
                onCancel: () => navigate(`/invoices/outgoing`),
                style: {top: 40},
            }}
            // submitTimeout={2000}
            onFinish={(values) => {
                // values.execution_wbs_version_id = +routerParams.executionWbsVersionId!
                console.log({values});
                // return Promise.resolve()
                return axiosApiInstance.post<API.LoggedInvoiceOutgoing>('/api/invoices/outgoing/', values)
                    .then(async (res) => {
                        if (plannedInvoiceId) {
                            try {
                                const response = await axiosApiInstance.put(`/api/projects/${projectValue}/invoices/${plannedInvoiceId}`, {
                                    id: plannedInvoiceId,
                                    issued: true,
                                    project_id: projectValue,
                                })
                            } catch (err) {
                                console.log(err)
                            }
                        }

                        invoicesOutgoingGlobalListRef?.current?.reload()
                        navigate(`/invoices/outgoing`)
                        return true
                    }).catch(reason => false)
            }}
            validateMessages={{required: "${label} is required!"}}
            // initialValues={{
            //     dateRange: []
            // }}
        >
            {loading ? <Skeleton active={true}/> :
                <>
                    <ProFormDigit
                        name="id"
                        hidden={true}
                    />

                    <ProFormGroup label={'Basic Information'}>
                        <ProFormSelect
                            label={'Project'}
                            name={'project_id'}
                            width={'lg'}
                            rules={[{required: true}]}
                            placeholder={'Select Project'}
                            options={projectsData?.map(value => ({
                                label: `${value.title} (${value.project_pretty_id})`,
                                value: value.id
                            }))}

                        />
                        <ProFormGroup>
                            <ProFormText
                                width="sm"
                                name="invoice_number"
                                label="Invoice Number"
                                rules={[{required: true}]}
                            />
                            <ProFormDatePicker name={"date"} label={"Date Issued"}
                                               rules={[{required: true}]}
                                               normalize={(value) => {
                                                   return value.toISOString()
                                                   // return new Date(value).toISOString()
                                               }}
                            />
                            <ProFormDatePicker name={"due_date"} label={"Due Date"}
                                               rules={[{required: true}]}
                                               normalize={(value) => {
                                                   return value.toISOString()
                                                   // return new Date(value).toISOString()
                                               }}
                                               fieldProps={{
                                                   presets: [
                                                       {
                                                           label: 'In 14 days',
                                                           value: dayjs.utc().add(14, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 30 days',
                                                           value: dayjs.utc().add(30, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 60 days',
                                                           value: dayjs.utc().add(60, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 90 days',
                                                           value: dayjs.utc().add(90, 'd').startOf('d')
                                                       },
                                                   ]
                                               }}
                            />

                        </ProFormGroup>

                        <ProFormGroup>


                            <ProFormMoney
                                name="cost"
                                label={'Cost'}
                                customSymbol={'€'}
                                fieldProps={{
                                    numberFormatOptions: {currency: 'EUR'}
                                }}
                                rules={[{required: true}]}
                                width={"xs"}
                            />

                            <ProFormSelect
                                options={taxCategories?.map(i => ({label: i.label, value: i.id}))}
                                width="xs"
                                name="tax_category_id"
                                label="Tax category"
                                // formItemProps={{}}
                                fieldProps={{
                                    onSelect: (tax_category_id: number | undefined) => {
                                        // console.log('SELECT')
                                        form.setFieldValue(['tax_percentage'], taxCategories?.find(value => value.id == tax_category_id)?.tax_percentage)
                                        form.setFieldValue(['tax_category'], taxCategories?.find(value => value.id == tax_category_id))
                                    }
                                }}
                                rules={[{required: true}]}
                            />

                            <ProFormItem
                                name="tax_percentage"
                                hidden={true}
                            />

                            <ProFormItem
                                name="tax_category"
                                hidden={true}
                            />

                            <ProFormItem
                                name="project_id"
                                initialValue={projectValue}
                                hidden={true}
                            />

                            {/*<ProFormMoney*/}
                            {/*    name="cost_incl_vat"*/}
                            {/*    label={'Cost Incl. VAT'}*/}
                            {/*    customSymbol={'€'}*/}
                            {/*    fieldProps={{*/}
                            {/*        numberFormatOptions: {currency: 'EUR'}*/}
                            {/*    }}*/}
                            {/*    rules={[{required: true}]}*/}
                            {/*    width={"xs"}*/}
                            {/*/>*/}

                            <ProFormDependency
                                name={[['cost'], ['tax_percentage']]}>
                                {({cost, tax_percentage}) => {
                                    // console.log("cost", effort, hourly_rate)
                                    form.setFieldValue('cost_incl_vat', (cost || 0) + (cost || 0) * (tax_percentage || 0))

                                    return (
                                        <ProFormMoney
                                            name="cost_incl_vat"
                                            label={'Cost Incl. VAT'}
                                            customSymbol={'€'}
                                            readonly
                                            fieldProps={{
                                                numberFormatOptions: {currency: 'EUR'}
                                            }}
                                            width={"xs"}
                                        />
                                    );
                                }}
                            </ProFormDependency>

                            {/*<ProFormRadio.Group*/}
                            {/*    valueEnum={{*/}
                            {/*        YES: 'Yes',*/}
                            {/*        NO: 'No',*/}
                            {/*        PARTIALLY: 'Partially',*/}
                            {/*    }}*/}
                            {/*    radioType={"button"}*/}
                            {/*    width="xl"*/}
                            {/*    name="paid"*/}
                            {/*    label="Paid"*/}
                            {/*    readonly*/}
                            {/*    rules={[{required: true}]}*/}
                            {/*/>*/}

                        </ProFormGroup>
                    </ProFormGroup>


                    <ProFormGroup>
                        <ProFormGroup label={'Customer'}>

                            {/*<ProFormSelect*/}
                            {/*    request={() =>*/}
                            {/*        axiosApiInstance.get<API.Customer[]>('/api/customers/')*/}
                            {/*            .then(res => {*/}
                            {/*                return res.data.map(i => ({*/}
                            {/*                    label: `${i.name} [${i.id}]`,*/}
                            {/*                    value: i.id*/}
                            {/*                }))*/}
                            {/*            })*/}
                            {/*    }*/}
                            {/*    width={"sm"}*/}
                            {/*    name="customer_id"*/}
                            {/*    label="Customer"*/}
                            {/*    rules={[{required: true}]}*/}
                            {/*    fieldProps={{*/}
                            {/*        showSearch: true, filterOption: (input, option) =>*/}
                            {/*            (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <ProFormSelect
                                request={() =>
                                    axiosApiInstance.get<API.BuyerType[]>('/api/buyers/types')
                                        .then(res => {
                                            return res.data.map(i => ({label: i.name, value: i.id}))
                                        })
                                }
                                name="buyer_type_id"
                                label="Customer Type"
                                rules={[{required: true}]}
                                width={"sm"}
                                initialValue={1}
                            />

                            <ProFormDependency name={[['buyer_type_id']]}>
                                {({buyer_type_id}) => {
                                    if (buyer_type_id === 1) {
                                        form.setFieldValue('external_buyer_id', null)
                                        form.setFieldValue('external_buyer', null)
                                        form.setFieldValue('accounting_customer_id', null)
                                        form.setFieldValue('accounting_customer', null)
                                        form.setFieldValue('customer', null)
                                        return <ProFormSelect
                                            request={() =>
                                                axiosApiInstance.get<API.Customer[]>('/api/customers/')
                                                    .then(res => {
                                                        return res.data.map(i => ({
                                                            label: `${i.name} [${i.id}]`,
                                                            value: i.id
                                                        }))
                                                    })
                                            }
                                            width="sm"
                                            name="customer_id"
                                            label="Existing Customer (LTD)"
                                            rules={[{required: true}]}
                                            fieldProps={{
                                                showSearch: true, filterOption: (input, option) =>
                                                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
                                            }}
                                        />
                                    }
                                    if (buyer_type_id === 2) {
                                        form.setFieldValue('external_buyer_id', null)
                                        form.setFieldValue('external_buyer', null)
                                        form.setFieldValue('customer_id', null)
                                        form.setFieldValue('customer', null)
                                        form.setFieldValue('accounting_customer', null)
                                        return <ProFormSelect
                                            request={() =>
                                                axiosApiInstance.get<API.AccountingCustomer[]>('/api/accounting_customers/')
                                                    .then(res => {
                                                        return res.data.map(i => ({
                                                            label: `${i.name} [${i.id}]`,
                                                            value: i.id
                                                        }))
                                                    })
                                            }
                                            width="sm"
                                            name="accounting_customer_id"
                                            label="Existing Customer (IKE)"
                                            rules={[{required: true}]}
                                            fieldProps={{
                                                showSearch: true, filterOption: (input, option) =>
                                                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
                                            }}
                                        />
                                    }
                                    form.setFieldValue('customer_id', null)
                                    form.setFieldValue('customer', null)
                                    form.setFieldValue('accounting_customer_id', null)
                                    form.setFieldValue('accounting_customer', null)
                                    form.setFieldValue('external_buyer', null)
                                    form.setFieldValue('employee_id', null)
                                    form.setFieldValue('employee', null)
                                    return (
                                        <ProFormSelect
                                            request={() =>
                                                axiosApiInstance.get<API.ExternalBuyer[]>('/api/buyers/')
                                                    .then(res => {
                                                        // console.log(res.data)
                                                        return res.data.map(i => ({
                                                            label: i.name,
                                                            value: i.id
                                                        }))
                                                    })
                                            }
                                            width="sm"
                                            name="external_buyer_id"
                                            label="External Customer"
                                            rules={[{required: true}]}
                                        />
                                    );
                                }}
                            </ProFormDependency>
                        </ProFormGroup>

                        <ProFormGroup label={'Related Cost Centers'}>
                            <ProFormTreeSelect
                                label={"Cost Centers"}
                                name={'checked_costs'}
                                width={"lg"}
                                // .then(res => {
                                //     return res.data.map(i => ({
                                //         label: `${i.name} [${i.id}]`,
                                //         value: i.id
                                //     }))
                                // })
                                //}
                                // fieldProps={{treeDataSimpleMode: true, treeDefaultExpandAll: true, multiple: true}}
                                fieldProps={{
                                    treeDefaultExpandAll: true,
                                    multiple: true,
                                    treeCheckable: true,
                                    showArrow: true,
                                    fieldNames: {label: 'title'},
                                    treeData: costCentersData,
                                    // treeNodeLabelProp: 'title'
                                    // maxTagCount:20,
                                    // onSelect: (value: any) => {
                                    //     console.log(value)
                                    // },
                                    // onChange: (value, labelList, extra) => {
                                    //     console.log(value)
                                    // }
                                }}
                            />
                        </ProFormGroup>

                    </ProFormGroup>

                    {/*<ProFormText*/}
                    {/*    name="description"*/}
                    {/*    label={'Description'}*/}
                    {/*/>*/}

                    <ProFormGroup label={'Other'}>
                        <ProFormText
                            width="xl"
                            name="file_url"
                            label="File URL"
                            // rules={[{required: true}]}
                        />
                    </ProFormGroup>
                </>
            }
        </ModalForm>
    );
};

export default NewInvoiceOutgoingGlobal;
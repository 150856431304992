type Output = {
    [k: string]: number[];
};

/**
 * This function will take a string of the following form: `key1::1:2:3|key2::4:5:6|...`
 * and parse it into an object as such:
 *
 * {
 * 	key1: [1, 2, 3],
 * 	key2: [4, 5, 6],
 * 	...
 * }
 *
 * this object can be used as a filter object with the LightFilter component of ant design pro
 *
 * @param p key-values pairs string
 * @returns filter object {[k: string]: number[]}
 */
const filterStringToObj = (p: string) => {
    const out: Output = {}; // output object
    if (!p) return out; // if input is empty, return empty object
    p.split('|') // split string into key-values pairs strings
        .map((e) => e.split('::')) // split each key-values string into [key, values] pair form
        .forEach(
            // for each [key, values] array of strings
            ([k, vs]) =>
                (out[k] = vs
                    .split(':') // split values delimited by '-'
                    .filter((e) => e !== '') // filter empty strings
                    .map((s) => parseInt(s))) // convert to numbers
        );

    return out;
};

export default filterStringToObj;

import React from 'react';
import { Button, DatePickerProps, Popover, Tooltip } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { DatePicker } from 'antd';
import { useSalesContext } from '../../context/SalesContextProvider';
import dayjs from 'dayjs';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const yesterday = dayjs().add(-1, 'day');
    return current && current > yesterday.endOf('day');
};

const TimestampDatePicker = () => {
    const { isLoading, timestamp, setTimestamp } = useSalesContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        searchParams.set('versioned_at', date?.format('YYYY-MM-DDTHH:mm:ss') || '');
        setTimestamp(date);
        setSearchParams((p) => p);
    };
    return (
        <DatePicker
            disabled={isLoading}
            disabledDate={disabledDate}
            defaultValue={timestamp}
            onChange={onChange}
        />
    );
};

const TimestampPicker = () => {
    return (
        <Tooltip title='View the opportunity table as it was at a past date'>
            <Popover trigger='hover' placement='bottom' content={<TimestampDatePicker />}>
                <Button type='text' icon={<ClockCircleOutlined />} />
            </Popover>
        </Tooltip>
    );
};

export default TimestampPicker;

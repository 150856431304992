import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Card, Empty, Row, Space, Statistic, Typography} from "antd";
import {ProCard, ProList} from "@ant-design/pro-components";
import {ArrowDownOutlined, ArrowUpOutlined, EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import {GlobalStateContext} from "../../context/GlobalContext";
import axiosApiInstance from "../../api/axiosClient";
import currencyFormatter from "../../util/currency_formatter";
import DeleteLoggedPayment from "./DeleteLoggedPayment";

dayjs.extend(utc)

type Props = {};
const LoggedPayments = (props: Props) => {
    // const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const [data, setData] = useState<API.LoggedPayment[] | undefined>(undefined);
    // const [loading, setLoading] = useState<boolean>(false);
    const routerParams = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {loggedPaymentsListRef} = useContext(GlobalStateContext);
    const [incomingPaymentsCompleteAmount, setIncomingPaymentsCompleteAmount] = useState<number>(0);
    const [outgoingPaymentsCompleteAmount, setOutgoingPaymentsCompleteAmount] = useState<number>(0);

    useEffect(() => {
        // if (location.pathname.includes('newinvoice'))
        //     return
        // fetchWbsVersionData();
        // fetchTaxCategoriesData()
    }, []);

    useEffect(() => {
        let incomingCompleteAmount = 0
        let outgoingCompleteAmount = 0
        data?.map((d) => {
            if (d.payment_status.title == 'Completed') {
                if (d.invoice_type == 'incoming') {
                    outgoingCompleteAmount += d.amount
                }
                if (d.invoice_type == 'outgoing') {
                    incomingCompleteAmount += d.amount
                }
            }
        })
        setIncomingPaymentsCompleteAmount(incomingCompleteAmount)
        setOutgoingPaymentsCompleteAmount(outgoingCompleteAmount)
    }, [data]);


    return (
        <>
            {/*<Alert type={"info"} style={{marginBottom: 4}}*/}
            {/*       showIcon closable*/}
            {/*       message={'Invoices being sent to the company by third parties (service providers, vendors, retailers, etc.)  on the basis of a purchased service.'}/>*/}
            <Alert
                type={incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount > 0 ? 'success' : incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount == 0 ? 'info' : 'warning'}
                style={{marginBottom: 4}}
                // showIcon
                closable
                message={<>
                    {incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount > 0 ? 'The project is cash flow positive.' : incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount == 0 ? 'The project is cash flow neutral.' : 'The project is cash flow negative.'}

                    <Row style={{marginTop: 4}}>
                        <Space>
                            <Card bordered={false} size={"small"}>
                                <Statistic
                                    title="Incoming Payments"
                                    value={currencyFormatter.format(incomingPaymentsCompleteAmount)}
                                    precision={2}
                                    // style={{fontSize:1}}
                                    valueStyle={{color: '#3f8600', fontSize: 14}}
                                    prefix={<ArrowUpOutlined/>}
                                />
                            </Card>
                            <Card bordered={false} size={"small"}>
                                <Statistic
                                    title="Outgoing Payments"
                                    value={currencyFormatter.format(outgoingPaymentsCompleteAmount)}
                                    precision={2}
                                    valueStyle={{color: '#cf1322', fontSize: 14}}
                                    prefix={<ArrowDownOutlined/>}
                                />
                            </Card>
                            <Card bordered={false} size={"small"}>
                                <Statistic
                                    title="Payments Total"
                                    value={currencyFormatter.format(Math.abs(incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount))}
                                    precision={2}
                                    valueStyle={{
                                        color: incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount > 0 ? '#3f8600' : incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount == 0 ? '#000' : '#cf1322',
                                        fontSize: 14
                                    }}
                                    prefix={incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount > 0 ?
                                        <ArrowUpOutlined/> : incomingPaymentsCompleteAmount - outgoingPaymentsCompleteAmount == 0 ? null :
                                            <ArrowDownOutlined/>}
                                />
                            </Card>
                        </Space>
                    </Row>
                </>}
            />
            <ProCard bordered
                // bodyStyle={{padding: 0}}
            >
                <ProList<API.LoggedPayment & { actions: any }>
                    locale={{
                        emptyText: <Empty
                            // image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={'No payments found for project'}/>
                    }}
                    toolBarRender={() => [
                        <Link to="new" state={{background: location}}>
                            <Button
                                icon={<PlusOutlined/>}
                                key="new"
                                type="primary"
                            >
                                New
                            </Button>
                        </Link>,
                        <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                            loggedPaymentsListRef?.current?.reload()
                        }}/>
                    ]}
                    // ghost
                    itemCardProps={{
                        // bodyStyle:{padding:"16px 32px 16px 32px"},
                        bodyStyle: {paddingLeft: 32, width: 250},
                        // headStyle: {paddingLeft: 0},
                        // style: {borderColor: 'green'}
                    }}
                    pagination={{
                        defaultPageSize: 8,
                        showSizeChanger: false,
                    }}
                    showActions="always"
                    // rowSelection={{}}
                    rowSelection={false}
                    grid={{gutter: 16}}
                    onItem={(record: API.LoggedPayment) => {
                        return {
                            onClick: () => {
                                navigate(`${record.id}`)
                            },
                            style: {borderColor: record.invoice_type == 'incoming' ? '#cf1322' : '#3f8600'}
                        };
                    }}
                    search={{filterType: "light"}}
                    metas={{
                        title: {
                            dataIndex: 'id',
                            search: false,
                            render: (dom, entity) => {
                                return `Payment #${entity.id}`
                                // return <Row justify={"start"}>Invoice {entity.name}</Row>
                            },
                        },
                        subTitle: {
                            search: false,
                        },
                        content: {
                            search: false,
                            render: (dom, entity) => {
                                return <>
                                    <Space direction={"vertical"} size={"small"}>
                                        <Typography.Text type={"secondary"} style={{marginRight: 10}}>
                                            Invoice Type: {entity.invoice_type}
                                            {/*<Tag color={entity.invoice_type == 'incoming' ? 'red' : 'green'}>{entity.invoice_type}</Tag>*/}
                                            {/*<Tag>{entity.invoice_type}</Tag>*/}
                                        </Typography.Text>
                                        <Typography.Text type={"secondary"} style={{marginRight: 10}}>
                                            Invoice ID: {entity.invoice_id}
                                        </Typography.Text>
                                        <Typography.Text type={"secondary"}
                                                         style={{marginRight: 10}}>Date
                                            Effective: {dayjs.utc(entity.date_effective).format('YYYY-MM-DD')}</Typography.Text>
                                        <Typography.Text type={"secondary"}
                                                         style={{marginRight: 10}}>Amount: {currencyFormatter.format(entity.amount)}</Typography.Text>
                                        <Typography.Text type={"secondary"}
                                                         style={{marginRight: 10}}>Status: {entity.payment_status.title}</Typography.Text>
                                    </Space>
                                </>
                            }
                        },
                        invoice_type: {
                            dataIndex: 'invoice_type',
                            title: 'Invoice Type',
                            valueType: 'radioButton',
                            valueEnum: {
                                incoming: {text: 'Incoming'},
                                outgoing: {text: 'Outgoing'},
                            }
                        },
                        type: {
                            search: false,
                        },
                        avatar: {
                            search: false,
                        },
                        actions: {cardActionProps: "actions"}
                    }}
                    // onDataSourceChange={dataSource => log}
                    actionRef={loggedPaymentsListRef}
                    // headerTitle="Payments"
                    headerTitle={"Payments"}
                    request={params => {
                        // console.log(params)
                        return axiosApiInstance.get<API.LoggedPayment[]>(`/api/projects/${routerParams.projectId}/payments`, {params})
                            .then(value => {
                                // console.log(value.data)
                                return {
                                    data: value.data.map((i) => ({
                                        ...i,
                                        actions: [
                                            <DeleteLoggedPayment id={i.id}/>,
                                            // <EditOutlined key="edit"/>,
                                            <Link to={`${i.id}/edit`}> <EditOutlined key="edit"/> </Link>,
                                            // <EllipsisOutlined key="ellipsis"/>,
                                        ],
                                    })),
                                    success: true,
                                    total: value.data.length
                                }
                            })
                            .catch(reason => {
                                // console.log(reason)
                                return {success: false}
                            })
                    }}
                    onRequestError={e => console.log(e)}
                    onDataSourceChange={dataSource => setData(dataSource)}
                    // onLoad={dataSource => console.log(dataSource)}
                />

                <Outlet/>

            </ProCard>
        </>
    );
};

export default LoggedPayments;
import {Link, useNavigate} from "react-router-dom";
import {Button, Result, Space} from 'antd';
import React from 'react';
import {UserAuth} from "../context/AuthContext";

type Props = {
    message?: string
};

const UnauthorizedPage = (props: Props) => {
    const {logout} = UserAuth();
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
            console.log('You are logged out')
        } catch (err: any) {
            console.log(err.message);
        }
    };

    return <Result
        status="403"
        title="403"
        subTitle={props.message || "Sorry, you are not authorized to access this page."}
        extra={
            <Space>
                <Link to={'/'}>
                    <Button type="primary">
                        Back Home
                    </Button>
                </Link>
                <Button onClick={handleLogout}>
                    Logout
                </Button>
            </Space>
        }
    />
};

export default UnauthorizedPage;

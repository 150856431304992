import React, {createContext, Dispatch, Ref, SetStateAction, useRef, useState} from 'react';
import {ActionType} from "@ant-design/pro-table";

//create a context, with createContext api
type ContextType = {
    deliverablesListRef?: React.MutableRefObject<ActionType | undefined>
    wbsListRef?: React.MutableRefObject<ActionType | undefined>
    projectsListRef?: React.MutableRefObject<ActionType | undefined>
    wbsVersionsListRef?: React.MutableRefObject<ActionType | undefined>
    proposedWbsVersionsListRef?: React.MutableRefObject<ActionType | undefined>
    executionWbsVersionsListRef?: React.MutableRefObject<ActionType | undefined>
    proposedDeliverablesListRef?: React.MutableRefObject<ActionType | undefined>
    executionDeliverablesListRef?: React.MutableRefObject<ActionType | undefined>
    executionProgressTableRef?: React.MutableRefObject<ActionType | undefined>
    proposedWBSVersionRef?: React.MutableRefObject<ActionType | undefined>
    executionWBSVersionRef?: React.MutableRefObject<ActionType | undefined>
    timeTrackerTableRef?: React.MutableRefObject<ActionType | undefined>
    timeTrackerGlobalTableRef?: React.MutableRefObject<ActionType | undefined>
    materialsUtilizationTableRef?: React.MutableRefObject<ActionType | undefined>
    materialsUtilizationGlobalTableRef?: React.MutableRefObject<ActionType | undefined>
    invoicesIncomingListRef?: React.MutableRefObject<ActionType | undefined>
    invoicesOutgoingListRef?: React.MutableRefObject<ActionType | undefined>
    taxCategories?: API.TaxCategory[]
    setTaxCategories?: Dispatch<SetStateAction<API.TaxCategory[] | undefined>>
    invoicingPlanListRef?: React.MutableRefObject<ActionType | undefined>
    proposedDeliverableTemplatesListRef?: React.MutableRefObject<ActionType | undefined>
    loggedPaymentsListRef?: React.MutableRefObject<ActionType | undefined>
    transactionsListRef?: React.MutableRefObject<ActionType | undefined>
    notificationsListRef?: React.MutableRefObject<ActionType | undefined>
    notifications?: API.NotificationVolatile[]
    setNotifications?: Dispatch<SetStateAction<API.NotificationVolatile[] | undefined>>
    invoicesIncomingGlobalListRef?: React.MutableRefObject<ActionType | undefined>
    invoicesOutgoingGlobalListRef?: React.MutableRefObject<ActionType | undefined>
}

export const GlobalStateContext = createContext<ContextType>({});


const GlobalStateProvider = ({children}: any) => {
    // this state will be shared with all components
    // const [deliverablesListRef, setDeliverablesListRef] = useState();
    const deliverablesListRef = useRef<ActionType>();
    const wbsListRef = useRef<ActionType>();
    const projectsListRef = useRef<ActionType>();
    const wbsVersionsListRef = useRef<ActionType>();
    const proposedWbsVersionsListRef = useRef<ActionType>();
    const executionWbsVersionsListRef = useRef<ActionType>();
    const proposedDeliverablesListRef = useRef<ActionType>();
    const executionDeliverablesListRef = useRef<ActionType>();
    const executionProgressTableRef = useRef<ActionType>();
    const proposedWBSVersionRef = useRef<ActionType>();
    const executionWBSVersionRef = useRef<ActionType>();
    const timeTrackerTableRef = useRef<ActionType>();
    const timeTrackerGlobalTableRef = useRef<ActionType>();
    const materialsUtilizationTableRef = useRef<ActionType>();
    const materialsUtilizationGlobalTableRef = useRef<ActionType>();
    const invoicesIncomingListRef = useRef<ActionType>();
    const invoicesOutgoingListRef = useRef<ActionType>();
    const [taxCategories, setTaxCategories] = useState<API.TaxCategory[] | undefined>(undefined);
    const invoicingPlanListRef = useRef<ActionType>();
    const proposedDeliverableTemplatesListRef = useRef<ActionType>();
    const loggedPaymentsListRef = useRef<ActionType>();
    const transactionsListRef = useRef<ActionType>();
    const notificationsListRef = useRef<ActionType>();
    const [notifications, setNotifications] = useState<API.NotificationVolatile[] | undefined>(undefined);
    const invoicesIncomingGlobalListRef = useRef<ActionType>();
    const invoicesOutgoingGlobalListRef = useRef<ActionType>();


    return (
        // this is the provider providing state
        <GlobalStateContext.Provider value={{
            deliverablesListRef,
            wbsListRef,
            projectsListRef,
            wbsVersionsListRef,
            proposedWbsVersionsListRef,
            executionWbsVersionsListRef,
            proposedDeliverablesListRef,
            executionDeliverablesListRef,
            executionProgressTableRef,
            proposedWBSVersionRef,
            executionWBSVersionRef,
            timeTrackerTableRef,
            timeTrackerGlobalTableRef,
            materialsUtilizationTableRef,
            materialsUtilizationGlobalTableRef,
            invoicesIncomingListRef,
            invoicesOutgoingListRef,
            taxCategories,
            setTaxCategories,
            invoicingPlanListRef,
            proposedDeliverableTemplatesListRef,
            loggedPaymentsListRef,
            transactionsListRef,
            notificationsListRef,
            notifications,
            setNotifications,
            invoicesIncomingGlobalListRef,
            invoicesOutgoingGlobalListRef
        }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateProvider;
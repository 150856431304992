import {ActionType, ModalForm} from '@ant-design/pro-components';
import * as React from 'react';
import {MutableRefObject, useContext} from 'react';
import {Typography} from "antd";
import axiosApiInstance from "../../api/axiosClient";
import {GlobalStateContext} from "../../context/GlobalContext";

type Props = {
    projectIds: number[]
    label: string | React.ReactNode
    onClearSelection?: any
    batch?: boolean
};

export const DeleteProjects = (props: Props) => {
    const {projectsListRef} = useContext(GlobalStateContext);

    return (
        <ModalForm
            width={400}
            title={props.projectIds.length > 1 ? `Delete ${props.projectIds.length} Cases` : "Delete Case"}
            trigger={
                <Typography.Link
                    // type={props.batch ? "danger" : undefined}
                    type={"danger"}
                >{props.label}</Typography.Link>
            }
            submitter={{submitButtonProps: {danger: true}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                // onCancel: () => console.log('run'),
                okText: 'Delete',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return axiosApiInstance.delete('/api/projects/' + props.projectIds, {data: {ids: props.projectIds}})
                    .then(res => {
                        if (props.batch)
                            props.onClearSelection()
                        projectsListRef?.current?.reload()
                        return true
                    }).catch(reason => false)
            }}
        >
            Are you sure?
        </ModalForm>
    );
};

export default DeleteProjects
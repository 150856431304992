import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Bar, BarConfig, FunnelConfig} from '@ant-design/plots';
import {useSalesContext} from '../../context/SalesContextProvider';
import {Carousel, Col, DatePicker, Row, Segmented, Space, Typography} from 'antd';
import getOpportunityData from '../../util/sales/getOpportunityData';
import SalesFunnelComponent from './SalesFunnelComponent';
import {CarouselRef} from 'antd/es/carousel';
import opportunityFilter from '../../util/sales/opportunityFilter';
import dayjs, {Dayjs} from 'dayjs';
import {RangePickerProps} from 'antd/es/date-picker';
import belongsToFiscalYear from '../../util/sales/belongsToFiscalYear';
import {useSearchParams} from 'react-router-dom';
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";
import currencyFormatter from "../../util/currency_formatter";

type FunnelDatum = {
    phase: string;
    number: number;
    value_unweighted: number;
    value_weighted: number;
    timestamp?: string;
};

const addTimestamp = (datum: FunnelDatum, timestamp: Dayjs, group: 'A' | 'B') => {
    return {...datum, timestamp: `${group}: ${timestamp.format('DD-MM-YYYY')}`};
};

const optmap = {
    Current: 0,
    Comparison: 1
};

/**
 * Το ακόλουθο component είναι το container των funnel. Περιέχει αντίγραφο της λίστας των opportunities έτσι ωστε να
 * μπορεί να την επεξεργάζεται χωρίς να αλλοιώνει τα δεδομένα του SalesContext. Επίσης περιέχει δύο timestamp, μια global
 * και μια local. Η global χρησιμοποιείται για το versioning του μονού funnel, και η local χρησιμοποιείται στο funnel σύγκρισης
 * @returns
 */
const SalesFunnel = () => {
    const {opportunities, filters, timestamp} = useSalesContext();
    const {opportunityPhases} = useSalesAuxDataContext()

    const today = dayjs();
    const last_month = today.add(-1, 'month');

    const [globalTimestamp, setGlobalTimestamp] = useState<Dayjs>(timestamp ? timestamp : today);
    const [globalOpportunities, setGlobalOpportunities] = useState<API.Opportunity[]>(opportunities);

    const [localTimestamp, setLocalTimestamp] = useState<Dayjs>(
        timestamp ? timestamp.add(-1, 'month') : last_month
    );
    const [localOpportunities, setLocalOpportunities] = useState<API.Opportunity[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const [carouselSlide, setCarouselSlide] = useState<number>(parseInt(searchParams.get('tab') || '0'));
    const carouselRef = useRef<CarouselRef>(null);
    const slideNo = useRef({index: '0'});

    // let localTimestamp = timestamp ? timestamp.add(-1, 'month') : last_month;

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current > today.endOf('day');
    };

    useEffect(() => {
        const updateLocalOpportunities = async () => {
            const newOpps = await getOpportunityData(localTimestamp);
            console.log('newOpps', newOpps)
            setLocalOpportunities(newOpps.filter((opp) => opportunityFilter(opp, filters)));
        };
        updateLocalOpportunities();
    }, [localTimestamp, filters]);

    useEffect(() => {
        const updateGlobalOpportunities = async () => {
            const newOpps = await getOpportunityData(globalTimestamp);
            console.log('newOpps', newOpps)
            setGlobalOpportunities(newOpps.filter((opp) => opportunityFilter(opp, filters)));
        };
        updateGlobalOpportunities();
    }, [globalTimestamp, filters]);

    /**
     * Συναρτηση για την παραγωγή data του funnel. Ουσιαστικά μετατρέπει καθε opportunity phase
     * σε ένα datum του funnel, με τιμες συμπληρωμένες απο το opportunity list
     */
    const buildData = useCallback((oppList: API.Opportunity[]) => {
        const result = opportunityPhases
            .filter((phase) => phase.sales_step_number !== 0) // active opportunities only
            .map((phase) => {
                const phaseOpps = oppList
                    .filter(o => !o.is_compound) // TODO test
                    .filter((opp) => opp.opportunity_phase.name === phase.name)
                    .filter((opp) => opportunityFilter(opp, filters));
                return {
                    phase: phase.name,
                    number: phaseOpps.length, // number of opps in given phase
                    value_unweighted: phaseOpps.reduce<number>((acc, curr) => {
                        return curr.proposed_value_in_euro! + acc;
                    }, 0),
                    value_weighted: phaseOpps.reduce<number>((acc, curr) => {
                        return curr.po_value_in_euro! + acc;
                    }, 0)
                };
            });
        return result;
    }, []);

    const handleOnChangeLocalTimestamp = useCallback(async (timestamp: Dayjs | null) => {
        let new_local_ts = timestamp ? timestamp : last_month;
        setLocalTimestamp(new_local_ts);
        setLocalOpportunities(await getOpportunityData(new_local_ts));
    }, []);

    const handleOnChangeGlobalTimestamp = useCallback(async (timestamp: Dayjs | null) => {
        let new_global_ts = timestamp ? timestamp : today;
        setGlobalTimestamp(new_global_ts);
        setGlobalOpportunities(await getOpportunityData(new_global_ts));
    }, []);

    const handleCarouselSlideChange = useCallback((currentSlide: number) => {
        setCarouselSlide(currentSlide);
    }, []);

    const presentData = useMemo(() => buildData(opportunities), [opportunities, filters, timestamp]);

    useEffect(() => {
        console.log('presentData', presentData,
            [
                ...buildData(globalOpportunities.filter((opp) => belongsToFiscalYear(opp, globalTimestamp))).map(
                    (datum) => addTimestamp(datum, globalTimestamp, 'A')
                ),
                ...buildData(localOpportunities.filter((opp) => belongsToFiscalYear(opp, localTimestamp))).map(
                    (datum) => addTimestamp(datum, localTimestamp, 'B')
                )
            ]
        )
    }, [presentData])

    /**
     * Το config του μονού funnel
     */
    const config: FunnelConfig = {
        data: [...presentData].filter((datum) => datum.number !== 0),
        xField: 'phase',
        yField: 'value_unweighted',
        compareField: undefined,
        tooltip: {
            customContent(title, data) {
                const dataObj = data[0]?.data;
                const color = data[0]?.color;
                return `<style>
                .tooltip {
                    padding: 0px 16px 16px 8px;
                }
                .title {
                    flex-direction: row;
                    flex: 1;
                }
                .dot {
                    height: 6px;
                    width: 6px;
                    background-color: ${color};
                    border-radius: 50%;
                    display: inline-block;
                  }
                </style>
                <div class="tooltip">
                    <h4><span class="dot"></span> ${dataObj?.phase}</h4>
                    <div>Cardinality: ${dataObj?.number}</div>
                    <div>Value Unweighted: ${dataObj?.value_unweighted.toLocaleString()}€</div>
                    <div>Value Weighted: ${dataObj?.value_weighted.toLocaleString()}€</div>
                </div>`;
            }
        },
        minSize: 0.1,
        label: {
            formatter: (datum) => {
                // console.log('datum', datum)
                return `${currencyFormatter.format(datum['value_unweighted'])}`;
            },

        },
    };

    const configCompare: FunnelConfig = {
        data: [
            ...buildData(globalOpportunities.filter((opp) => belongsToFiscalYear(opp, globalTimestamp))).map(
                (datum) => addTimestamp(datum, globalTimestamp, 'A')
            ),

            ...buildData(localOpportunities.filter((opp) => belongsToFiscalYear(opp, localTimestamp))).map(
                (datum) => addTimestamp(datum, localTimestamp, 'B')
            )
        ],
        xField: 'phase',
        yField: 'value_unweighted',
        compareField: 'timestamp',
        tooltip: {
            customContent(title, data) {
                const dataObj = data[0]?.data;
                const color = data[0]?.color;
                return `<style>
                    .tooltip {
                        padding: 0px 16px 16px 8px;
                    }
                    .title {
                        flex-direction: row;
                        flex: 1;
                    }
                    .dot {
                        height: 6px;
                        width: 6px;
                        background-color: ${color};
                        border-radius: 50%;
                        display: inline-block;
                      }
                    </style>
                    <div class="tooltip">
                        <h4><span class="dot"></span> ${dataObj?.phase}</h4>
                        <div>Cardinality: ${dataObj?.number}</div>
                        <div>Value Unweighted: ${dataObj?.value_unweighted.toLocaleString()}€</div>
                        <div>Value Weighted: ${dataObj?.value_weighted.toLocaleString()}€</div>
                    </div>`;
            }
        },
        label: {
            style: {
                fillOpacity: 0
            }
        }
    };

    /**
     * Config του συγκριτικού funnel το οποίο στην ουσία είναι ενα bar graph για να φαίνονται καλύτερα οι τιμές
     */
    const configBar: BarConfig = {
        data: [
            ...buildData(globalOpportunities.filter((opp) => belongsToFiscalYear(opp, globalTimestamp))).map(
                (datum) => addTimestamp(datum, globalTimestamp, 'A')
            ),
            ...buildData(localOpportunities.filter((opp) => belongsToFiscalYear(opp, localTimestamp))).map(
                (datum) => addTimestamp(datum, localTimestamp, 'B')
            )
        ],
        xField: 'value_unweighted',
        yField: 'phase',
        isGroup: true,
        seriesField: 'timestamp',
        marginRatio: 0,
        label: undefined,
        tooltip: {
            formatter: (datum) => {
                return {name: datum.timestamp, value: `${datum.value_unweighted.toLocaleString()}€`};
            }
        }
    };

    return (
        <Space direction='vertical' style={{width: '100%'}}>
            <Space direction='horizontal'>
                {/* <Button onClick={handleOnClickTimestamp}>Ok</Button> */}
                <Segmented
                    options={[
                        {
                            label: 'Current',
                            value: 0
                        },
                        {
                            label: 'Comparison',
                            value: 1
                        }
                    ]}
                    onChange={(slide) => {
                        carouselRef.current?.goTo(slide as number, false);
                    }}
                />
            </Space>

            <Carousel
                beforeChange={handleCarouselSlideChange}
                afterChange={(slide) => {
                    searchParams.set('tab', '' + slide);
                    setSearchParams((p) => p);
                }}
                ref={carouselRef}
                dots={false}>
                <div className='current_funnel_container'>
                    <Row>
                        <Col span={24}>
                            <SalesFunnelComponent config={config}/>
                        </Col>
                    </Row>
                </div>
                <div className='comparison_funnel_container'>
                    <Row align='middle'>
                        <Col span={4}>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'center',
                                    paddingBottom: '45%'
                                }}>
                                <Space direction='vertical'>
                                    <Typography.Text>Group A</Typography.Text>
                                    <DatePicker
                                        format='DD-MM-YYYY'
                                        defaultValue={today}
                                        allowClear={false}
                                        disabledDate={disabledDate}
                                        onChange={(date, daystring) => {
                                            // setLocalTimestamp(date ? date : today);
                                            handleOnChangeGlobalTimestamp(date);
                                            // localTimestamp = date ? date : today;
                                        }}
                                    />
                                    <Typography.Text>Group B</Typography.Text>
                                    <DatePicker
                                        format='DD-MM-YYYY'
                                        defaultValue={last_month}
                                        allowClear={false}
                                        disabledDate={disabledDate}
                                        onChange={(date, daystring) => {
                                            handleOnChangeLocalTimestamp(date);
                                        }}
                                    />
                                </Space>
                            </div>
                        </Col>
                        <Col span={20}>
                            {/* <SalesFunnelComponent config={configCompare} /> */}
                            <Bar {...configBar} />
                        </Col>
                    </Row>
                </div>
                {/* <SalesFunnelComponent config={configCompare} /> */}
            </Carousel>
        </Space>
    );
};

export default SalesFunnel;

import type {ActionType} from '@ant-design/pro-components';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {PageContainer} from "@ant-design/pro-layout";
import {UserAuth} from "../../context/AuthContext";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import axiosApiInstance from "../../api/axiosClient";
import {Alert, Card, Row, Segmented, Space} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';


export default () => {

    const [projectData, setProjectData] = useState<API.Project | undefined>(undefined);
    const [tab, setTab] = useState("info");
    // const [tab, setTab] = useState("wbs");
    const actionRef = useRef<ActionType>();

    //maybe use for editing permissions
    const {can} = UserAuth();
    const location = useLocation();
    const routerParams = useParams();
    const navigate = useNavigate();

    const getData = async () => {
        try {
            const res = await axiosApiInstance.get<API.Project>('/api/projects/' + routerParams.projectId)
            setProjectData(res.data)
        } catch (e) {
        }
    }

    useEffect(() => {
        getData()
    }, [])

    // console.log(location.pathname)
    return (
        <PageContainer
            // title={"Case " + (projectData ? projectData?.project_pretty_id : routerParams.projectId)}
            title={`${projectData?.title || 'Untitled'}`}
            subTitle={`Case ${projectData?.project_pretty_id || '-'}`}
            // title={"Project " + routerParams.projectId}
            // breadcrumb={{}}
            tabList={[
                {
                    tab: 'General',
                    key: 'info',
                },
                {
                    tab: 'Proposals',
                    key: 'proposals',
                },
                {
                    tab: 'On Execution',
                    // key: 'on_execution',
                    key: 'executions',
                },
                {
                    tab: 'Progress',
                    key: 'progress',
                },
                {
                    tab: 'Time Tracker',
                    key: 'tracker',
                },
                {
                    tab: 'Materials Utilization',
                    key: 'materials',
                },
                {
                    tab: 'Invoices',
                    key: 'invoices',
                },
                {
                    tab: 'Payments',
                    key: 'payments',
                },
                {
                    tab: 'Accounting',
                    key: 'accounting',
                },
                {
                    tab: 'Documents',
                    key: 'documents',
                },
                // {
                //     tab: 'Financials',
                //     key: 'financials',
                // },
            ]}
            // onTabChange={setTab}
            onTabChange={(path) => {
                // setTab(path)
                // navigate(`../${path}`); // <-- sibling path
                navigate(`./${path}`); // <-- sibling path
            }}
            // tabActiveKey={tab}
            tabActiveKey={location.pathname.includes("invoices") ?
                "invoices" : location.pathname.includes("accounting") ? "accounting" :
                    location.pathname.split('/')[location.pathname.split('/').length - 1]}
            tabProps={{
                type: "card",
            }}
        >
            {location.pathname.includes("invoices") &&
            <Row justify={"space-between"} style={{display: "flex"}}>
                {location.pathname.includes("incoming") ?
                    <Alert type={"info"}
                        // style={{marginBottom: 4}}
                           style={{flex: 1}}
                           showIcon
                        // closable
                           message={'Invoices being sent to the company by third parties (service providers, vendors, retailers, etc.)  on the basis of a purchased service.'}/>

                    :
                    <Alert type={"info"}
                        // style={{marginBottom: 4}}
                           style={{flex: 1}}
                           showIcon
                        // closable
                           message={'Invoices being sent from the side of the company to its customers, counterparties, partners, etc.'}/>
                }

                <Space style={{marginBottom: 4}}>
                    <Card bodyStyle={{padding: 0}} bordered>
                        <Segmented size="large"
                                   options={[
                                       {label: 'Incoming', value: 'incoming', icon: <ArrowDownOutlined/>,},
                                       {label: 'Outgoing', value: 'outgoing', icon: <ArrowUpOutlined/>}
                                   ]}
                                   value={location.pathname.includes("incoming") ? 'incoming' : 'outgoing'}
                                   onChange={value => navigate(`invoices/${value}`, {relative: 'path'})}
                            // style={{ borderWidth: 1, borderStyle:"solid"}}
                        />
                    </Card>
                </Space>
            </Row>
            }

            {location.pathname.includes("accounting") &&
            <Row justify={"end"}>
                <Space style={{marginBottom: 4}}>
                    <Card bodyStyle={{padding: 0}} bordered>
                        <Segmented size="large"
                                   options={[
                                       {label: 'Suggested', value: 'suggested'},
                                       {label: 'Committed', value: 'committed'}
                                   ]}
                                   value={location.pathname.includes("suggested") ? 'suggested' : 'committed'}
                                   onChange={value => navigate(`../${value}`, {relative: 'path'})}
                            // style={{ borderWidth: 1, borderStyle:"solid"}}
                        />
                    </Card>
                </Space>
            </Row>
            }

            <Outlet/>

        </PageContainer>


    );
};

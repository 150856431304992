import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Form, Space, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormGroup,
    ProFormMoney,
    ProFormRadio,
    ProFormSelect,
    ProFormText
} from "@ant-design/pro-components";
import {ProFormInstance} from "@ant-design/pro-form/es/BaseForm/BaseForm";
import {PageLoading} from "@ant-design/pro-layout";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import {GlobalStateContext} from "../../context/GlobalContext";
import axiosApiInstance from "../../api/axiosClient";

dayjs.extend(utc)

type Props = {
    editable: boolean
};

const DEFAULT_GROSS_MARGIN_P_OTC = 38

const LoggedPayment = (props: Props) => {
    const [form] = Form.useForm<API.LoggedPayment>();
    const navigate = useNavigate();
    const routerParams = useParams();
    // const [editable, setEditable] = useState<boolean>(false);
    // const [editable, setEditable] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    // const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const [incomingInvoices, setIncomingInvoices] = useState<API.LoggedInvoiceIncoming[] | undefined>(undefined);
    const [outgoingInvoices, setOutgoingInvoices] = useState<API.LoggedInvoiceOutgoing[] | undefined>(undefined);
    const formRef = useRef<ProFormInstance>();
    const [paymentData, setPaymentData] = useState<API.LoggedPayment | undefined>(undefined);
    const {loggedPaymentsListRef} = useContext(GlobalStateContext);

    const fetchPaymentData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.LoggedPayment>(`/api/payments/${routerParams.paymentId}`)
            console.log(response.data)
            setPaymentData(response.data);
            form.setFieldsValue(response.data)
            setLoading(false)
        } catch (e) {
            // console.log('catch error')
            setPaymentData(undefined)
            setLoading(false)
        }

    }

    const fetchIncomingInvoices = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.LoggedInvoiceIncoming[]>(`/api/projects/${routerParams.projectId}/invoices/incoming`)
            // console.log(response.data)
            setIncomingInvoices(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setIncomingInvoices(undefined)
            setLoading(false)
        }
    };
    const fetchOutgoingInvoices = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.LoggedInvoiceOutgoing[]>(`/api/projects/${routerParams.projectId}/invoices/outgoing`)
            // console.log(response.data)
            setOutgoingInvoices(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setOutgoingInvoices(undefined)
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchPaymentData();
        // fetchWbsVersionData();
        fetchIncomingInvoices()
        fetchOutgoingInvoices()
    }, []);

    // if (loading) {
    //     return <PageLoading/>
    // }

    // console.log(taxCategories)

    return (
        <ModalForm<API.LoggedPayment>
            // title={`Deliverable ${routerParams.deliverableId}`}
            title={<Space>
                {/*<Typography.Text>Deliverable {routerParams.deliverableId}</Typography.Text>*/}
                {/*{invoiceData && <Typography.Text>Invoice {invoiceData.invoice_number}</Typography.Text>}*/}
                {paymentData && <Typography.Text>Payment #{routerParams.paymentId}</Typography.Text>}
                {/*<Button*/}
                {/*    key="ok" type={"text"}*/}
                {/*    icon={<EditOutlined/>}*/}
                {/*    onClick={() => {*/}
                {/*        setEditable(!editable)*/}
                {/*    }}*/}
                {/*/>*/}
            </Space>}
            visible={true}
            form={form}
            formRef={formRef}
            autoFocusFirstInput
            // width={"100%"}
            // width={"80%"}
            submitter={props.editable ? {} : false}
            modalProps={{
                destroyOnClose: true,
                okText: 'Save',
                okButtonProps: {hidden: !props.editable, loading: loading},
                onCancel: () => navigate(`/projects/${routerParams.projectId}/payments`),
                bodyStyle: {height: props.editable ? "calc(100vh - 270px)" : "calc(100vh - 370px)", overflowY: 'auto'},
                style: {top: props.editable ? 40 : 90},
                // style: {top: 20},
            }}
            onFinish={async formData => {
                console.log(formData)
                // return
                return axiosApiInstance.put('/api/payments/' + routerParams.paymentId, formData)
                    .then(() => {
                        fetchPaymentData();
                        loggedPaymentsListRef?.current?.reload()
                        return false
                    })
                // .then(()=>form.resetFields())
            }}
            validateMessages={{required: "${label} is required!"}}
            readonly={!props.editable}
            requiredMark={props.editable}
            // colon={true}
            // onValuesChange={(changedValues, values) => console.log(changedValues, values)}
        >

            {loading ? <PageLoading/> :
                <>

                    <ProFormDigit
                        name="id"
                        hidden={true}
                    />

                    <ProFormGroup label={'General Information'}>
                        <ProFormGroup>
                            <ProFormMoney
                                name="amount"
                                label={'Amount'}
                                customSymbol={'€'}
                                fieldProps={{
                                    numberFormatOptions: {currency: 'EUR'}
                                }}
                                width={"xs"}
                                rules={[{required: true}]}
                            />
                            <ProFormRadio.Group
                                // valueEnum={{
                                //     1: {text: 'Bank Account'},
                                //     2: {text: 'Paypal'},
                                // }}
                                options={[
                                    {value: 1, label: 'Bank Account'},
                                    {value: 2, label: 'Paypal'},
                                ]}
                                radioType={"button"}
                                width="xl"
                                name="payment_method_id"
                                label="Payment method"
                                rules={[{required: true}]}
                            />
                            <ProFormText
                                width="sm"
                                name="transaction_id"
                                label="Transaction ID"
                                // rules={[{required: true}]}
                            />
                        </ProFormGroup>
                        <ProFormGroup>
                            <ProFormRadio.Group
                                // valueEnum={{
                                //     1: {text: 'Pending', status: 'warning'},
                                //     2: {text: 'Complete', status: 'success'},
                                //     3: {text: 'Refunded', status: 'success'},
                                //     4: {text: 'Failed', status: 'error'},
                                //     5: {text: 'Cancelled', status: 'error'},
                                // }}
                                options={[
                                    {value: 1, label: 'Pending'},
                                    {value: 2, label: 'Completed'},
                                    {value: 3, label: 'Refunded'},
                                    {value: 4, label: 'Failed'},
                                    {value: 5, label: 'Cancelled'},
                                ]}
                                radioType={"button"}
                                width="xl"
                                name="payment_status_id"
                                label="Payment status"
                                rules={[{required: true}]}
                            />
                            <ProFormDatePicker name={"date_effective"} label={"Date Effective"}
                                               rules={[{required: true}]}
                                               normalize={(value) => {
                                                   return value.toISOString()
                                               }}
                            />
                        </ProFormGroup>


                        {/*<ProFormItem*/}
                        {/*    name="payment_status"*/}
                        {/*    hidden={true}*/}
                        {/*/>*/}

                        {/*<ProFormItem*/}
                        {/*    name="payment_method"*/}
                        {/*    hidden={true}*/}
                        {/*/>*/}


                    </ProFormGroup>

                    <ProFormGroup label={'Relative Invoice'}>
                        <ProFormSelect
                            valueEnum={{
                                incoming: 'Incoming',
                                outgoing: 'Outgoing',
                            }}
                            width={"sm"}
                            name="invoice_type"
                            label="Invoice Type"
                            rules={[{required: true}]}
                            fieldProps={{onSelect: () => form.setFieldValue(['invoice_id'], undefined)}}
                        />

                        <ProFormDependency name={[['invoice_type']]}>
                            {({invoice_type}) => {
                                if (invoice_type === 'incoming') {
                                    let options: any = []
                                    incomingInvoices?.map((c) => {
                                        options.push({
                                            value: c.id,
                                            label: `${c.description}`
                                        })
                                    })
                                    // console.log(wbsVersionData?.execution_deliverables)
                                    return <ProFormSelect
                                        options={options}
                                        width={"md"}
                                        name="invoice_id"
                                        label="Invoice"
                                        rules={[{required: true}]}
                                    />
                                }
                                let options: any = []
                                outgoingInvoices?.map((c) => {
                                    options.push({
                                        value: c.id,
                                        label: `${c.description}`
                                    })
                                })
                                return <ProFormSelect
                                    options={options}
                                    width={"md"}
                                    name="invoice_id"
                                    label="Invoice"
                                    rules={[{required: true}]}
                                />
                            }}
                        </ProFormDependency>

                    </ProFormGroup>


                </>
            }

        </ModalForm>
    );
};

export default LoggedPayment;

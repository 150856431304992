import {Button, Tooltip} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import exportToExcel from '../../util/sales/exportToExcel';

type Props = {
    opportunities: API.Opportunity[];
};

const ExportExcel = (props: Props) => {
    return <Tooltip title={'Export to Excel'}>
        <Button type='text' icon={<DownloadOutlined />} onClick={()=>exportToExcel(props.opportunities)} />
    </Tooltip>;
};

export default ExportExcel;

import * as React from 'react';
import './ProposedWBSVersions.css'
import {useContext, useState} from 'react';
import {GlobalStateContext} from "../../context/GlobalContext";
import axiosApiInstance from "../../api/axiosClient";
import {Link, Outlet, useParams} from "react-router-dom";
import {ProList} from "@ant-design/pro-components";
import {Button, Col, Row, Space, Tag, Tooltip, Typography} from "antd";
import {BuildTwoTone, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import DeleteProposedWBSVersion from "../wbs/proposals/DeleteProposedWBSVersion";
import currencyFormatter from "../../util/currency_formatter";

type Props = {};


const ProposedWBSVersions = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    // const actionRef = useRef<ActionType>();
    // const {wbsVersionsListRef} = useContext(GlobalStateContext);
    const {proposedWbsVersionsListRef} = useContext(GlobalStateContext);

    const copyProposal = async (wbsVersion: API.ProposedWBSVersion) => {
        try {
            setLoading(true)
            await axiosApiInstance.post<API.ProposedWBSVersion>(`/api/proposals/${wbsVersion.id}/copy`)
            setLoading(false)
            proposedWbsVersionsListRef?.current?.reload()
        } catch (e) {
            console.log('catch error')
            setLoading(false)
        }
    }

    const setActiveProposal = async (proposalId: number) => {
        try {
            setLoading(true)
            await axiosApiInstance.post<API.ProposedWBSVersion>(`/api/proposals/${proposalId}/setactive`)
            setLoading(false)
            proposedWbsVersionsListRef?.current?.reload()
        } catch (e) {
            console.log('catch error')
            setLoading(false)
        }
    }

    const routerParams = useParams();

    return (
        <div>

            {/* show wbs versions */}
            {/*<ProCard>*/}
            <ProList<API.ProposedWBSVersion>
                className={'ProposedWBSVersions'}
                cardBordered
                pagination={{
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    // hideOnSinglePage: true
                    size: "small"
                }}
                headerTitle={'Proposed Structures'}
                actionRef={proposedWbsVersionsListRef}
                rowClassName={(record: API.ProposedWBSVersion) => record.is_active ? 'normal-row' : "disabled-row"}
                // search={{filterType: "light"}}
                toolBarRender={() => {
                    return [
                        <Tooltip title={"To create a new Proposal from scratch, click here."}>
                            <Button type="primary" icon={<PlusOutlined/>}
                                    onClick={async () => {
                                        await axiosApiInstance.post<API.ProposedWBSVersion>(`/api/projects/${routerParams.projectId}/structures/proposal`)
                                        proposedWbsVersionsListRef?.current?.reload()
                                    }}>
                                New
                            </Button>
                            {/*{props.content == 'onexecution' &&*/}
                            {/*<Button type="primary" icon={<PlusOutlined/>}*/}
                            {/*        onClick={async ()=> {*/}
                            {/*            await axiosApiInstance.post<API.ProposedWBSVersion>(`/api/projects/${routerParams.projectId}/onexecution`)*/}
                            {/*            wbsVersionsListRef?.current?.reload()*/}
                            {/*        }}>*/}
                            {/*    New*/}
                            {/*</Button>*/}
                            {/*}*/}
                        </Tooltip>,
                        <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                            proposedWbsVersionsListRef?.current?.reset!()
                            // actionRef.current?.reset!()
                        }}/>
                    ];
                }}
                // showActions={"hover"}
                tooltip="A work breakdown structure is subject to explicit versioning."
                metas={{
                    title: {
                        search: false,
                        // formItemProps:(form, config) => ({style:{pointerEvents:"none"}}),
                        // fieldProps:(form, config) => ({style:{pointerEvents:"none"}}),
                        render: (dom, entity) => entity.is_active ? <Link
                                to={`/projects/${routerParams.projectId}/proposals/${entity.id}`}>Version {entity.wbs_version_no}</Link> :
                            <Typography.Text strong style={{
                                color: '#919191',
                                cursor: "default",
                            }}>Version {entity.wbs_version_no}</Typography.Text>
                        // render:(dom, entity) => (`Version ${entity.wbs_version_no}`)
                        // render: (dom, entity) =><Link
                        //                                 to={`/projects/${routerParams.projectId}/proposals/${entity.id}`}>Version {entity.wbs_version_no}</Link>
                    },
                    subTitle: {
                        search: false,
                        render: (dom, entity) => (
                            <>
                                <Space>
                                    <Tag color="blue">{entity.proposed_deliverables?.length || 'No'} deliverables</Tag>
                                    {entity.is_active && <Tag color={"green"}>Active</Tag>}
                                    {/*{entity.status?.title && <Tag color="red">{entity.status.title}</Tag>}*/}
                                    {/*{new Date(item.created_at).toLocaleDateString('el')}*/}
                                </Space>
                            </>)
                    }
                    ,
                    // type: {},
                    description: {
                        search: false,
                        dataIndex: 'pretty_id',
                        title: 'Pretty ID',
                        valueType: 'text'
                        // search: false,
                    },
                    avatar: {
                        // dataIndex: 'avatar',
                        search: false,
                        render: () => <Row justify={"center"} align="middle" style={{width: 36, height: 48}}>
                            <Col>
                                <BuildTwoTone style={{fontSize: 24}}/>
                            </Col>
                        </Row>
                        // render: () => <BuildTwoTone style={{fontSize: 24}}/>
                    },
                    content: {
                        search: false,
                        render: (dom, entity) => {

                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: "wrap",
                                        rowGap: 16,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '150px',
                                            // width: '200px',
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Total Cost'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{currencyFormatter.format(entity.total_cost)}</Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: '150px',
                                            // width: '200px',
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Total Price'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{currencyFormatter.format(entity.total_price)}</Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: '150px',
                                            // width: '200px',
                                            // flexGrow: 1
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Duration (days)'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{entity.total_estimated_duration_days}</Typography.Text>
                                        </Space>
                                    </div>
                                    <div
                                        style={{
                                            width: '80px',
                                            // width: '150px',
                                            // width: '200px',
                                            // alignSelf: 'start'
                                        }}
                                    >
                                        <Space direction={"vertical"} align={"start"}>
                                            <Typography.Text type={"secondary"}>{'Created At'}</Typography.Text>
                                            <Typography.Text
                                                type={"secondary"}>{new Date(entity.created_at).toLocaleDateString('el')}</Typography.Text>
                                        </Space>
                                    </div>
                                </div>
                            )
                        },
                    },
                    actions: {
                        render: (dom, entity) => {
                            return <Space>
                                <DeleteProposedWBSVersion id={entity.id}/>
                                <Button type={"link"} onClick={() => copyProposal(entity)}>Make a Copy</Button>
                                <Button type={"link"} onClick={() => setActiveProposal(entity.id!)}>Set as
                                    Active</Button>
                            </Space>
                        },
                    },
                }}
                request={() => axiosApiInstance.get<API.ProposedWBSVersion[]>(`/api/projects/${routerParams.projectId}/structures/proposals`)}
            />
            {/*</ProCard>*/}

            <Outlet/>

        </div>
    );
};

export default ProposedWBSVersions;
import {Column} from '@ant-design/plots';
import {ProCard} from '@ant-design/pro-components';
import {ArrowDownOutlined, ArrowUpOutlined, CalendarOutlined, TagsOutlined} from '@ant-design/icons';
import {Card, Statistic} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';

import getOpportunityData from '../util/sales/getOpportunityData';
import belongsToFiscalYear from '../util/sales/belongsToFiscalYear';
import getOpportunityPhases from '../util/sales/getOpportunityPhases';
import getCashflow from '../util/projects/getCashflow';
import getInvoicesIncoming from '../util/projects/getInvoicesIncoming';
import getInvoicesOutgoing from '../util/projects/getInvoicesOutgoing';
import {PageLoading} from "@ant-design/pro-layout";
import {ColumnConfig} from "@ant-design/plots/es/components/column";
import currencyFormatter from "../util/currency_formatter";

const PreviewCardStyle: React.CSSProperties = {
    // height: '60vh',
    // minHeight: 360,
    height: 560,
    // width: 879,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    boxShadow: 'rgba(35, 49, 128, 0.02) 0px 2px 4px 0px, rgba(49, 69, 179, 0.02) 0px 4px 8px 0px'
};

const InfoCard: React.FC<{
    title: string;
    index: number;
    desc: string;
    href: string;
}> = ({title, href, index, desc}) => {
    return (
        <div
            style={{
                backgroundColor: '#FFFFFF',
                boxShadow: '0 2px 4px 0 rgba(35,49,128,0.02), 0 4px 8px 0 rgba(49,69,179,0.02)',
                borderRadius: '8px',
                fontSize: '14px',
                color: 'rgba(0,0,0,0.65)',
                textAlign: 'justify',
                lineHeight: ' 22px',
                padding: '16px 19px',
                flex: 1
            }}>
            <div
                style={{
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center'
                }}>
                <div
                    style={{
                        width: 48,
                        height: 48,
                        lineHeight: '22px',
                        backgroundSize: '100%',
                        textAlign: 'center',
                        padding: '8px 16px 16px 12px',
                        color: '#FFF',
                        fontWeight: 'bold',
                        backgroundImage:
                            "url('https://gw.alipayobjects.com/zos/bmw-prod/daaf8d50-8e6d-4251-905d-676a24ddfa12.svg')"
                    }}>
                    {index}
                </div>
                <div
                    style={{
                        fontSize: '16px',
                        color: 'rgba(0, 0, 0, 0.85)',
                        paddingBottom: 8
                    }}>
                    {title}
                </div>
            </div>
            <div
                style={{
                    fontSize: '14px',
                    color: 'rgba(0,0,0,0.65)',
                    textAlign: 'justify',
                    lineHeight: '22px',
                    marginBottom: 8
                }}>
                {desc}
            </div>
            <Link to={href}>Go {'>'}</Link>
            {/*<a href={href} target="_blank" rel="noreferrer">*/}
            {/*    Link {'>'}*/}
            {/*</a>*/}
        </div>
    );
};

const Homepage: React.FC = () => {
    const [opportunities, setOpportunities] = useState<API.Opportunity[]>([]);
    const [cashflow, setCashflow] = useState<API.BrandsCashFlowStatus | undefined>();
    const [incoming, setIncoming] = useState<API.LoggedInvoiceIncoming[]>([]);
    const [outgoing, setOutgoing] = useState<API.LoggedInvoiceOutgoing[]>([]);
    const [phases, setPhases] = useState<API.OpportunityPhase[]>([]);
    const [config, setConfig] = useState<ColumnConfig | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    /**
     * Gather the relevant data needed to preview on the front page of the website.
     * glossary:
     *
     * os: opportunities
     * ps: phases
     * cf: cashflow
     * ii: invoices incoming
     * io: invoices outgoing
     */
    useEffect(() => {
        setLoading(true);
        Promise.all([
            getOpportunityData(),
            getOpportunityPhases(),
            getCashflow({
                // current year
                invoice_issue_date_start: dayjs().startOf('year').format('YYYY-MM-DD'),
                invoice_issue_date_end: dayjs().endOf('year').format('YYYY-MM-DD'),
            }),
            getInvoicesIncoming({
                due_date_start: dayjs().startOf('month').format('YYYY-MM-DD'),
                due_date_end: dayjs().endOf('month').format('YYYY-MM-DD')
            }),
            getInvoicesOutgoing({
                due_date_start: dayjs().startOf('month').format('YYYY-MM-DD'),
                due_date_end: dayjs().endOf('month').format('YYYY-MM-DD')
            })
        ])
            .then(([os, ps, cf, ii, io]) => {
                setOpportunities(os.filter(o => !o.is_compound).filter((o) => belongsToFiscalYear(o, dayjs()))); // get current fiscal year opportunities
                setPhases(ps.filter((p) => !p.status.disabled)); // filter out phases that signify an opportunity as disabled
                setCashflow(cf);
                setIncoming(ii);
                setOutgoing(io);
                setLoading(false);
            })
            .catch((e) => {
                console.error(e)
                setLoading(false);
            });
    }, []);

    /**
     * Initialize funnel config, refresh on opportunities or phases change
     */
    useEffect(() => {
        // setLoading(true)
        const data = buildData().filter((d) => d.number !== 0);
        const cfg: ColumnConfig = {
            data,
            // renderer: 'canvas',
            height: 120,
            xField: 'phase',
            yField: 'value_unweighted',
            legend: false,
            // label: false,
            label: {
                position: 'top',
                formatter: (d) => d.value_unweighted.toLocaleString() + ' €'
            },
            padding: [32, 0, 0, 0],
            tooltip: {
                formatter: (d) => ({name: d.phase, value: `${d.value_unweighted.toLocaleString()} €`})
            },
            // maxColumnWidth: 40,
            theme: {
                columnStyle: {
                    stroke: '#fff',
                    lineWidth: 1
                }
            },
            // xAxis: false,
            yAxis: false,
            interactions: [{type: 'element-active'}]
        };
        // const cfg: TinyColumnConfig = {
        //     data: data.map((d) => (d.value_unweighted)),
        //
        //     tooltip: {
        //         customContent: function (x, data) {
        //             return `${data[0].data.phase}`;
        //         },
        //     },
        // };
        setConfig(cfg);
        // setLoading(false)
    }, [opportunities, phases]);


    /**
     * transforms each phase into Funnel datum with aggregates from each corresponding opportunity from opportunities list
     */
    const buildData = useCallback(() => {
        const result = phases.map((phase) => {
            const phaseOpps = opportunities.filter((opp) => opp.opportunity_phase.name === phase.name);
            return {
                phase: phase.name,
                number: phaseOpps.length, // number of opps in given phase
                value_unweighted: phaseOpps.reduce<number>(
                    (acc, curr) => curr.proposed_value_in_euro! + acc,
                    0
                ),
                value_weighted: phaseOpps.reduce<number>((acc, curr) => curr.po_value_in_euro! + acc, 0)
            };
        });
        return result;
    }, [opportunities, phases]);

    return (
        <>
            {!loading ?
                <Card
                    style={{
                        borderRadius: 8
                    }}
                    bodyStyle={{
                        backgroundImage: 'radial-gradient(circle at 97% 10%, #EBF2FF 0%, #F5F8FF 28%, #EBF1FF 124%)'
                    }}>

                    <div
                        style={{
                            backgroundPosition: '100% -6%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '274px auto',
                            backgroundImage:
                                "url('https://gw.alipayobjects.com/mdn/rms_a9745b/afts/img/A*BuFmQqsB2iAAAAAAAAAAAAAAARQnAQ')"
                        }}>
                        <div
                            style={{
                                fontSize: '20px',
                                color: '#1A1A1A'
                            }}>
                            Brand Deals
                        </div>
                        <p
                            style={{
                                fontSize: '14px',
                                color: 'rgba(0,0,0,0.65)',
                                lineHeight: '22px',
                                marginTop: 16,
                                marginBottom: 32,
                                width: '65%'
                            }}>
                            Digital Minds Brand Deals Application
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                gap: 16
                            }}>
                            <InfoCard index={1} title='Sales' href='/sales' desc='Sales opportunities'/>
                            {/*<InfoCard*/}
                            {/*    index={2}*/}
                            {/*    title="Work Breakdown Structures"*/}
                            {/*    href="/wbs"*/}
                            {/*    desc="Work Breakdown Structures"*/}
                            {/*/>*/}
                            <InfoCard index={2} title='Cases' href='/projects' desc='All cases (projects)'/>
                        </div>
                        {/**
                         * Container for statistics summary
                         */}
                        <ProCard
                            // ghost
                            gutter={[16, 8]}
                            split='vertical'
                            style={{
                                display: 'flex',
                                flex: 1,
                                // height: '65vh',
                                borderRadius: 8,
                                padding: 12,
                                marginTop: 16
                            }}>
                            {/**
                             * Left side displaying opportunity summary in the following layout:
                             * Total Opportunities  │   Unweighted Revenue
                             * Fiscal Year          │   Weighted Revenue
                             * ─────────────────────┼─────────────────────────────
                             * Recent Opportunities │   Funnel
                             */}
                            <ProCard colSpan={12} direction='column'>
                                <ProCard split='vertical' style={{borderRadius: 8}}>
                                    <ProCard colSpan={12}>
                                        <Statistic
                                            title='Fiscal Year'
                                            prefix={<CalendarOutlined style={{color: '#aaa'}}/>}
                                            groupSeparator=''
                                            value={dayjs().year()}
                                        />
                                    </ProCard>
                                    <ProCard colSpan={12}>
                                        <Statistic
                                            title='Total Opportunities'
                                            prefix={<TagsOutlined style={{color: '#aaa'}}/>}
                                            value={opportunities.length}
                                        />
                                    </ProCard>
                                </ProCard>
                                <ProCard split='vertical'>
                                    <ProCard colSpan={12}>
                                        <Statistic
                                            title='Unweighted Revenue'
                                            // suffix='€'
                                            value={
                                                currencyFormatter.format(Math.round(opportunities.reduce((acc, opp) => acc + opp.proposed_value_in_euro!, 0) * 100) / 100)}
                                        />
                                    </ProCard>
                                    <ProCard colSpan={12}>
                                        <Statistic
                                            title='Weighted Revenue'
                                            // suffix='€'
                                            value={currencyFormatter.format(Math.round(opportunities.reduce((acc, opp) => acc + opp.po_value_in_euro!, 0) * 100) / 100)}
                                        />
                                    </ProCard>
                                </ProCard>

                                {/*<ProCard style={{height: 'auto', flex: 1, borderRadius: 8}} bodyStyle={{}} bordered>*/}
                                {/*<ProCard colSpan={12} bodyStyle={{padding: 0}}>*/}
                                {/*    <ProList<API.Opportunity>*/}
                                {/*        rowKey='name'*/}
                                {/*        headerTitle='Recent Opportunities'*/}
                                {/*        style={{*/}
                                {/*            height: 300,*/}
                                {/*            overflowX: 'hidden',*/}
                                {/*            overflowY: 'auto'*/}
                                {/*        }}*/}
                                {/*        dataSource={opportunities*/}
                                {/*            .sort((prev, next) => {*/}
                                {/*                const prevDate = new Date(prev.created_at);*/}
                                {/*                const nextDate = new Date(next.created_at);*/}

                                {/*                return nextDate.valueOf() - prevDate.valueOf();*/}
                                {/*            })*/}
                                {/*            .slice(0, 12)}*/}
                                {/*        onRow={(opp: API.Opportunity) => ({*/}
                                {/*            onClick: () => {*/}
                                {/*                navigate(`/sales/${opp.id}`);*/}
                                {/*            }*/}
                                {/*        })}*/}
                                {/*        metas={{*/}
                                {/*            title: {*/}
                                {/*                dataIndex: 'title',*/}
                                {/*                title: 'title',*/}
                                {/*                render: (_, dom) => (*/}
                                {/*                    <Typography.Text*/}
                                {/*                        style={{width: 200}}*/}
                                {/*                        ellipsis={{tooltip: dom.title}}>*/}
                                {/*                        {dom.title}*/}
                                {/*                    </Typography.Text>*/}
                                {/*                )*/}
                                {/*            },*/}
                                {/*            subTitle: {*/}
                                {/*                dataIndex: 'opportunity_pretty_id',*/}
                                {/*                render: (_, row) => (*/}
                                {/*                    <Tag color='blue'>*/}
                                {/*                        {row.project_pretty_id || row.opportunity_pretty_id}*/}
                                {/*                    </Tag>*/}
                                {/*                )*/}
                                {/*            }*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</ProCard>*/}
                                <ProCard
                                    // colSpan={12}
                                    bodyStyle={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        paddingTop: 8,
                                        paddingBottom: 8
                                    }}>
                                    {config && <Column {...config} />}
                                </ProCard>
                                {/*</ProCard>*/}
                            </ProCard>

                            {/**
                             * Right side displaying wbs summary in the following layout:
                             *
                             * Invoices Completed Incoming   │   Invoices Completed Outgoing
                             * Invoices Pending Incoming    │   Invoices Pending Outgoing
                             * ─────────────────────────────┼─────────────────────────────
                             * LoggedInvoiceIncoming list   │   LoggedInvoiceOutgoing list
                             */}
                            <ProCard
                                colSpan={12}
                                //  style={PreviewCardStyle}
                                direction='column'>
                                <ProCard split='vertical'
                                    // title='Receivables'
                                >
                                    <ProCard colSpan={12}>
                                        <Statistic
                                            title={
                                                <>
                                                    <div style={{color: '#3f8600'}}>
                                                        Receivables <ArrowUpOutlined style={{marginTop: 8}}/>
                                                    </div>
                                                </>
                                            }
                                            precision={2}
                                            value={currencyFormatter.format(cashflow?.account_receivables || 0)}
                                            // suffix='€'
                                        />
                                    </ProCard>
                                    <ProCard colSpan={12}>
                                        <Statistic
                                            title={
                                                <>
                                                    <div style={{color: '#cf1322'}}>
                                                        Payables <ArrowDownOutlined style={{marginTop: 8}}/>
                                                    </div>
                                                </>
                                            }
                                            precision={2}
                                            value={currencyFormatter.format(cashflow?.account_payables || 0)}
                                            // suffix='€'
                                        />
                                    </ProCard>
                                </ProCard>
                                <ProCard split='vertical' title='Invoices Due Next Month'>
                                    <ProCard colSpan={12}>
                                        <Statistic
                                            title={
                                                <>
                                                    <div style={{color: '#cf1322'}}>
                                                        <span>Incoming
                                                        ({incoming.filter(
                                                                inv => dayjs(inv.due_date).isBetween(dayjs().startOf('month'), dayjs().endOf('month'))
                                                            ).filter(inv => inv.paid === 'NO').reduce((acc, inv) => acc + 1, 0)})
                                                            {' '}
                                                            <ArrowDownOutlined style={{marginTop: 8}}/> </span>
                                                    </div>
                                                </>
                                            }
                                            // precision={2}
                                            value={currencyFormatter.format(Math.round(incoming.filter(
                                                inv => dayjs(inv.due_date).isBetween(dayjs().startOf('month'), dayjs().endOf('month'))
                                            ).filter(inv => inv.paid === 'NO').reduce((acc, inv) => acc + inv.cost, 0) * 100) / 100)}
                                            // suffix='€'
                                        />
                                    </ProCard>
                                    <ProCard colSpan={12}>
                                        <Statistic
                                            title={
                                                <>
                                                    <div style={{color: '#3f8600'}}>
                                                        Outgoing
                                                        ({outgoing.filter(
                                                        inv => dayjs(inv.due_date).isBetween(dayjs().startOf('month'), dayjs().endOf('month'))
                                                    ).filter(inv => inv.paid === 'NO').reduce((acc, inv) => acc + 1, 0)})
                                                        {' '}
                                                        <ArrowUpOutlined style={{marginTop: 8}}/>
                                                    </div>
                                                </>
                                            }
                                            // precision={2}
                                            value={currencyFormatter.format(Math.round(outgoing.filter(
                                                inv => dayjs(inv.due_date).isBetween(dayjs().startOf('month'), dayjs().endOf('month'))
                                            ).filter(inv => inv.paid === 'NO').reduce((acc, inv) => acc + inv.cost, 0) * 100) / 100)}
                                            // suffix='€'
                                        />
                                    </ProCard>
                                </ProCard>

                                {/*<ProCard*/}
                                {/*    direction='row'*/}
                                {/*    bodyStyle={{*/}
                                {/*        paddingInline: 0,*/}
                                {/*        paddingBlock: 0*/}
                                {/*    }}*/}
                                {/*    style={{*/}
                                {/*        height: '100%',*/}
                                {/*        width: '100%',*/}
                                {/*        // backgroundColor: '#bee'*/}
                                {/*        paddingInline: 0,*/}
                                {/*        paddingBlock: 0*/}
                                {/*    }}>*/}
                                {/*    <ProCard colSpan={12}*/}
                                {/*             style={{*/}
                                {/*                 padding: 0,*/}
                                {/*                 paddingInline: 0,*/}
                                {/*                 paddingBlock: 0*/}
                                {/*             }}*/}
                                {/*             bodyStyle={{*/}
                                {/*                 padding: 0,*/}
                                {/*                 paddingInline: 0,*/}
                                {/*                 paddingBlock: 0*/}
                                {/*             }}>*/}
                                {/*        <ProList<API.LoggedInvoiceIncoming>*/}
                                {/*            rowKey='id'*/}
                                {/*            style={{*/}
                                {/*                overflowY: 'auto',*/}
                                {/*                overflowX: 'hidden',*/}
                                {/*                padding: 0,*/}
                                {/*                height: 200*/}
                                {/*            }}*/}
                                {/*            tableStyle={{padding: 0}}*/}
                                {/*            dataSource={incoming.filter((i) => i.paid === 'NO').slice(0, 12)}*/}
                                {/*            onRow={(inv: API.LoggedInvoiceIncoming) => ({*/}
                                {/*                onClick: () => {*/}
                                {/*                    console.log(inv);*/}
                                {/*                }*/}
                                {/*            })}*/}
                                {/*            metas={{*/}
                                {/*                title: {*/}
                                {/*                    dataIndex: 'invoice_number',*/}
                                {/*                    render: (_, row) => <Tag color='red'>{row.invoice_number}</Tag>*/}
                                {/*                },*/}
                                {/*                // avatar: {*/}
                                {/*                //     render: () => (*/}
                                {/*                //         // <div style={{color: '#3f8600'}}>*/}
                                {/*                //         <ArrowUpOutlined/>*/}
                                {/*                //         // </div>*/}
                                {/*                //     )*/}
                                {/*                // },*/}
                                {/*                subTitle: {*/}
                                {/*                    dataIndex: 'due_date',*/}
                                {/*                    render: (_, row) => (*/}
                                {/*                        `Due ${dayjs(row.due_date).format('DD/MM/YYYY')}`*/}
                                {/*                    )*/}
                                {/*                }*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </ProCard>*/}
                                {/*    <ProCard colSpan={12}*/}
                                {/*             style={{*/}
                                {/*                 paddingInline: 0,*/}
                                {/*                 paddingBlock: 0*/}
                                {/*             }}*/}
                                {/*             bodyStyle={{*/}
                                {/*                 padding: 0,*/}
                                {/*                 paddingInline: 0,*/}
                                {/*                 paddingBlock: 0*/}
                                {/*             }}>*/}
                                {/*        <ProList<API.LoggedInvoiceOutgoing>*/}
                                {/*            rowKey='id'*/}
                                {/*            dataSource={outgoing.filter((i) => i.paid === 'NO').slice(0, 3)}*/}
                                {/*            metas={{*/}
                                {/*                title: {*/}
                                {/*                    dataIndex: 'invoice_number',*/}
                                {/*                    render: (_, row) => <Tag color='green'>{row.invoice_number}</Tag>*/}
                                {/*                },*/}
                                {/*                subTitle: {*/}
                                {/*                    dataIndex: 'due_date',*/}
                                {/*                    render: (_, row) => (*/}
                                {/*                        `Due ${dayjs(row.due_date).format('DD/MM/YYYY')}`*/}
                                {/*                    )*/}
                                {/*                }*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </ProCard>*/}
                                {/*</ProCard>*/}
                            </ProCard>
                        </ProCard>
                    </div>
                </Card> :
                <PageLoading/>
            }
        </>
    );
};

export default Homepage;

import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Avatar, Divider, Layout, Skeleton, Space, Tag} from 'antd';
import {ActionType, ProCard, ProDescriptions, ProDescriptionsItemProps} from '@ant-design/pro-components';
import {Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {InfoCircleOutlined, SearchOutlined, UserOutlined, WarningOutlined} from "@ant-design/icons";
import currencyFormatter from "../../util/currency_formatter";
import {useSalesContext} from "../../context/SalesContextProvider";
import OpportunityCard from "./OpportunityCard";

type Props = {
    // target: API.Opportunity;
    // target: Partial<API.Opportunity>;
};

type Options = {
    label: string;
    value: number | string;
};

const {Header, Content, Footer} = Layout;

const OpportunityInfoLayout = ({}: Props) => {
    // const [employees, setEmployees] = useState<Options[]>([]);
    const [selectedEmp, setSelectedEmp] = useState<number | null>(null);
    // const [allEmployees, setAllEmployees] = useState<Options[]>([]);
    // const [opportunityData, setOpportunityData] = useState<API.Opportunity | undefined>(undefined);
    const {opportunitiesUnfiltered:opportunities} = useSalesContext();
    // const {employees} = useSalesAuxDataContext();
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const {id: opportunity_id} = useParams();
    const target = opportunities.find((opp) => opportunity_id == `${opp.id}`);

    // useEffect(() => {
    //     console.log({target});
    // }, [target]);

    // useEffect(() => {
    //     const getAllEmployees = async () => {
    //         await getEmployeeOptions()
    //             .then((emps) => emps.map((e) => ({label: e.name, value: e.id})))
    //             .then((emps) => {
    //                 setEmployees(emps);
    //                 setAllEmployees(emps);
    //             })
    //             .catch((e) => {
    //                 setEmployees([]);
    //                 setAllEmployees([]);
    //             });
    //     };
    //     getAllEmployees();
    // }, []);

    const columns: ProDescriptionsItemProps<API.Opportunity>[] = [
        {
            dataIndex: 'id',
            valueType: 'indexBorder',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            copyable: true,
            // ellipsis: true,
            editable: false,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            copyable: true,
            // ellipsis: true,
            editable: false,
            // render: (dom, entity) => {
            //     if (!entity.description)
            //         return '-'
            //     return dom
            // }
        },
        {
            title: 'Created At',
            key: 'showTime',
            dataIndex: 'created_at',
            valueType: 'date',
            // hideInSearch: true
            editable: false,
            render: (text, record) => new Date(record.created_at).toLocaleDateString('el')
        },
        {
            title: () => {
                if (target?.buyer_type_id == 1) {
                    return 'Customer (Existing LTD)'
                } else if (target?.buyer_type_id == 2) {
                    return 'Customer (Existing IKE)'
                } else if (target?.buyer_type_id == 3) {
                    return 'Customer (External)'
                }
                return 'Customer'
            },
            // dataIndex: 'customer_id',
            editable: false,
            copyable: true,
            // valueType: "digit",
            // render:text => text?.toLocaleString('el')
            render: (dom, entity) => {
                if (entity.buyer_type_id == 1) {
                    return `${entity.customer?.name} [${entity.customer_id}]`
                }
                if (entity.buyer_type_id == 2) {
                    return `${entity.accounting_customer?.name} [IKE${entity.accounting_customer_id}]`
                }
                if (entity.buyer_type_id == 3) {
                    return `${entity.external_buyer?.name} [EXT${String(entity.external_buyer_id).padStart(3, '0')}]`
                }
            }
        },
        {
            title: 'Manager',
            // dataIndex: 'employee_id',
            copyable: true,
            // valueType: "text",
            editable: false,
            render: (text, record) => {
                return <Space size={5}><Avatar
                    size={28}
                    icon={<UserOutlined/>}
                    // shape={'square'}
                    // src='https://cdn-icons-png.flaticon.com/512/706/706807.png'
                    style={{backgroundColor: '#fde3cf', color: '#f56a00'}}
                /> {record.employee?.name}</Space>
            }
        },
        {
            title: 'Pretty ID',
            dataIndex: 'project_pretty_id',
            copyable: true,
            editable: false,
        },
        {
            title: 'Type',
            dataIndex: 'opportunity_type_id',
            copyable: true,
            editable: false,
            render: (dom, entity) => entity.opportunity_type.name
        },
        {
            title: 'Phase',
            dataIndex: 'phase_id',
            copyable: true,
            editable: false,
            hideInDescriptions: target?.is_compound,
            render: (dom, entity) => <Tag
                icon={<InfoCircleOutlined/>}
                color={'blue'}>{entity.opportunity_phase.name}</Tag>
        },
        {
            title: 'Forecast Risk Factor',
            dataIndex: 'forecast_risk_factor_id',
            copyable: true,
            editable: false,
            render: (dom, entity) => <Tag
                icon={<WarningOutlined/>}
                color={'geekblue'}>{entity.forecast_risk_factor.title}</Tag>
        },
        {
            title: 'Initial Estimated Value',
            dataIndex: 'initial_estimated_value_in_euro',
            copyable: true,
            editable: false,
            render: (dom, entity) => currencyFormatter.format(entity.initial_estimated_value_in_euro || 0)
        },
        {
            title: 'Proposed Value',
            dataIndex: 'proposed_value_in_euro',
            copyable: true,
            editable: false,
            render: (dom, entity) => currencyFormatter.format(entity.proposed_value_in_euro || 0)
        },
        {
            title: 'PO Value',
            dataIndex: 'po_value_in_euro',
            copyable: true,
            editable: false,
            render: (dom, entity) => currencyFormatter.format(entity.po_value_in_euro || 0)
        },
        {
            title: 'Expected PO Date',
            key: 'showTime',
            dataIndex: 'expected_po_date',
            valueType: 'date',
            editable: false,
            hideInDescriptions: target?.is_compound,
            render: (text, record) => new Date(record.expected_po_date!).toLocaleDateString('el')
        },
        {
            title: 'Parent Opportunity',
            dataIndex: 'parent_id',
            editable: false,
            hideInDescriptions: !target?.parent_id,
            render: (dom, entity) => entity.parent_id != null ?
                <Link
                    reloadDocument={!location.pathname.includes('list')}
                    // state={{background: location}}
                    to={{pathname: `../${entity.parent_id}`, search: location.search}}>View <SearchOutlined/>
                </Link> : '-'

        }
    ];

    const actionRef = useRef<ActionType>();

    const routerParams = useParams();

    const navigate = useNavigate();
    useEffect(() => {
        actionRef.current?.reload()
    }, [target])
    useEffect(() => {
        actionRef.current?.reload()
        // navigate(0)
    }, [])

    return (
        <>
            {!target ? <Skeleton/> :
                <ProCard bordered>
                    <ProDescriptions<API.Opportunity>
                        // dataSource={target}
                        loading={!target}
                        layout={"vertical"}
                        labelStyle={{color: 'rgba(0,0,0,.45)'}}
                        onRequestError={e => {
                            // dummy handler for proper behavior
                            console.log(e)
                        }}
                        columns={columns}
                        actionRef={actionRef}
                        dataSource={target}
                        // request={params => {
                        //     // return axiosApiInstance.get<API.Opportunity>('/api/sales/opportunities/' + routerParams.opportunity_id)
                        //     setLoading(true)
                        //     return axiosApiInstance.get<API.Opportunity>('/api/sales/opportunities/' + target.id)
                        // }}
                        // onDataSourceChange={value => {
                        //     // console.log(value)
                        //     // console.log('onDataSourceChange', value)
                        //     console.log(opportunities)
                        //     setOpportunityData(value)
                        //     setLoading(false)
                        // }}
                        // editable={{
                        //     onSave: (key, record, originRow, newLineConfig) => {
                        //         // console.log('allTags', allTags)
                        //         // console.log({key})
                        //         // console.log(key, record, originRow, newLineConfig, projectData)
                        //         // console.log({[key as string]: record[key as keyof API.Project] == undefined ? null : record[key as keyof API.Project]})
                        //         // return Promise.resolve()
                        //         // if (key == 'project_tags') {
                        //         //     return updateProjectTags(record.project_tags as any)
                        //         //     // return updateProjectTags()
                        //         //     // console.log(selectedTags)
                        //         // }
                        //         let newValue = record[key as keyof API.Project] == undefined ? null : record[key as keyof API.Project];
                        //         return axiosApiInstance.put('/api/projects/' + routerParams.projectId, {[key as string]: newValue})
                        //             .then(value => {
                        //                 actionRef.current?.reload()
                        //                 return true
                        //             })
                        //         // .then(fetchWbsVersionData)
                        //     },
                        // }}
                        // layout={"vertical"}
                        // labelStyle={{fontSize: 18}}

                    />
                </ProCard>
            }
            {target?.is_compound && !loading && <>
                <Divider>Purchase Orders</Divider>
                {/*<Divider>↕️</Divider>*/}
                {/*<br/>*/}
                <ProCard bordered bodyStyle={{padding: 0}}>
                    {opportunities.filter(value => value.parent_id == target.id).map(value => (
                        // <a href={`/sales/${value.id}`}>
                        <OpportunityCard target={value} key={value.id} controlled_by_compound/>
                        // </a>
                        // <OpportunityCard target={value} key={value.id} simple/>
                        // <a href={`/sales/${value.id}`}> View</a>
                        // 'Test'
                    ))}
                </ProCard>

            </>
            }

            <Outlet/>
        </>
    );
};

export default OpportunityInfoLayout;

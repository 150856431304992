import React, {useEffect, useState} from 'react';
import createNewOpportunity from '../../util/sales/createNewOpportunity';
import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormField,
    ProFormList,
    ProFormMoney,
    ProFormSelect,
    ProFormSwitch,
    ProFormText
} from '@ant-design/pro-components';
import {Button, Divider, Form, message, notification, Space, Tooltip, Typography} from 'antd';
import {RangePickerProps} from 'antd/es/date-picker';
import dayjs from 'dayjs';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {DownOutlined, LoginOutlined, PlusOutlined} from "@ant-design/icons";
import createNewExternalBuyer from "../../util/sales/createNewExternalBuyer";
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";
import assignManagerToExtBuyer from "../../util/sales/assignManagerToExtBuyer";

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().endOf('day');
};

type Options = {
    label: string;
    value: number | string;
    employees?: Options[];
};

const NewOpportunityForm = () => {
    const {opportunityTypes, customers, accountingCustomers, employees, isLoading, buyerTypes, externalBuyers, updateExternal} =
        useSalesAuxDataContext();
    const [empOpts, setEmpOpts] = useState<Options[]>([]);
    // const [selectedEmp, setSelectedEmp] = useState<number | null>(null);
    const [allEmployees, setAllEmployees] = useState<Options[]>([]);
    const [form] = Form.useForm<{ name: string }>();
    const navigate = useNavigate();
    const [currentQueryParameters, setSearchParams] = useSearchParams();

    const inBuyerType = Form.useWatch('buyer_type_id', form);
    const inExternalId = Form.useWatch('external_buyer_id', form);
    const inCustomerId = Form.useWatch('customer_id', form);
    const inAccountingCustomerId = Form.useWatch('accounting_customer_id', form);
    const inEmployeeId = Form.useWatch('employee_id', form);
    const isCompound = Form.useWatch('is_compound', form);
    const location = useLocation();

    useEffect(() => {
        let emps: Options[] = [];
        if (employees) {
            emps = employees.map((e) => ({label: e.name, value: e.id}));
            setEmpOpts(emps);
            setAllEmployees(emps);
        }
    }, [employees]);

    const addExternalCustomer = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        const new_external_customer = form.getFieldValue(['new_external_customer'])
        if (!new_external_customer) {
            message.error('Please enter a name for the new customer');
            return;
        }
        form.resetFields(['new_external_customer'])
        await createNewExternalBuyer({name: new_external_customer})
        updateExternal()
        // setExternal(await getExternalOptions())
    };

    return (
        <ModalForm<{ name: string }>
            title='New Opportunity'
            // style={{height: '60vh'}}
            form={form}
            open
            // trigger={
            //     <Button
            //         type='dashed'
            //         size='middle'
            //         disabled={!isCurrent}
            //         loading={isLoading}
            //         style={{ width: '100%', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
            //         icon={<PlusSquareOutlined />}>
            //         New Opportunity
            //     </Button>
            // }
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                onCancel: () => navigate(-1)
                // onCancel: () => console.log('modal closed'),
                // onOk: (e) => console.log(e)
            }}
            // onValuesChange={(changedValues, allValues) => {
            //     console.log(changedValues, allValues);
            // }}
            onFinish={async (values) => {
                console.log(values); // TODO: remove
                (await createNewOpportunity(values))
                    ? message.success('New opportunity created')
                    : message.error('Failed to create opportunity');
                // updateOpportunities();
                // return false;
                // navigate(`/sales?update=true`);
                // navigate to sales page by keeping the other search params if any
                // console.log(currentQueryParameters.get('search'))
                // console.log(location.search + (location.search ? `&update=true` : '?update=true'))
                // return false;
                navigate({
                    pathname: '/sales/list',
                    search: location.search + (location.search ? `&update=true` : '?update=true')
                });
                return true;
            }}>
            {/*<Spin spinning={isLoading}/>*/}
            <ProForm.Group>
                <ProFormText
                    name='title'
                    width='md'
                    label='Opportunity Title'
                    tooltip='Give your new opportunity a short memorable name!'
                    placeholder='Title'
                    rules={[
                        {
                            required: true,
                            message: 'Please name your new sale opportunity'
                        }
                    ]}
                />
                <ProFormSwitch
                    name='is_compound'
                    label='Compound Opportunity'
                    tooltip='Is this opportunity a compound opportunity?'
                    initialValue={false}
                    fieldProps={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No'
                    }}
                />

            </ProForm.Group>
            <ProForm.Group>
                <ProFormText
                    name='description'
                    width='md'
                    label='Opportunity Description'
                    tooltip='Describe the specific details of this new opportunity (optional)'
                    placeholder='Description'
                />
                <ProFormSelect
                    name='opportunity_type_id'
                    label='Opportunity Type'
                    // options={oppTypeOpts}
                    options={opportunityTypes.map((o) => ({label: o.name, value: o.id}))}
                    allowClear={false}
                    style={{marginRight: 16}}
                    width='sm'
                    rules={[{required: true}]}
                    fieldProps={{
                        loading: isLoading,
                    }}
                />
            </ProForm.Group>
            <ProForm.Group>
                <ProFormSelect
                    // options={buyerTypeOpts}
                    options={buyerTypes.map((b) => ({label: b.name, value: b.id}))}
                    name="buyer_type_id"
                    label="Customer Type"
                    rules={[{required: true}]}
                    width={"sm"}
                    initialValue={1}
                    fieldProps={{
                        loading: isLoading,
                    }}
                />
                <ProFormDependency name={[['buyer_type_id']]}>
                    {({buyer_type_id}) => {
                        if (buyer_type_id === 1) {
                            form.setFieldValue('external_buyer_id', null)
                            form.setFieldValue('external_buyer', null)
                            form.setFieldValue('accounting_customer_id', null)
                            form.setFieldValue('accounting_customer', null)
                            form.setFieldValue('customer', null)
                            return <ProFormSelect
                                // options={registered}
                                options={customers.map((c) => ({
                                    label: `${c.name}: [${c.id}]`,
                                    value: c.id,
                                    employees: c.employees.map((e) => ({
                                        label: e.name,
                                        value: e.id
                                    }))
                                }))}
                                width="sm"
                                name="customer_id"
                                label="Existing Customer (LTD)"
                                rules={[{required: true}]}
                                fieldProps={{
                                    showSearch: true, filterOption: (input, option) =>
                                        (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                                    onChange(value: any, option: any) {
                                        // console.log(value, option)
                                        const newEmpOpts =
                                            option.employees.length > 0 ? option.employees : allEmployees;
                                        if (newEmpOpts.length > 0)
                                            form.setFieldValue('employee_id', newEmpOpts[0]?.value || null);
                                        setEmpOpts(newEmpOpts);
                                    },
                                    loading: isLoading,
                                }}
                            />
                        }
                        if (buyer_type_id === 2) {
                            form.setFieldValue('external_buyer_id', null)
                            form.setFieldValue('external_buyer', null)
                            form.setFieldValue('customer', null)
                            form.setFieldValue('customer_id', null)
                             form.setFieldValue('accounting_customer', null)
                            return <ProFormSelect
                                // options={registered}
                                options={accountingCustomers.map((c) => ({
                                    label: `${c.name}: [${c.id}]`,
                                    value: c.id,
                                    employees: c.employees?.map((e) => ({
                                        label: e.name,
                                        value: e.id
                                    }))
                                }))}
                                width="sm"
                                name="accounting_customer_id"
                                label="Existing Customer (IKE)"
                                rules={[{required: true}]}
                                fieldProps={{
                                    showSearch: true, filterOption: (input, option) =>
                                        (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                                    onChange(value: any, option: any) {
                                        // console.log(value, option)
                                        const newEmpOpts =
                                            option.employees.length > 0 ? option.employees : allEmployees;
                                        if (newEmpOpts.length > 0)
                                            form.setFieldValue('employee_id', newEmpOpts[0]?.value || null);
                                        setEmpOpts(newEmpOpts);
                                    },
                                    loading: isLoading,
                                }}
                            />
                        }
                        form.setFieldValue('customer_id', null)
                        form.setFieldValue('customer', null)
                        form.setFieldValue('external_buyer', null)
                        // form.setFieldValue('employee_id', null)
                        form.setFieldValue('employee', null)
                        setEmpOpts(allEmployees)
                        return (
                            <ProFormSelect
                                // options={external}
                                options={externalBuyers.map((b) => ({
                                    label: `${b.name} [${b.id}]`,
                                    value: b.id,
                                    employees: b.employees.map((e) => ({
                                        label: e.name,
                                        value: e.id
                                    }))
                                }))}
                                width="sm"
                                name="external_buyer_id"
                                label="External Customer"
                                rules={[{required: true}]}
                                fieldProps={{
                                    dropdownRender: (menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{margin: '8px 0'}}/>
                                            <Space>
                                                <ProFormText
                                                    placeholder="Please enter"
                                                    name={'new_external_customer'}
                                                    allowClear
                                                    noStyle
                                                />
                                                <Button type="text" icon={<PlusOutlined/>}
                                                        onClick={addExternalCustomer}
                                                >
                                                    Add
                                                </Button>
                                            </Space>
                                        </>
                                    ),
                                    onChange(value: any, option: any) {
                                        // console.log(value, option)
                                        const newEmpOpts =
                                            option.employees.length > 0 ? option.employees : allEmployees;
                                        if (option.employees.length > 0)
                                            form.setFieldValue('employee_id', newEmpOpts[0]?.value || null);
                                        // setEmpOpts(newEmpOpts);
                                    },
                                    loading: isLoading,
                                }}
                            />
                        );
                    }}
                </ProFormDependency>


                <ProFormSelect
                    options={empOpts}
                    width="sm"
                    name="employee_id"
                    label="Account Manager"
                    rules={[{required: true}]}
                    fieldProps={{
                        loading: isLoading,
                        dropdownRender: (menu) => (
                            <>
                                {menu}
                                {empOpts.length !== allEmployees.length &&
                                    <>
                                        {/*<Space>*/}
                                            <Divider style={{margin: '8px 0'}}/>
                                            <Button
                                                style={{width: '100%'}}
                                                size={"small"} type="text" icon={<DownOutlined/>}
                                                    onClick={() => setEmpOpts(allEmployees)}
                                            >
                                                <Typography.Text type={"secondary"}> Show all </Typography.Text>
                                            </Button>
                                        {/*</Space>*/}
                                    </>
                                }
                            </>
                        ),
                    }}
                    addonAfter={
                        inBuyerType == 2 && inExternalId && inEmployeeId ?
                            <Tooltip title={'Assign manager to the selected customer'}>
                                <Button
                                    type="default"
                                    icon={<LoginOutlined/>}
                                    onClick={async () => {
                                        try {
                                            const res = await assignManagerToExtBuyer({
                                                manager_id: inEmployeeId,
                                                customer_id: inExternalId
                                            })
                                            notification.success({
                                                message: 'Successfully assigned manager',
                                                duration: 1
                                            })
                                            await updateExternal()
                                        } catch (error) {
                                        }
                                    }}
                                />
                            </Tooltip>
                            : null
                    }
                    // help={
                    //     inBuyerType == 2 && inExternalId && inEmployeeId ?
                    //         // form.getFieldValue('buyer_type_id') == 2 && form.getFieldValue('external_buyer_id') &&
                    //         // form.getFieldValue('employee_id') ?
                    //         // <><WarningOutlined/> No
                    //         //     email found</> :
                    //         <div style={{
                    //             display: 'flex',
                    //             // alignItems: 'center',
                    //             // justifyContent: 'space-between'
                    //             columnGap: '6px',
                    //             marginLeft: '1px'
                    //         }}>
                    //             {/*<Tooltip title={'Notify via email'} placement={"bottom"}>*/}
                    //             <Checkbox
                    //                 checked={assignManagerToCustomer}
                    //                 onChange={(e) => setAssignManagerToCustomer(e.target.checked)}
                    //             />
                    //             {/*</Tooltip>*/}
                    //             {'Assign to selected customer'}
                    //         </div> : null
                    // }
                />

            </ProForm.Group>
            {!isCompound &&
                <ProForm.Group>
                    <ProFormDatePicker
                        name='expected_po_date'
                        label='Expected P.O. Date'
                        width='md'
                        fieldProps={{
                            // disabledDate: disabledDate,
                            format: 'DD/MM/YYYY'
                        }}
                        normalize={(value) => {
                            return value.toISOString()
                        }}
                        rules={[{required: true}]}
                    />
                    <ProFormMoney
                        name='initial_estimated_value_in_euro'
                        label='Estimated Unweighted Value (€)'
                        locale='fr-FR'
                        min={0}
                        max={999_999_999} // 999 million
                        width='md'
                        required
                    />
                </ProForm.Group>
            }
            {isCompound &&
                <ProFormList name="purchase_orders"
                             creatorButtonProps={{creatorButtonText: 'Add Purchase Order'}}
                             initialValue={[{}]}
                    // min={1}
                >
                    {(meta, index, action, count) => {
                        return (
                            <ProForm.Group>
                                <ProFormField
                                    valueType={'indexBorder'}
                                    // labelCol={{span: 0}}
                                    // wrapperCol={{span: 0}}
                                    width={'xs'}
                                    initialValue={index}
                                    label={' '}
                                    name={'index'}
                                />

                                <ProFormDatePicker
                                    name='expected_po_date'
                                    label='Expected P.O. Date'
                                    width='sm'
                                    fieldProps={{
                                        // disabledDate: disabledDate,
                                        format: 'DD/MM/YYYY'
                                    }}
                                    normalize={(value) => {
                                        return value.toISOString()
                                    }}
                                    rules={[{required: true}]}
                                />
                                <ProFormMoney
                                    name='initial_estimated_value_in_euro'
                                    label='Estimated Unweighted Value (€)'
                                    locale='fr-FR'
                                    min={0}
                                    max={999_999_999} // 999 million
                                    width='md'
                                    required
                                />
                            </ProForm.Group>
                        );
                    }}
                </ProFormList>
            }
        </ModalForm>
    );
};

export default NewOpportunityForm;

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB7yvnjsZr4pilHT0qJZzYo-WjUTlzcpuc",
    authDomain: "dm-payments.firebaseapp.com",
    projectId: "dm-payments",
    storageBucket: "dm-payments.appspot.com",
    messagingSenderId: "88072290093",
    appId: "1:88072290093:web:a57d649a55dc53b33f1da4",
    measurementId: "G-SKRGLRF0SF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export default app;
import dayjs, { Dayjs } from 'dayjs';

/**
 * Helper function used to determine whether an opportunity belongs to a given fiscal year or not
 * intendet to be used as a parameter in array.filter function
 *
 * @param opp Opportunity to be checked
 * @param fyear just a dayjs object
 * @returns bool - true if opportunity year = given year
 */
const belongsToFiscalYear = (opp: API.Opportunity, fyear: Dayjs) => {
    const closure_year = dayjs(opp.closure_date).year();
    const fiscal_year = fyear.year();
    const current_year = dayjs().year();
    const expected_po_year = dayjs(opp.expected_po_date).year();
    const is_closed = opp.opportunity_phase.status.completed || opp.opportunity_phase.status.disabled;

    // if past year, check only based on the expected PO date
    if (fiscal_year !== current_year) {
        return expected_po_year === fiscal_year;
    }

    // if fiscal year chosen is current, check expected PO date and also if opportunity is still open (not won or disabled)
    return expected_po_year === fiscal_year || !is_closed
};

export default belongsToFiscalYear;

import {ModalForm} from '@ant-design/pro-components';
import * as React from 'react';
import {useContext} from 'react';
import {Button} from "antd";
import axiosApiInstance from "../../api/axiosClient";
import {GlobalStateContext} from "../../context/GlobalContext";

type Props = {
    transactions: API.Transaction[]
    onCleanSelected: any
};

export const CommitTransactionsDialog = (props: Props) => {
    const {transactionsListRef} = useContext(GlobalStateContext);

    return (
        <ModalForm
            width={400}
            title={"Commit selected transactions"}
            trigger={
                <Button type={"link"}>Commit selected</Button>
            }
            // submitter={{}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Yes',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                // return true
                return axiosApiInstance.post('/api/projects/transactions/multiple', props.transactions)
                    .then(res => {
                        transactionsListRef?.current?.reload()
                        props.onCleanSelected()
                        return true
                    }).catch(reason => false)
            }}
        >
            Are you sure?
        </ModalForm>
    );
};

export default CommitTransactionsDialog
import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import {UserAuthContextProvider} from "./context/AuthContext";
import {BrowserRouter} from "react-router-dom";
import GlobalStateProvider from "./context/GlobalContext";
import SalesAuxDataContextProvider from "./context/SalesAuxDataContextProvider";
// import reportWebVitals from './reportWebVitals';
// import 'antd/dist/antd.css';
// import "./antd-theme/antd-customized.css";


ReactDOM.render(
    <React.StrictMode>
        <UserAuthContextProvider>
            <GlobalStateProvider>
                <BrowserRouter>
                    <SalesAuxDataContextProvider>
                        <App/>
                    </SalesAuxDataContextProvider>
                </BrowserRouter>
            </GlobalStateProvider>
        </UserAuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

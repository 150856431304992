import React, {useCallback, useEffect, useRef, useState} from 'react';
import {notification, Progress, Radio, Typography} from 'antd';
import {ModalForm, ProFormCheckbox, ProFormInstance} from '@ant-design/pro-components';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import axiosApiInstance from '../../api/axiosClient';
import getOpportunityPhases from '../../util/sales/getOpportunityPhases';
import updateOpportunity from '../../util/sales/updateOpportunity';
import {useSalesContext} from '../../context/SalesContextProvider';

type T = API.Opportunity;

/**
 * Το παρόν component είναι το modal που εμφανίζεται όταν πατιέται το κουμπί Advance σε μια κάρτα ενός
 * opportunity. Χρησιμοποιεί το id που βρίσκεται στα url params για να κάνει fetch τα αντίστοιχα δεδομένα.
 * Κατα την ενέργεια sumbit, ανανεώνει την λίστα των opportunities με την συναρτήση updateOpportunities
 * που ορίζεται στο SalesContext
 *
 * @returns
 */
const AdvanceOpportunityModal = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [target, setTarget] = useState<API.Opportunity | undefined>(undefined);
    const [phases, setPhases] = useState<API.OpportunityPhase[] | undefined>(undefined);
    const [nextPhase, setNextPhase] = useState<API.OpportunityPhase | undefined>(undefined);
    const [expandChecked, setExpandChecked] = useState<boolean>(false);
    const [statusValue, setStatusValue] = useState<number>(0);
    const {updateOpportunities} = useSalesContext();

    const formRef = useRef<ProFormInstance>(null);

    // Εδώ φέρνει τα δεδομένα του αντίστοιχου opportunity, το object που επιστρέφει αποθηκεύεται στο state target
    const fetchTarget = useCallback(async () => {
        axiosApiInstance
            .get<T>(`/api/sales/opportunities/${id}`)
            .then(({data}) => data)
            .then((opp) => {
                setTarget(opp);
                // Εαν το phase είναι 7 σημαίνει πως είναι won&expand
                if (opp.opportunity_phase_id === 7) setExpandChecked(true);
                // Εαν το phase είναι πανω απο 7 σημαίνει πως το opportunity είναι σε unhappy path (dropped/lost)
                if (opp.opportunity_phase_id > 7) setStatusValue(opp.opportunity_phase_id);
            })
            .catch((e) => {
                console.error(e);
                setTarget(undefined);
            });
    }, []);

    // θέλουμε τα phases καθώς δεν τα φέρνουμε απο το SalesContext επειδή κάνει το modal αργό
    const fetchPhases = useCallback(async () => {
        getOpportunityPhases()
            .then((res) => setPhases(res))
            .catch((e) => console.error(e));
    }, []);

    // on mount
    useEffect(() => {
        fetchTarget();
        fetchPhases();
    }, []);

    const [fromPath, setFromPath] = React.useState<string | undefined>(undefined)
    const location = useLocation();

    useEffect(() => {
        const fromPath = location.state?.from_path
        setFromPath(fromPath)
        // console.log('fromPath', fromPath)
    }, [location])

    // το ακόλουθο useEffect hook υπολογίζει την θέση που βρίσκεται η παρούσα opportunity και με βάση
    // αυτό υπολογίζει την επόμενη θέση που θα προχωρήσει το opportunity (είναι λιγο σαχλός τρόπος)
    useEffect(() => {
        if (target && phases) {
            const current_phase = target.opportunity_phase_id;
            let next: API.OpportunityPhase | undefined = undefined;

            // για current_phase < 5 βρισκόμαστε ακόμη στο happy path, το opportunity προχωράει κανονικά
            if (current_phase < 5) {
                next = phases?.find((phase) => phase.id === current_phase + 1);
                // για current_phase < 8 είμαστε σε τελικό στάδιο (won&deploy/expand)
            } else if (current_phase < 8) {
                next = phases?.find((phase) => phase.id === (expandChecked ? 7 : 6));
            } else {
                next = phases?.find((phase) => phase.id === current_phase);
            }

            // αν το statusValue δεν ειναι 0 τότε είμαστε σε unhappy path (dropped/lost)
            if (statusValue !== 0) {
                next = phases?.find((phase) => phase.id === statusValue);
            }
            setNextPhase(next);
        }
    }, [target, phases, expandChecked, statusValue]);

    const getStatus = useCallback(() => {
        if (statusValue !== 0) {
            return 'exception';
        }

        if (nextPhase?.completion_percentage === 1) {
            return 'success';
        }

        return 'active';
    }, [statusValue, nextPhase]);

    // helper συνάρτηση που μας δίνει την completion τιμή για το Progress component του antd
    const getPercentage = useCallback(() => {
        if (!target || !nextPhase) {
            return 0;
        }

        if (statusValue !== 0) {
            return target.opportunity_phase.completion_percentage * 100;
        }

        return nextPhase.completion_percentage * 100;
    }, [target, nextPhase, statusValue]);

    return (
        <ModalForm<any>
            title={target?.title}
            open={true}
            formRef={formRef}
            modalProps={{
                destroyOnClose: true,
                okText: 'Advance',
                onCancel: () => navigate(-1), // go back
                centered: true
            }}
            onFinish={async (values) => {
                updateOpportunity({
                    id: target?.id,
                    opportunity_phase_id: nextPhase?.id,
                    proposed_value_in_euro: target?.proposed_value_in_euro,
                    project_pretty_id: values.project_id || null
                })
                    .then((f) => {
                        updateOpportunities()
                        // navigate(`/sales?update=true`);
                        if (fromPath)
                            navigate({
                                pathname: fromPath,
                                search: location.search + (location.search ? `&update=true` : '?update=true')
                            });
                        else
                            // navigate(`/sales?update=true`);
                            navigate({
                                pathname: `/sales/list`,
                                search: location.search + (location.search ? `&update=true` : '?update=true')
                            });
                    })
                    .catch((e) => notification.error(e));


            }}>
            <Typography.Text
                type='secondary'>{`${target?.opportunity_phase.name} >> ${nextPhase?.name}`}</Typography.Text>
            <Progress percent={getPercentage()} status={getStatus()}/>
            <ProFormCheckbox
                name='expand'
                disabled={target ? target.opportunity_phase_id < 5 : true}
                fieldProps={{
                    checked: expandChecked,
                    onClick: () => setExpandChecked((prev) => !prev)
                }}>
                Expand
            </ProFormCheckbox>

            <Radio.Group
                defaultValue={statusValue}
                onChange={({target: {value}}) => {
                    setStatusValue(value);
                }}
                options={[
                    {label: 'Active', value: 0},
                    {label: 'Dropped', value: 8},
                    {label: 'Lost', value: 9}
                ]}
            />
        </ModalForm>
    );
};

export default AdvanceOpportunityModal;

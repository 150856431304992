import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ProCard} from "@ant-design/pro-components";
import {useParams} from "react-router-dom";
import {GlobalStateContext} from "../../context/GlobalContext";
import {Badge, Button, Select, Typography} from "antd";
import {ProTable, ProColumns} from "@ant-design/pro-table";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import axiosApiInstance from "../../api/axiosClient";
import LogDeliverableMaterialUtilization from "../../components/tracking/LogDeliverableMaterialUtilization";
import {PageLoading} from "@ant-design/pro-layout";
import dayjs, {Dayjs} from 'dayjs';
import utc from "dayjs/plugin/utc";
// import LPRTUT from "../../components/LongProcessRealTimeUpdateTest";

dayjs.extend(utc)

type Props = {};


const MaterialsUtilizationGlobal = (props: Props) => {

    const [columns, setColumns] = useState<ProColumns<Record<string, any>>[] | undefined>([]);
    const [days, setDays] = useState<Dayjs[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const [data, setData] = useState<any>(undefined);
    const routerParams = useParams();
    const {materialsUtilizationGlobalTableRef} = useContext(GlobalStateContext);
    const [materialsOptions, setMaterialsOptions] = useState<{ label: any, value: number }[]>([]);
    const [selectedMaterialValue, setSelectedMaterialValue] = useState<number | undefined>(undefined);
    const [projectsData, setProjectsData] = useState<API.Project[] | undefined>(undefined);
    const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const fetchProjectsData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.Project[]>(`/api/projects/onexecution`)
            // console.log(response.data)
            setProjectsData(response.data);
            setLoading(false)

            if (response.data.length > 0) {
                setSelectedProjectId(response.data[0].id)
                // timeTrackerGlobalTableRef?.current?.reload()
            }
        } catch (e) {
            console.log('catch error')
            setProjectsData(undefined)
            setLoading(false)
        }
    };

    const fetchOwnMaterialsData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.OwnMaterial[]>('/api/deliverables/ownmaterials')
            // console.log(response.data)
            let options = []
            for (const material of response.data) {
                options.push({
                    value: material.id,
                    label: material.name
                })
            }
            setMaterialsOptions(options);
            if (options.length > 0) {
                setSelectedMaterialValue(options[0].value)
                materialsUtilizationGlobalTableRef?.current?.reload()
            }
            setLoading(false)
        } catch (e) {
            // console.log('catch error')
            setMaterialsOptions([])
            setLoading(false)
        }
    };


    useEffect(() => {
        fetchProjectsData().then(fetchOwnMaterialsData).then(initDays)
    }, [])

    const initDays = () => {
        let newDays = []
        let curDay = dayjs().utc().startOf('week').startOf('d')
        for (let i = 0; i < 7; i++) {
            newDays.push(curDay.clone())
            curDay = curDay.add(1, 'd')
            // console.log(curDay.format('YYYY-W'))
        }
        // console.log(newDays)
        setDays(newDays)
    }

    useEffect(() => {
        // console.log('cols')
        let newColumns: ProColumns<Record<string, any>>[] = []
        for (const daysKey of days) {
            // console.log(daysKey.format('YYYY-W'))
            let title;
            // let text = daysKey.format('YYYY-MM-DD')
            let text = daysKey.format('ddd D MMM')
            if (daysKey.isSame(dayjs().utc().startOf('d'))) {
                // title = <Tag color={'blue'} style={{fontSize:14}}>{daysKey.format('YYYY-W')}</Tag>
                title = <Badge status="processing" text={text}/>
            } else {
                title = text
            }
            newColumns.push({
                title,
                align: 'center',
                width: '12%',
                dataIndex: daysKey.format('YYYY-MM-DD'),
                render: (dom, entity) => {
                    if (entity.row_level == 'cost') {
                        return <LogDeliverableMaterialUtilization project_id={selectedProjectId}
                                                                  day={daysKey}
                                                                  cost_id={entity.cost_id}
                                                                  cost_type={entity.cost_type}
                                                                  start_date={entity.start_date}
                                                                  own_material_id={selectedMaterialValue}
                                                                  hours={entity[daysKey.format('YYYY-MM-DD')]}
                        />
                    }
                    if (!daysKey.isBefore(dayjs.utc(entity.start_date))) {
                        // console.log(daysKey, dayjs(entity.start_date).startOf('isoWeek'))
                        if (entity[daysKey.format('YYYY-MM-DD')]) return `${entity[daysKey.format('YYYY-MM-DD')]}hr`
                        return `0hr`
                    }
                }
            })
        }
        newColumns = [
            {
                title: 'Deliverable', dataIndex: 'name', fixed: 'left', width: '15%',
                render: (dom, entity) => {
                    if (entity.row_level == 'deliverable') {
                        return <Typography.Text strong>{dom}</Typography.Text>
                    }
                    return <Typography.Text>Task: {dom}</Typography.Text>
                }
            },
            ...newColumns,
            {
                title: 'Total',
                dataIndex: 'total_hours',
                fixed: 'right',
                valueType: "digit",
                render: (dom, entity) => `${entity.total_hours || 0}hr`
            },
        ]
        setColumns(newColumns)
        materialsUtilizationGlobalTableRef?.current?.reload()
    }, [days])

    const previousWeek = () => {
        let newDays = []
        let curDay = days[0].subtract(1, 'w')
        for (let i = 0; i < 7; i++) {
            newDays.push(curDay.clone())
            curDay = curDay.add(1, 'd')
        }
        setDays(newDays)
    }

    const nextWeek = () => {
        let newDays = []
        let curDay = days[days.length - 1].add(1, 'd')
        for (let i = 0; i < 7; i++) {
            newDays.push(curDay.clone())
            curDay = curDay.add(1, 'd')
        }
        setDays(newDays)
    }


    return (
        <div>
                    {/*<LPRTUT/>*/}
            {selectedProjectId == undefined || selectedMaterialValue == undefined ? <PageLoading/> :
                <ProCard bordered bodyStyle={{padding: 0}}>
                    <ProTable
                        actionRef={materialsUtilizationGlobalTableRef}
                        columns={columns}
                        rowKey={"id"}
                        // dataSource={rows}
                        search={false}
                        // toolBarRender={false}
                        pagination={false}
                        toolBarRender={(action) => [
                            <Typography.Text>Select Project (On Execution) and Material:</Typography.Text>,
                            <Select options={projectsData?.map(value => ({
                                label: `${value.title} (${value.project_pretty_id})`,
                                value: value.id
                            }))}
                                    style={{width: 350}} placeholder={'Select Project'}
                                    value={selectedProjectId}
                                    onSelect={(value: number) => {
                                        setSelectedProjectId(value)
                                        materialsUtilizationGlobalTableRef?.current?.reload()
                                    }}
                            />,
                            <Select options={materialsOptions} style={{width: 300}} placeholder={'Select Material'}
                                    value={selectedMaterialValue}
                                    onSelect={(value: number) => {
                                        setSelectedMaterialValue(value)
                                        materialsUtilizationGlobalTableRef?.current?.reload()
                                    }}/>,
                            <Typography.Text
                                strong>{days[0]?.format('D MMM YYYY')} - {days[days.length - 1]?.format('D MMM YYYY')}</Typography.Text>,
                            <Button onClick={() => previousWeek()}><LeftOutlined/>Previous week</Button>,
                            <Button onClick={() => nextWeek()}>Next week<RightOutlined/></Button>
                        ]}
                        // toolbar={{actions:[]}}
                        request={() => {
                            // if (selectedEmployee == undefined) {
                            //     return Promise.reject()
                            // }
                            // console.log(weeks)
                            let dates = []
                            for (let day of days) {
                                // let s = day.utcOffset(0, true).format()
                                let s = day.format()
                                dates.push(s)
                                // console.log(s, day.toISOString())
                                // dates.push(day.format())
                            }
                            return axiosApiInstance.get(`/api/projects/${selectedProjectId}/material_utilization`, {
                                params: {
                                    days: dates,
                                    own_material_id: selectedMaterialValue,
                                }
                            })
                                .then(value => {
                                    // console.log(value.data)
                                    return {data: value.data, success: true, total: value.data.length}
                                })
                            // return axiosApiInstance.get(`/api/projects/${routerParams.projectId}/progress`, {params: {weeks}})
                        }}
                        // expandable={{expandedRowRender: record => expandedRowRender(record, data, days, selectedMaterialValue!)}}
                        expandedRowKeys={expandedRowKeys}
                        onDataSourceChange={dataSource => {
                            setData(dataSource)
                            setExpandedRowKeys(dataSource?.map((i: any) => i.id))
                        }}

                    />
                </ProCard>
            }
        </div>
    );
};

export default MaterialsUtilizationGlobal;
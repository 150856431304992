import React, {useEffect, useRef, useState} from 'react';
import {ModalForm, ProFormInstance} from '@ant-design/pro-components';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useSalesContext} from '../../context/SalesContextProvider';
import {InfoCircleOutlined, WarningOutlined} from "@ant-design/icons";
import {Typography} from "antd";
import deleteOpportunity from "../../util/sales/deleteOpportunity";
import convertOpportunityToCompound from "../../util/sales/convertOpportunityToCompound";

type T = API.Opportunity;

type Props = {
    // callback?: () => void;
};

const ConvertOpportunityToCompoundModal = ({}: Props) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [target, setTarget] = useState<API.Opportunity | undefined>(undefined);
    const {updateOpportunities} = useSalesContext();
    const formRef = useRef<ProFormInstance>(null);
    const [fromPath, setFromPath] = React.useState<string | undefined>(undefined)
    const location = useLocation();

    useEffect(() => {
        const fromPath = location.state?.from_path
        setFromPath(fromPath)
        // console.log('fromPath', fromPath)
    }, [location])
    return (
        <ModalForm<any>
            title={"Convert Opportunity to Compound"}
            open={true}
            formRef={formRef}
            width={400}
            modalProps={{
                onCancel: () => navigate(-1), // go back
                // onCancel: () => navigate(`/sales/list`),
                destroyOnClose: true,
                okText: 'Convert',
                cancelText: 'No'
            }}
            // submitter={{submitButtonProps: {danger: true}}}
            onFinish={async (values) => {
                await convertOpportunityToCompound(+id!);
                if (fromPath)
                    navigate({
                        pathname: fromPath,
                        search: location.search + (location.search ? `&update=true` : '?update=true')
                    });
                else
                    // navigate(`/sales?update=true`);
                    navigate({
                        pathname: `/sales/list`,
                        search: location.search + (location.search ? `&update=true` : '?update=true')
                    });

            }}>
            <Typography.Text>
                Are you sure you want to convert this to a compound opportunity?
            </Typography.Text>
            <Typography.Paragraph>
                <WarningOutlined/> This action cannot be undone.
            </Typography.Paragraph>
            <Typography.Paragraph>
                <InfoCircleOutlined/> This will create a new compound opportunity, containing one purchase order with the same name and description.
            </Typography.Paragraph>
        </ModalForm>
    );
};

export default ConvertOpportunityToCompoundModal;

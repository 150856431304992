import React from 'react';
import {ProCard, ProDescriptions} from '@ant-design/pro-components';
import {Badge, Button, Space, Tag, Typography} from 'antd';
import {RingProgress, TinyColumn, TinyColumnConfig} from '@ant-design/plots';
import {Link, useLocation} from 'react-router-dom';

type Props = {
    target: API.Opportunity;
};

const LABEL_SIZE = 10;

/**
 * RenderItem της opportunity list, εμφανίζεται στο grid layout
 */
const OpportunityBoxCard = ({target}: Props) => {
    const location = useLocation();
    const progressConf = {
        height: 100,
        width: 100,
        autoFit: false,
        percent: target.opportunity_phase.completion_percentage,
        color: [
            target.opportunity_phase.status.completed
                ? '#89df63'
                : target.opportunity_phase.status.disabled
                    ? '#f76459'
                    : '#5B8FF9',
            '#E8EDF3'
        ]
    };

    const columnConf: TinyColumnConfig = {
        height: 100,
        autoFit: false,
        data: [target.initial_estimated_value_in_euro!, target.proposed_value_in_euro!],
        pattern: {
            type: 'line'
        },
        tooltip: {
            customContent(title, data) {
                const cat = title === '0' ? 'initial estimate' : 'current estimate';
                return `${cat}: ${data[0]?.data?.y.toLocaleString('el')}€`;
            }
        },
        annotations: [
            {
                type: 'line',
                // position: ['max', target.po_value_in_euro!],
                start: [0, target.po_value_in_euro!],
                end: [1, target.po_value_in_euro!],
                offsetX: 20,
                text: {
                    content: target.opportunity_phase.status.completed ? '◥' : '◢',
                    offsetY: target.opportunity_phase.status.completed ? -1 : 2,
                    position: 'right',
                    style: {
                        textAlign: 'right',
                        fontSize: 10,
                        fill: 'rgba(40,80,190,0.80)',
                        textBaseline: target.opportunity_phase.status.completed ? 'top' : 'bottom'
                    }
                },
                style: {
                    stroke: 'rgba(40,80,190,0.80)',
                    lineWidth: 2
                }
            }
        ],
        color: '#89df63'
    };
    return (
        <div style={{margin: 16}}>
            <Link to={{pathname: `${target.id}`, search: location.search}}>
                <Badge.Ribbon
                    text={
                        <b>
                            {target.expected_po_date !== '0001-01-01T00:00:00Z'
                                ? new Date(target.expected_po_date!).toLocaleDateString('el')
                                : 'No Date'}
                        </b>
                    }>
                    <ProCard
                        title={<b>{target.title}</b>}
                        subTitle={
                            <Tag color='blue'>{target.project_pretty_id || target.opportunity_pretty_id}</Tag>
                        }
                        layout='center'
                        direction='column'
                        bordered
                        hoverable
                        headerBordered
                        gutter={[0, 0]}
                        headStyle={{
                            backgroundColor: '#f5fffa'
                        }}
                        actions={
                            !target.is_compound ?
                                [<Link to={{pathname: `advance/${target.id}`, search: location.search}}
                                       state={{background: location}}>
                                    <Button
                                        type='link'
                                        disabled={
                                            // !isCurrent ||
                                            target.opportunity_phase.completion_percentage === 0
                                        }>
                                        Advance
                                    </Button>
                                </Link>,
                                    <Link
                                        to={`/projects/by_pretty_id/${target.project_pretty_id?.replaceAll('/', '_')}`}
                                    >
                                        <Button type='link'>Case</Button>
                                    </Link>,
                                    <Link to={{pathname: `delete/${target.id}`, search: location.search}}
                                          state={{background: location}}>
                                        <Button type='link' danger>Delete</Button>
                                    </Link>
                                ] :
                                [<Link to={{pathname: `delete/${target.id}`, search: location.search}}
                                       state={{background: location}}>
                                    <Button type='link' danger>Delete</Button>
                                </Link>
                                ]}
                        // style={{ maxWidth: 500, height: 200 }}
                    >
                        <ProDescriptions column={12}>
                            <ProDescriptions.Item
                                span={12}
                                valueType='text'
                                contentStyle={{
                                    maxWidth: '80%'
                                }}
                                label={<Typography.Text type='secondary'>Customer</Typography.Text>}>
                                {target.customer?.name || target.external_buyer?.name || target.accounting_customer?.name}
                            </ProDescriptions.Item>
                            <ProDescriptions.Item
                                span={12}
                                valueType='text'
                                contentStyle={{
                                    maxWidth: '80%'
                                }}
                                label={<Typography.Text type='secondary'>Manager</Typography.Text>}>
                                {target.employee.name}
                            </ProDescriptions.Item>
                        </ProDescriptions>
                        <ProCard direction='row'>
                            <ProCard colSpan={12} direction='column'>
                                <Space direction='vertical' align='center'>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            fontSize: LABEL_SIZE
                                        }}>
                                        {target.opportunity_phase.name}
                                    </Typography.Text>
                                    <RingProgress {...progressConf} />
                                </Space>
                            </ProCard>
                            <ProCard colSpan={12}>
                                <Space direction='vertical'>
                                    <Typography.Text>Value Graph</Typography.Text>
                                    <TinyColumn {...columnConf} />
                                </Space>
                            </ProCard>
                        </ProCard>
                    </ProCard>
                </Badge.Ribbon>
            </Link>
        </div>
    );
};

export default OpportunityBoxCard;

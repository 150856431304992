// import * as React from 'react';
import {useEffect, useState} from 'react';
import {LockOutlined, MobileOutlined, UserOutlined} from '@ant-design/icons';
import {LoginFormPage, ProFormCaptcha, ProFormCheckbox, ProFormText,} from '@ant-design/pro-components';
import {Divider, message, notification, Tabs} from 'antd';
import {ReactComponent as Logo} from '../assets/dm_logo_long.svg';
import {UserAuth} from "../context/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import GoogleButton from 'react-google-button'
// import {ReactComponent as Logo} from '../assets/dm_logo_compact_trans.svg';


type Props = {};

type LoginType = 'phone' | 'account';

// const iconStyles: CSSProperties = {
//     // color: 'rgba(0, 0, 0, 0.2)',
//     fontSize: '18px',
//     verticalAlign: 'middle',
//     cursor: 'pointer',
// };

type LoginUserCredentials = {
    email: string,
    password: string
}


export function UserLogin(props: Props) {

    const [loginType, setLoginType] = useState<LoginType>('account');
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const navigate = useNavigate();
    // const { state } = useLocation();
    const location = useLocation();

    const {signInWithGoogle, signInEmailPassword, user} = UserAuth();

    const handleEmailPasswordSignIn = async ({email, password}: LoginUserCredentials) => {
        if (authenticating) return

        setAuthenticating(true)
        // setError('')
        try {
            const res = await signInEmailPassword(email, password)
            // console.log(await res.user.getIdToken())
            const origin = location.state?.from?.pathname || '/';
            navigate(origin);
        } catch (e) {
            // setError(e.message)
            // console.log(e.message)
            //TODO handle error
            console.log(e)
            setAuthenticating(false)
            notification.error({message: 'Could not login.'})
        }
        setAuthenticating(false)
    };

    const handleGoogleSignIn = async () => {
        if (authenticating) return

        setAuthenticating(true)
        try {
            await signInWithGoogle();
            const origin = location.state?.from?.pathname || '/';
            navigate(origin);

        } catch (e) {
            //TODO handle error
            console.log(e);
            notification.error({message: 'Could not login.'})
            setAuthenticating(false)
        }
        setAuthenticating(false)
    };

    useEffect(() => {
        if (user != null) {
            // console.log(location.state)
            const origin = location.state?.from?.pathname || '/';
            navigate(origin);
        }
    }, [user]);

    return (
        <div style={{backgroundColor: 'white', height: 'calc(100vh - 48px)', margin: 0}}>
            <LoginFormPage
                backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
                onFinish={async (formData: LoginUserCredentials) => handleEmailPasswordSignIn(formData)}
                logo={<Logo fill='#0E3748'
                            style={{width: 400, height: 200, marginTop: -70, marginLeft: -170}}/>}
                // title="Digital Minds"
                subTitle={<></>}
                actions={
                    // <div style={{marginTop: -42}}> TODO fix empty margin when logging out !!
                    <div>
                        <Divider plain>
                            {/*<Divider plain={false}  >*/}
                            <span style={{color: '#CCC', fontWeight: 'normal', fontSize: 14}}>
                Other login methods
              </span>
                        </Divider>
                        {/*<Button size={"large"} style={{width: '100%'}} icon={*/}
                        {/*    <GoogleOutlined style={{*/}
                        {/*        color: authenticating ? '#bfbfbf' : '#1677FF',*/}
                        {/*        // background: "-webkit-linear-gradient(top, #cc0223 28%, #016b14 29%, #016b14 44%, #016b14 63%, #3b01c1 64%, #3b01c1 85%, #febf01 85%)",*/}
                        {/*        // WebkitBackgroundClip: "text",*/}
                        {/*        // WebkitTextFillColor: "transparent",*/}
                        {/*    }}/>*/}
                        {/*}*/}
                        {/*        onClick={handleGoogleSignIn}*/}
                        {/*        disabled={authenticating}*/}
                        {/*>*/}
                        {/*    Sign in with Google*/}
                        {/*</Button>*/}
                        <GoogleButton
                            style={{width: '100%', pointerEvents: authenticating ? 'none' : 'auto'}}
                            disabled={authenticating}
                            onClick={handleGoogleSignIn}
                        />
                    </div>
                }
            >
                <Tabs
                    centered
                    activeKey={loginType}
                    onChange={(activeKey) => setLoginType(activeKey as LoginType)}
                >
                    <Tabs.TabPane key={'account'} tab={'Email/Password login'}/>
                    {/*<Tabs.TabPane key={'phone'} tab={'Phone login'} disabled />*/}
                </Tabs>
                {loginType === 'account' && (
                    <>
                        <ProFormText
                            name="email"
                            fieldProps={{
                                size: 'large',
                                prefix: <UserOutlined className={'prefixIcon'}/>,
                            }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        />
                        <ProFormText.Password
                            name="password"
                            fieldProps={{
                                size: 'large',
                                prefix: <LockOutlined className={'prefixIcon'}/>,
                            }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        />
                    </>
                )}
                {loginType === 'phone' && (
                    <>
                        <ProFormText
                            fieldProps={{
                                size: 'large',
                                prefix: <MobileOutlined className={'prefixIcon'}/>,
                            }}
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    pattern: /^1\d{10}$/,
                                },
                            ]}
                        />
                        <ProFormCaptcha
                            fieldProps={{
                                size: 'large',
                                prefix: <LockOutlined className={'prefixIcon'}/>,
                            }}
                            captchaProps={{
                                size: 'large',
                            }}
                            captchaTextRender={(timing, count) => {
                                if (timing) {
                                    return `${count} Get verification code`;
                                }
                                return 'Get verification code';
                            }}
                            name="captcha"
                            rules={[
                                {
                                    required: true,
                                    // message: '请输入验证码！',
                                },
                            ]}
                            onGetCaptcha={async () => {
                                message.success('Received verification code successfully! The verification code is：1234');
                            }}
                        />
                    </>
                )}
                <div
                    style={{
                        marginBlockEnd: 24,
                    }}
                >
                    <ProFormCheckbox noStyle name="autoLogin">
                        Automatic log-in
                    </ProFormCheckbox>
                    <a
                        style={{
                            float: 'right',
                            pointerEvents: 'none'
                        }}
                    >
                        Forgot password
                    </a>
                </div>
            </LoginFormPage>
        </div>
    );


}
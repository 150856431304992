import axiosApiInstance from '../../api/axiosClient';

type Cashflow = API.BrandsCashFlowStatus;

const getExternalBuyers = async (params?: any) => {
    return axiosApiInstance
        .get<Cashflow>('/api/projects/cashflow_simple', {params: params,})
        .then(({data}) => data)
        .catch((e) => {
            throw e;
        });
};

export default getExternalBuyers;

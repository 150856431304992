import React, {useEffect, useRef} from 'react';
import StatusCard from '../components/sales/StatusCard';
import {PageContainer} from '@ant-design/pro-layout';
import {
    CheckOutlined,
    FunnelPlotOutlined,
    LineChartOutlined,
    TagsOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import {Skeleton, Tabs} from 'antd';
import SalesFunnel from '../components/sales/SalesFunnel';
import OppListNew from '../components/sales/OppListNew';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useSalesContext} from '../context/SalesContextProvider';
import ProListSkeleton from '../components/sales/ProListSkeleton';
import SalesPageExtras from '../components/sales/SalesPageExtras';
import exportToExcel from '../util/sales/exportToExcel';
import filterStringToObj from '../util/sales/convertFilterStringToObject';
import {useSalesAuxDataContext} from "../context/SalesAuxDataContextProvider";
import SalesSummaryTable from "../components/sales/SalesSummaryTable";
import SalesValidationTable from "../components/sales/SalesValidationTable";
import SalesProgressChart from "../components/sales/SalesProgressChart";

type Props = {
    card: 'list' | 'funnel' | 'summary' | 'validation' | 'progress';
};

const SalesPage = ({card}: Props) => {
    const extraContentRef = useRef<any>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const {isLoading, updateOpportunities, setSearchFilter, updateFilters, opportunities} = useSalesContext();
    const {isLoading: isAuxDataLoading} = useSalesAuxDataContext();
    const navigate = useNavigate();
    const minWidth = 680;
    const location = useLocation();

    /**
     * check for search params and initialize states accordingly
     */
    useEffect(() => {
        search: if (searchParams.has('search')) {
            const s = searchParams.get('search');
            if (s === '') break search;
            setSearchFilter(s);
        }
        if (searchParams.has('update')) {
            updateOpportunities();
            // setSearchParams(undefined);
            searchParams.delete('update');
            setSearchParams((p) => p);
            // navigate('/sales/list');
        }
        if (searchParams.has('download')) {
            exportToExcel(opportunities);
            searchParams.delete('download');
            setSearchParams((p) => p);
        }
        if (searchParams.has('filters')) {
            const p = filterStringToObj(searchParams.get('filters')!);
            updateFilters(p);
        }
    }, [searchParams]);

    return (
        <PageContainer>
            <div
                className='SalesPageBody'
                style={{
                    height: '100%',
                    flex: 1,
                    padding: '16px'
                }}>
                <div className='StatisticsCard' style={{marginBottom: 32}}>
                    <StatusCard/>
                </div>
                <Tabs
                    defaultActiveKey={card}
                    activeKey={card}
                    onTabClick={(tab) => {
                        navigate({pathname: `../${tab}`, search: location.search});
                    }}
                    // centered
                    type='card'
                    tabBarExtraContent={
                        <div style={{display: 'inline-flex'}} ref={extraContentRef}>
                            <SalesPageExtras containerRef={extraContentRef}/>
                        </div>
                    }
                    items={[
                        {
                            label: (
                                <span>
                                    <TagsOutlined/>
                                    List
                                </span>
                            ),
                            key: 'list',
                            disabled: isLoading,
                            children: (
                                <div
                                    className='OpportunitiesProList'
                                    style={{
                                        marginTop: 8,
                                        backgroundColor: 'white',
                                        borderRadius: '6px',
                                        minWidth
                                    }}>
                                    {isLoading || isAuxDataLoading ? <ProListSkeleton/> : <OppListNew/>}
                                    {/* <NewOpportunityForm /> */}
                                    {/*<NewOpportunityButton />*/}
                                </div>
                            )
                        },
                        {
                            label: (
                                <span>
                                    <FunnelPlotOutlined/>
                                    Funnel
                                </span>
                            ),
                            key: 'funnel',
                            disabled: isLoading || isAuxDataLoading,
                            children: (
                                <div
                                    className='FunnelPanel'
                                    style={{
                                        marginTop: 8,
                                        padding: 16,
                                        paddingTop: 32,
                                        paddingBottom: 32,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'white',
                                        borderRadius: '6px',
                                        minWidth
                                    }}>
                                    <div
                                        className='Funnel'
                                        style={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            width: '100%'
                                        }}>
                                        {isLoading || isAuxDataLoading ?
                                            <Skeleton.Node>
                                                <FunnelPlotOutlined style={{fontSize: 40, color: '#bfbfbf'}}/>
                                            </Skeleton.Node>
                                            : <SalesFunnel/>}
                                    </div>
                                </div>
                            )
                        },
                        {
                            label: (
                                <span>
                                    <UnorderedListOutlined/>
                                    Summary
                                </span>
                            ),
                            key: 'summary',
                            disabled: isLoading || isAuxDataLoading,
                            children: (
                                <div
                                    // className='FunnelPanel'
                                    style={{
                                        marginTop: 8,
                                        // padding: 16,
                                        // paddingTop: 32,
                                        paddingBottom: 32,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'white',
                                        borderRadius: '6px',
                                        minWidth
                                    }}>
                                    <div
                                        // className='Funnel'
                                        style={{
                                            // display: 'flex',
                                            // alignContent: 'center',
                                            // justifyContent: 'center',
                                            // width: '100%'
                                        }}>
                                        {isLoading || isAuxDataLoading ?
                                            <ProListSkeleton/>
                                            : <SalesSummaryTable/>}
                                    </div>
                                </div>
                            )
                        },
                        {
                            label: (
                                <span>
                                    <CheckOutlined/>
                                    Validation
                                </span>
                            ),
                            key: 'validation',
                            disabled: isLoading || isAuxDataLoading,
                            children: (
                                <div
                                    // className='FunnelPanel'
                                    style={{
                                        marginTop: 8,
                                        // padding: 16,
                                        // paddingTop: 32,
                                        paddingBottom: 32,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'white',
                                        borderRadius: '6px',
                                        minWidth
                                    }}>
                                    <div
                                        // className='Funnel'
                                        style={{
                                            // display: 'flex',
                                            // alignContent: 'center',
                                            // justifyContent: 'center',
                                            // width: '100%'
                                        }}>
                                        {isLoading || isAuxDataLoading ?
                                            <ProListSkeleton/>
                                            : <SalesValidationTable/>
                                        }
                                    </div>
                                </div>
                            )
                        },
                        {
                            label: (
                                <span>
                                    <LineChartOutlined/>
                                    Progress Chart
                                </span>
                            ),
                            key: 'progress',
                            disabled: isLoading || isAuxDataLoading,
                            children: (
                                <div
                                    // className='FunnelPanel'
                                    style={{
                                        marginTop: 8,
                                        // padding: 16,
                                        // paddingTop: 32,
                                        paddingBottom: 32,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'white',
                                        borderRadius: '6px',
                                        minWidth
                                    }}>
                                    <div
                                        // className='Funnel'
                                        style={{
                                            // display: 'flex',
                                            // alignContent: 'center',
                                            // justifyContent: 'center',
                                            // width: '100%'
                                        }}>
                                        {isLoading || isAuxDataLoading ?
                                            <ProListSkeleton/>
                                            : <SalesProgressChart/>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    ]}
                />
            </div>
        </PageContainer>
    );
};

export default SalesPage;

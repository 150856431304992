import React, {useCallback, useContext, useEffect, useState} from 'react';
import getOpportunityData from '../util/sales/getOpportunityData';
import opportunityFilter from '../util/sales/opportunityFilter';
import dayjs, {Dayjs} from 'dayjs';
import {message, notification} from 'antd';
import {useSearchParams} from 'react-router-dom';
import {auth} from "../config/firebase";
import belongsToFiscalYear from "../util/sales/belongsToFiscalYear";
import {stringToFilterObj} from "../util/sales/convertFilterObjectToString";
import opportunitySearch from "../util/sales/opportunitySearch";

type SalesContextType = {
    isLoading: boolean;
    isCurrent: boolean;
    filters: Record<string, any>;
    searchFilter: string;
    opportunities: Array<API.Opportunity>;
    opportunitiesFilteredOnly: Array<API.Opportunity>;
    opportunitiesUnfiltered: Array<API.Opportunity>;
    updateOpportunities: () => Promise<void>;
    updateFilters: (tags: Record<string, number[]>) => void;
    timestamp: Dayjs | undefined;
    setTimestamp: React.Dispatch<any>;
    fiscalYear: Dayjs | undefined;
    setFiscalYear: React.Dispatch<any>;
    // fiscalDateRange: [Dayjs, Dayjs] | undefined;
    // setFiscalDateRange: React.Dispatch<any>;
    setSearchFilter: React.Dispatch<any>;
};

type Props = {
    children: JSX.Element;
};

const SalesContext = React.createContext<SalesContextType>({
    isLoading: true,
    isCurrent: true,
    opportunities: [],
    opportunitiesFilteredOnly: [],
    opportunitiesUnfiltered: [],
    filters: [],
    searchFilter: '',
    updateOpportunities: () => Promise.resolve(),
    updateFilters: (tags: Record<string, any>) => {
    },
    timestamp: undefined,
    setTimestamp: () => {
    },
    fiscalYear: dayjs(),
    setFiscalYear: () => {
    },
    setSearchFilter: () => {
    },
    // fiscalDateRange: [dayjs(), dayjs()],
    // setFiscalDateRange: () => {
    // }
});

//|-|--|---|----|-----|------|-------|--------|---------|----------|-----------|-----------|----------|
/**
 * This is the sales context that preloads data necessary for the sales branch of the application to function.
 * The reason it's needed is because various components nested several layers deep require access to the same data,
 * so all of it is preloaded and handled accordingly in order to save time, without needing to query our backend multiple
 * times. The context provides an opportunity list which is dynamically updated when the states that affect which
 * opportunities must be shown are updated, those being selectedOpportunities, selectedFilters, timestamp and fiscalYear.
 * The data provided by sales context is the following:
 *
 * @property {boolean} isLoading - Determines whether context is currently loading data
 * @property {boolean} isCurrent - Determines whether the opportunities loaded are current or versioned
 * @property {API.Opportunity[]} selectedOpportunities - Current or historical opportunities, internal use only
 * @property {API.Opportunity[]} opportunities - The exposed opportunities list that other components interface with
 * @property {Record<string,any>[]} selectedFilters - The filters object generated by antdesign Pro LightFilter component
 * @property {dayjs} timestamp - Dayjs object that indicates the date for which to fetch the versioned opportunity table
 * @property {dayjs} fiscalYear - Dayjs object that indicates the fiscal year to be displayed
 * @property {string} searchFilter - String to filter opportunities by
 *
 * The context requires a React Component as children param to wrap around it:
 *
 * @param children React component to render in context
 * @returns context with loaded data
 */
const SalesContextProvider = ({children}: Props) => {
    const [searchParams] = useSearchParams();
    // isCurrent tracks whether the list of opportunities on display is current or historical
    const [isLoading, setIsLoading] = useState(true);
    const [isCurrent, setIsCurrent] = useState(true);

    // selected opportunities is either a copy of current or historical.
    // "opportunities" is the public interface object other modules read and interract with.
    const [selectedOpportunities, setSelectedOpportunities] = useState<API.Opportunity[]>([]);
    const [opportunities, setOpportunities] = useState<API.Opportunity[]>([]);
    // opportunitiesFilteredOnly is the list of opportunities that are filtered by the search bar without being filtered by fiscal time range
    const [opportunitiesFilteredOnly, setOpportunitiesFilteredOnly] = useState<API.Opportunity[]>([]);
    const [opportunitiesUnfiltered, setOpportunitiesUnfiltered] = useState<API.Opportunity[]>([]);

    const [selectedFilters, setSelectedFilters] = useState<Record<string, any>>([]);

    const [timestamp, setTimestamp] = useState<Dayjs | undefined>(undefined);
    const [fiscalYear, setFiscalYear] = useState<Dayjs>(
        searchParams.get('fyear') ? dayjs(searchParams.get('fyear'), 'YYYY') : dayjs()
    );
    // const [fiscalDateRange, setFiscalDateRange] = useState<[Dayjs, Dayjs]>(
    //     [searchParams.get('fstartdate') ? dayjs(searchParams.get('fstartdate'), 'YYYY-MM') : dayjs().startOf('year'),
    //         searchParams.get('fenddate') ? dayjs(searchParams.get('fenddate'), 'YYYY-MM') : dayjs().endOf('year')]
    // )
    const [searchFilter, setSearchFilter] = useState<string>(searchParams.get('search') || '');

    /**
     * on change timestamp, load the versioned opportunities for the given timestamp.
     * if timestamp is undefined, then the current unversioned opportunities are loaded instead
     * keep track of whether the opportunities are current or versioned with setIsCurrent
     */
    useEffect(() => {
        setIsLoading(true);
        getOpportunityData(timestamp)
            .then((res) => {
                setSelectedOpportunities(res);
                setIsCurrent(timestamp ? false : true);
            })
            .catch((e) => message.error(e))
            .finally(() => setIsLoading(false));
    }, [timestamp]);

    /**
     * on url search params change, set the search filter to the string in the search params, or set to empty string
     * if param is not set
     */
    useEffect(() => {
        // console.log('search params changed: ', searchParams.get('search'));
        setSearchFilter(searchParams.get('search') || '');

        if (searchParams.get('filters')) {
            const filterObj = stringToFilterObj(searchParams.get('filters') || '');
            console.log('filterObj: ', filterObj)
            setSelectedFilters(filterObj);
        }

    }, [searchParams]);

    /**
     * when selectedOpportunities, selectedFilters or fiscalYear is changed,
     * the opportunities interface object needs to be recalculated
     */
    useEffect(() => {
        // console.log('states altered: ',
        //     // selectedOpportunities,
        //     selectedFilters,
        //     // fiscalYear,
        //     // fiscalDateRange
        // );
        setOpportunities(
            selectedOpportunities
                .filter((opp) => opportunityFilter(opp, selectedFilters))
                .filter((opp) => belongsToFiscalYear(opp, fiscalYear))
                .filter((opp) => opportunitySearch(opp, searchFilter))

            // .filter((opp) => belongsToFiscalDateRange(opp, fiscalDateRange))
        );
        setOpportunitiesFilteredOnly(
            selectedOpportunities
                .filter((opp) => opportunityFilter(opp, selectedFilters))
        );
        setOpportunitiesUnfiltered(
            selectedOpportunities
        );
    }, [selectedOpportunities, selectedFilters,
        fiscalYear, searchFilter
        // fiscalDateRange
    ]);

    // useEffect(() => {
    //     console.log({
    //         opportunitiesFilteredOnly: opportunitiesFilteredOnly.filter((opp) => !opp.is_compound).filter((opp) => belongsToFiscalYear(opp, fiscalYear))
    //     });
    // }, [opportunitiesFilteredOnly])

    /**
     * this function is called when SalesContext is mounted, and initializes the states that keep track of data
     */
    const bootstrap = useCallback(async () => {
        if (auth.currentUser === null) {
            // wait for user to be logged in
            await new Promise((resolve) => {
                    const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                            unsubscribe();
                            resolve(user);
                        }
                    });
                }
            );
        }

        setIsLoading(true);

        Promise.all([
            getOpportunityData()
        ])
            .then((values) => {
                const [opportunities] = values;
                setSelectedOpportunities(opportunities);
                console.log('opportunities: ', opportunities, 'selected: ', selectedOpportunities);
            })
            .catch((e) => {
                // notification.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []); // useCallback hook: declare function on mount only

    /**
     * Callback function used to update the selected opportunities for a given timestamp
     */
    const updateOpportunities = useCallback(async (timestamp?: Dayjs) => {
        setIsLoading(true)
        await getOpportunityData(timestamp)
            .then((val) => setSelectedOpportunities(val))
            .catch((e) => console.error(e))
            .finally(() => setIsLoading(false));
    }, []); // useCallback hook: declare function on mount only

    /**
     * Callback function used to update filters
     */
    const updateFilters = useCallback((newFilters: Record<string, any>) => {
        setSelectedFilters(newFilters);
    }, []);

    /**
     * Call bootstrap function on mount
     */
    useEffect(() => {
        bootstrap();
    }, [bootstrap]); // on mount

    return (
        <SalesContext.Provider
            value={{
                isLoading,
                isCurrent,
                opportunities,
                filters: selectedFilters,
                searchFilter,
                updateOpportunities,
                opportunitiesFilteredOnly,
                opportunitiesUnfiltered,
                updateFilters,
                timestamp,
                setTimestamp,
                fiscalYear,
                setFiscalYear,
                setSearchFilter,
                // fiscalDateRange,
                // setFiscalDateRange,
            }}>
            {children}
        </SalesContext.Provider>
    );
};

/**
 * This hook provides the sales context, and exposes the following data to the user:
 *
 * @property {boolean} isLoading - Determines whether context is currently loading data
 * @property {boolean} isCurrent - Determines whether the opportunities loaded are current or versioned
 * @property {API.Opportunity[]} opportunities - The exposed opportunities list that other components interface with
 * @property {API.Opportunity[]} opportunitiesFilteredOnly - The exposed opportunities list filtered only by filters (not fiscal year)
 * @property {API.Opportunity[]} opportunitiesUnfiltered - The exposed opportunities list unfiltered (no filters applied at all)
 * @property {Record<string,any>[]} filters - The filters object generated by antdesign Pro LightFilter component
 * @property {Record<string,any>[]} selectedFilters - The filters object generated by antdesign Pro LightFilter component
 * @property {dayjs} timestamp - Dayjs object that indicates the date for which to fetch the versioned opportunity table
 * @property {dayjs} fiscalYear - Dayjs object that indicates the fiscal year to be displayed
 * @callback updateOpportunities - refresh opportunity list and apply selected filters
 * @callback updateFilters - set a new antd-pro LightFilter object
 * @callback setTimestamp - set a new dayjs timestamp
 * @callback setFiscalYear - set a new dayjs fiscal year
 * @callback setSearchFilter - set a new string to filter opportunities by name
 *
 * @returns {SalesContextType} context data
 */
export const useSalesContext = () => {
    return useContext(SalesContext);
};

export default SalesContextProvider;

import * as React from 'react';
import {Key, useContext, useState} from 'react';
import axiosApiInstance from "../../api/axiosClient";
import {Link, useLocation, useParams} from "react-router-dom";
import {Button, Col, Row, Space, Typography} from "antd";
import {UserAuth} from "../../context/AuthContext";
import {GlobalStateContext} from "../../context/GlobalContext";
import {ProjectTwoTone, ReloadOutlined} from "@ant-design/icons";
import currencyFormatter from "../../util/currency_formatter";
import {ProList} from "@ant-design/pro-components";
import './CommittedTransactions.css'

type Props = {};

const CommittedTransactions = (props: Props) => {
    const routerParams = useParams();
    const [data, setData] = useState<API.Transaction[] | undefined>(undefined);

    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [radioValue, setRadioValue] = useState('all-time');
    const {can} = UserAuth();
    const {transactionsListRef} = useContext(GlobalStateContext);
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <div className={"CommittedTransactions"}>

            {/*{data?.map((i) =>*/}
            {/*    <Row> {i.debit_account.description} | {i.credit_account.description} | {i.amount} | {dayjs.utc(i.suggested_at).format('YYYY-MM-DD')} </Row>)}*/}


            <ProList<API.Transaction>
                className={'Transactions'}
                cardBordered
                toolBarRender={() => {
                    return [
                        <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                            transactionsListRef?.current?.reset!()
                            transactionsListRef?.current?.reload()
                        }}/>
                    ];
                }}
                // search={{}}
                actionRef={transactionsListRef}
                search={{filterType: "light"}}
                rowSelection={{
                    selectedRowKeys,
                    onChange: (keys: Key[]) => setSelectedRowKeys(keys as number[]),
                    renderCell: (value, record, index, originNode) => {
                        return <Row justify={"center"} align="middle" style={{height: 46}}>
                            <Col>
                                {originNode}
                            </Col>
                        </Row>
                    },
                }}
                rowKey="id"
                onDataSourceChange={setData}
                headerTitle="Committed Accounting Entries"
                request={async (params = {}) => {
                    return axiosApiInstance.get<API.Transaction[]>(`/api/projects/${routerParams.projectId}/transactions/committed`)
                }
                }
                pagination={{
                    pageSize: 8,
                    hideOnSinglePage: true
                }}
                tableAlertRender={({selectedRowKeys}) => {
                    return (
                        <Space size={24}>
                          <span>
                                Selected {selectedRowKeys.length} {selectedRowKeys.length > 1 ? 'transactions' : 'transaction'}
                          </span>
                        </Space>
                    )
                }}
                tableAlertOptionRender={({selectedRowKeys, onCleanSelected}) => {
                    return (
                        <Space size={16}>
                            {/*<DeleteProjects transactionIds={selectedRowKeys as number[]}*/}
                            {/*                label={'Batch delete'} onClearSelection={onCleanSelected} batch/>*/}
                            <a style={{marginInlineStart: 8}} onClick={onCleanSelected}>
                                Clear selection
                            </a>
                            {/*<a>Delete batch</a>*/}
                        </Space>
                    );
                }}
                metas={{
                    // title: {
                    //     dataIndex: 'title',
                    //     title: 'Title',
                    //     // render: (dom, entity) => <Link to={'' + entity.id}>{dom}</Link>
                    //     render: (dom, entity) => <div style={{flex: 'content'}}><Link
                    //         to={'' + entity.id}>{dom}</Link></div>
                    // },
                    avatar: {
                        search: false,
                        render: () => <Row justify={"center"} align="middle" style={{width: 36, height: 48}}>
                            <Col>
                                <ProjectTwoTone style={{fontSize: 22}}/>
                            </Col>
                        </Row>
                    },
                    // description: {
                    //     dataIndex: 'transaction_pretty_id',
                    //     title: 'Case Code',
                    //     // search: false,
                    // },
                    actions: {
                        search: false,
                        // render: (dom, entity) => {
                        //     // return <a key="delete">Commit</a>;
                        //     // return <Button size={"small"}>Commit</Button>;
                        //     // return <CommitTransactionDialog transaction={entity}/>;
                        //     // return <a key="delete"> <DeleteProject listRef={actionRef}/> </a>;
                        //     // return <DeleteProjects transactionIds={[entity.id!]} label={'Delete'}/>;
                        // },
                    },
                    content: {
                        search: false,
                        render: (dom, entity) => {
                            let parentType = ''
                            let parentUrl = `/projects/${routerParams.projectId}/`
                            if (entity.logged_invoice_incoming_id) {
                                parentUrl += `invoices/incoming/${entity.logged_invoice_incoming_id}`
                                parentType = 'Incoming Invoice #' + entity.logged_invoice_incoming_id
                            } else if (entity.logged_invoice_outgoing_id) {
                                parentUrl += `invoices/outgoing/${entity.logged_invoice_outgoing_id}`
                                parentType = 'Outgoing Invoice #' + entity.logged_invoice_outgoing_id
                            } else if (entity.logged_payment_id) {
                                parentUrl += `payments/${entity.logged_payment_id}`
                                parentType = 'Payment #' + entity.logged_payment_id
                            }
                            return <div
                                style={{
                                    display: 'flex',
                                    flexWrap: "wrap",
                                    rowGap: 16
                                }}
                            >
                                <div
                                    style={{
                                        width: '250px',
                                    }}
                                >
                                    <Space direction={"vertical"} align={"start"}>
                                        <Typography.Text>{'Debit Account'}</Typography.Text>
                                        <Typography.Text
                                        >{entity.debit_account.description}</Typography.Text>
                                    </Space>
                                </div>
                                <div
                                    style={{
                                        width: '250px',
                                    }}
                                >
                                    <Space direction={"vertical"} align={"start"}>
                                        <Typography.Text>{'Credit Account'}</Typography.Text>
                                        <Typography.Text
                                        >{entity.credit_account.description}</Typography.Text>
                                    </Space>
                                </div>
                                <div
                                    style={{
                                        width: '140px',
                                    }}
                                >
                                    <Space direction={"vertical"} align={"start"}>
                                        <Typography.Text>{'Amount'}</Typography.Text>
                                        <Typography.Text
                                        >{currencyFormatter.format(entity.amount)}</Typography.Text>
                                    </Space>
                                </div>
                                <div
                                    style={{
                                        width: '220px',
                                    }}
                                >
                                    <Space direction={"vertical"} align={"start"}>
                                        <Typography.Text>{'Suggested By'}</Typography.Text>
                                        <Link to={parentUrl}>{parentType}</Link>
                                    </Space>
                                </div>
                                <div
                                    style={{
                                        width: '150px',
                                    }}
                                >
                                    <Space direction={"vertical"} align={"start"}>
                                        <Typography.Text>{'Suggested At'}</Typography.Text>
                                        <Typography.Text
                                        >{new Date(entity.suggested_at).toLocaleDateString('el')}</Typography.Text>
                                    </Space>
                                </div>
                                <div
                                    style={{
                                        width: '150px',
                                    }}
                                >
                                    <Space direction={"vertical"} align={"start"}>
                                        <Typography.Text>{'Committed At'}</Typography.Text>
                                        <Typography.Text
                                        >{new Date(entity.created_at).toLocaleDateString('el')}</Typography.Text>
                                    </Space>
                                </div>
                            </div>

                        },
                    },
                }}
            />

        </div>
    );
};

export default CommittedTransactions;
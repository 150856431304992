import * as React from 'react';
import {useEffect, useState} from 'react';
import {ProTable, ProColumns} from "@ant-design/pro-table";
import {useSalesContext} from "../../context/SalesContextProvider";
import {LightFilter, ProFormDatePicker} from "@ant-design/pro-components";
import dayjs, {Dayjs} from "dayjs";
import countFormatter from "../../util/count_formatter";
import currencyFormatter from "../../util/currency_formatter";

type Props = {};

type OpportunitiesSummaryDatum = {
    month: string;
    totalUnweightedValue: number;
    totalWeightedValue: number;
    totalNumberOfOpportunities: number;
};

const SalesSummaryTable = (props: Props) => {
// this component will be used to display the summary table on the sales page
    // on the top, it will display a year picker
    // below that, it will display a table with the following columns:
    // 1. month
    // 2. total unweighted value
    // 3. total weighted value
    // 4. total number of opportunitiesFilteredOnly

    const {opportunitiesFilteredOnly} = useSalesContext()
    const [year, setYear] = useState<Dayjs | null>(dayjs());

    const columns: ProColumns<any>[] = [
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            width: 100,
            fixed: 'left',
            sorter: (a: any, b: any) => dayjs(a.month, 'YYYY-MM').isBefore(dayjs(b.month, 'YYYY-MM')) ? -1 : 1,
            render: (text: any, record: any) => {
                return <span>{dayjs(record.month, 'YYYY-MM').format('MMMM YYYY')}</span>
            },
            // renderFormItem: (item, {value, onChange}) => {
            //     return (
            //         <DatePicker.YearPicker
            //             value={year}
            //             onChange={(value) => {
            //                 setYear(value);
            //             }}
            //             style={{width: '100%'}}
            //         />
            //     )
            // },
            align: 'center',
            defaultSortOrder: "ascend"
        },
        {
            title: 'Total Number of Opportunities',
            dataIndex: 'totalNumberOfOpportunities',
            key: 'totalNumberOfOpportunities',
            width: 200,
            sorter: (a: any, b: any) => a.totalNumberOfOpportunities - b.totalNumberOfOpportunities,
            render: (text: any, record: any) => {
                return <span>{countFormatter.format(record.totalNumberOfOpportunities)}</span>
            },
            search: false,
            align: 'right'
        },
        {
            title: 'Total Unweighted Value',
            dataIndex: 'totalUnweightedValue',
            key: 'totalUnweightedValue',
            width: 150,
            sorter: (a: any, b: any) => a.totalUnweightedValue - b.totalUnweightedValue,
            render: (text: any, record: any) => {
                return <span>{currencyFormatter.format(record.totalUnweightedValue)}</span>
            },
            search: false,
            align: 'right'
        },
        {
            title: 'Total Weighted Value',
            dataIndex: 'totalWeightedValue',
            key: 'totalWeightedValue',
            width: 150,
            sorter: (a: any, b: any) => a.totalWeightedValue - b.totalWeightedValue,
            render: (text: any, record: any) => {
                return <span>{currencyFormatter.format(record.totalWeightedValue)}</span>
            },
            search: false,
            align: 'right'
        },
    ]

    const [data, setData] = useState<OpportunitiesSummaryDatum[]>([]);

    useEffect(() => {
        // the data will be an array of opportunitiesFilteredOnly of the selected year, grouped by month
        // for each month, we will calculate the total unweighted value, total weighted value, and total number of opportunitiesFilteredOnly
        const groupedOpportunities = opportunitiesFilteredOnly.filter((opportunity) => !opportunity.is_compound).reduce((result: {
            [date: string]: OpportunitiesSummaryDatum
        }, opportunity) => {

            const closureDate = dayjs(opportunity.closure_date);
            const expectedPoDate = dayjs(opportunity.expected_po_date);

            // "forecast to complete"
            if (!opportunity.opportunity_phase.status.completed && expectedPoDate.year() < year!.year()) {
                return result;
            }
            // else ("up-to-date" forecast)
            const fiscalMonthForOpportunity = opportunity.opportunity_phase.status.completed ? closureDate : expectedPoDate;
            // console.log(fiscalMonthForOpportunity.format('YYYY-MM'));
            // if (fiscalMonthForOpportunity.year() == 1) {
            // }
            const key = fiscalMonthForOpportunity.format('YYYY-MM');
            if (!result[key]) {
                result[key] = {
                    month: key,
                    totalUnweightedValue: opportunity.proposed_value_in_euro!,
                    totalWeightedValue: opportunity.po_value_in_euro!,
                    totalNumberOfOpportunities: 1,
                }
            } else {
                result[key].totalUnweightedValue += opportunity.proposed_value_in_euro!;
                result[key].totalWeightedValue += opportunity.po_value_in_euro!;
                result[key].totalNumberOfOpportunities += 1;
            }

            return result;
        }, {});

        const data = Object.values(groupedOpportunities);

        data.sort((a, b) => {
            return dayjs(a.month, 'YYYY-MM').isBefore(dayjs(b.month, 'YYYY-MM')) ? -1 : 1;
        })

        if (!year) {
            setData(data);
        } else {
            setData(data.filter((opportunity) => {
                return dayjs(opportunity.month, 'YYYY-MM').year() === year?.year();
            }))
        }


    }, [opportunitiesFilteredOnly, year])

    return (
        <div>
            {/* table */}
            <ProTable
                columns={columns}
                dataSource={data}
                pagination={false}
                search={false}
                options={false}
                // toolBarRender={false}
                rowKey="id"
                tableStyle={{width: '100%'}}
                size="small"
                scroll={{x: 'max-content'}}
                style={{marginTop: 16}}
                // search={{}}
                toolbar={{
                    style: {
                        display: 'flex',
                        alignItems: 'start',
                    },
                    filter: (
                        <LightFilter>
                            <ProFormDatePicker.Year
                                name="year"
                                label="Year"
                                fieldProps={{
                                    style: {
                                        width: '100%',
                                    },
                                    value: year,
                                    onChange: (value) => {
                                        if (value)
                                            setYear(value);
                                    }
                                }}
                                placeholder="Select year"
                                help={'Select fiscal year'}
                                allowClear={false}
                            />
                        </LightFilter>
                    ),
                }}
                summary={(pageData) => {
                    let totalUnweightedValue = 0;
                    let totalWeightedValue = 0;
                    let totalNumberOfOpportunities = 0;

                    pageData.forEach(({
                                          totalUnweightedValue: unweightedValue,
                                          totalWeightedValue: weightedValue,
                                          totalNumberOfOpportunities: numberOfOpportunities
                                      }) => {
                        totalUnweightedValue += unweightedValue;
                        totalWeightedValue += weightedValue;
                        totalNumberOfOpportunities += numberOfOpportunities;
                    });


                    let totalForecastToCompleteUnweightedValue = 0;
                    let totalForecastToCompleteWeightedValue = 0;
                    let totalForecastToCompleteNumberOfOpportunities = 0;
                    opportunitiesFilteredOnly.filter((opportunity) => !opportunity.is_compound).forEach((opportunity) => {
                        const expectedPoDate = dayjs(opportunity.expected_po_date);
                        // "forecast to complete"
                        if (!opportunity.opportunity_phase.status.completed && expectedPoDate.year() < year!.year()) {
                            // console.log('forecast to complete', opportunity)
                            totalForecastToCompleteUnweightedValue += opportunity.proposed_value_in_euro!;
                            totalForecastToCompleteWeightedValue += opportunity.po_value_in_euro!;
                            totalForecastToCompleteNumberOfOpportunities += 1;
                        }
                    })

                    return (
                        <>
                            <ProTable.Summary.Row>
                                <ProTable.Summary.Cell index={0} align={"center"}>Forecast To
                                    Complete</ProTable.Summary.Cell>
                                <ProTable.Summary.Cell
                                    index={1}
                                    align={"right"}>{countFormatter.format(totalForecastToCompleteNumberOfOpportunities)}</ProTable.Summary.Cell>
                                <ProTable.Summary.Cell
                                    index={2}
                                    align={"right"}>{currencyFormatter.format(totalForecastToCompleteUnweightedValue)}</ProTable.Summary.Cell>
                                <ProTable.Summary.Cell
                                    index={3}
                                    align={"right"}>{currencyFormatter.format(totalForecastToCompleteWeightedValue)}</ProTable.Summary.Cell>
                            </ProTable.Summary.Row>
                            <ProTable.Summary.Row>
                                <ProTable.Summary.Cell index={0} align={"center"}>Total</ProTable.Summary.Cell>
                                <ProTable.Summary.Cell
                                    index={1}
                                    align={"right"}>{countFormatter.format(totalNumberOfOpportunities + totalForecastToCompleteNumberOfOpportunities)}</ProTable.Summary.Cell>
                                <ProTable.Summary.Cell
                                    index={2}
                                    align={"right"}>{currencyFormatter.format(totalUnweightedValue + totalForecastToCompleteUnweightedValue)}</ProTable.Summary.Cell>
                                <ProTable.Summary.Cell
                                    index={3}
                                    align={"right"}>{currencyFormatter.format(totalWeightedValue + totalForecastToCompleteWeightedValue)}</ProTable.Summary.Cell>
                            </ProTable.Summary.Row>
                        </>
                    );
                }}

                // the search bar must contain only one year picker

            />


        </div>
    );
};

export default SalesSummaryTable;
import * as React from 'react';
import {useContext} from 'react';
import {ModalForm, ProFormDatePicker, ProFormDigit, ProFormMoney} from "@ant-design/pro-components";
import {Form} from "antd";
import axiosApiInstance from "../../../api/axiosClient";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {GlobalStateContext} from "../../../context/GlobalContext";
import dayjs from "dayjs";
import currencyFormatter from "../../../util/currency_formatter";
import {ArrowRightOutlined} from '@ant-design/icons'

type Props = {};

const NewPlannedInvoice = (props: Props) => {
    const [form] = Form.useForm<API.InvoicingPlanItem>();
    const navigate = useNavigate();
    const routerParams = useParams();
    const location = useLocation();
    const {invoicingPlanListRef, notificationsListRef} = useContext(GlobalStateContext);

    const amountNotInvoiced = location.state?.amountNotInvoiced;
    const amountNotPlanned = location.state?.amountNotPlanned;

    const amountValue = Form.useWatch('amount', form);

    return (
        <ModalForm<API.InvoicingPlanItem>
            title="Create a new Planned Invoice"
            visible={true}
            form={form}
            autoFocusFirstInput
            // width={"100%"}
            width={400}
            modalProps={{
                destroyOnClose: true,
                okText: 'Create',
                onCancel: () => navigate(`/projects/${routerParams.projectId}/executions/${routerParams.executionWbsVersionId}`),
            }}
            // submitTimeout={2000}
            onFinish={(values) => {
                console.log({values});
                // return Promise.resolve()
                return axiosApiInstance.post<API.InvoicingPlanItem>(`/api/projects/${routerParams.projectId}/invoices`, values)
                    .then(res => {
                        invoicingPlanListRef?.current?.reload()
                        notificationsListRef?.current?.reload()
                        navigate(`/projects/${routerParams.projectId}/executions/${routerParams.executionWbsVersionId}`)
                        return true
                    }).catch(reason => false)
            }}
            validateMessages={{required: "${label} is required!"}}
            // initialValues={{
            //     dateRange: []
            // }}
        >
            {/*<ProFormDigit*/}
            {/*    name="project_id"*/}
            {/*    initialValue={+routerParams.projectId!}*/}
            {/*    hidden={true}*/}
            {/*/>*/}
            <ProFormDigit
                name="execution_wbs_version_id"
                initialValue={+routerParams.executionWbsVersionId!}
                hidden={true}
            />

            <ProFormDatePicker name={"date"} label={"Date"}
                               rules={[{required: true}]}
                               normalize={(value) => {
                                   return dayjs.utc(value).startOf('d').toISOString()
                                   // return new Date(value).toISOString()
                               }}
                               fieldProps={{
                                   // disabledDate: (currentDay) => dayjs.utc(currentDay).isBefore(dayjs.utc()), // TODO enable
                                   showToday: false
                               }}
            />

            <ProFormMoney
                name="amount"
                label={'Amount'}
                customSymbol={'€'}
                width={"sm"}
                fieldProps={{
                    numberFormatOptions: {currency: 'EUR'}
                }}
                // rules={[{required: true}]}
                // help={`Amount not planned: ${currencyFormatter.format(amountNotPlanned)}`}
                help={<>
                    Amount not planned: {currencyFormatter.format(amountNotPlanned)} {amountValue != undefined && <>
                    <ArrowRightOutlined/> {currencyFormatter.format(amountNotPlanned - amountValue)}</>}
                </>}
                rules={[
                    // {required: true},
                    {
                        // message: 'this is custom',
                        validator: (_, value) => {
                            // console.log(value)
                            if ((value || 0) <= amountNotPlanned) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('Some message here');
                            }
                        }
                    }
                ]}
            />
        </ModalForm>
    );
};

export default NewPlannedInvoice;
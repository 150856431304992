import { ProList } from '@ant-design/pro-components';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

const LIST_MIN_WIDTH = 1480;

/**
 * Το skeleton που εμφανίζεται ως placeholder οταν φορτώνει η σελίδα των sales
 * @returns 
 */
const ProListSkeleton = () => {
    const [column, setColumn] = useState<number>(window.innerWidth < LIST_MIN_WIDTH ? 1 : 2);
    const data = [1, 2, 3, 4, 5, 6].map((v) => ({ value: v }));

    useEffect(() => {
        function handleResize() {
            if (column === 2 && window.innerWidth < LIST_MIN_WIDTH) {
                setColumn(1);
            }
            if (column === 1 && window.innerWidth > LIST_MIN_WIDTH) {
                setColumn(2);
            }
        }
        window.addEventListener('resize', handleResize);
    });

    return (
        <ProList<{ value: number }>
            rowKey='number'
            headerTitle='Sales Opportunities'
            cardBordered
            dataSource={data}
            pagination={{
                defaultPageSize: 6,
                pageSizeOptions: [3, 6]
            }}
            renderItem={(datum) => (
                <Skeleton
                    active
                    loading
                    paragraph={{ rows: 2 }}
                    style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8 }}
                />
            )}
            grid={{ column: column }}
        />
    );
};

export default ProListSkeleton;

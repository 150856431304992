import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, notification, Skeleton, Space, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormMoney,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTreeSelect
} from "@ant-design/pro-components";
import {ProFormInstance} from "@ant-design/pro-form/es/BaseForm/BaseForm";
import axiosApiInstance from "../../../../api/axiosClient";
import {GlobalStateContext} from "../../../../context/GlobalContext";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import {PaperClipOutlined} from '@ant-design/icons'
import currencyFormatter from "../../../../util/currency_formatter";

dayjs.extend(utc)

type Props = {
    editable: boolean
};

const DEFAULT_GROSS_MARGIN_P_OTC = 38

const InvoiceOutgoingGlobal = (props: Props) => {
    const [form] = Form.useForm<API.LoggedInvoiceOutgoing>();
    const navigate = useNavigate();
    const routerParams = useParams();
    // const [editable, setEditable] = useState<boolean>(false);
    // const [editable, setEditable] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [generating, setGenerating] = useState<boolean>(false);
    const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const formRef = useRef<ProFormInstance>();
    const [invoiceData, setInvoiceData] = useState<API.LoggedInvoiceOutgoing | undefined>(undefined);
    const {invoicesOutgoingGlobalListRef, taxCategories} = useContext(GlobalStateContext);
    const [projectsData, setProjectsData] = useState<API.Project[] | undefined>(undefined);
    const [costCentersData, setCostCentersData] = useState<any>([]);

    const fetchInvoiceData = async () => {
        console.log('fetchInvoiceData')
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.LoggedInvoiceOutgoing>(`/api/invoices/outgoing/${routerParams.invoiceId}`)
            console.log(response.data)
            setInvoiceData(response.data);
            form.setFieldsValue(response.data)
            setLoading(false)
        } catch (e) {
            // console.log('catch error')
            setInvoiceData(undefined)
            setLoading(false)
        }

    }

    const fetchProjectsData = async () => {
        try {
            // setLoading(true)
            const response = await axiosApiInstance.get<API.Project[]>(`/api/projects/onexecution`)
            // console.log(response.data)
            setProjectsData(response.data);
            // setLoading(false)

            // if (response.data.length > 0) {
            //     // setSelectedProjectId(response.data[0].id)
            //     form.setFieldsValue({project_id: response.data[0].id})
            // }
        } catch (e) {
            console.log('catch error')
            setProjectsData(undefined)
            // setLoading(false)
        }
    };

    const fetchWbsVersionData = async () => {
        // console.log(invoiceData)
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.ExecutionWBSVersion>(`/api/projects/${invoiceData?.project_id}/structures/executions/active`)
            // console.log(response.data)
            setWbsVersionData(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setWbsVersionData(undefined)
            setLoading(false)
        }
    };

    const generateInvoiceDocument = async () => {
        setGenerating(true)
        axiosApiInstance.post<API.LoggedInvoiceOutgoing>(`/api/invoices/outgoing/${routerParams.invoiceId}`)
            .then(() => {
                fetchInvoiceData();
                invoicesOutgoingGlobalListRef?.current?.reload()
                notification.success({message: 'Success'})
                setGenerating(false)
            })
            .catch(reason => {
                setGenerating(false)
            })
    }

    useEffect(() => {
        fetchInvoiceData();
        fetchProjectsData();
        // fetchWbsVersionData();
    }, []);

    useEffect(() => {
        if (invoiceData) {
            fetchWbsVersionData();
            fetchCostCenters();
        }
    }, [invoiceData]);

    const fetchCostCenters = async () => {
        try {
            const res = await axiosApiInstance.get(`/api/projects/${invoiceData?.project_id}/tree`)
            setCostCentersData(res.data)
        } catch (err) {
            console.log(err)
        }
    }

    // if (loading) {
    //     return <PageLoading/>
    // }

    // console.log(taxCategories)

    return (
        <ModalForm<API.LoggedInvoiceOutgoing>
            // title={`Deliverable ${routerParams.deliverableId}`}
            title={<Space>
                {/*<Typography.Text>Deliverable {routerParams.deliverableId}</Typography.Text>*/}
                {/*{invoiceData && <Typography.Text>Invoice {invoiceData.invoice_number}</Typography.Text>}*/}
                {invoiceData && <Typography.Text>Invoice #{routerParams.invoiceId}</Typography.Text>}
                {/*<Button*/}
                {/*    key="ok" type={"text"}*/}
                {/*    icon={<EditOutlined/>}*/}
                {/*    onClick={() => {*/}
                {/*        setEditable(!editable)*/}
                {/*    }}*/}
                {/*/>*/}
            </Space>}
            open={true}
            form={form}
            formRef={formRef}
            autoFocusFirstInput
            // width={"100%"}
            // width={"80%"}
            // submitter={props.editable ? {} : false}
            submitter={{
                render: (submitterProps, defaultDoms) => {
                    return props.editable ? [
                        <Button
                            key="generate"
                            type={"text"}
                            loading={generating}
                            disabled={loading}
                            onClick={() => {
                                // submitterProps.submit();
                                generateInvoiceDocument()
                            }}
                        >
                            Generate Document
                        </Button>,
                        ...defaultDoms,
                    ] : false
                },
            }}
            modalProps={{
                destroyOnClose: true,
                okText: 'Save',
                okButtonProps: {hidden: !props.editable, loading: loading, disabled: generating},
                onCancel: () => navigate(`/invoices/outgoing`),
                bodyStyle: {height: props.editable ? "calc(100vh - 270px)" : "calc(100vh - 350px)", overflowY: 'auto'},
                style: {top: props.editable ? 40 : 90},
                // style: {top: 20},
            }}
            onFinish={async formData => {
                console.log(formData)
                // return
                return axiosApiInstance.put('/api/invoices/outgoing/' + routerParams.invoiceId, formData)
                    .then(() => {
                        fetchInvoiceData();
                        invoicesOutgoingGlobalListRef?.current?.reload()
                        return false
                    })
                // .then(()=>form.resetFields())
            }}
            validateMessages={{required: "${label} is required!"}}
            // request={() => {
            //     return axiosApiInstance.get<API.Deliverable>(`/api/deliverables/${routerParams.deliverableId}`)
            //         .then(value => {
            //             // console.log(value.data)
            //             return value.data
            //         })
            //     // return Promise.resolve({})
            // }}
            // initialValues={deliverableData}
            readonly={!props.editable}
            requiredMark={props.editable}
            // colon={true}
            // onValuesChange={(changedValues, values) => console.log(changedValues, values)}
        >

            {loading ? <Skeleton active/> :
                <>

                    <ProFormDigit
                        name="id"
                        hidden={true}
                    />

                    <ProFormGroup label={'General Information'}>
                        <ProFormGroup>
                            <ProFormSelect
                                disabled
                                label={'Project'}
                                name={'project_id'}
                                width={'lg'}
                                rules={[{required: true}]}
                                placeholder={'Select Project'}
                                options={projectsData?.map(value => ({
                                    label: `${value.title} (${value.project_pretty_id})`,
                                    value: value.id
                                }))}

                            />
                            <ProFormText
                                width="sm"
                                name="invoice_number"
                                label="Invoice Number"
                                rules={[{required: true}]}
                            />
                            <ProFormDatePicker name={"date"} label={"Date Issued"}
                                               rules={[{required: true}]}
                                // transform={(value) => {
                                //     // console.log(value)
                                //     const d1 = moment(value).startOf('d').toISOString()
                                //     return {date: d1}
                                // }}
                                               normalize={(value) => {
                                                   // console.log(value)
                                                   return value.toISOString()
                                                   // return new Date(value).toISOString()
                                                   // return value
                                               }}
                            />
                            <ProFormDatePicker name={"due_date"} label={"Due Date"}
                                // rules={[{required: true}]}
                                // transform={(value) => {
                                //     // console.log(value)
                                //     const d1 = moment(value).startOf('d').toISOString()
                                //     return {date: d1}
                                // }}
                                               rules={[{required: true}]}
                                               normalize={(value) => {
                                                   // console.log(value)
                                                   return value.toISOString()
                                                   // return new Date(value).toISOString()
                                                   // return value
                                               }}
                                               fieldProps={{
                                                   presets: [
                                                       {
                                                           label: 'In 14 days',
                                                           value: dayjs.utc().add(14, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 30 days',
                                                           value: dayjs.utc().add(30, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 60 days',
                                                           value: dayjs.utc().add(60, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 90 days',
                                                           value: dayjs.utc().add(90, 'd').startOf('d')
                                                       },
                                                   ]
                                               }}
                            />

                        </ProFormGroup>
                        <ProFormGroup>


                            {/*<ProFormDependency name={[['cost_id']]}>*/}
                            {/*    {({cost_id}) => {*/}
                            {/*        return */}
                            <ProFormMoney
                                // shouldUpdate={(prevValues, nextValues) => prevValues.cost_id!=nextValues.cost_id}
                                name="cost"
                                label={'Cost'}
                                customSymbol={'€'}
                                fieldProps={{
                                    numberFormatOptions: {currency: 'EUR'}
                                }}
                                rules={[{required: true},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            // console.log(value)

                                            const cost_id = getFieldValue('cost_id')
                                            let cost_type = getFieldValue('cost_type');
                                            if (cost_id == undefined || cost_type == undefined) {
                                                return Promise.resolve();
                                            }

                                            let costMeta: API.ThirdPartyCost | undefined = undefined
                                            // console.log(form.getFieldValue('cost_type'))
                                            if (cost_type === '_execution_third_party_time_costs') {
                                                wbsVersionData?.execution_deliverables?.map((d) => {
                                                    d.third_party_time_costs?.map((c) => {
                                                        if (c.id == cost_id) {
                                                            costMeta = c
                                                            return
                                                        }
                                                    })
                                                })
                                            } else {
                                                wbsVersionData?.execution_deliverables?.map((d) => {
                                                    d.third_party_material_costs?.map((c) => {
                                                        if (c.id == cost_id) {
                                                            costMeta = c
                                                            return
                                                        }
                                                    })
                                                })
                                            }
                                            // console.log(costMeta)
                                            if (!value || costMeta!.cost >= value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Cost exceeds the task budget'));
                                        },
                                        warningOnly: true,
                                    }),
                                ]}
                                width={"xs"}
                            />
                            {/*    }}*/}
                            {/*</ProFormDependency>*/}

                            <ProFormSelect
                                options={taxCategories?.map(i => ({label: i.label, value: i.id}))}
                                width="xs"
                                name="tax_category_id"
                                label="Tax category"
                                // formItemProps={{}}
                                fieldProps={{
                                    onSelect: (tax_category_id: number | undefined) => {
                                        // console.log('SELECT')
                                        form.setFieldValue(['tax_percentage'], taxCategories?.find(value => value.id == tax_category_id)?.tax_percentage)
                                        form.setFieldValue(['tax_category'], taxCategories?.find(value => value.id == tax_category_id))
                                    }
                                }}
                                rules={[{required: true}]}
                            />

                            <ProFormItem
                                name="tax_percentage"
                                hidden={true}
                            />

                            <ProFormItem
                                name="tax_category"
                                hidden={true}
                            />

                            {/*<ProFormItem*/}
                            {/*    name="project_id"*/}
                            {/*    initialValue={+invoiceData!.project_id!}*/}
                            {/*    hidden={true}*/}
                            {/*/>*/}


                            {/*<ProFormMoney*/}
                            {/*    name="cost_incl_vat"*/}
                            {/*    label={'Cost Incl. VAT'}*/}
                            {/*    customSymbol={'€'}*/}
                            {/*    fieldProps={{*/}
                            {/*        numberFormatOptions: {currency: 'EUR'}*/}
                            {/*    }}*/}
                            {/*    rules={[{required: true}]}*/}
                            {/*    width={"xs"}*/}
                            {/*/>*/}


                            <ProFormDependency
                                name={[['cost'], ['tax_percentage']]}>
                                {({cost, tax_percentage}) => {
                                    // console.log("cost", effort, hourly_rate)
                                    form.setFieldValue('cost_incl_vat', (cost || 0) + (cost || 0) * (tax_percentage || 0))

                                    return (
                                        <ProFormMoney
                                            name="cost_incl_vat"
                                            label={'Cost Incl. VAT'}
                                            customSymbol={'€'}
                                            readonly
                                            fieldProps={{
                                                numberFormatOptions: {currency: 'EUR'}
                                            }}
                                            width={"xs"}
                                        />
                                    );
                                }}
                            </ProFormDependency>

                            <ProFormRadio.Group
                                valueEnum={{
                                    YES: {text: 'Yes', status: 'success'},
                                    NO: {text: 'No', status: 'error'},
                                    PARTIALLY: {text: 'Partially', status: 'warning'},
                                }}
                                radioType={"button"}
                                width="xl"
                                name="paid"
                                label="Paid"
                                readonly
                                tooltip={`Amount paid: ${currencyFormatter.format(invoiceData?.paid_amount!)}`}
                                // rules={[{required: true}]}
                            />

                        </ProFormGroup>
                    </ProFormGroup>

                    <ProFormGroup>
                        <ProFormGroup label={'Customer'}>

                            {/*<ProFormSelect*/}
                            {/*    request={() =>*/}
                            {/*        axiosApiInstance.get<API.Customer[]>('/api/customers/')*/}
                            {/*            .then(res => {*/}
                            {/*                return res.data.map(i => ({*/}
                            {/*                    label: `${i.name} [${i.id}]`,*/}
                            {/*                    value: i.id*/}
                            {/*                }))*/}
                            {/*            })*/}
                            {/*    }*/}
                            {/*    width={"sm"}*/}
                            {/*    name="customer_id"*/}
                            {/*    label="Customer"*/}
                            {/*    rules={[{required: true}]}*/}
                            {/*    fieldProps={{*/}
                            {/*        showSearch: true, filterOption: (input, option) =>*/}
                            {/*            (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <ProFormSelect
                                request={() =>
                                    axiosApiInstance.get<API.BuyerType[]>('/api/buyers/types')
                                        .then(res => {
                                            return res.data.map(i => ({label: i.name, value: i.id}))
                                        })
                                }
                                name="buyer_type_id"
                                label="Customer Type"
                                rules={[{required: true}]}
                                width={"sm"}
                                initialValue={1}
                            />

                            <ProFormDependency name={[['buyer_type_id']]}>
                                {({buyer_type_id}) => {
                                    if (buyer_type_id === 1) {
                                        form.setFieldValue('external_buyer_id', null)
                                        form.setFieldValue('external_buyer', null)
                                        form.setFieldValue('accounting_customer_id', null)
                                        form.setFieldValue('accounting_customer', null)
                                        form.setFieldValue('customer', null)
                                        return <ProFormSelect
                                            request={() =>
                                                axiosApiInstance.get<API.Customer[]>('/api/customers/')
                                                    .then(res => {
                                                        return res.data.map(i => ({
                                                            label: `${i.name} [${i.id}]`,
                                                            value: i.id
                                                        }))
                                                    })
                                            }
                                            width="sm"
                                            name="customer_id"
                                            label="Existing Customer (LTD)"
                                            rules={[{required: true}]}
                                            fieldProps={{
                                                showSearch: true, filterOption: (input, option) =>
                                                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
                                            }}
                                        />
                                    }
                                    if (buyer_type_id === 2) {
                                        form.setFieldValue('external_buyer_id', null)
                                        form.setFieldValue('external_buyer', null)
                                        form.setFieldValue('customer_id', null)
                                        form.setFieldValue('customer', null)
                                        form.setFieldValue('accounting_customer', null)
                                        return <ProFormSelect
                                            request={() =>
                                                axiosApiInstance.get<API.AccountingCustomer[]>('/api/accounting_customers/')
                                                    .then(res => {
                                                        return res.data.map(i => ({
                                                            label: `${i.name} [${i.id}]`,
                                                            value: i.id
                                                        }))
                                                    })
                                            }
                                            width="sm"
                                            name="accounting_customer_id"
                                            label="Existing Customer (IKE)"
                                            rules={[{required: true}]}
                                            fieldProps={{
                                                showSearch: true, filterOption: (input, option) =>
                                                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
                                            }}
                                        />
                                    }
                                    form.setFieldValue('customer_id', null)
                                    form.setFieldValue('customer', null)
                                    form.setFieldValue('accounting_customer_id', null)
                                    form.setFieldValue('accounting_customer', null)
                                    form.setFieldValue('external_buyer', null)
                                    form.setFieldValue('employee_id', null)
                                    form.setFieldValue('employee', null)
                                    return (
                                        <ProFormSelect
                                            request={() =>
                                                axiosApiInstance.get<API.ExternalBuyer[]>('/api/buyers/')
                                                    .then(res => {
                                                        // console.log(res.data)
                                                        return res.data.map(i => ({
                                                            label: i.name,
                                                            value: i.id
                                                        }))
                                                    })
                                            }
                                            width="sm"
                                            name="external_buyer_id"
                                            label="External Customer"
                                            rules={[{required: true}]}
                                        />
                                    );
                                }}
                            </ProFormDependency>


                        </ProFormGroup>
                        <ProFormGroup label={'Related Cost Centers'}>
                            <ProFormTreeSelect
                                label={"Cost Centers"}
                                name={'checked_costs'}
                                width={"lg"}
                                // request={async () => {
                                //     const res = await axiosApiInstance.get(`/api/projects/${invoiceData?.project_id}/tree`)
                                //     // console.log(res.data)
                                //     return Promise.resolve(res.data)
                                // }
                                // .then(res => {
                                //     return res.data.map(i => ({
                                //         label: `${i.name} [${i.id}]`,
                                //         value: i.id
                                //     }))
                                // })
                                // }
                                // fieldProps={{treeDataSimpleMode: true, treeDefaultExpandAll: true, multiple: true}}
                                fieldProps={{
                                    treeDefaultExpandAll: true,
                                    multiple: true,
                                    treeCheckable: true,
                                    showArrow: true,
                                    fieldNames: {label: 'title'},
                                    treeData: costCentersData,
                                    // treeNodeLabelProp: 'title'
                                    // maxTagCount:20,
                                    // onSelect: (value: any) => {
                                    //     console.log(value)
                                    // },
                                    // onChange: (value, labelList, extra) => {
                                    //     console.log(value)
                                    // }
                                }}
                            />
                        </ProFormGroup>
                    </ProFormGroup>

                    {/*<ProFormText*/}
                    {/*    name="description"*/}
                    {/*    label={'Description'}*/}
                    {/*/>*/}

                    <ProFormGroup label={'Other'}>
                        <ProFormText
                            width="xl"
                            name="file_url"
                            // label="File URL"
                            label={form.getFieldValue('file_url') != undefined && form.getFieldValue('file_url') != '' ?
                                <>File URL
                                    <a href={form.getFieldValue('file_url')} target={"_blank"}>
                                        <Button type={"link"} icon={<PaperClipOutlined/>}/>
                                    </a> </>
                                : "File URL"}
                            // rules={[{required: true}]}
                        />
                    </ProFormGroup>
                    {/*</ProFormGroup>*/}
                    {/*</ProCard>*/}


                </>
            }

        </ModalForm>
    );
};

export default InvoiceOutgoingGlobal;

import { Button, DatePicker, Popover } from 'antd';
import dayjs from 'dayjs';
import { useSalesContext } from '../../context/SalesContextProvider';
import { CalendarOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

type Props = {};

const FiscalYearDatePicker = () => {
    const { fiscalYear, setFiscalYear } = useSalesContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const defaultYear = searchParams.get('fyear');

    console.log('default year', defaultYear);

    return (
        <DatePicker
            onChange={(value) => {
                searchParams.set('fyear', value?.year().toString()!);
                setSearchParams((p) => p);
                setFiscalYear(value);
            }}
            allowClear={false}
            defaultValue={fiscalYear}
            picker='year'
        />
    );
};

const FiscalYearSelector = (props: Props) => {
    return (
        <Popover trigger='hover' placement='bottom' content={<FiscalYearDatePicker />}>
            <Button type='text' icon={<CalendarOutlined />} />
        </Popover>
    );
};

export default FiscalYearSelector;

import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {PageContainer} from "@ant-design/pro-layout";
import {ProDescriptions, ProList} from "@ant-design/pro-components";
import {Button, Col, Divider, Row, Space, Statistic, Switch, Tag, Typography} from "antd";
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import axiosApiInstance from "../../api/axiosClient";
import {DollarTwoTone, EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {GlobalStateContext} from "../../context/GlobalContext";
import DeleteExecutionDeliverable from "../../components/wbs/executions/DeleteExecutionDeliverable";
import currencyFormatter from "../../util/currency_formatter";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import DeletePlannedInvoice from "../../components/wbs/executions/DeletePlannedInvoice";

dayjs.extend(utc)

type Props = {};
const ExecutionWBSVersion = (props: Props) => {
    const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const [executionDeliverablesData, setExecutionDeliverablesData] = useState<API.ExecutionDeliverable[] | undefined>(undefined);
    const [invoicePlanItemsData, setInvoicePlanItemsData] = useState<API.InvoicingPlanItem[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const routerParams = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        executionDeliverablesListRef,
        executionWBSVersionRef,
        invoicingPlanListRef,
        notificationsListRef
    } = useContext(GlobalStateContext);
    const [outgoingInvoicesData, setOutgoingInvoicesData] = useState<API.LoggedInvoiceOutgoing[] | undefined>(undefined);
    const [invoicedTotal, setInvoicedTotal] = useState<number>(0);
    const [invoicePlanTotalIssued, setInvoicePlanTotalIssued] = useState<number>(0);
    const [invoicePlanTotalPending, setInvoicePlanTotalPending] = useState<number>(0);

    const fetchWbsVersionData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.ExecutionWBSVersion>('/api/executions/' + routerParams.executionWbsVersionId)
            // console.log(response.data)
            setWbsVersionData(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setWbsVersionData(undefined)
            setLoading(false)
        }
    };

    const fetchInvoicesOutgoingData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.LoggedInvoiceOutgoing[]>(`/api/projects/${routerParams.projectId}/invoices/outgoing`)
            // console.log(response.data)
            let data = response.data;
            setOutgoingInvoicesData(data);
            setLoading(false)

            let invoicedTotal = 0
            data?.map((d) => {
                invoicedTotal += d.cost
            })
            setInvoicedTotal(invoicedTotal)
        } catch (e) {
            console.log('catch error')
            setOutgoingInvoicesData(undefined)
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchWbsVersionData();
    }, []);

    useEffect(() => {
        fetchInvoicesOutgoingData();
    }, [wbsVersionData]);

    useEffect(() => {
        let totalIssued = 0
        let totalPending = 0
        invoicePlanItemsData?.map((d) => {
            if (d.issued)
                totalIssued += d.amount
            else
                totalPending += d.amount
        })
        setInvoicePlanTotalIssued(totalIssued)
        setInvoicePlanTotalPending(totalPending)
    }, [invoicePlanItemsData]);

    const togglePlannedInvoiceState = async (id: number, checked: boolean) => {
        try {
            // setLoading(true)
            const response = await axiosApiInstance.put(`/api/projects/${routerParams.projectId}/invoices/${id}`, {
                id: id,
                issued: checked,
                project_id: +routerParams.projectId!
            })
            // setLoading(false)
            invoicingPlanListRef?.current?.reload()
            notificationsListRef?.current?.reload()

        } catch (e) {
            console.log('catch error')
            // setLoading(false)
        }
    }

    return (
        <PageContainer
            title={wbsVersionData ? wbsVersionData.pretty_id : ""}
            // title={wbsVersionData?.pretty_id}
            // loading={loading} // too much
            content={
                <Row>
                    <ProDescriptions
                        actionRef={executionWBSVersionRef}
                        loading={loading}
                        editable={{
                            onSave: (key, record, originRow, newLineConfig) => {
                                // console.log(key,record,originRow,newLineConfig)
                                console.log({[key as string]: record[key as keyof API.ExecutionWBSVersion]})
                                return axiosApiInstance.put('/api/executions/' + routerParams.executionWbsVersionId, {[key as string]: record[key as keyof API.ExecutionWBSVersion]})
                                    .then(fetchWbsVersionData)
                            }
                        }}
                        dataSource={wbsVersionData}
                        // column={4}
                        column={{lg: 4, md: 3, sm: 2, xs: 1}}
                        style={{maxWidth: 1200}}
                        // contentStyle={{maxWidth:1200}}
                        // style={{marginBlockEnd: -16}}
                        // formProps={{
                        // onValuesChange: (e, f) => console.log(f),
                        // onFinish:values => console.log(values),
                        // onSubmitCapture:event => console.log(event)
                        // }}
                        columns={[
                            {
                                title: 'Version no',
                                dataIndex: 'wbs_version_no',
                                editable: false,
                            },
                            {
                                title: 'Duration (days)',
                                dataIndex: 'duration_days',
                                editable: false,
                            },
                            {
                                title: 'Total Cost',
                                dataIndex: 'total_cost',
                                // valueType: 'digit',
                                editable: false,
                                render: (text, record) => currencyFormatter.format(text as number),
                            },
                            {
                                title: 'Total Price',
                                dataIndex: 'total_price',
                                // valueType: 'digit',
                                editable: false,
                                render: (text, record) => currencyFormatter.format(text as number),
                            },
                            {
                                title: 'Earliest Start',
                                dataIndex: 'earliest_start_date',
                                valueType: 'date',
                                editable: false,
                                render: (text, record) => new Date(record.earliest_start_date).toLocaleString('el'),
                            },
                            {
                                title: 'Latest End',
                                dataIndex: 'latest_end_date',
                                valueType: 'date',
                                editable: false,
                                render: (text, record) => new Date(record.latest_end_date).toLocaleString('el'),
                            },
                            {
                                title: 'Created At',
                                dataIndex: 'created_at',
                                valueType: 'date',
                                editable: false,
                                render: (text, record) => new Date(record.created_at).toLocaleString('el'),
                            },
                        ]}
                    />
                </Row>
            }
        >
            <Row gutter={[100, 20]}>
                {/*<Col xxl={{span: 13}} xl={{span: 24}} lg={{span: 24}} md={{span: 24}}>*/}
                {/*<Col xxl={{span: 13}} sm={{span: 24}} xs={{span: 24}}>*/}
                {/*<Col xxl={{span: 13}} xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>*/}
                <Col xxl={{span: 13}} xl={{span: 24}} xs={{span: 24}}>

                    <ProList<API.ExecutionDeliverable & { actions: any }>
                        className={'ExecutionDeliverables'}
                        toolBarRender={() => [
                            <Link to="newdeliverable" state={{background: location}}>
                                <Button
                                    icon={<PlusOutlined/>}
                                    key="new"
                                    type="primary"
                                >
                                    New
                                </Button>
                            </Link>,
                            <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                                executionDeliverablesListRef?.current?.reload()
                            }}/>
                        ]}
                        ghost
                        itemCardProps={{
                            // bodyStyle:{padding:"16px 32px 16px 32px"},
                            bodyStyle: {paddingLeft: 32, width: 330},
                            // headStyle: {paddingLeft: 0}
                        }}
                        pagination={{
                            defaultPageSize: 8,
                            showSizeChanger: false,
                        }}
                        showActions="always"
                        // rowSelection={{}}
                        rowSelection={false}
                        grid={{gutter: 16}}
                        onItem={(record: any) => {
                            return {
                                onClick: () => {
                                    navigate(`deliverables/${record.id}`)
                                },
                            };
                        }}
                        metas={{
                            title: {
                                // dataIndex: 'name',
                                render: (dom, entity) => {
                                    return `Deliverable ${entity.name || ''}`
                                    // return <Row justify={"start"}>Deliverable {entity.name}</Row>
                                },
                            },
                            subTitle: {
                                // dataIndex: 'description'
                                // dataIndex: 'description'
                                render: (dom, entity) => {
                                    const estimated_duration_days = dayjs.utc(entity.end_date).diff(dayjs.utc(entity.start_date), 'days') + 1
                                    return `${estimated_duration_days} days`
                                }
                            },
                            // type: {},
                            // avatar: {},
                            content: {
                                render: (dom, entity) => {
                                    let otc = 0
                                    let omc = 0
                                    let tptc = 0
                                    let tpmc = 0
                                    entity.own_time_costs?.map(value => {
                                        otc += value.cost
                                    })
                                    entity.own_material_costs?.map(value => {
                                        omc += value.cost
                                    })
                                    entity.third_party_time_costs?.map(value => {
                                        tptc += value.cost
                                    })
                                    entity.third_party_material_costs?.map(value => {
                                        tpmc += value.cost
                                    })
                                    return <>
                                        {/*<Row>*/}
                                        <Space direction={"vertical"} size={"small"}>

                                            {/*<span>Own time cost: €{otc} , {entity.own_time_costs?.length || '0'} item(s)</span>*/}
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <Typography.Text
                                                    style={{marginRight: 10}}>Own time cost: €{otc}</Typography.Text>
                                                <Typography.Text
                                                    type={"secondary"}>{entity.own_time_costs?.length || '0'} item(s)</Typography.Text>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <Typography.Text
                                                    style={{marginRight: 10}}>Own materials cost:
                                                    €{omc} </Typography.Text>
                                                <Typography.Text
                                                    type={"secondary"}>{entity.own_material_costs?.length || '0'} item(s)</Typography.Text>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <Typography.Text
                                                    style={{marginRight: 10}}>3P time cost: €{tptc} </Typography.Text>
                                                <Typography.Text
                                                    type={"secondary"}>{entity.third_party_time_costs?.length || '0'} item(s)</Typography.Text>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <Typography.Text
                                                    style={{marginRight: 10}}>3P materials cost:
                                                    €{tpmc} </Typography.Text>
                                                <Typography.Text
                                                    type={"secondary"}>{entity.third_party_material_costs?.length || '0'} item(s)</Typography.Text>
                                            </div>

                                            <Divider style={{marginTop: 8, marginBottom: 8}}/>
                                            <Row gutter={8} justify={"space-evenly"}>
                                                <Col span={12}>
                                                    <Statistic title="Total Cost" value={entity.total_cost}
                                                               precision={2}
                                                               prefix={'€'}
                                                               valueStyle={{fontSize: 18}}/>
                                                </Col>
                                                {/*<Divider type={'vertical'}/>*/}
                                                <Col span={12}>
                                                    <Statistic title="Total Price" value={entity.total_price}
                                                               precision={2}
                                                               prefix={'€'}
                                                               valueStyle={{fontSize: 18}}/>
                                                </Col>
                                            </Row>
                                        </Space>
                                        {/*</Row>*/}
                                    </>
                                }
                            },
                            type: {},
                            avatar: {},
                            actions: {cardActionProps: "actions"}
                        }}
                        onDataSourceChange={setExecutionDeliverablesData}
                        actionRef={executionDeliverablesListRef}
                        headerTitle="Deliverables"
                        request={params => {
                            return axiosApiInstance.get<API.ExecutionDeliverable[]>(`/api/executions/${routerParams.executionWbsVersionId}/deliverables`)
                                .then(value => {
                                    return {
                                        data: value.data.map((i) => ({
                                            ...i,
                                            actions: [
                                                // <DeleteOutlined key="edit2"/>,
                                                // <EditOutlined key="edit"/>,
                                                <DeleteExecutionDeliverable id={i.id}/>,
                                                <Link to={`deliverables/${i.id}/edit`}> <EditOutlined key="edit"/>
                                                </Link>,
                                                // <EllipsisOutlined key="ellipsis"/>,
                                            ],
                                        })),
                                        success: true,
                                        total: value.data.length
                                    }
                                })
                                .catch(reason => {
                                    // console.log(reason)
                                    return {success: false}
                                })
                        }}
                        onRequestError={e => console.log(e)}
                        // onDataSourceChange={dataSource => console.log(dataSource)}
                        // onLoad={dataSource => console.log(dataSource)}
                    />
                </Col>

                {/*<Col xxl={{span: 11}} xl={{span: 24}} lg={{span: 24}} md={{span: 24}}>*/}
                {/*<Col xxl={{span: 11}}  >*/}
                {/*<Col xxl={{span: 11}} xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>*/}
                <Col xxl={{span: 11}} xl={{span: 24}} xs={{span: 24}}>

                    <Row style={{paddingBlockEnd: 20, display: "flex"}}>
                        <Typography.Title style={{alignSelf: 'flex-start', flexGrow: 1}} level={5}>
                            Invoicing Plan
                        </Typography.Title>
                        <Space style={{alignSelf: 'flex-end'}}>
                            <Link to="newinvoice" state={{
                                background: location,
                                amountNotInvoiced: (wbsVersionData?.total_price || 0) - invoicedTotal,
                                amountNotPlanned: (wbsVersionData?.total_price || 0) - (invoicePlanTotalIssued + invoicePlanTotalPending)
                            }}>
                                <Button
                                    icon={<PlusOutlined/>}
                                    key="new"
                                    type="primary"
                                >
                                    New
                                </Button>
                            </Link>
                            <Button icon={<ReloadOutlined/>} type={"text"}
                                    onClick={() => {
                                        invoicingPlanListRef?.current?.reset!()
                                        invoicingPlanListRef?.current?.reload()
                                    }}/>
                        </Space>
                    </Row>

                    <Row>
                        <Typography.Text type={"secondary"}>
                            Project
                            Price: {currencyFormatter.format(wbsVersionData?.total_price || 0)} (Invoiced: {currencyFormatter.format(invoicedTotal)},
                            Not
                            Invoiced: {currencyFormatter.format((wbsVersionData?.total_price || 0) - invoicedTotal)})
                        </Typography.Text>
                    </Row>
                    <Row>
                        <Typography.Text type={"secondary"}>
                            Invoice
                            Plan: {currencyFormatter.format(invoicePlanTotalIssued + invoicePlanTotalPending)} (Issued: {currencyFormatter.format(invoicePlanTotalIssued)},
                            Not Issued: {currencyFormatter.format(invoicePlanTotalPending)}, Not
                            Planned: {currencyFormatter.format((wbsVersionData?.total_price || 0) - (invoicePlanTotalIssued + invoicePlanTotalPending))})
                        </Typography.Text>
                    </Row>

                    <ProList<API.InvoicingPlanItem>
                        cardBordered
                        actionRef={invoicingPlanListRef}
                        onDataSourceChange={setInvoicePlanItemsData}
                        rowKey="id"
                        request={async (params = {}) =>
                            // axiosApiInstance.post<API.Project[]>('/api/projects/', params)
                        {
                            const res = await axiosApiInstance.get<API.InvoicingPlanItem[]>(`/api/projects/${routerParams.projectId}/invoices`)
                            // console.log(res.data)

                            return Promise.resolve({
                                data: res.data,
                                success: true,
                            })
                        }
                        }
                        pagination={{
                            pageSize: 5,
                            hideOnSinglePage: true
                        }}
                        // tableStyle={{paddingBlockStart: 100}}
                        cardProps={{style: {paddingBlockStart: 24}}}
                        // size={"default"}
                        split={false}
                        metas={{
                            title: {
                                dataIndex: 'id',
                                title: 'Title',
                                render: (dom, entity) => <div>Planned Invoice #{dom}</div>
                            },
                            avatar: {
                                // dataIndex: 'avatar',
                                search: false,
                                render: () => <DollarTwoTone style={{fontSize: 22}}/>
                            },
                            subTitle: {
                                render: (dom, entity) => {
                                    return (
                                        <Space size={0}>
                                            <Tag color="blue">
                                                Date: {new Date(entity.date).toLocaleDateString('el')}
                                            </Tag>
                                            <Tag color="#5BD8A6">
                                                Amount: {currencyFormatter.format(entity.amount)}
                                            </Tag>
                                        </Space>
                                    );
                                },
                            },
                            actions: {
                                render: (dom, entity) => {
                                    return <Space>
                                        <Switch checked={entity.issued} checkedChildren={'Issued'}
                                                unCheckedChildren={'Pending'}
                                                onClick={checked => togglePlannedInvoiceState(entity.id, checked)}/>
                                        <Button disabled={entity.issued} shape={"round"}
                                                onClick={() => navigate(`/projects/${routerParams.projectId}/invoices/outgoing/new`,
                                                    {
                                                        state: {
                                                            data: {date: entity.date, cost: entity.amount},
                                                            plannedInvoiceId: entity.id
                                                        }
                                                    })}
                                        >
                                            Issue
                                        </Button>
                                        <DeletePlannedInvoice id={entity.id}/>
                                    </Space>
                                },
                            },
                        }}
                    />

                </Col>
            </Row>
            <Outlet/>

        </PageContainer>
    );
};

export default ExecutionWBSVersion
import axiosApiInstance from '../../api/axiosClient';

type BuyerTypes = API.BuyerType[];

const getBuyerTypes = async () => {
    return axiosApiInstance
        .get<BuyerTypes>('/api/buyers/types')
        .then(({data}) => data)
        .catch((e) => {
            throw e;
        });
};

export default getBuyerTypes;

import * as React from 'react';
import {useEffect, useState} from 'react';
import {useAtomValue} from "jotai/index";
import {fetchParamsAtom, revenueCostNetAtom} from "../../store/reporting";
import {Line, LineConfig} from "@ant-design/plots";
import currencyFormatter from "../../util/currency_formatter";
import {Row} from "antd";
import {ProFormDateMonthRangePicker, ProFormDateRangePicker} from "@ant-design/pro-components";
import dayjs from "dayjs";
import {useAtom} from "jotai";

type Props = {};

export const CumulativeNetChart = (props: Props) => {
    const revenueCostNet = useAtomValue(revenueCostNetAtom)
    const [fetchParams, setFetchParams] = useAtom(fetchParamsAtom)
    const [chartData, setChartData] = useState<any[]>([])

    useEffect(() => {

        let data: any[] = []
        revenueCostNet?.data?.forEach((item) => {
            data.push({
                month: item.month,
                value: item.revenue,
                metric: 'Revenue',
            })
            data.push({
                month: item.month,
                value: item.cost,
                metric: 'Cost',
            })
            data.push({
                month: item.month,
                value: item.cumulative_net,
                metric: 'Cumulative Net',
            })
        })
        setChartData(data)

    }, [revenueCostNet.data]);

    const config: LineConfig = {
        data: chartData,
        xField: 'month',
        yField: 'value',
        seriesField: 'metric',
        style: {
            minHeight: 500,
        },
        // appendPadding: [20, 0, 0, 0],
        xAxis: {
            nice: true,
            label: {
                rotate: Math.PI / 6,
                offset: 24,
                style: {
                    fill: '#aaa',
                    fontSize: 12,
                },
            },
            title: {
                text: 'Month',
                style: {
                    fontSize: 16,
                },
            },
            line: {
                style: {
                    stroke: '#aaa',
                },
            },
            tickLine: {
                style: {
                    lineWidth: 2,
                    stroke: '#aaa',
                },
                length: 5,
            },
            grid: {
                line: {
                    style: {
                        stroke: '#ddd',
                        lineDash: [4, 2],
                    },
                },
                alternateColor: 'rgba(0,0,0,0.05)',
            },
        },
        yAxis: {
            label: {
                autoRotate: false,
                style: {
                    fill: '#aaa',
                    fontSize: 12,
                },
            },
            title: {
                text: 'Amount',
                style: {
                    fontSize: 16,
                },
            },
            line: {
                style: {
                    stroke: '#aaa',
                },
            },
            tickLine: {
                style: {
                    lineWidth: 2,
                    stroke: '#aaa',
                },
                length: 5,
            },
            grid: {
                line: {
                    style: {
                        stroke: '#ddd',
                        lineDash: [4, 2],
                    },
                },
                alternateColor: 'rgba(0,0,0,0.05)',
            },
        },
        label: {
            layout: [
                {
                    type: 'hide-overlap',
                },
            ],
            style: {
                textAlign: 'right',
            },
            formatter: (item) => {
                // if (mainMetric === 'totalNumberOfOpportunities') {
                //     return item[mainMetric];
                // }
                return currencyFormatter.format(item['value']);
            }
        },
        point: {
            size: 5,
            style: {
                lineWidth: 1,
                fillOpacity: 1,
            },
            shape: (item) => {
                return 'diamond';
            },
        },
        legend: {
            position: 'top-right',
            itemName: {
                style: {
                    fill: '#000',
                },
            },
            // show all rows of legend
            flipPage: false,
            // padding: [40, 0, 20, 0],
        },
        meta: {
            //     year: {
            //         range: [0, 1],
            //     },
            value: {
                formatter: (v) => {
                    // console.log('v', v)
                    return currencyFormatter.format(+v);
                }
            },
            // timeFrame: {
            //     formatter: (name) => {
            //         return timeFrameToLabel[name];
            //     }
            // }
        },
        // loading: isLoading,
        // padding: [20, 0, 0, 0]
        // appendPadding: [20, 0, 0, 0]
    };

    return (
        <div>

            <Row>
                <ProFormDateMonthRangePicker
                    label="Date Range"
                    fieldProps={{
                        format: 'YYYY-MM-DD',
                        value: [dayjs(fetchParams.start_date), dayjs(fetchParams.end_date)],
                        onChange: (value: any) => {
                            setFetchParams({
                                start_date: dayjs(value[0]).startOf('month').format('YYYY-MM-DD'),
                                end_date: dayjs(value[1]).endOf('month').format('YYYY-MM-DD'),
                            })
                        }
                    }}
                    help={"Set a range for the issue date of the invoices"}
                />
            </Row>

            <Line {...config} />

        </div>
    );
};

export default CumulativeNetChart;
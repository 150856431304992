import axiosApiInstance from '../../api/axiosClient';
import axios from 'axios';
import { notification } from 'antd';
import dayjs from 'dayjs';

/**
 * Function to interface with backend
 * 
 * @param values 
 * @returns 
 */
const createNewOpportunity = async (values: any) => {
    console.log('values passed to createNewOpportunity(): ', values);
    try {
        const obj = {
            ...values,
            // expected_po_date: dayjs(values.expected_po_date, 'DD-MM-YYYY').toISOString(),
        };
        console.log('object being passed to axios api: ', obj);
        const { status } = await axiosApiInstance.post('/api/sales/opportunities/', obj);
        return true;
    } catch (e: any) {
        if (!axios.isAxiosError(e)) {
            notification.error({ message: e.message || 'Unexpected error' });
        }
        return false;
    }
};

export default createNewOpportunity;

import {ModalForm} from '@ant-design/pro-components';
import * as React from 'react';
import {useContext} from 'react';
import axiosApiInstance from "../../../api/axiosClient";
import {DeleteOutlined} from "@ant-design/icons";
import {GlobalStateContext} from "../../../context/GlobalContext";

type Props = {
    id?: number
};

export const DeleteExecutionDeliverable = (props: Props) => {
    const {executionDeliverablesListRef} = useContext(GlobalStateContext);

    return (
        <ModalForm
            width={400}
            title={"Delete Deliverable"}
            trigger={
                // <Typography.Link
                //     type={props.batch ? "danger" : undefined}>{props.label}</Typography.Link>
                <DeleteOutlined/>
                // <Button  type={"text"} danger icon={<DeleteOutlined  />} />
            }
            submitter={{submitButtonProps: {danger: true}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Delete',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return axiosApiInstance.delete('/api/deliverables/execution/' + props.id)
                    .then(res => {
                        executionDeliverablesListRef?.current?.reload()
                        return true
                    }).catch(reason => false)
            }}
        >
            Are you sure?
        </ModalForm>
    );
};

export default DeleteExecutionDeliverable
import axiosApiInstance from '../../api/axiosClient';

type Phases = API.OpportunityPhase[];

const getOpportunityPhases = async () => {
    return axiosApiInstance
        .get<Phases>('/api/sales/phases')
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};

export default getOpportunityPhases;

import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSalesContext} from "../../context/SalesContextProvider";
import {ProColumns} from "@ant-design/pro-components";
import dayjs, {Dayjs} from "dayjs";
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";
import {notification, Row, Select, Space, Switch, Typography} from "antd";
import {useLocation} from "react-router-dom";
import getOpportunityData from "../../util/sales/getOpportunityData";
import opportunityFilter from "../../util/sales/opportunityFilter";
import belongsToFiscalYear from "../../util/sales/belongsToFiscalYear";
import opportunitySearch from "../../util/sales/opportunitySearch";
import {Line, LineConfig} from "@ant-design/plots";
import currencyFormatter from "../../util/currency_formatter";

type Props = {};

type OpportunitiesSummaryDatum = {
    timeFrame: string;
    timeFrameDate: Dayjs;
    phase: string;
    phaseId: number;
    totalNumberOfOpportunities: number;
    totalUnweightedValue: number;
    totalWeightedValue: number;
}

const metricToLabel: { [metric: string]: string } = {
    totalNumberOfOpportunities: 'Total Number Of Opportunities',
    totalUnweightedValue: 'Total Unweighted Value',
    totalWeightedValue: 'Total Weighted Value',

}

const timeFrameToLabel: { [timeFrame: string]: string } = {
    now: 'Now',
    yesterday: 'Yesterday',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    lastQuarter: 'Last Quarter',
    lastHalfYear: 'Last Half Year',
    lastYear: 'Last Year',
}

const SalesProgressChart = (props: Props) => {
// this component will be used to display the summary table on the sales page
    // on the top, it will display a year picker
    // below that, it will display a table with the following columns:
    // 1. month
    // 2. total unweighted value
    // 3. total weighted value
    // 4. total number of opportunitiesFilteredOnly

    const {
        opportunitiesFilteredOnly,
        searchFilter,
        filters,
        fiscalYear,
        opportunities,
        opportunitiesUnfiltered
    } = useSalesContext()
    const {opportunityPhases} = useSalesAuxDataContext()

    const [year, setYear] = useState<Dayjs | null>(dayjs());
    const [columns, setColumns] = useState<ProColumns<any>[]>([]);
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [timeFrameToOpportunities, setTimeFrameToOpportunities] = useState<{
        [timeFrame: string]: API.Opportunity[]
    }>({});
    const [timeFrameToFilteredOpportunities, setTimeFrameToFilteredOpportunities] = useState<{
        [timeFrame: string]: API.Opportunity[]
    }>({});

    const [applySelectedFilters, setApplySelectedFilters] = useState(false);
    const [data, setData] = useState<OpportunitiesSummaryDatum[]>([]);
    const [mainMetric, setMainMetric] = useState('totalNumberOfOpportunities');

    const baseOpportunities = (opps: API.Opportunity[]) => {
        return opps
            .filter((opp) => !opp.is_compound)
    }

    const filteredOpportunities = (opps: API.Opportunity[]) => {
        return opps
            .filter((opp) => opportunityFilter(opp, filters))
            .filter((opp) => belongsToFiscalYear(opp, fiscalYear || dayjs()))
            .filter((opp) => opportunitySearch(opp, searchFilter))
    }

    useEffect(() => {
        setIsLoading(true);

        Promise.all([
            // getOpportunityData(),
            // getOpportunityData for yesterday
            getOpportunityData(getDateByTimeframe('yesterday')),
            // getOpportunityData for last week
            getOpportunityData(getDateByTimeframe('lastWeek')),
            // getOpportunityData for last month
            getOpportunityData(getDateByTimeframe('lastMonth')),
            // getOpportunityData for last quarter
            getOpportunityData(getDateByTimeframe('lastQuarter')),
            // getOpportunityData for last half year
            getOpportunityData(getDateByTimeframe('lastHalfYear')),
            // getOpportunityData for last year
            getOpportunityData(getDateByTimeframe('lastYear')),


        ])
            .then((values) => {
                const [yesterday, lastWeek, lastMonth, lastQuarter, lastHalfYear, lastYear] = values;

                setTimeFrameToOpportunities({
                    now: baseOpportunities(opportunitiesUnfiltered),
                    yesterday: baseOpportunities(yesterday),
                    lastWeek: baseOpportunities(lastWeek),
                    lastMonth: baseOpportunities(lastMonth),
                    lastQuarter: baseOpportunities(lastQuarter),
                    lastHalfYear: baseOpportunities(lastHalfYear),
                    lastYear: baseOpportunities(lastYear),
                })

                setTimeFrameToFilteredOpportunities({
                    now: opportunities,
                    yesterday: filteredOpportunities(baseOpportunities(yesterday)),
                    lastWeek: filteredOpportunities(baseOpportunities(lastWeek)),
                    lastMonth: filteredOpportunities(baseOpportunities(lastMonth)),
                    lastQuarter: filteredOpportunities(baseOpportunities(lastQuarter)),
                    lastHalfYear: filteredOpportunities(baseOpportunities(lastHalfYear)),
                    lastYear: filteredOpportunities(baseOpportunities(lastYear)),
                })


            })
            .catch((e) => {
                notification.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [
        opportunities, opportunitiesUnfiltered, filters, fiscalYear, searchFilter
    ]);

    const getDateByTimeframe = (timeFrame: string) => {
        switch (timeFrame) {
            case 'now':
                return dayjs();
            case 'yesterday':
                return dayjs().subtract(1, 'day').startOf('day');
            case 'lastWeek':
                return dayjs().subtract(1, 'week').startOf('day');
            case 'lastMonth':
                return dayjs().subtract(1, 'month').startOf('day');
            case 'lastQuarter':
                return dayjs().subtract(3, 'month').startOf('day');
            case 'lastHalfYear':
                return dayjs().subtract(6, 'month').startOf('day');
            case 'lastYear':
                return dayjs().subtract(1, 'year').startOf('day');
            default:
                return dayjs();
        }
    }


    useEffect(() => {

        let workingOpportunitiesMap: { [timeFrame: string]: API.Opportunity[] } = {};
        if (applySelectedFilters) {
            workingOpportunitiesMap = timeFrameToFilteredOpportunities;
        } else {
            workingOpportunitiesMap = timeFrameToOpportunities;
        }

        // construct the (grouped) data array from the workingOpportunitiesMap
        // each element is of type OpportunitiesSummaryDatum
        // each element will have the following properties:
        // 1. timeframe
        // 2. opportunity phase
        // 3. total cardinality of opportunities
        // 4. total unweighted value
        // 5. total weighted value


        let data: OpportunitiesSummaryDatum[] = [];
        Object.entries(workingOpportunitiesMap).forEach((entry) => {
            const [timeFrame, opportunities] = entry;
            opportunityPhases.forEach((opportunityPhase) => {
                const phaseId = opportunityPhase.id;
                const phase = opportunityPhase.name;
                const opportunitiesInPhase = opportunities.filter((opportunity) => opportunity.opportunity_phase_id === phaseId);
                const totalNumberOfOpportunities = opportunitiesInPhase.length;
                const totalUnweightedValue = opportunitiesInPhase.reduce((acc, opportunity) => acc + opportunity.proposed_value_in_euro!, 0);
                const totalWeightedValue = opportunitiesInPhase.reduce((acc, opportunity) => acc + opportunity.po_value_in_euro!, 0);
                const timeFrameDate = getDateByTimeframe(timeFrame);
                data.push({
                    timeFrame,
                    timeFrameDate,
                    phase,
                    phaseId,
                    totalNumberOfOpportunities,
                    totalUnweightedValue,
                    totalWeightedValue
                })
            })

        })

        // sort the data array by timeFrameDate and phaseId
        data.sort((a, b) => {
            if (a.timeFrame === b.timeFrame) {
                return a.phaseId - b.phaseId;
            }
            return dayjs(a.timeFrameDate).isBefore(dayjs(b.timeFrameDate)) ? -1 : 1;
        })

        console.log('data', data)

        setData(data);

    }, [timeFrameToFilteredOpportunities, timeFrameToOpportunities, applySelectedFilters])


    const config: LineConfig = {
        data: data,
        xField: 'timeFrame',
        yField: mainMetric,
        seriesField: 'phase',
        style: {
            minHeight: 500,
        },
        xAxis: {
            nice: true,
            // tickCount: 8,
            // 文本标签
            label: {
                // autoRotate: false,
                rotate: Math.PI / 6,
                offset: 24,
                style: {
                    fill: '#aaa',
                    fontSize: 12,
                },
                // formatter: (name) => name
            },
            title: {
                text: 'Time',
                style: {
                    fontSize: 16,
                },
            },
            line: {
                style: {
                    stroke: '#aaa',
                },
            },
            tickLine: {
                style: {
                    lineWidth: 2,
                    stroke: '#aaa',
                },
                length: 5,
            },
            grid: {
                line: {
                    style: {
                        stroke: '#ddd',
                        lineDash: [4, 2],
                    },
                },
                alternateColor: 'rgba(0,0,0,0.05)',
            },
        },
        yAxis: {
            label: {
                autoRotate: false,
                style: {
                    fill: '#aaa',
                    fontSize: 12,
                },
                // formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
            title: {
                text: metricToLabel[mainMetric],
                style: {
                    fontSize: 16,
                },
            },
            line: {
                style: {
                    stroke: '#aaa',
                },
            },
            tickLine: {
                style: {
                    lineWidth: 2,
                    stroke: '#aaa',
                },
                length: 5,
            },
            grid: {
                line: {
                    style: {
                        stroke: '#ddd',
                        lineDash: [4, 2],
                    },
                },
                alternateColor: 'rgba(0,0,0,0.05)',
            },
        },
        // label
        label: {
            layout: [
                {
                    type: 'hide-overlap',
                },
            ],
            // 隐藏重叠label
            style: {
                textAlign: 'right',
            },
            formatter: (item) => {
                if (mainMetric === 'totalNumberOfOpportunities') {
                    return item[mainMetric];
                }
                return currencyFormatter.format(item[mainMetric]);
            }
        },
        // point
        point: {
            size: 5,
            style: {
                lineWidth: 1,
                fillOpacity: 1,
            },
            shape: (item) => {
                // if (item.category === 'Cement production') {
                //     return 'circle';
                // }

                return 'diamond';
            },
            // tooltip: (item) => {
            //     return {
            //         name: item.phase,
            //         value: item[mainMetric],
            //     };
            // },
        },
        // annotations: [],
        legend: {
            position: 'top-right',
            itemName: {
                style: {
                    fill: '#000',
                },
                // formatter: (name) => name,
            },
            // show all rows of legend
            flipPage: false,
            padding: [0, 0, 20, 0],
        },
        meta: {
            //     year: {
            //         range: [0, 1],
            //     },
            [mainMetric]: {
                formatter: (v) => {
                    // console.log('v', v)
                    if (mainMetric === 'totalNumberOfOpportunities') {
                        return v;
                    }
                    return currencyFormatter.format(+v);
                }
            },
            timeFrame: {
                formatter: (name) => {
                    return timeFrameToLabel[name];
                }
            }
        },
        loading: isLoading,
        // padding: [20, 0, 0, 0]
        // appendPadding: [20, 0, 0, 0]
    };


    return (

        <div style={{padding: 20}}>
            <Row justify={'space-between'}>
                <Space>
                    <Typography.Text>Select Y Axis Metric: </Typography.Text>
                    <Select
                        value={mainMetric}
                        // style={{width: 200}}
                        onChange={(value) => {
                            setMainMetric(value);
                        }}
                        options={[
                            {label: metricToLabel['totalNumberOfOpportunities'], value: 'totalNumberOfOpportunities'},
                            {label: metricToLabel['totalUnweightedValue'], value: 'totalUnweightedValue'},
                            {label: metricToLabel['totalWeightedValue'], value: 'totalWeightedValue'},
                        ]}
                    />
                </Space>
                <Space>
                    <Typography.Text>Apply Selected Filters (Manager, Customer, Fiscal Year etc.): </Typography.Text>
                    <Switch
                        // title={'Apply Selected Filters'}
                        checked={applySelectedFilters} onChange={(checked) => setApplySelectedFilters(checked)}/>
                </Space>
            </Row>
            <br/>

            <Line {...config} />

        </div>
    );
};

export default SalesProgressChart;
import {ModalForm} from '@ant-design/pro-components';
import * as React from 'react';
import {useContext} from 'react';
import axiosApiInstance from "../../../api/axiosClient";
import {GlobalStateContext} from "../../../context/GlobalContext";
import {Button} from "antd";

type Props = {
    id?: number
};

export const DeleteExecutionWBSVersion = (props: Props) => {
    // const {wbsVersionsListRef} = useContext(GlobalStateContext);
    const {executionWbsVersionsListRef} = useContext(GlobalStateContext);

    return (
        <ModalForm
            width={400}
            title={"Delete Structure"}
            trigger={
                <Button type={"link"} danger
                    // icon={<DeleteOutlined/>}
                >Delete</Button>
            }
            submitter={{submitButtonProps: {danger: true}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Delete',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return axiosApiInstance.delete(`/api/executions/${props.id}`)
                    .then(res => {
                        // console.log(res)
                        executionWbsVersionsListRef?.current?.reload()
                        return true
                    }).catch(reason => false)
            }}
        >
            Are you sure?
        </ModalForm>
    );
};

export default DeleteExecutionWBSVersion
import axiosApiInstance from '../../api/axiosClient';
import { AxiosResponse } from 'axios';

type Customers = API.Customer[];

const getCustomerOptions = async () => {
    return axiosApiInstance
        .get<Customers>('/api/customers/')
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};

export default getCustomerOptions;

import { Button, Popover, Space } from 'antd';
import React from 'react';
import FilterBarNew from './FilterBarNew';
import TimestampPicker from './TimestampPicker';
import { FormOutlined } from '@ant-design/icons';
import FiscalYearSelector from './FiscalYearSelector';
import NameSearcher from './NameSearcher';
import FiscalDateRangeSelector from "./FiscalDateRangeSelector";

type Props = {
    containerRef: React.MutableRefObject<any>;
};

/**
 * Το component αυτό περιλαμβάνει όλες τις extra λειτουργίες που βρίσκονται στο πάνω δεξιά μέρος του sales page, όπως
 * το search, τα φιλτρα κλπ
 * @param param0
 * @returns
 */
const SalesPageExtras = ({ containerRef }: Props) => {
    return (
        <Space
            // Εδω προστείθεται ενα αρνητικό margin έτσι ωστε οι καρτέλες List/Funnel του sales page να βρίσκονται πάντα στο κέντρο
            style={{
                // marginLeft: -(containerRef.current?.firstChild.clientWidth || 0)
            }}
        >
            <NameSearcher />
            <TimestampPicker />
            <FiscalYearSelector />
            {/*<FiscalDateRangeSelector />*/}
            <FilterBarNew />
        </Space>
    );
};

export default SalesPageExtras;

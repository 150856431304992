import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {ActionType, ProCard, ProDescriptions, ProDescriptionsItemProps} from "@ant-design/pro-components";
import axiosApiInstance from "../../api/axiosClient";
import {UserAuth} from "../../context/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import currencyFormatter from "../../util/currency_formatter";
import {Avatar, Input, InputRef, Space, Tag, theme} from "antd";
import {ClockCircleOutlined, InfoCircleOutlined, PlusOutlined, SyncOutlined, UserOutlined} from "@ant-design/icons";

const {CheckableTag} = Tag;

type Props = {};


const ProjectGeneralInfo = (props: Props) => {
    const [inputTagValue, setInputTagValue] = useState('');
    const inputRef = useRef<InputRef>(null);
    // const [selectedTags, setSelectedTags] = useState<number[]>([]);
    // const [options, setOptions] = useState<{ label: string, value: number }[]>([]);
    // const [filteredOptions, setFilteredOptions] = useState<{ label: string, value: number }[]>([]);
    const [projectData, setProjectData] = useState<API.Project | undefined>(undefined);
    const [allTags, setAllTags] = useState<API.ProjectTag[]>([]);
    const [inputVisible, setInputVisible] = useState(false);
    const {token} = theme.useToken();

    useEffect(() => {
        // console.log()
        // get options from server
        getTagOptions();
        // getProjectTags();
    }, []);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    const getTagOptions = () => {
        // console.log('getTagOptions')
        axiosApiInstance.get<API.ProjectTag[]>('/api/projects/tags')
            .then(({data}) => {
                // console.log(data);
                // setOptions(data.map((tag) => {
                //     return {label: tag.tag, value: tag.id}
                // }))
                setAllTags(data);
            }).catch((error) => {
            console.log(error);
        })
    }

    // const getProjectTags = () => {
    //     axiosApiInstance.get<API.ProjectTag[]>(`/api/projects/${routerParams.projectId}/tags`)
    //         .then(({data}) => {
    //             console.log(data);
    //             setSelectedItems(data.map((tag) => tag.id))
    //         }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    // useEffect(() => {
    //     filterOptions()
    // }, [allTags, options,
    //     // selectedTags
    // ]);
    // useEffect(() => {
    //     console.log('useEffect allTags')
    //     console.log('allTags', allTags)
    //     const opts = allTags.map((tag) => {
    //         return {label: tag.tag, value: tag.id}
    //     });
    //     setOptions(opts)
    //     // setFilteredOptions(opts)
    // }, [allTags]);

    // useEffect(() => {
    //     console.log('useEffect options, projectData?.project_tags')
    //     filterOptions()
    // }, [options, projectData?.project_tags]);

    // useEffect(() => {
    //     if (projectData?.project_tags) {
    //         console.log('useEffect projectData?.project_tags')
    //         updateProjectTags();
    //     }
    // }, [projectData?.project_tags]);

    // const filterOptions = () => {
    //     console.log('filterOptions')
    //     // filter options
    //     // const filtered = options.filter((o) => !selectedTags.includes(o.value));
    //     console.log('options', options, 'projectTags', projectData?.project_tags.map(t => t.id));
    //     const filtered = options.filter((o) => !projectData?.project_tags.map(t => t.id).includes(o.value));
    //     // console.log('selectedItems', selectedTags);
    //     console.log('filtered', filtered);
    //     setFilteredOptions(filtered)
    // }
    const columnsProject: ProDescriptionsItemProps<API.Project>[] = [
        {
            dataIndex: 'id',
            valueType: 'indexBorder',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            copyable: true,
            ellipsis: true,
            editable: false,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            copyable: true,
            // ellipsis: true,
            // ellipsis: {rows: 3, expandable: true},
            editable: false,
            // render: (dom, entity) => {
            //     if (!entity.description)
            //         return '-'
            //     return dom
            //     // return <Typography.Paragraph ellipsis={{rows: 3}}>
            //     //     {entity.description}
            //     // </Typography.Paragraph>
            // }
        },
        {
            title: 'Created At',
            key: 'showTime',
            dataIndex: 'created_at',
            valueType: 'date',
            // hideInSearch: true
            editable: false,
            render: (text, record) => new Date(record.created_at).toLocaleDateString('el')
        },
        // {
        //     title: 'Customer Type',
        //     dataIndex: 'buyer_type_id',
        //     editable: false,
        //     copyable: true,
        //     render: (dom, entity) => {
        //         if (entity.buyer_type_id == 1) {
        //             return 'Existing Customer'
        //         } else {
        //             return 'External Customer'
        //         }
        //     },
        // },
        // {
        //     title: 'Customer ID',
        //     // dataIndex: 'customer_id',
        //     editable: false,
        //     copyable: true,
        //     // valueType: "digit",
        //     // render:text => text?.toLocaleString('el')
        //     render: (dom, entity) => entity.customer_id != null ? entity.customer_id : `EXT${String(entity.external_buyer_id).padStart(3, '0')}`
        // },
        {
            title: () => {
                if (projectData?.buyer_type_id == 1) {
                    return 'Customer (Existing LTD)'
                } else if (projectData?.buyer_type_id == 2) {
                    return 'Customer (Existing IKE)'
                } else if (projectData?.buyer_type_id == 3) {
                    return 'Customer (External)'
                }
            },
            // dataIndex: 'customer_id',
            editable: false,
            copyable: true,
            // valueType: "digit",
            // render:text => text?.toLocaleString('el')
            render: (dom, entity) => {
                if (entity.buyer_type_id == 1) {
                    return entity.customer_id != null ? `${entity.customer.name} [${entity.customer_id}]` : '-'
                }
                if (entity.buyer_type_id == 2) {
                    return entity.accounting_customer_id != null ? `${entity.accounting_customer.name} [IKE${entity.accounting_customer_id}]` : '-'
                }
                if (entity.buyer_type_id == 3) {
                    return entity.external_buyer_id != null ? `${entity.external_buyer.name} [EXT${String(entity.external_buyer_id).padStart(3, '0')}]` : '-'
                }
                return '-'
            }
        },
        {
            title: 'Manager',
            // dataIndex: 'employee_id',
            copyable: true,
            // valueType: "text",
            editable: false,
            render: (text, record) => {
                return <Space size={5}><Avatar
                    size={28}
                    icon={<UserOutlined/>}
                    // shape={'square'}
                    // src='https://cdn-icons-png.flaticon.com/512/706/706807.png'
                    style={{backgroundColor: '#fde3cf', color: '#f56a00'}}
                /> {record.employee?.name}</Space>
            }
        },
        {
            title: 'Pretty ID',
            dataIndex: 'project_pretty_id',
            copyable: true,
            editable: false,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            copyable: true,
            editable: false,
            render: (dom, entity) => <Tag
                icon={entity.status == 'On Proposal' ? <ClockCircleOutlined/> : entity.status == 'On Execution' ?
                    <SyncOutlined/> : <InfoCircleOutlined/>}
                color={entity.status == 'On Proposal' ? 'gold' : entity.status == 'On Execution' ? 'volcano' : 'blue'}>{entity.status}</Tag>
        },
        {
            title: 'Proposed Cost',
            dataIndex: 'active_proposal_cost',
            copyable: true,
            editable: false,
            render: (dom, entity) => currencyFormatter.format(entity.active_proposal_cost)
            // render: (dom, entity) => {
            //     return <Card bordered={false}>
            //         <Statistic
            //             title="Proposed Cost"
            //             value={entity.active_proposal_cost}
            //             precision={2}
            //             // style={{backgroundSize: 10}}
            //             valueStyle={{fontSize: 14}}
            //             // valueStyle={{color: '#3f8600'}}
            //             // prefix={<ArrowUpOutlined/>}
            //             prefix={'€'}
            //             // suffix="%"
            //         />
            //     </Card>
            // }
        },
        {
            title: 'Proposed Price',
            dataIndex: 'active_proposal_price',
            copyable: true,
            editable: false,
            render: (dom, entity) => currencyFormatter.format(entity.active_proposal_price)
        },
        {
            title: 'Execution Cost',
            dataIndex: 'active_execution_cost',
            copyable: true,
            editable: false,
            render: (dom, entity) => currencyFormatter.format(entity.active_execution_cost)

        },
        {
            title: 'Execution Price',
            dataIndex: 'active_execution_price',
            copyable: true,
            editable: false,
            render: (dom, entity) => currencyFormatter.format(entity.active_execution_price)
        },
        {
            title: 'Tags',
            dataIndex: 'project_tags',
            copyable: false,
            // editable: (value, record, index) => true,
            editable: false,
            valueType: 'select',
            render: (dom, entity) => {
                return <Space size={[0, 8]} wrap>
                    {allTags.map((tag) => (
                        <CheckableTag
                            key={tag.id}
                            checked={projectData?.project_tags?.map(t => t.id).includes(tag.id) || false}
                            // onChange={(checked) => handleChange(tag, checked)}
                            onChange={(checked) => {
                                // console.log({checked})
                                // console.log('onChange', {allTags})
                                // let selectedTags = allTags.filter((tag) => value.includes(tag.id))
                                // console.log({selectedTags})
                                if (checked) {
                                    setProjectData({
                                        ...projectData!,
                                        project_tags: [...(projectData?.project_tags || []), tag]
                                    })
                                } else {
                                    setProjectData({
                                        ...projectData!,
                                        project_tags: (projectData?.project_tags || []).filter((t) => t.id != tag.id)
                                    })
                                }
                                toggleProjectTag(tag, checked)
                            }}
                        >
                            {tag.tag}
                        </CheckableTag>
                    ))}

                    {inputVisible ? (
                        <Input
                            ref={inputRef}
                            type="text"
                            size="small"
                            style={{
                                width: 78,
                                verticalAlign: 'top',
                                marginInlineEnd: 8,
                                paddingInline: 7
                            }}
                            value={inputTagValue}
                            onChange={onChangeInputTagValue}
                            onBlur={() => setInputVisible(false)}
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                    setInputTagValue('')
                                    setInputVisible(false)
                                }
                            }}
                            // onBlur={addItem}
                            onPressEnter={addItem}
                        />
                    ) : (
                        <Tag style={{
                            background: token.colorBgContainer,
                            borderStyle: 'dashed',
                            marginInlineEnd: 8,
                            paddingInline: 7
                        }} onClick={() => {
                            setInputVisible(true)
                            // inputRef.current?.focus()
                        }}>
                            <PlusOutlined/> New Tag
                        </Tag>
                    )}
                </Space>
            },
            fieldProps: {
                // mode: 'multiple',
                // mode: 'tags',
                // dropdownMatchSelectWidth: 300,
                // defaultOpen: true,
                // width: 500,
                // options: [
                //     {label: 'Tag 1', value: 'tag1'},
                //     {label: 'Tag 2', value: 'tag2'},
                // ],
                // options: filteredOptions,
                // value: selectedTags,
                value: projectData?.project_tags?.map((tag) => tag.id),
                // onChange: (value, option) => {
                //     console.log('onChange', value, option, {allTags})
                //     // console.log(value, option)
                //     // setSelectedTags(value);
                //     let selectedTags = allTags.filter((tag) => value.includes(tag.id))
                //     console.log({selectedTags})
                //     setProjectData({
                //         ...projectData!,
                //         project_tags: selectedTags
                //     })
                // },

                // tagRender: (props) => {
                //     const { label, value, closable, onClose } = props;
                //     const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                //         event.preventDefault();
                //         event.stopPropagation();
                //     };
                //     const tagLabel = allTags.find((tag) => tag.id == value)?.tag
                //     return (
                //         <Tag
                //             // color={value}
                //             onMouseDown={onPreventMouseDown}
                //             closable={closable}
                //             onClose={onClose}
                //             // style={{ marginRight: 3 }}
                //         >
                //             {tagLabel}
                //         </Tag>
                //     );
                // },
                // onSelect: (value: any) => {
                //     // console.log(value)
                //     filterOptions()
                // },
                // onDeselect: (value: any) => {
                //     // console.log(value)
                //     filterOptions()
                // },
                // dropdownRender: (menu) => (
                //     <>
                //         {menu}
                //         <Divider style={{margin: '8px 0'}}/>
                //         <Space style={{padding: '0 8px 4px'}}>
                //             <Input
                //                 placeholder="Enter new tag"
                //                 ref={inputRef}
                //                 value={inputTagValue}
                //                 onChange={onChangeInputTagValue}
                //             />
                //             <Button type="text" icon={<PlusOutlined/>} onClick={addItem}>
                //                 {/*Add item*/}
                //                 Add
                //             </Button>
                //         </Space>
                //     </>
                // ),
                // multiple: true,
                // notFoundContent: <></>,
            }
        },
    ];

    // useEffect(() => {
    //     setSelectedTags(projectData?.project_tags?.map(tag => tag.id) || []);
    // }, [projectData]);

    // useEffect(() => {
    //     console.log('selectedTags', selectedTags);
    // }, [selectedTags]);

    const actionRef = useRef<ActionType>();

    //maybe use for editing permissions
    const {can} = UserAuth();

    const routerParams = useParams();
    const navigate = useNavigate();

    const onChangeInputTagValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputTagValue(event.target.value);
    };

    const addItem = async () => {
        // console.log('addItem')
        // e.preventDefault();
        // console.log('addItem', inputTagValue)

        try {
            const {data} = await axiosApiInstance.post('/api/projects/tags', {tag: inputTagValue} as API.ProjectTag);
            // console.log(data);

            // setItems([...items, inputTagValue || `New item`]);
            setInputTagValue('');

            getTagOptions();

            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        } catch (error) {
            console.log(error);
        }
        setInputVisible(false);
    };

    // const updateProjectTags = (newTags: number[]) => {
    //     console.log('updateProjectTags')
    //     // const newValue = selectedTags.map(tagId => ({id: tagId, tag: ''}));
    //     console.log('newTags', newTags, 'allTags', allTags)
    //     const projectTags = allTags.filter(tag => newTags.includes(tag.id));
    //     console.log('updateProjectTags', projectTags)
    //     // return Promise.resolve()
    //     return axiosApiInstance.put(`/api/projects/${routerParams.projectId}/tags`, projectTags)
    //         .then(value => {
    //             actionRef.current?.reload()
    //             return true
    //         })
    // }

    // const updateProjectTags = () => {
    //     console.log('updateProjectTags', projectData?.project_tags)
    //     return axiosApiInstance.put(`/api/projects/${routerParams.projectId}/tags`, projectData?.project_tags)
    //         .then(value => {
    //             actionRef.current?.reload()
    //             return true
    //         }).catch(reason => {
    //             console.log(reason)
    //             return false
    //         })
    // }
    const toggleProjectTag = (tag: API.ProjectTag, checked: boolean) => {
        // console.log('updateProjectTags', projectData?.project_tags)
        return axiosApiInstance.put(`/api/projects/${routerParams.projectId}/tags/toggle/${tag.id}/${checked}`)
            .then(value => {
                actionRef.current?.reload()
                return true
            }).catch(reason => {
                console.log(reason)
                return false
            })
    }

    return (
        <div>
            <ProCard bordered>
                <ProDescriptions<API.Project>
                    layout={"vertical"}
                    labelStyle={{color: 'rgba(0,0,0,.45)'}}
                    onRequestError={e => {
                        // dummy handler for proper behavior
                        console.log(e)
                    }}
                    columns={columnsProject}
                    actionRef={actionRef}
                    request={params => {
                        return axiosApiInstance.get<API.Project>('/api/projects/' + routerParams.projectId)
                    }}
                    onDataSourceChange={value => {
                        // console.log(value)
                        console.log('onDataSourceChange', value)
                        setProjectData(value)
                    }}
                    editable={{
                        onSave: (key, record, originRow, newLineConfig) => {
                            // console.log('allTags', allTags)
                            // console.log({key})
                            // console.log(key, record, originRow, newLineConfig, projectData)
                            // console.log({[key as string]: record[key as keyof API.Project] == undefined ? null : record[key as keyof API.Project]})
                            // return Promise.resolve()
                            // if (key == 'project_tags') {
                            //     return updateProjectTags(record.project_tags as any)
                            //     // return updateProjectTags()
                            //     // console.log(selectedTags)
                            // }
                            let newValue = record[key as keyof API.Project] == undefined ? null : record[key as keyof API.Project];
                            return axiosApiInstance.put('/api/projects/' + routerParams.projectId, {[key as string]: newValue})
                                .then(value => {
                                    actionRef.current?.reload()
                                    return true
                                })
                            // .then(fetchWbsVersionData)
                        },
                    }}
                    // layout={"vertical"}
                    // labelStyle={{fontSize: 18}}

                />
            </ProCard>
        </div>
    );
};

export default ProjectGeneralInfo;
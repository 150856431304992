import React, {useEffect, useRef, useState} from 'react';
import {ModalForm, ProFormInstance} from '@ant-design/pro-components';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useSalesContext} from '../../context/SalesContextProvider';
import {InfoCircleOutlined, WarningOutlined} from "@ant-design/icons";
import {Typography} from "antd";
import deleteOpportunity from "../../util/sales/deleteOpportunity";

type T = API.Opportunity;

type Props = {
    callback?: () => void;
};

const DeleteOpportunityModal = ({callback}: Props) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [target, setTarget] = useState<API.Opportunity | undefined>(undefined);
    const {updateOpportunities} = useSalesContext();
    const formRef = useRef<ProFormInstance>(null);
    const [fromPath, setFromPath] = React.useState<string | undefined>(undefined)
    const location = useLocation();

    useEffect(() => {
        const fromPath = location.state?.from_path
        setFromPath(fromPath)
        // console.log('fromPath', fromPath)
    }, [location])
    return (
        <ModalForm<any>
            title={"Delete Opportunity"}
            open={true}
            formRef={formRef}
            width={400}
            modalProps={{
                onCancel: () => navigate(-1), // go back
                destroyOnClose: true,
                okText: 'Delete',
                cancelText: 'No'
            }}
            submitter={{submitButtonProps: {danger: true}}}
            onFinish={async (values) => {
                await deleteOpportunity(+id!);
                if (fromPath)
                    navigate({
                        pathname: fromPath,
                        search: location.search + (location.search ? `&update=true` : '?update=true')
                    });
                else
                    // navigate(`/sales?update=true`);
                    navigate({
                        pathname: `/sales/list`,
                        search: location.search + (location.search ? `&update=true` : '?update=true')
                    });

            }}>
            <Typography.Text>
                Are you sure you want to delete this opportunity?
            </Typography.Text>
            <Typography.Paragraph>
                <WarningOutlined/> For non-compound opportunities, the related case will also be deleted.
            </Typography.Paragraph>
            <Typography.Paragraph>
                <InfoCircleOutlined/> For compound opportunities, the child opportunities will be deleted as well.
            </Typography.Paragraph>
        </ModalForm>
    );
};

export default DeleteOpportunityModal;

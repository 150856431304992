import axiosApiInstance from '../../api/axiosClient';

const getAccountingCustomerOptions = async () => {
    return axiosApiInstance
        .get<API.AccountingCustomer[]>('/api/accounting_customers/')
        .then(({data}) => data)
        .catch((e) => {
            throw e;
        });
};

export default getAccountingCustomerOptions;

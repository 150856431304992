import { ModalForm, ProFormInstance, ProFormSelect } from '@ant-design/pro-components';
import { Button, Col, Divider, Input, InputRef, notification, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosApiInstance from '../../api/axiosClient';
import updateOpportunity from '../../util/sales/updateOpportunity';

type T = API.Opportunity;
type P = API.Project[];
type M = {
    project_id: number;
};


/**
 * DERPECATED
 * Σκοπός εδώ ήταν να γίνεται manually ή συνδεση ενός opportunity με ένα project με την βοήθεια του ακόλουθου component
 * όμως η διαδικασία αυτή θα γίνεται αυτόματα στο τελικό build
 * @returns 
 */
const LinkWithProjectModal = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [target, setTarget] = useState<API.Opportunity | undefined>(undefined);

    const inputRef = useRef<InputRef>(null);
    const selectRef = useRef<any>(null);
    const formRef = useRef<ProFormInstance>(null);

    useEffect(() => {
        console.log('mounted linkwithprojectmodal');
        axiosApiInstance
            .get<T>(`/api/sales/opportunities/${id}`)
            .then(({ data }) => setTarget(data))
            .catch((e) => {
                notification.error(e);
                setTarget(undefined);
            });
    }, []);

    return (
        <ModalForm<M>
            title={`Link with project`}
            formRef={formRef}
            open={true}
            modalProps={{
                destroyOnClose: true,
                okText: 'Link',
                onCancel: () => navigate(`/sales`),
                centered: true
            }}
            onFinish={async (values) => {
                updateOpportunity({
                    id: target?.id,
                    ...values
                });
            }}>
            <ProFormSelect
                name='project_id'
                label='Associated Project'
                width='md'
                request={async () => {
                    const { data: projects } = await axiosApiInstance.get<API.Project[]>('/api/projects/');
                    return projects.map((proj) => ({
                        label: proj.title,
                        value: proj.id
                    }));
                }}
                showSearch
                fieldProps={{
                    ref: selectRef,
                    dropdownRender: (menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Row align='stretch' gutter={8}>
                                <Col flex={4}>
                                    <Input placeholder='New Project' ref={inputRef} />
                                </Col>
                                <Col flex={1}>
                                    <Button
                                        size='middle'
                                        type='dashed'
                                        onClick={() => {
                                            const newEntryTitle = inputRef.current?.input?.value;
                                            if (!newEntryTitle) {
                                                console.error('no title given');
                                                return;
                                            }
                                            axiosApiInstance
                                                .post<API.Project>('/api/projects/', {
                                                    title: newEntryTitle,
                                                    customer_id: target!.customer_id,
                                                    employee_id: target!.employee_id
                                                })
                                                .then((res) => {
                                                    formRef.current?.resetFields();
                                                    inputRef.current?.select();
                                                    selectRef.current.focus();
                                                })
                                                .catch((reason) => console.error(reason));
                                        }}>
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )
                }}
            />
        </ModalForm>
    );
};

export default LinkWithProjectModal;

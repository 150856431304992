import {atomWithHash} from "jotai-location";
import axiosApiInstance from "../api/axiosClient";
import {atomWithQuery, atomWithSuspenseQuery} from "jotai-tanstack-query";
import {ListGridType} from "antd/es/list";
import {atom} from "jotai";
import dayjs from "dayjs";
import {ProjectRangeEnum} from "./projects";

type FetchParams = API.LoggedInvoiceOutgoing & {
    range: ProjectRangeEnum;
    status: string;
    // tagIds: number[];
}

export const fetchParamsAtom = atomWithHash<Partial<FetchParams>>('invoicesOutSearchParams', {});

const opportunitySearch = (opp: API.Opportunity, search?: string) => {

    if (!search) return true;
    return opp.title.toLowerCase().includes(search.toLowerCase())
        || opp.project_pretty_id?.toLowerCase().includes(search.toLowerCase())
        || opp.customer?.name?.toLowerCase().includes(search.toLowerCase())
        || opp.accounting_customer?.name.toLowerCase().includes(search.toLowerCase())
        || opp.external_buyer?.name?.toLowerCase().includes(search.toLowerCase())

};

export default opportunitySearch;

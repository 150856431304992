import React, {useEffect} from 'react';
import {LightFilter, ProFormDependency, ProFormGroup, ProFormSelect} from '@ant-design/pro-components';
import {FilterTwoTone} from '@ant-design/icons';
import {Button, Form, Tooltip} from 'antd';
import {useSalesContext} from '../../context/SalesContextProvider';
import {useSearchParams} from 'react-router-dom';
import filterObjToString from '../../util/sales/convertFilterObjectToString';
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";

const mtg = 1;

// Το οβαλ κουμπί για τα filters πανω αριστερά απο τα sales list/funnel
// https://procomponents.ant.design/en-US/components/query-filter
export default () => {
    const {isLoading, filters, updateFilters} =
        useSalesContext();
    const {
        customers,
        employees,
        opportunityTypes,
        opportunityPhases,
        buyerTypes,
        externalBuyers,
        accountingCustomers,
        isLoading: isAuxDataLoading
    } = useSalesAuxDataContext()
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    // const inBuyerType = Form.useWatch('buyer_type_id', form) || 1;
    // const empl = Form.useWatch('employee_id', form);
    // const inBuyerType:number = 1;

    useEffect(() => {
        if (filters)
            form.setFieldsValue(filters);
    }, [filters]);


    return (
        // <Tooltip title='Apply filters'>
            <LightFilter
                form={form}
                title='filters'
                disabled={isLoading || isAuxDataLoading}
                // collapse
                collapseLabel={
                    <Button type='default' shape='round' icon={<FilterTwoTone/>}>
                        Filters
                    </Button>
                }
                // onChange={(event => {
                //     event?.currentTarget.blur()
                // })}
                onFinish={async (values) => {
                    // console.log('values', values ,
                    //     Object.entries(values)
                    //         .map(([k, vs]) => [k, typeof vs])
                    // )
                    const p = filterObjToString(values);
                    // console.log('filters', p)
                    searchParams.set('filters', p);
                    setSearchParams(searchParams);
                    // updateFilters(values);
                }}>
                {/* <div style={{ width: 440 }}> */}
                <ProFormGroup style={{width: 440}}>
                    <ProFormSelect
                        name='employee_id'
                        key='employee_id'
                        // label='Manager'
                        placeholder='Managers'
                        showSearch
                        mode='multiple'
                        options={employees.map((emp) => ({label: emp.name, value: emp.id}))}
                        fieldProps={{
                            maxTagCount: mtg
                        }}
                    />
                    <ProFormSelect
                        name='buyer_type_id'
                        key='buyer_type_id'
                        // label='Phase'
                        // initialValue={1}
                        placeholder='Customer Type'
                        // showSearch
                        // mode='single'
                        // fieldProps={{
                        //     maxTagCount: mtg,
                        // maxLength: 1,
                        // }}
                        options={buyerTypes.map((buyerType) => ({
                            label: `${buyerType.name}`,
                            value: buyerType.id
                        }))}
                    />
                    <ProFormDependency name={['buyer_type_id']}>
                        {({buyer_type_id}) => {
                            if (buyer_type_id == 1) {
                                return (
                                    <ProFormSelect
                                        name='customer_id'
                                        key='customer_id'
                                        // label='Employee'
                                        placeholder='Customers LTD'
                                        showSearch
                                        mode='multiple'
                                        options={customers.map((cus) => ({
                                            label: `${cus.name} [${cus.id}]`,
                                            value: cus.id
                                        }))}
                                        fieldProps={{
                                            maxTagCount: mtg
                                        }}
                                    />
                                );
                            } else if (buyer_type_id == 3) {
                                return (
                                    <ProFormSelect
                                        name='external_buyer_id'
                                        key='external_buyer_id'
                                        placeholder='Ext. Customers'
                                        showSearch
                                        mode='multiple'
                                        options={externalBuyers.map((cus) => ({
                                            label: `${cus.name} [${cus.id}]`,
                                            value: cus.id
                                        }))}
                                        fieldProps={{
                                            maxTagCount: mtg
                                        }}
                                    />
                                );
                            } else if (buyer_type_id == 2) {
                                return (
                                    <ProFormSelect
                                        name='accounting_customer_id'
                                        key='accounting_customer_id'
                                        placeholder='Customers IKE'
                                        showSearch
                                        mode='multiple'
                                        options={accountingCustomers.map((cus) => ({
                                            label: `${cus.name} [${cus.id}]`,
                                            value: cus.id
                                        }))}
                                        fieldProps={{
                                            maxTagCount: mtg
                                        }}
                                    />
                                );
                            } else {
                                return null;
                            }
                        }
                        }
                    </ProFormDependency>

                    <ProFormSelect
                        name='opportunity_phase_id'
                        key='opportunity_phase_id'
                        // label='Phase'
                        placeholder='Phases'
                        showSearch
                        mode='multiple'
                        options={opportunityPhases.map((phase) => ({
                            label: `${phase.name}`,
                            value: phase.id
                        }))}
                        fieldProps={{
                            maxTagCount: mtg
                        }}
                    />
                    <ProFormSelect
                        name='opportunity_type_id'
                        key='opportunity_type_id'
                        // label='Phase'
                        placeholder='Types'
                        showSearch
                        mode='multiple'
                        options={opportunityTypes.map((type) => ({label: type.name, value: type.id}))}
                        fieldProps={{
                            maxTagCount: mtg
                        }}
                    />
                    <ProFormSelect
                        name='is_compound'
                        key='is_compound'
                        placeholder='Compound'
                        // valueEnum={{
                        //     0: 'Non Compound Only',
                        //     1: 'Compound Only'
                        // }}
                        options={[
                            {label: 'Non Compound Only', value: 'false'},
                            {label: 'Compound Only', value: 'true'}
                        ]}
                        allowClear
                    />

                </ProFormGroup>
            </LightFilter>
        // </Tooltip>
    );
};

import React, {useEffect, useRef} from 'react';
import {
    ActionType,
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormField,
    ProFormList,
    ProFormMoney,
    ProFormRadio,
    ProFormSelect,
    ProFormSlider,
    ProFormText,
    ProFormTextArea
} from '@ant-design/pro-components';
import {Form, Skeleton, Tag, Tooltip} from 'antd';
import {useSalesContext} from '../../context/SalesContextProvider';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import updateOpportunity from '../../util/sales/updateOpportunity';
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";
import {CheckOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import './EditOpportunityForm.css';
import axiosApiInstance from "../../api/axiosClient";

const FORM_ITEM_WIDTH_SM = 210
const FORM_ITEM_WIDTH_XS = 130
const FORM_ITEM_WIDTH_XXS = 120

type Props = {
    // defaults?: API.Opportunity;
    // callback?: (values: any) => void;
};

/**
 * TODO
 * Ολο αυτο το form πρεπει να ξαναγραφτεί με μια φυσιολογική λογική. Εδω κάνει πράγματα τα οποία είναι απομεινάρια
 * των πειραματισμών που έκανα με το να φορτώνει τα δεδομένα μία φορα με την φόρτιση της σελίδας των sales, και έπειτα
 * όλα τα δεδομένα να επεξεργάζονται up front στο frontend. Αυτό προφανώς δημιούργησε μπέρδεμα στην περίπτωση που
 * μεταφερόμαστε σε διαφορετική σελίδα, οπως αυτήν εδώ
 *
 * Επομένως, με το refactoring που έχει γίνει στην σελίδα των sales, πλέον θα αρκεί απλώς να γίνει update το opportunity
 * με το opportunity_id που του αντιστοιχεί απο το url, το οποίο το λαμβάνουμε απο το useParams hook, μέσω της συνάρτησης
 * updateOpportunity. Δεν χρειάζεται να καταλάβετε τι ακριβώς προσπαθεί να κάνει η παρακάτω υλοποίηση
 * @param o
 * @returns
 */

// Βοηθητική συνάρτηση για να δημιουργήσει τις ταμπέλες του slider για τις φάσεις των opportunities σε ένα αναγνώσιμο format
const buildOpportunityPhaseMarks = (o: Array<API.OpportunityPhase>) => {
    return Object.fromEntries(
        o
            .filter((obj) => obj.sales_step_number !== 0)
            .map((obj) => [obj.id, <p style={{maxWidth: '6em'}}>{obj.name}</p>])
    );
};

type SubmitItem = API.Opportunity & { opportunity_status: number };

/**
 * το EditOpportunityForm λαμβάνει ως παράμετρο ένα object που λέγεται defaults, το οποίο είναι απλώς το υπάρχον opportunity
 * object το οποίο αντιστοιχεί στην σελίδα απο την οποία κλήθηκε το form. Έχει σκοπό να κάνει fill in την φόρμα με τα
 * υπάρχοντα values του opportunity, καθώς και να γεμίσει το request body με αυτά κατα το submit, καθώς η συναρτήση onFinish
 * της φόρμας περιέχει μόνο τα values που επεξεργάστηκαν (επομένως εαν κάποιος κάνει π.χ edit το description μονο, τότε το
 * title και ολα τα υπολοιπα fields θα αλλαξουν στα κενά values)
 * @param param0
 * @returns
 */
const EditOpportunityForm = ({}: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {opportunities, updateOpportunities} = useSalesContext();
    const {customers, employees, opportunityTypes, opportunityPhases} = useSalesAuxDataContext()
    const [form] = Form.useForm<SubmitItem>();
    const {id: opportunity_id} = useParams();
    const sliderMarks = buildOpportunityPhaseMarks(opportunityPhases);
    const target = opportunities.find((opp) => opportunity_id == `${opp.id}`);
    const actionRef = useRef<ActionType>();
    const [fromPath, setFromPath] = React.useState<string | undefined>(undefined)
    // console.log('pretty_id:', prettyid);
    // console.log('opportunity_id:', opportunity_id);
    // console.log('opportunities', opportunities)
    // console.log('defaults', defaults)
    // console.log('target:', target);
    useEffect(() => {
        const fromPath = location.state?.from_path
        setFromPath(fromPath)
        // console.log('fromPath', fromPath)
    }, [location])

    /// form watch
    // const purchase_orders = Form.useWatch('purchase_orders', form)

    // useEffect(() => {
    //     console.log('defaults', defaults);
    // }, [])

    useEffect(() => {
        console.log('target', target, opportunities);

        form.setFieldsValue({
            ...target,
            opportunity_status:
                target?.opportunity_phase.completion_percentage !== 0
                    ? 1
                    : target.opportunity_phase_id
        });

        if (target?.is_compound) {
            let purchase_orders = opportunities.filter((opp) => opp.parent_id === target.id).map((opp) => ({
                expected_po_date: opp.expected_po_date,
                proposed_value_in_euro: opp.proposed_value_in_euro,
                id: opp.id,
                pretty_id: opp.project_pretty_id,
            }));
            form.setFieldValue('purchase_orders', purchase_orders);
        }
    }, [target])

    return (
        <ModalForm<SubmitItem>
            title={!target?.parent_id ? 'Edit Opportunity' : 'Edit Purchase Order'}
            // initialValues={{
            //     ...target,
            //     opportunity_status:
            //         target?.opportunity_phase.completion_percentage !== 0
            //             ? 1
            //             : target.opportunity_phase_id
            // }}
            modalProps={{
                onCancel: () => {
                    if (location.pathname.includes('list')) {
                        navigate({pathname: '/sales/list', search: location.search})
                    } else {
                        navigate('./..')
                    }
                },
                // onCancel: () => navigate(-1),
                okText: 'Save',
                // transitionName: 'none'
            }}
            form={form}
            open={true}
            // trigger={
            //     // Entity that brings up the drawer form
            //     <Button type='text' size='middle' icon={<FormOutlined/>}>
            //         Edit
            //     </Button>
            // }
            // submitter={{
            // renders the submit and cancel buttons
            // render: (props, defaultProps) => {
            //     return [
            //         defaultProps[0], // cancel button
            //         <Button
            //             key='ok'
            //             type='primary'
            //             style={{marginLeft: 8}}
            //             onClick={() => {
            //                 props.submit();
            //             }}>
            //             Submit
            //         </Button>
            //     ];
            // }
            // }}
            onFinish={async (values) => {

                // hide the property purchase_orders from the final object when save is clicked
                if ((values as any).purchase_orders) {
                    delete (values as any).purchase_orders;
                }
                // console.log('values', values);
                // return false;

                await updateOpportunity({
                    ...values,
                    id: +opportunity_id!, // το opportunity_id προέρχεται απο το useParams hook και είναι string
                    opportunity_phase_id:
                        values.opportunity_status === 1
                            ? values.opportunity_phase_id > 7
                                ? 6
                                : values.opportunity_phase_id
                            : values.opportunity_status
                });
                await updateOpportunities();
                // navigate('./..');

                if (fromPath) {
                    navigate({
                        pathname: fromPath,
                        search: location.pathname.includes('list') ? '' : '?update=true'
                    });
                } else {
                    navigate({pathname: './..', search: location.pathname.includes('list') ? '' : '?update=true'})
                }
                // navigate({pathname: './..', search: location.pathname.includes('list') ? '' : '?update=true'})
                // if (callback) {
                //     console.log('values', values);
                //     callback(values);
                // }
                return true;
            }}
            // layout='vertical'
            style={{
                flex: 1,
                paddingLeft: '8%',
                paddingRight: '5%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {!target ? <Skeleton active={true} paragraph={{rows: 5}}/> : <>
                <ProForm.Group
                    direction='vertical'
                    // style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}
                >
                    <ProFormText
                        name='title'
                        label='Title'
                        rules={[
                            {
                                required: true
                            }
                        ]}
                        width='lg'
                    />
                    <ProFormTextArea name='description' label='Description' width='xl'/>
                </ProForm.Group>
                <ProForm.Group direction='horizontal' style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
                    {/*<ProFormSelect*/}
                    {/*    name='customer_id'*/}
                    {/*    label='Customer'*/}
                    {/*    width='sm'*/}
                    {/*    showSearch*/}
                    {/*    style={{ marginRight: 16 }}*/}
                    {/*    options={customers.map((c) => ({ value: c.id, label: `${c.name} [${c.id}]` }))}*/}
                    {/*    allowClear={false}*/}
                    {/*/>*/}
                    {/*<ProFormDependency name={[['customer_id']]}>*/}
                    {/*    {({customer_id}) => {*/}
                    {/*        const c = customers.find((c) => c.id == customer_id);*/}
                    {/*        if (c) form.setFieldValue(['employee_id'], c?.employee_id);*/}
                    {/*        return (*/}
                    <ProFormSelect
                        name='employee_id'
                        label='Manager'
                        showSearch
                        width='sm'
                        style={{marginRight: 16}}
                        options={employees.map((e) => ({value: e.id, label: `${e.name}`}))}
                        allowClear={false}
                    />
                    {/*        );*/}
                    {/*    }}*/}
                    {/*</ProFormDependency>*/}
                    <ProFormSelect
                        name='opportunity_type_id'
                        label='Opportunity Type'
                        options={opportunityTypes.map((type) => ({label: type.name, value: type.id}))}
                        allowClear={false}
                        style={{marginRight: 16}}
                        width='xs'
                    />
                </ProForm.Group>
                <ProFormDependency name={['opportunity_status']}>
                    {({opportunity_status}) => {
                        return (
                            <ProFormSlider
                                name='opportunity_phase_id'
                                label='Opportunity Phase'
                                min={1}
                                max={7}
                                style={{width: 540}}
                                marks={sliderMarks}
                                disabled={opportunity_status !== 1}
                            />
                        );
                    }}
                </ProFormDependency>

                <ProForm.Group direction='vertical' style={{marginTop: 16, marginBottom: 16, width: 500}}>
                    <br/>
                    {/* <ProFormSelect
                    name='opportunity_phase_id'
                    label='Opportunity Lost/Dropped'
                    width='xs'
                    options={[
                        {
                            label: 'Lost',
                            value: 7
                        },
                        {
                            label: 'Dropped',
                            value: 8
                        }
                    ]}
                /> */}
                    <ProFormRadio.Group
                        name='opportunity_status'
                        label='Status'
                        options={[
                            {label: 'Active', value: 1},
                            {label: 'Dropped', value: 8},
                            {label: 'Lost', value: 9}
                        ]}
                    />
                </ProForm.Group>

                {!target.is_compound &&
                    <ProForm.Group>
                        <ProFormMoney
                            name='proposed_value_in_euro'
                            label='Unweighted Proposed Value'
                            customSymbol='€'
                            min={0}
                            max={999_999_999}
                        />
                        <ProFormDatePicker name='expected_po_date' label='Expected P.O. Date'
                                           normalize={(value) => {
                                               return value.toISOString()
                                           }}
                        />
                    </ProForm.Group>
                }

                {target.is_compound &&
                    <ProFormList
                        name="purchase_orders"
                        creatorButtonProps={{creatorButtonText: 'Add Purchase Order'}}
                        initialValue={[{}]}
                        deleteIconProps={{tooltipText: 'Delete'}}

                        copyIconProps={false}
                        // deleteIconProps={false}
                        actionRender={(field, action, defaultActionDom, count) => {
                            // console.log(form.getFieldValue('purchase_orders')[field.name].id)
                            if (form.getFieldValue('purchase_orders')[field.name].id) {
                                return [
                                    <Tooltip title="Edit">
                                        <Link
                                            className={'list-action-icon'}
                                            style={{
                                                marginInlineStart: 8,
                                            }}
                                            // to={`/sales/list/${form.getFieldValue('purchase_orders')[field.name].id}/edit`}>
                                            // state={{background: location}}
                                            state={{
                                                from_path: location.pathname
                                            }}
                                            to={`./../../${form.getFieldValue('purchase_orders')[field.name].id}/edit`}>
                                            {/*<Button type="link" key="edit" icon={<EditOutlined/>}/>*/}
                                            <EditOutlined/>
                                        </Link>
                                    </Tooltip>
                                    ,
                                    <Tooltip title="Delete">
                                        <Link
                                            className={'list-action-icon'}
                                            style={{
                                                marginInlineStart: 8,
                                            }}
                                            to={`/sales${location.pathname.includes('list') ? '/list' : ''}/delete/${form.getFieldValue('purchase_orders')[field.name].id}`}
                                            state={{
                                                // background: location,
                                                from_path: location.pathname
                                            }}
                                        >
                                            {/*<Button type="link" danger key="delete" icon={<DeleteOutlined/>}/>*/}
                                            <DeleteOutlined/>
                                        </Link>
                                    </Tooltip>
                                ]
                            }
                            return [
                                ...defaultActionDom,
                                <a
                                    className={'list-action-icon'}
                                    style={{
                                        marginInlineStart: 8,
                                    }}
                                >
                                    <Tooltip title="Save"
                                    >
                                        {/*<Button type="link" danger key="delete" icon={<DeleteOutlined/>}/>*/}
                                        <CheckOutlined
                                            onClick={async () => {
                                                console.log(form.getFieldValue('purchase_orders')[field.name])
                                                await axiosApiInstance.put(`/api/sales/opportunities/${target.id}/add_po`,
                                                    form.getFieldValue('purchase_orders')[field.name]
                                                )
                                                await updateOpportunities()
                                            }}
                                        />
                                    </Tooltip>
                                </a>
                            ]
                        }}

                        // min={1}
                    >
                        {(meta, index, action, count) => {
                            return (
                                <ProForm.Group>
                                    <ProFormField
                                        valueType={'indexBorder'}
                                        // labelCol={{span: 0}}
                                        // wrapperCol={{span: 0}}
                                        width={'xs'}
                                        initialValue={index}
                                        label={' '}
                                        name={'index'}
                                    />
                                    <ProFormField
                                        name={'id'}
                                        hidden={true}
                                    />
                                    <ProFormField
                                        name={'pretty_id'}
                                        formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_XXS}}}}
                                        label={'ID'}
                                        readonly={true}
                                        render={(dom) => dom && <Tag color={'blue'}>{dom}</Tag>}
                                    />

                                    <ProFormDatePicker
                                        name='expected_po_date'
                                        label='Expected P.O. Date'
                                        width='sm'
                                        fieldProps={{
                                            // disabledDate: disabledDate,
                                            format: 'DD/MM/YYYY'
                                        }}
                                        normalize={(value) => {
                                            return value.toISOString()
                                        }}
                                        rules={[{required: true}]}
                                        // disabled={!!form.getFieldValue('purchase_orders')[index].id}
                                        readonly={!!form.getFieldValue('purchase_orders')[index].id}
                                        // style={{pointerEvents: !!form.getFieldValue('purchase_orders')[index].id ? 'none' : 'auto'}}
                                        formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_XS}}}}
                                    />
                                    <ProFormMoney
                                        name='proposed_value_in_euro'
                                        label='Estimated Unweighted Value (€)'
                                        // locale='fr-FR'
                                        fieldProps={{
                                            numberFormatOptions: {currency: 'EUR'},
                                        }}
                                        customSymbol={'€'}
                                        formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                        min={0}
                                        max={999_999_999} // 999 million
                                        width='sm'
                                        rules={[{required: true}]}
                                        readonly={!!form.getFieldValue('purchase_orders')[index].id}
                                        // disabled={!!form.getFieldValue('purchase_orders')[index].id}
                                    />
                                </ProForm.Group>
                            );
                        }}
                    </ProFormList>
                }
            </>}
        </ModalForm>
    );
};

export default EditOpportunityForm;

import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDateRangePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormList,
    ProFormMoney,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTextArea
} from "@ant-design/pro-components";
import {Form, Tabs} from "antd";
import axiosApiInstance from "../../../api/axiosClient";
import {useNavigate, useParams} from "react-router-dom";
import {GlobalStateContext} from "../../../context/GlobalContext";
import dayjs from "dayjs";
import {ProFormInstance} from "@ant-design/pro-form/es/BaseForm/BaseForm";
import {PageLoading} from "@ant-design/pro-layout";

const TabPane = Tabs.TabPane;

const DEFAULT_GROSS_MARGIN_P_OTC = 38
const DEFAULT_GROSS_MARGIN_P_OMC = 10
const DEFAULT_GROSS_MARGIN_P_3PTC = 10
const DEFAULT_GROSS_MARGIN_P_3PMC = 0

type Props = {};

const NewExecutionDeliverable = (props: Props) => {
    const [form] = Form.useForm<API.ExecutionDeliverable>();
    const navigate = useNavigate();
    const routerParams = useParams();
    const {executionDeliverablesListRef, executionWBSVersionRef} = useContext(GlobalStateContext);
    const [skillsData, setSkillsData] = useState<API.Skill[] | undefined>([]);
    const [ownMaterialsData, setOwnMaterialsData] = useState<API.OwnMaterial[] | undefined>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [employeesData, setEmployeesData] = useState<API.Employee[] | undefined>([]);
    const formRef = useRef<ProFormInstance>();
    const [deliverableData, setDeliverableData] = useState<API.ExecutionDeliverable | undefined>(undefined);
    const [activeTabKey, setActiveTabKey] = useState("1");

    const fetchSkillsData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.Skill[]>('/api/skills/')
            // console.log(response.data)
            setSkillsData(response.data);
            setLoading(false)
        } catch (e) {
            // console.log('catch error')
            setSkillsData(undefined)
            setLoading(false)
        }
    };

    const fetchOwnMaterialsData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.OwnMaterial[]>('/api/deliverables/ownmaterials')
            // console.log(response.data)
            setOwnMaterialsData(response.data);
            setLoading(false)
        } catch (e) {
            // console.log('catch error')
            setOwnMaterialsData(undefined)
            setLoading(false)
        }
    };

    const fetchEmployeesData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.Employee[]>('/api/employees/')
            // console.log(response.data)
            setEmployeesData(response.data);
            setLoading(false)
        } catch (e) {
            // console.log('catch error')
            setEmployeesData(undefined)
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchSkillsData();
        fetchOwnMaterialsData();
        fetchEmployeesData();
    }, []);

    // if (loading) {
    //     return <PageLoading/>
    // }
    const tabItemClick = (key: any) => {
        setActiveTabKey(key);
    };

    return (
        <ModalForm<API.ExecutionDeliverable>
            title="Create a new Deliverable"
            visible={true}
            form={form}
            autoFocusFirstInput
            // width={"100%"}
            width={"75%"}
            modalProps={{
                destroyOnClose: true,
                okText: 'Create',
                onCancel: () => navigate(`/projects/${routerParams.projectId}/executions/${routerParams.executionWbsVersionId}`),
                bodyStyle: {height: "calc(100vh - 300px)", overflowY: 'auto'},
                style: {top: 70},
            }}
            // submitTimeout={2000}
            onFinish={(values) => {
                values.execution_wbs_version_id = +routerParams.executionWbsVersionId!
                console.log({values});
                // return Promise.resolve()
                return axiosApiInstance.post<API.ExecutionDeliverable>('/api/deliverables/execution/', values)
                    .then(res => {
                        executionDeliverablesListRef?.current?.reload()
                        executionWBSVersionRef?.current?.reload()
                        navigate(`/projects/${routerParams.projectId}/executions/${routerParams.executionWbsVersionId}`)
                        return true
                    }).catch(reason => false)
            }}
            validateMessages={{required: "${label} is required!"}}
            // initialValues={{
            //     dateRange: []
            // }}
        >
            {loading ? <PageLoading/> :
                <>
                    <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={tabItemClick}
                        // type={'card'}
                    >
                        <TabPane tab="General" key="1" forceRender>
                            <ProFormText
                                width="xl"
                                name="name"
                                label="Name"
                                rules={[{required: true}]}
                            />
                            <ProFormTextArea width="xl"
                                             name="description"
                                             label="Description"
                            />
                            {/*<ProFormDigit width="xs"*/}
                            {/*              name="estimated_duration_days"*/}
                            {/*              label="Estimated duration"*/}
                            {/*              initialValue={0}*/}
                            {/*              addonAfter={'days'}*/}
                            {/*/>*/}
                            <ProFormDependency name={[['start_date'], ['end_date']]}>
                                {({start_date, end_date}) => {
                                    form.setFieldValue('dateRange', [start_date, end_date])
                                    return (
                                        <ProFormDateRangePicker name="dateRange" label="Start / End Date"
                                                                transform={(value) => {
                                                                    return {start_date: value[0], end_date: value[1]}
                                                                }}
                                                                normalize={(value) => {
                                                                    return [dayjs.utc(value[0]).startOf('d').toISOString(), dayjs.utc(value[1]).startOf('d').toISOString()]
                                                                }}
                                                                rules={[{required: true}]}
                                        />
                                    )
                                }}
                            </ProFormDependency>

                            <ProFormDatePicker name={"start_date"} hidden/>
                            <ProFormDatePicker name={"end_date"} hidden/>

                            <ProFormDigit
                                name="id"
                                hidden={true}
                            />
                            <ProFormDigit
                                name="execution_wbs_version_id"
                                initialValue={routerParams.executionWbsVersionId}
                                hidden={true}
                            />

                        </TabPane>
                        <TabPane tab={`Own Time Costs (${deliverableData?.own_time_costs?.length || 0})`} key="2"
                                 forceRender>
                            {/*OWN_TIME_COSTS*/}
                            {/*<ProCard>*/}

                            <ProFormList name="own_time_costs"
                                // label={'Own Time Costs'}
                                //          copyIconProps={props.editable ? {} : false}
                                //          deleteIconProps={props.editable ? {} : false}
                                         creatorButtonProps={{creatorButtonText: 'Add cost'}}
                                // actionGuard={{
                                //     beforeRemoveRow: (params) => {
                                //         const costId = form.getFieldValue(['own_time_costs', params as number, 'id'])
                                //         if (costId == undefined) return true
                                //         return axiosApiInstance.delete('/api/owntimecosts/execution/' + costId)
                                //             .then(value => {
                                //                 fetchDeliverableData()
                                //                 executionDeliverablesListRef?.current?.reload();
                                //                 return true
                                //             })
                                //         // .then(res => true).catch(reason => false)
                                //     }
                                // }}
                            >
                                {(f, index, action) => {
                                    return (
                                        <ProFormGroup>
                                            <ProFormDigit
                                                name="id"
                                                hidden={true}
                                            />
                                            <ProFormDigit
                                                name="execution_deliverable_id"
                                                initialValue={+routerParams.deliverableId!}
                                                hidden={true}
                                            />
                                            <ProFormText
                                                label=" "
                                                readonly
                                                width={"xs"}
                                                fieldProps={{value: `${index + 1})`, style: {opacity: 0.5}}}
                                            />

                                            <ProFormDigit
                                                name="time_amount"
                                                label={'Effort'}
                                                fieldProps={{precision: 0}}
                                                width={"xs"}
                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                            />

                                            <ProFormRadio.Group
                                                name="time_unit"
                                                label="Time Unit"
                                                options={[
                                                    {
                                                        label: 'Hours',
                                                        value: 'hours',
                                                    },
                                                    {
                                                        label: 'Days',
                                                        value: 'days',
                                                    },
                                                ]}
                                                initialValue={'hours'}
                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                            />

                                            <ProFormSelect
                                                // request={async () =>
                                                //     // axiosApiInstance.get<API.Skill[]>('/api/skills/')
                                                //     //     .then(res => {
                                                //     //         return res.data.map(i => ({label: i.name, value: i.id}))
                                                //     //     })
                                                //     skillsData?.map(i => ({label: i.name, value: i.id}))
                                                // }
                                                options={skillsData?.map(i => ({label: i.name, value: i.id}))}
                                                width="sm"
                                                name="skill_id"
                                                label="Skill"
                                                // formItemProps={{}}
                                                fieldProps={{
                                                    onSelect: (skill_id: number | undefined) => {
                                                        // console.log('SELECT')
                                                        form.setFieldValue(['own_time_costs', index, 'hourly_rate'], skillsData?.find(value => value.id == skill_id)?.default_hourly_rate)
                                                        form.setFieldValue(['own_time_costs', index, 'skill'], skillsData?.find(value => value.id == skill_id))
                                                    },
                                                    onChange: value => {
                                                        form.setFieldValue(['own_time_costs', index, 'employee_ids'], [])
                                                    }
                                                }}
                                                key={index}
                                                rules={[{required: true}]}
                                                // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                            />

                                            <ProFormItem
                                                name="skill"
                                                hidden={true}
                                            />

                                            <ProFormDependency name={[['skill_id'], ['time_unit']]}>
                                                {({skill_id, time_unit}) => {
                                                    // console.log('CHANGE skill_id')
                                                    // console.log(skillsData?.find(value => value.id == skill_id)?.default_hourly_rate)
                                                    let s = skillsData?.find(value => value.id == skill_id)
                                                    let defaultHourlyRate = s?.default_hourly_rate
                                                    let defaultDailyRate = s?.default_daily_rate
                                                    // console.log(s, time_unit)
                                                    // form.setFieldValue(['own_time_costs', index, 'skill'], s)
                                                    // console.log(form.getFieldValue(['own_time_costs', index]))
                                                    // form.setFieldValue(['own_time_costs', index, 'hourly_rate'],r)
                                                    // form.resetFields([['own_time_costs', index, 'hourly_rate']])
                                                    if (time_unit == 'hours') {
                                                        form.setFieldValue(['own_time_costs', index, 'daily_rate'], 0)
                                                        if (!form.getFieldValue(['own_time_costs', index, 'hourly_rate']))
                                                            form.setFieldValue(['own_time_costs', index, 'hourly_rate'], defaultHourlyRate)
                                                        return (
                                                            <ProFormMoney
                                                                // locale="el-GR"
                                                                // locale="it-IT"
                                                                // key={id}
                                                                key={index}
                                                                customSymbol={'€'}
                                                                name="hourly_rate"
                                                                label={'Rate (Hourly)'}
                                                                // initialValue={form.getFieldValue(['own_time_costs', index, 'skill', 'default_hourly_rate'])}
                                                                // initialValue={defaultHourlyRate}
                                                                // fieldProps={{
                                                                //     value: r
                                                                // }}
                                                                fieldProps={{
                                                                    numberFormatOptions: {currency: 'EUR'}
                                                                }}
                                                                width={"xs"}
                                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                                            />
                                                        )
                                                    }
                                                    // console.log('daily', defaultDailyRate)
                                                    form.setFieldValue(['own_time_costs', index, 'hourly_rate'], 0)
                                                    if (!form.getFieldValue(['own_time_costs', index, 'daily_rate']))
                                                        form.setFieldValue(['own_time_costs', index, 'daily_rate'], defaultDailyRate)
                                                    return (
                                                        <ProFormMoney
                                                            // locale="el-GR"
                                                            // locale="it-IT"
                                                            // key={id}
                                                            key={index + 1}
                                                            // onReset={() => {
                                                            //     console.log('RESET')
                                                            // }}
                                                            customSymbol={'€'}
                                                            name="daily_rate"
                                                            label={'Rate (Daily)'}
                                                            // initialValue={form.getFieldValue(['own_time_costs', index, 'skill', 'default_hourly_rate'])}
                                                            // initialValue={defaultDailyRate}
                                                            // fieldProps={{
                                                            //     value: r
                                                            // }}
                                                            fieldProps={{
                                                                numberFormatOptions: {currency: 'EUR'}
                                                            }}
                                                            width={"xs"}
                                                            // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>

                                            <ProFormDependency
                                                name={[['time_amount'], ['hourly_rate'], ['daily_rate'], ['time_unit']]}>
                                                {({time_amount, hourly_rate, daily_rate, time_unit}) => {
                                                    // console.log("cost", effort, hourly_rate)
                                                    // form.setFieldValue(['own_material_costs', index, 'price'], (cost || 0) / (1 - ((gross_margin_percentage || 0) / 100)))

                                                    if (time_unit == 'hours') {
                                                        form.setFieldValue(['own_time_costs', index, 'cost'], (time_amount || 0) * (hourly_rate || 0))
                                                    } else if (time_unit == 'days') {
                                                        form.setFieldValue(['own_time_costs', index, 'cost'], (time_amount || 0) * (daily_rate || 0))
                                                    } else {
                                                        form.setFieldValue(['own_time_costs', index, 'cost'], 0)
                                                    }
                                                    return (
                                                        <ProFormMoney
                                                            // locale="el-GR"
                                                            // locale="it-IT"
                                                            customSymbol={'€'}
                                                            name="cost"
                                                            readonly
                                                            label={'Cost'}
                                                            // help={'= Effort * Rate * 8'}
                                                            tooltip={'= Effort * Rate * 8'}
                                                            fieldProps={{
                                                                numberFormatOptions: {currency: 'EUR'},
                                                            }}
                                                            // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>

                                            <ProFormDigit
                                                width={"xs"}
                                                name="gross_margin_percentage"
                                                label={'Gross Margin'}
                                                fieldProps={{
                                                    // precision: 2, max: 1, min: 0, step: 0.01,
                                                    // addonAfter: '%',
                                                    precision: 0, max: 100, min: 0, step: 1,
                                                    formatter: (value) => `${value}%`,
                                                    // formatter:(value) => `${value}%`,
                                                    // formatter: (value) => {
                                                    //     console.log(value)
                                                    //     return `${(+value!) * 100}%`
                                                    // },
                                                    parser: (value) => +value!.replace('%', ''),
                                                    // parser:(value) => parseInt(value!.replace('%', ''))/100,
                                                }}
                                                initialValue={DEFAULT_GROSS_MARGIN_P_OTC}
                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                            />


                                            <ProFormDependency
                                                name={[['cost'], ['gross_margin_percentage']]}
                                                // shouldUpdate={(prevValues, nextValues, info) => {
                                                //     console.log(info, prevValues, nextValues, prevValues.own_time_costs[index].gross_margin !== nextValues.own_time_costs[index].gross_margin, prevValues.own_time_costs[index].cost !== nextValues.own_time_costs[index].cost)
                                                //     return prevValues.own_time_costs[index].gross_margin !== nextValues.own_time_costs[index].gross_margin || prevValues.own_time_costs[index].cost !== nextValues.own_time_costs[index].cost
                                                // }}
                                            >
                                                {({cost, gross_margin_percentage}) => {
                                                    // console.log('price', cost, gross_margin_percentage)
                                                    form.setFieldValue(['own_time_costs', index, 'price'], (cost || 0) / (1 - ((gross_margin_percentage || 0) / 100)))
                                                    return (
                                                        <ProFormMoney
                                                            customSymbol={'€'}
                                                            name="price"
                                                            readonly
                                                            label={'Price'}
                                                            // help={'= Effort * Rate * 8'}
                                                            tooltip={'= Cost / (1 - Gross Margin)'}
                                                            fieldProps={{
                                                                numberFormatOptions: {currency: 'EUR'},
                                                            }}
                                                            // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>

                                            <ProFormDependency
                                                name={[['skill_id']]}
                                            >
                                                {({skill_id}) => {
                                                    // console.log('skill_id', skill_id)
                                                    // let skillId = form.getFieldValue(['own_time_costs', index, 'skill_id'])
                                                    return (
                                                        <ProFormSelect
                                                            // request={async () =>
                                                            //     axiosApiInstance.get<API.Employee[]>('/api/employees/', {params: {skills: [{id: skillId}]}})
                                                            //         .then(res => {
                                                            //             return res.data.map(i => ({label: i.name, value: i.id}))
                                                            //         })
                                                            //     // skillsData?.map(i => ({label: i.name, value: i.id}))
                                                            // }
                                                            options={employeesData?.filter(i => {
                                                                for (const x of i.skills!) {
                                                                    if (x.id == skill_id)
                                                                        return true
                                                                }
                                                                return false
                                                            }).map(i => ({
                                                                label: i.name,
                                                                value: i.id
                                                                // value: i
                                                            }))}
                                                            width="sm"
                                                            name="employee_ids"
                                                            label="Available to Employees"
                                                            fieldProps={{
                                                                mode: "multiple",
                                                                onChange: (employee_ids: number[]) => {
                                                                    // console.log({employee_ids})
                                                                    form.setFieldValue(['own_time_costs', index, 'employees'], employeesData?.filter(i => {
                                                                        return employee_ids.includes(i.id);
                                                                    }))
                                                                },
                                                            }}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>

                                            <ProFormItem
                                                name="employees"
                                                hidden={true}
                                            />

                                        </ProFormGroup>
                                    );
                                }}
                            </ProFormList>
                            {/*</ProCard>*/}
                        </TabPane>
                        <TabPane tab={`Own Material Costs (${deliverableData?.own_material_costs?.length || 0})`}
                                 key="3" forceRender>
                            {/*OWN_MATERIAL_COSTS*/}
                            <ProFormList name="own_material_costs"
                                // label={'Own Material Costs'}
                                //          copyIconProps={props.editable ? {} : false}
                                //          deleteIconProps={props.editable ? {} : false}
                                         creatorButtonProps={{creatorButtonText: 'Add cost'}}
                                // actionGuard={{
                                //     beforeRemoveRow: (params) => {
                                //         const costId = form.getFieldValue(['own_material_costs', params as number, 'id'])
                                //         if (costId == undefined) return true
                                //         return axiosApiInstance.delete('/api/ownmaterialcosts/execution/' + costId)
                                //             .then(value => {
                                //                 fetchDeliverableData()
                                //                 executionDeliverablesListRef?.current?.reload();
                                //                 return true
                                //             })
                                //     }
                                // }}
                                // style={{display:"flex"}}
                                // style={{alignItems:"center"}}
                            >
                                {(f, index, action) => {
                                    return (
                                        <ProFormGroup>
                                            <ProFormDigit
                                                name="id"
                                                hidden={true}
                                            />

                                            <ProFormDigit
                                                name="execution_deliverable_id"
                                                initialValue={+routerParams.deliverableId!}
                                                hidden={true}
                                            />

                                            <ProFormText
                                                label=" "
                                                readonly
                                                width={"xs"}
                                                fieldProps={{value: `${index + 1})`, style: {opacity: 0.5}}}
                                            />

                                            <ProFormDigit
                                                name="time_amount"
                                                label={'Time Amount'}
                                                fieldProps={{precision: 0}}
                                                width={"xs"}
                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                            />

                                            <ProFormRadio.Group
                                                name="time_unit"
                                                label="Time Unit"
                                                options={[
                                                    {
                                                        label: 'Hours',
                                                        value: 'hours',
                                                    },
                                                    {
                                                        label: 'Days',
                                                        value: 'days',
                                                    },
                                                ]}
                                                initialValue={'hours'}
                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                            />

                                            <ProFormSelect
                                                options={ownMaterialsData?.map(i => ({label: i.name, value: i.id}))}
                                                width="sm"
                                                name="own_material_id"
                                                label="Material"
                                                fieldProps={{
                                                    onSelect: (own_material_id: number | undefined) => {
                                                        // console.log('SELECT')
                                                        form.setFieldValue(['own_material_costs', index, 'hourly_rate'], ownMaterialsData?.find(value => value.id == own_material_id)?.default_hourly_rate)
                                                        form.setFieldValue(['own_material_costs', index, 'own_material'], ownMaterialsData?.find(value => value.id == own_material_id))
                                                    }
                                                }}
                                                rules={[{required: true}]}
                                                // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                            />

                                            <ProFormItem
                                                name="own_material"
                                                hidden={true}
                                            />


                                            <ProFormDependency name={[['own_material_id'], ['time_unit']]}>
                                                {({own_material_id, time_unit}) => {
                                                    let s = ownMaterialsData?.find(value => value.id == own_material_id)
                                                    let defaultHourlyRate = s?.default_hourly_rate
                                                    let defaultDailyRate = s?.default_daily_rate
                                                    if (time_unit == 'hours') {
                                                        form.setFieldValue(['own_material_costs', index, 'daily_rate'], 0)
                                                        if (!form.getFieldValue(['own_material_costs', index, 'hourly_rate']))
                                                            form.setFieldValue(['own_material_costs', index, 'hourly_rate'], defaultHourlyRate)
                                                        return (
                                                            <ProFormMoney
                                                                key={index}
                                                                customSymbol={'€'}
                                                                name="hourly_rate"
                                                                label={'Rate (Hourly)'}
                                                                fieldProps={{
                                                                    numberFormatOptions: {currency: 'EUR'}
                                                                }}
                                                                width={"xs"}
                                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                                            />
                                                        );
                                                    }
                                                    form.setFieldValue(['own_material_costs', index, 'hourly_rate'], 0)
                                                    if (!form.getFieldValue(['own_material_costs', index, 'daily_rate']))
                                                        form.setFieldValue(['own_material_costs', index, 'daily_rate'], defaultDailyRate)
                                                    return (
                                                        <ProFormMoney
                                                            key={index + 1}
                                                            customSymbol={'€'}
                                                            name="daily_rate"
                                                            label={'Rate (Daily)'}
                                                            fieldProps={{
                                                                numberFormatOptions: {currency: 'EUR'}
                                                            }}
                                                            width={"xs"}
                                                            // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>

                                            <ProFormDependency
                                                name={[['time_amount'], ['hourly_rate'], ['daily_rate'], ['time_unit']]}>
                                                {({time_amount, hourly_rate, daily_rate, time_unit}) => {
                                                    // console.log("cost", effort, hourly_rate)
                                                    if (time_unit == 'hours') {
                                                        form.setFieldValue(['own_material_costs', index, 'cost'], (time_amount || 0) * (hourly_rate || 0))
                                                    } else if (time_unit == 'days') {
                                                        form.setFieldValue(['own_material_costs', index, 'cost'], (time_amount || 0) * (daily_rate || 0))
                                                    } else {
                                                        form.setFieldValue(['own_material_costs', index, 'cost'], 0)
                                                    }
                                                    return (
                                                        <ProFormMoney
                                                            customSymbol={'€'}
                                                            name="cost"
                                                            readonly
                                                            label={'Cost'}
                                                            tooltip={'= Effort * Rate'}
                                                            fieldProps={{
                                                                numberFormatOptions: {currency: 'EUR'}
                                                            }}
                                                            // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>


                                            <ProFormDigit
                                                width={"xs"}
                                                name="gross_margin_percentage"
                                                label={'Gross Margin'}
                                                fieldProps={{
                                                    precision: 0, max: 100, min: 0, step: 1,
                                                    formatter: (value) => `${value}%`,
                                                    parser: (value) => +value!.replace('%', ''),
                                                }}
                                                initialValue={DEFAULT_GROSS_MARGIN_P_OMC}
                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                            />

                                            <ProFormDependency name={[['cost'], ['gross_margin_percentage']]}>
                                                {({cost, gross_margin_percentage}) => {
                                                    // console.log(person_days_effort, hourly_rate,gross_margin)
                                                    form.setFieldValue(['own_material_costs', index, 'price'], (cost || 0) / (1 - ((gross_margin_percentage || 0) / 100)))
                                                    return (
                                                        <ProFormMoney
                                                            customSymbol={'€'}
                                                            name="price"
                                                            readonly
                                                            label={'Price'}
                                                            // help={'= Effort * Rate * 8'}
                                                            tooltip={'= Cost / (1 - Gross Margin)'}
                                                            fieldProps={{
                                                                // value: cost * (1 / (1 - gross_margin)) || 0,
                                                                numberFormatOptions: {currency: 'EUR'}
                                                            }}
                                                            // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>
                                        </ProFormGroup>
                                    );
                                }}
                            </ProFormList>
                        </TabPane>
                        <TabPane tab={`3P Time Costs (${deliverableData?.third_party_time_costs?.length || 0})`} key="4"
                                 forceRender>
                            <ProFormList name="third_party_time_costs"
                                // label={'3rd Party Time Costs'}
                                //          copyIconProps={props.editable ? {} : false}
                                //          deleteIconProps={props.editable ? {} : false}
                                         creatorButtonProps={{creatorButtonText: 'Add cost'}}
                                // actionGuard={{
                                //     beforeRemoveRow: (params) => {
                                //         const costId = form.getFieldValue(['third_party_time_costs', params as number, 'id'])
                                //         if (costId == undefined) return true
                                //         return axiosApiInstance.delete('/api/thirdpartytimecosts/execution/' + costId)
                                //             .then(value => {
                                //                 fetchDeliverableData()
                                //                 executionDeliverablesListRef?.current?.reload();
                                //                 return true
                                //             })
                                //     }
                                // }}
                            >
                                {(f, index, action) => {
                                    return (
                                        <ProFormGroup>
                                            <ProFormDigit
                                                name="id"
                                                hidden={true}
                                            />
                                            <ProFormDigit
                                                name="execution_deliverable_id"
                                                initialValue={+routerParams.deliverableId!}
                                                hidden={true}
                                            />

                                            <ProFormText
                                                label=" "
                                                readonly
                                                width={"xs"}
                                                fieldProps={{value: `${index + 1})`, style: {opacity: 0.5}}}
                                            />

                                            <ProFormMoney
                                                name="cost"
                                                label={'Cost'}
                                                customSymbol={'€'}
                                                fieldProps={{
                                                    numberFormatOptions: {currency: 'EUR'}
                                                }}
                                                rules={[{required: true}]}
                                                width={"sm"}
                                                // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                            />

                                            <ProFormSelect
                                                request={() =>
                                                    axiosApiInstance.get<API.SupplierType[]>('/api/suppliers/types')
                                                        .then(res => {
                                                            return res.data.map(i => ({label: i.name, value: i.id}))
                                                        })
                                                }
                                                name="supplier_type_id"
                                                label="Supplier Type"
                                                rules={[{required: true}]}
                                                width={"sm"}
                                                // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                            />

                                            <ProFormDependency name={[['supplier_type_id']]}>
                                                {({supplier_type_id}) => {
                                                    if (supplier_type_id === 1) {
                                                        form.setFieldValue(['third_party_time_costs', index, 'supplier_external_id'], null)
                                                        form.setFieldValue(['third_party_time_costs', index, 'external_supplier'], null)
                                                        form.setFieldValue(['third_party_time_costs', index, 'customer'], null)
                                                        return <ProFormSelect
                                                            request={() =>
                                                                axiosApiInstance.get<API.Customer[]>('/api/customers/')
                                                                    .then(res => {
                                                                        return res.data.map(i => ({
                                                                            label: `${i.name} [${i.id}]`,
                                                                            value: i.id
                                                                        }))
                                                                    })
                                                            }
                                                            width="sm"
                                                            name="supplier_customer_id"
                                                            label="Customer"
                                                            rules={[{required: true}]}
                                                            fieldProps={{
                                                                showSearch: true, filterOption: (input, option) =>
                                                                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
                                                            }}
                                                            // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    }
                                                    form.setFieldValue(['third_party_time_costs', index, 'supplier_customer_id'], null)
                                                    form.setFieldValue(['third_party_time_costs', index, 'customer'], null)
                                                    form.setFieldValue(['third_party_time_costs', index, 'external_supplier'], null)
                                                    return (
                                                        <ProFormSelect
                                                            request={() =>
                                                                axiosApiInstance.get<API.ExternalSupplier[]>('/api/suppliers/')
                                                                    .then(res => {
                                                                        // console.log(res.data)
                                                                        return res.data.map(i => ({
                                                                            label: i.name,
                                                                            value: i.id
                                                                        }))
                                                                    })
                                                            }
                                                            width="sm"
                                                            name="supplier_external_id"
                                                            label="External Supplier"
                                                            rules={[{required: true}]}
                                                            // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>

                                            {/*<ProFormText*/}
                                            {/*    name="description"*/}
                                            {/*    label={'Description'}*/}
                                            {/*/>*/}

                                            <ProFormDigit
                                                width={"xs"}
                                                name="gross_margin_percentage"
                                                label={'Gross Margin'}
                                                fieldProps={{
                                                    precision: 0, max: 100, min: 0, step: 1,
                                                    formatter: (value) => `${value}%`,
                                                    parser: (value) => +value!.replace('%', ''),
                                                }}
                                                initialValue={DEFAULT_GROSS_MARGIN_P_3PTC}
                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                            />

                                            <ProFormDependency name={[['cost'], ['gross_margin_percentage']]}>
                                                {({cost, gross_margin_percentage}) => {
                                                    // console.log(person_days_effort, hourly_rate,gross_margin)
                                                    form.setFieldValue(['third_party_time_costs', index, 'price'], (cost || 0) / (1 - ((gross_margin_percentage || 0) / 100)))
                                                    return (
                                                        <ProFormMoney
                                                            customSymbol={'€'}
                                                            name="price"
                                                            readonly
                                                            label={'Price'}
                                                            // help={'= Effort * Rate * 8'}
                                                            tooltip={'= Cost / (1 - Gross Margin)'}
                                                            fieldProps={{
                                                                // value: cost * (1 / (1 - gross_margin)) || 0,
                                                                numberFormatOptions: {currency: 'EUR'}
                                                            }}
                                                            // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>

                                        </ProFormGroup>
                                    );
                                }}
                            </ProFormList>
                        </TabPane>
                        <TabPane tab={`3P Material Costs (${deliverableData?.third_party_material_costs?.length || 0})`}
                                 key="5" forceRender>
                            <ProFormList name="third_party_material_costs"
                                // label={'3rd Party Material Costs'}
                                //          copyIconProps={props.editable ? {} : false}
                                //          deleteIconProps={props.editable ? {} : false}
                                         creatorButtonProps={{creatorButtonText: 'Add cost'}}
                                // actionGuard={{
                                //     beforeRemoveRow: (params) => {
                                //         const costId = form.getFieldValue(['third_party_material_costs', params as number, 'id'])
                                //         if (costId == undefined) return true
                                //         return axiosApiInstance.delete('/api/thirdpartymaterialcosts/execution/' + costId)
                                //             .then(value => {
                                //                 fetchDeliverableData()
                                //                 executionDeliverablesListRef?.current?.reload();
                                //                 return true
                                //             })
                                //     }
                                // }}
                            >
                                {(f, index, action) => {
                                    return (
                                        <ProFormGroup>
                                            <ProFormDigit
                                                name="id"
                                                hidden={true}
                                            />
                                            <ProFormDigit
                                                name="execution_deliverable_id"
                                                initialValue={+routerParams.deliverableId!}
                                                hidden={true}
                                            />

                                            <ProFormText
                                                label=" "
                                                readonly
                                                width={"xs"}
                                                fieldProps={{value: `${index + 1})`, style: {opacity: 0.5}}}
                                            />

                                            <ProFormMoney
                                                name="cost"
                                                label={'Cost'}
                                                customSymbol={'€'}
                                                fieldProps={{
                                                    numberFormatOptions: {currency: 'EUR'}
                                                }}
                                                rules={[{required: true}]}
                                                width={"sm"}
                                                // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                            />

                                            <ProFormSelect
                                                request={() =>
                                                    axiosApiInstance.get<API.SupplierType[]>('/api/suppliers/types')
                                                        .then(res => {
                                                            return res.data.map(i => ({label: i.name, value: i.id}))
                                                        })
                                                }
                                                width={"sm"}
                                                name="supplier_type_id"
                                                label="Supplier Type"
                                                rules={[{required: true}]}
                                                // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                            />

                                            <ProFormDependency name={[['supplier_type_id']]}>
                                                {({supplier_type_id}) => {
                                                    if (supplier_type_id === 1) {
                                                        form.setFieldValue(['third_party_material_costs', index, 'supplier_external_id'], null)
                                                        form.setFieldValue(['third_party_material_costs', index, 'external_supplier'], null)
                                                        form.setFieldValue(['third_party_material_costs', index, 'customer'], null)
                                                        return <ProFormSelect
                                                            request={() =>
                                                                axiosApiInstance.get<API.Customer[]>('/api/customers/')
                                                                    .then(res => {
                                                                        return res.data.map(i => ({
                                                                            label: `${i.name} [${i.id}]`,
                                                                            value: i.id
                                                                        }))
                                                                    })
                                                            }
                                                            width={"sm"}
                                                            name="supplier_customer_id"
                                                            label="Customer"
                                                            rules={[{required: true}]}
                                                            fieldProps={{
                                                                showSearch: true, filterOption: (input, option) =>
                                                                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
                                                            }}
                                                            // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    }
                                                    form.setFieldValue(['third_party_material_costs', index, 'supplier_customer_id'], null)
                                                    form.setFieldValue(['third_party_material_costs', index, 'customer'], null)
                                                    form.setFieldValue(['third_party_material_costs', index, 'external_supplier'], null)
                                                    return (
                                                        <ProFormSelect
                                                            request={() =>
                                                                axiosApiInstance.get<API.ExternalSupplier[]>('/api/suppliers/')
                                                                    .then(res => {
                                                                        // console.log(res.data)
                                                                        return res.data.map(i => ({
                                                                            label: i.name,
                                                                            value: i.id
                                                                        }))
                                                                    })
                                                            }
                                                            width={"sm"}
                                                            name="supplier_external_id"
                                                            label="External Supplier"
                                                            rules={[{required: true}]}
                                                            // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>

                                            {/*<ProFormText*/}
                                            {/*    name="description"*/}
                                            {/*    label={'Description'}*/}
                                            {/*/>*/}

                                            <ProFormDigit
                                                width={"xs"}
                                                name="gross_margin_percentage"
                                                label={'Gross Margin'}
                                                fieldProps={{
                                                    precision: 0, max: 100, min: 0, step: 1,
                                                    formatter: (value) => `${value}%`,
                                                    parser: (value) => +value!.replace('%', ''),
                                                }}
                                                initialValue={DEFAULT_GROSS_MARGIN_P_3PMC}
                                                // formItemProps={{wrapperCol: {style: {minWidth: FORM_ITEM_WIDTH_XS}}}}
                                            />

                                            <ProFormDependency name={[['cost'], ['gross_margin_percentage']]}>
                                                {({cost, gross_margin_percentage}) => {
                                                    console.log(gross_margin_percentage)
                                                    // console.log(person_days_effort, hourly_rate,gross_margin_percentage)
                                                    form.setFieldValue(['third_party_material_costs', index, 'price'], (cost || 0) / (1 - ((gross_margin_percentage || 0) / 100)))
                                                    return (
                                                        <ProFormMoney
                                                            customSymbol={'€'}
                                                            name="price"
                                                            readonly
                                                            label={'Price'}
                                                            // help={'= Effort * Rate * 8'}
                                                            tooltip={'= Cost / (1 - Gross Margin)'}
                                                            fieldProps={{
                                                                // value: cost * (1 / (1 - gross_margin)) || 0,
                                                                numberFormatOptions: {currency: 'EUR'}
                                                            }}
                                                            // formItemProps={{wrapperCol: {style: {width: FORM_ITEM_WIDTH_SM}}}}
                                                        />
                                                    );
                                                }}
                                            </ProFormDependency>
                                        </ProFormGroup>
                                    );
                                }}
                            </ProFormList>
                        </TabPane>
                    </Tabs>


                </>
            }
        </ModalForm>
    );
};

export default NewExecutionDeliverable;
import { Dayjs } from 'dayjs';
import axiosApiInstance from '../../api/axiosClient';

type Opportunities = API.Opportunity[];

/**
 * Η παρακάτω συνάρτηση χρησιμοποιείται για να φέρει τον πίνακα opportunities απο το backend, είτε την παρούσα μορφή του
 * είτε versioned. Εαν δοθεί timestamp ως παράμετρος της συνάρτησης τοτε φέρνει τον versioned πινακα, αλλιώς φέρνει τα
 * παροντικά opportunities
 * 
 * @param timestamp if null, get current opportunities
 * @returns versioned opportunity list
 */
const getOpportunityData = async (timestamp?: Dayjs) => {
    const endpoint =
        '/api/sales/opportunities/' +
        (timestamp ? `versioned/${timestamp.format('YYYY-MM-DDT23:59:59')}` : '');

    const data = await axiosApiInstance
        .get<Opportunities>(endpoint)
        .then(({ data }) => data)
        .catch((reason) => {
            throw reason;
        });
    return data;
};

export default getOpportunityData;

import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, Card, Row, Skeleton, Space, Statistic, Typography} from "antd";
import {ProColumns, ProFormInstance, ProFormSelect} from "@ant-design/pro-components";
import {EditOutlined, EyeOutlined, FileExcelOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
// import moment from "moment";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import {GlobalStateContext} from "../../context/GlobalContext";
import currencyFormatter from "../../util/currency_formatter";
import XLSX from "sheetjs-style-v2";
import {useAtom, useAtomValue} from "jotai/index";
import {fetchParamsAtom} from "../../store/invoices_out";
import {combinedCustomersOptionsAtom, projectsOnExecutionUnfilteredAtom} from "../../store/projects";
import {ProTable} from "@ant-design/pro-table";
import axiosApiInstance from "../../api/axiosClient";
import {RESET} from "jotai/utils";

dayjs.extend(utc)

type Props = {};
const InvoicesOutgoingGlobalTable = (props: Props) => {
    // const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    // const [loading, setLoading] = useState<boolean>(false);
    const routerParams = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {invoicesOutgoingGlobalListRef, setTaxCategories} = useContext(GlobalStateContext);
    const [data, setData] = useState<API.LoggedInvoiceOutgoing[] | undefined>(undefined);
    const [invoicedTotal, setInvoicedTotal] = useState<number>(0);
    const [paidTotal, setPaidTotal] = useState<number>(0);
    const [vatTotal, setVatTotal] = useState<number>(0);
    const {data: projectsData} = useAtomValue(projectsOnExecutionUnfilteredAtom)
    // const {data: taxCategoriesData} = useAtomValue(invoiceTaxCategoriesAtom)
    const combinedCustomersOptions = useAtomValue(combinedCustomersOptionsAtom)

    useEffect(() => {
        let invoicedTotal = 0
        let paidTotal = 0
        let vatTotal = 0
        data?.map((d) => {
            invoicedTotal += d.cost
            paidTotal += d.paid_amount
            vatTotal += d.cost_incl_vat - d.cost
        })

        setInvoicedTotal(invoicedTotal)
        setPaidTotal(paidTotal)
        setVatTotal(vatTotal)
    }, [data]);

    // based on the columns of the table export the data to excel
    const buildAndDownloadXLSX = () => {
        // console.log('buildAndDownloadXLSX', invoicesOutgoingGlobalListRef?.current.)
        let title: string = 'Invoices_Outgoing_' + dayjs().format('YYYYMMDD') + '.xlsx'
        let sheetData = data!.map((item, index) => {
            return {
                'Invoice ID': item.id,
                'Invoice Number': item.invoice_number,
                'Case ID': item.project_pretty_id,
                'Date Issued': dayjs.utc(item.date).format('YYYY-MM-DD'),
                'Cost': item.cost,
                'Cost Incl. VAT': item.cost_incl_vat,
                'Paid': item.paid,
                'Paid Amount': item.paid_amount,
            }
        })

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetData, {dateNF: 'dd/mm/yyyy', cellDates: true});
        const wb: XLSX.WorkBook = {Sheets: {Report: ws}, SheetNames: ['Report']};
        XLSX.writeFile(wb, title, {
            bookType: 'xlsx',
            type: 'array'
        });
    };

    const columns: ProColumns<API.LoggedInvoiceOutgoing>[] = [
        {
            title: 'Invoice ID',
            dataIndex: 'id',
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            sorter: (a, b) => (a.invoice_number ?? '').localeCompare(b.invoice_number ?? ''),
            formItemProps: {label: 'Invoice No'},
        },
        {
            title: 'Case',
            dataIndex: 'project_pretty_id',
            sorter: (a, b) => (a.project_pretty_id ?? '').localeCompare(b.project_pretty_id ?? ''),
            valueType: 'select',
            fieldProps: {
                options: projectsData?.map(value => ({
                    label: `${value.title} (${value.project_pretty_id})`,
                    value: value.project_pretty_id
                })),
                multiple: true,
            },
            renderFormItem: (item, config) => {
                return <ProFormSelect
                    {...config}
                    fieldProps={{
                        showSearch: true, filterOption: (input, option) =>
                            (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                        popupMatchSelectWidth: true,
                        // mode: "multiple",
                        mode: "tags",
                        maxTagCount: "responsive",
                    }}
                    wrapperCol={{span: 24}}
                />
            }
        },
        {
            title: 'Date Issued',
            dataIndex: 'date',
            valueType: 'dateRange',
            render: (text, record, index) => {
                return dayjs.utc(record.date).format('YYYY-MM-DD')
            },
            sorter: (a, b) => dayjs.utc(a.date).unix() - dayjs.utc(b.date).unix(),
            // fieldProps: {
            //     format: 'YYYY-MM-DD',
            // },
            search: {
                transform: (value) => {
                    return {
                        date_issued_start: value[0],
                        date_issued_end: value[1]
                    }
                }
            }
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            render: (text, record, index) => {
                return currencyFormatter.format(record.cost)
            },
            sorter: (a, b) => a.cost - b.cost,
            search: false,
        },
        {
            title: 'Cost Incl. VAT',
            dataIndex: 'cost_incl_vat',
            render: (text, record, index) => {
                return currencyFormatter.format(record.cost_incl_vat)
            },
            sorter: (a, b) => a.cost_incl_vat - b.cost_incl_vat,
            search: false,
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            valueType: 'select',
            valueEnum: {
                YES: {text: 'Yes'},
                NO: {text: 'No'},
                PARTIALLY: {text: 'Partially'},
            },
            sorter: (a, b) => (a.paid ?? '').localeCompare(b.paid ?? ''),
            fieldProps: {
                label: 'Paid Status',
                allowClear: true,
            }
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amount',
            render: (text, record, index) => {
                return currencyFormatter.format(record.paid_amount)
            },
            sorter: (a, b) => a.paid_amount - b.paid_amount,
            search: false,
        },
        {
            // title: 'Actions',
            valueType: 'option',
            width: 80,
            fixed: 'right',
            render: (text, record, _, action) => [
                <Link to={`${record.id}`}> <Button type="link" icon={<EyeOutlined/>}>View</Button> </Link>,
                <Link to={`${record.id}/edit`}> <EditOutlined key="edit"/> </Link>,
            ],
        },
        {
            title: 'Customer', dataIndex: 'customer', valueType: 'text',
            // hideInSearch: true,
            render: (_, record) => (
                <Typography.Text>
                    {record.customer_id != null ? record.customer.name : record.external_buyer_id != null ?
                        record.external_buyer.name : record.accounting_customer.name}
                </Typography.Text>
            ),
            // +Unified customer search implementation
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <ProFormSelect
                        placeholder="Select customer"
                        options={combinedCustomersOptions}
                        width={"lg"}
                        wrapperCol={{span: 24}}
                        mode={'multiple'}
                        showSearch
                    />
                )
            },
            // +Unified customer search implementation
            search: {
                transform: (value) => {
                    let parsedVals = value.map((v: string) => JSON.parse(v))
                    return {
                        customer_id: parsedVals.filter((v: any) => v.buyer_type_id === 1).map((v: any) => v.customer_id),
                        accounting_customer_id: parsedVals.filter((v: any) => v.buyer_type_id === 2).map((v: any) => v.accounting_customer_id),
                        external_buyer_id: parsedVals.filter((v: any) => v.buyer_type_id === 3).map((v: any) => v.external_buyer_id),
                    }
                }
            }
        },
    ];

    const [fetchParams, setFetchParams] = useAtom(fetchParamsAtom);
    const form = useRef<ProFormInstance>();

    useEffect(() => {
        // if fetchParams exist, set the form values
        if (Object.keys(fetchParams).length > 0) {
            let params = JSON.parse(JSON.stringify(fetchParams))
            // console.log('fetchParams', fetchParams)
            form.current?.setFieldsValue(fetchParams)
            // console.log('form.current?.getFieldsValue()', form.current?.getFieldsValue())

            let date_obj: any = {}
            if (params.date_issued_start) {
                date_obj['date_issued_start'] = dayjs.utc(params.date_issued_start)
            }
            if (params.date_issued_end) {
                date_obj['date_issued_end'] = dayjs.utc(params.date_issued_end)
            }

            form.current?.setFieldsValue({date: [date_obj.date_issued_start, date_obj.date_issued_end]})

            // +Unified customer search implementation
            let customers_search_obj: any = {}
            if (params.customer_id) {
                customers_search_obj['customer_id'] = params.customer_id?.map((id: number) => JSON.stringify({
                    buyer_type_id: 1,
                    customer_id: id
                }))
            }
            if (params.accounting_customer_id) {
                customers_search_obj['accounting_customer_id'] = params.accounting_customer_id?.map((id: number) => JSON.stringify({
                    buyer_type_id: 2,
                    accounting_customer_id: id
                }))
            }
            if (params.external_buyer_id) {
                customers_search_obj['external_buyer_id'] = params.external_buyer_id?.map((id: number) => JSON.stringify({
                    buyer_type_id: 3,
                    external_buyer_id: id
                }))
            }
            let arr = []
            if (customers_search_obj.customer_id) {
                arr.push(...customers_search_obj.customer_id)
            }
            if (customers_search_obj.accounting_customer_id) {
                arr.push(...customers_search_obj.accounting_customer_id)
            }
            if (customers_search_obj.external_buyer_id) {
                arr.push(...customers_search_obj.external_buyer_id)
            }
            form.current?.setFieldsValue({customer: arr})

        }
    }, [fetchParams]);

    useEffect(() => {
        // console.log({fetchParams})
        return () => {
            setFetchParams(RESET)
        }
    }, []);

    return (
        <>
            <Row style={{marginTop: 4, marginBottom: 16}}>
                {data === undefined ? <Skeleton.Button active block/> :
                    <Space>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Amount Invoiced"
                                value={currencyFormatter.format(invoicedTotal)}
                                precision={2}
                                valueStyle={{fontSize: 16}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Amount VAT"
                                value={currencyFormatter.format(vatTotal)}
                                precision={2}
                                valueStyle={{fontSize: 16}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Amount Paid"
                                value={currencyFormatter.format(paidTotal)}
                                precision={2}
                                valueStyle={{fontSize: 16}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Amount to be Paid"
                                value={currencyFormatter.format(invoicedTotal + vatTotal - paidTotal)}
                                precision={2}
                                valueStyle={{fontSize: 16}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Account Receivables"
                                value={currencyFormatter.format(invoicedTotal)}
                                precision={2}
                                valueStyle={{color: '#3f8600', fontSize: 16}}
                            />
                        </Card>
                    </Space>
                }
            </Row>

            <ProTable
                columns={columns}
                request={params => {
                    // console.log(params)
                    return axiosApiInstance.get<API.LoggedInvoiceOutgoing[]>(`/api/invoices/outgoing/`, {params: params})
                }}
                onSubmit={async (params) => {
                    console.log({params})
                    setFetchParams(params)
                }}
                onReset={() => {
                    setFetchParams({})
                }}
                actionRef={invoicesOutgoingGlobalListRef}
                formRef={form}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false,
                    setting: false,
                    search: false,
                    // search: {
                    //     placeholder: "Search...",
                    //     onChange: (e) => {
                    //         setFilterSearch(e.target.value)
                    //     },
                    //     value: filterSearch,
                    //     allowClear: true,
                    //     enterButton: <SearchOutlined/>
                    // }
                }}
                search={{
                    searchText: 'Search',
                    defaultCollapsed: false,
                    defaultColsNumber: 3,
                }}
                pagination={{
                    pageSize: 10,
                }}
                // onRow={(record) => {
                //     return {
                //         onClick: () => {
                //             navigate(`${record.id}`)
                //         },
                //     };
                // }}
                toolBarRender={() => [
                    <Link to="new" state={{background: location}}>
                        <Button
                            icon={<PlusOutlined/>}
                            key="new"
                            type="primary"
                        >
                            New
                        </Button>
                    </Link>,
                    <Button
                        size={'large'}
                        type={'dashed'}
                        icon={<FileExcelOutlined style={{color: '#575757'}}/>}
                        onClick={() => {
                            buildAndDownloadXLSX();
                        }}
                        disabled={data === undefined}
                    />,
                    <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                        invoicesOutgoingGlobalListRef?.current?.reload()
                    }}/>
                ]}
                params={fetchParams}
                onDataSourceChange={dataSource => setData(dataSource)}
                // onRequestError={e => console.log(e)}
            />

            <Outlet/>


        </>
    );
};

export default InvoicesOutgoingGlobalTable;
import axiosApiInstance from '../../api/axiosClient';
import axios, { AxiosResponse } from 'axios';
import { notification } from 'antd';

const updateOpportunity = async (values: Partial<API.Opportunity>) => {
    return axiosApiInstance
        .put<API.Opportunity>(`/api/sales/opportunities/${values.id}`, values)
        .then(() => true)
        .catch((e) => false);
};

export default updateOpportunity;

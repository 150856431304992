import React, {useEffect, useState} from 'react';
import {ProCard} from '@ant-design/pro-components';
import {useSalesContext} from '../../context/SalesContextProvider';
import {Skeleton, Statistic, Typography} from 'antd';
import currencyFormatter from "../../util/currency_formatter";

const StatusCard = () => {
    const {opportunities, isLoading} = useSalesContext();
    const [loadedOpps, setLoadedOpps] = useState<API.Opportunity[]>([]);

    useEffect(() => {
        // setLoadedOpps(opportunities.filter(o => !o.is_compound));
        console.log('opportunities', opportunities);
    }, [opportunities]);

    // useEffect(() => {
    //     console.log('loadedOpps', loadedOpps);
    //     // console.log(loadedOpps.reduce((acc, current) => acc + current.proposed_value_in_euro!, 0))
    // }, [loadedOpps]);



    return (
        <ProCard.Group
            // title='Opportunity Statistics'
            loading={isLoading && <Skeleton active style={{margin: 16}} paragraph={{rows: 0}}/>}>
            {/*<ProCard>*/}
            {/*    <Statistic title='Total' value={loadedOpps.length}/>*/}
            {/*</ProCard>*/}
            <ProCard>
                <Statistic title='Total Compound' value={opportunities.filter(o => o.is_compound).length}/>
                <Typography.Text type='secondary'> Total Children: {opportunities.filter(o => o.parent_id).length}</Typography.Text>
            </ProCard>
            <ProCard>
                <Statistic title='Total Simple' value={opportunities.filter(o => !o.is_compound && !o.parent_id).length}/>
            </ProCard>
            <ProCard>
                <Statistic
                    title='Total Won'
                    value={
                        opportunities.filter(o => !o.is_compound).filter((opp) => opp.opportunity_phase.completion_percentage === 1).length
                    }
                />
            </ProCard>
            <ProCard>
                <Statistic
                    title='Total Lost or Dropped'
                    value={opportunities.filter(o => !o.is_compound).filter((opp) => opp.opportunity_phase.sales_step_number === 0).length}
                />
            </ProCard>
            <ProCard.Divider type='vertical'/>
            <ProCard>
                <Statistic
                    title='Total Unweighted'
                    // suffix='€'
                    value={currencyFormatter.format(opportunities.filter(o => !o.is_compound).reduce((acc, current) => acc + current.proposed_value_in_euro!, 0))}
                    // value={loadedOpps.reduce((acc, current) => acc + current.proposed_value_in_euro!, 0)}

                />
            </ProCard>
            <ProCard>
                <Statistic
                    title='Total Weighted'
                    // suffix='€'
                    value={
                        currencyFormatter.format(opportunities.filter(o => !o.is_compound).reduce((acc, current) => acc + current.po_value_in_euro!, 0))}
                />
            </ProCard>
        </ProCard.Group>
    );
};

export default StatusCard;

import React, {Suspense, useState} from 'react';
import './App.css';
import {MyLayout} from './pages/MyLayout';
import {Navigate, Route, Routes, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import NoFoundPage from './pages/404';
import Homepage from './pages/Homepage';
import SalesPage from './pages/SalesPage';
import {UserLogin} from './pages/UserLogin';
import PrivateRoutes from './components/PrivateRoutes';
import ProjectsPage from './pages/projects/ProjectsPage';
import ProjectPage from './pages/projects/ProjectPage';
import NewProject from './components/projects/NewProject';
import SalesContextProvider from './context/SalesContextProvider';
import RedirectProjectIndexToProjectInfo from './components/wbs/RedirectProjectIndexToProjectInfo';
import ProposedWBSVersions from './components/projects/ProposedWBSVersions';
import ProjectGeneralInfo from './components/projects/ProjectGeneralInfo';
import ExecutionWBSVersions from './components/projects/ExecutionWBSVersions';
import ProposedWBSVersion from './pages/wbs/ProposedWBSVersion';
import NewProposedDeliverable from './components/wbs/proposals/NewProposedDeliverable';
import RedirectDeliverablesToProposal from './components/wbs/proposals/RedirectDeliverablesToProposal';
import ProposedDeliverable from './components/wbs/proposals/ProposedDeliverable';
import ExecutionWBSVersion from './pages/wbs/ExecutionWBSVersion';
import NewExecutionDeliverable from './components/wbs/executions/NewExecutionDeliverable';
import ExecutionDeliverable from './components/wbs/executions/ExecutionDeliverable';
import RedirectDeliverablesToExecution from './components/wbs/executions/RedirectDeliverablesToExecution';
import ExecutionProgress from './components/projects/ExecutionProgress';
import TimeTracker from './components/tracking/TimeTracker';
import MaterialsUtilization from './components/tracking/MaterialsUtilization';
import AdvanceOpportunityModal from './components/sales/AdvanceOpportunityModal';
import {ConfigProvider, Skeleton, theme} from 'antd';
import enUS from 'antd/es/locale/en_US';
import InvoicesIncoming from './components/tracking/invoices/incoming/InvoicesIncoming';
import NewInvoiceIncoming from './components/tracking/invoices/incoming/NewInvoiceIncoming';
import InvoiceIncoming from './components/tracking/invoices/incoming/InvoiceIncoming';
import RedirectProjectInvoicesToIncoming from './components/wbs/RedirectProjectInvoicesToIncoming';
import InvoicesOutgoing from './components/tracking/invoices/outgoing/InvoicesOutgoing';
import NewInvoiceOutgoing from './components/tracking/invoices/outgoing/NewInvoiceOutgoing';
import InvoiceOutgoing from './components/tracking/invoices/outgoing/InvoiceOutgoing';
import TimeTrackerGlobal from './pages/tracking/TimeTrackerGlobal';
import MaterialsUtilizationGlobal from './pages/tracking/MaterialsUtilizationGlobal';
import NewPlannedInvoice from './components/wbs/executions/NewPlannedInvoice';
import ProjectDocuments from './components/projects/ProjectDocuments';
import ProposedDeliverableTemplates from './components/wbs/proposals/ProposedDeliverableTemplates';
import LoggedPayments from './components/payments/LoggedPayments';
import LoggedPayment from './components/payments/LoggedPayment';
import NewLoggedPayment from './components/payments/NewLoggedPayment';
import SuggestedTransactions from './components/accounting/SuggestedTransactions';
import RedirectProjectAccountingToSuggested from './components/wbs/RedirectProjectAccountingToSuggested';
import CommittedTransactions from './components/accounting/CommittedTransactions';
import LinkWithProjectModal from './components/sales/LinkWithProjectModal';
import RedirectToSalesList from './components/sales/RedirectToSalesList';
import NewOpportunityForm from './components/sales/NewOpportunityForm';
import InvoicesGlobal from './pages/tracking/InvoicesGlobal';
import NewInvoiceIncomingGlobal from './components/tracking/invoices/incoming/NewInvoiceIncomingGlobal';
import InvoiceIncomingGlobal from './components/tracking/invoices/incoming/InvoiceIncomingGlobal';
import NewInvoiceOutgoingGlobal from './components/tracking/invoices/outgoing/NewInvoiceOutgoingGlobal';
import InvoiceOutgoingGlobal from './components/tracking/invoices/outgoing/InvoiceOutgoingGlobal';
import DeleteOpportunityModal from "./components/sales/DeleteOpportunityModal";
import ViewCaseByPrettyId from "./components/projects/ViewCaseByPrettyId";
import OpportunityInfoModal from "./components/sales/OpportunityInfoModal";
import SaleInfoPage from "./pages/SaleInfoPage";
import EditOpportunityForm from "./components/sales/EditOpportunityForm";
import ConvertOpportunityToCompoundModal from "./components/sales/ConvertOpportunityToCompoundModal";
import ChangeCustomerOfOpportunityModal from "./components/sales/ChangeCustomerOfOpportunityModal";
import {QueryClientProvider,} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {queryClientAtom} from "jotai-tanstack-query";
import {useAtomValue} from "jotai";
import InvoicesIncomingGlobalTable from "./pages/tracking/InvoicesIncomingGlobalTable";
import InvoicesOutgoingGlobalTable from "./pages/tracking/InvoicesOutgoingGlobalTable";
import ReportingPage from "./pages/ReportingPage";

function App() {
    const location = useLocation();
    const background = location.state && location.state.background;
    const [currentTheme, setCurrentTheme] = useState<'light' | 'dark'>('light');
    // console.log(background)
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useAtomValue(queryClientAtom);
    queryClient.setDefaultOptions({
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 10 * 60 * 1000
        }
    })

    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider
                theme={{
                    algorithm: currentTheme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm
                    // algorithm: theme.darkAlgorithm
                }}
                locale={enUS}>
                <div className='App'>
                    <Routes location={background || location}>
                        <Route element={<PrivateRoutes/>}>
                            <Route element={<MyLayout/>}>
                                <Route path='/' element={<Homepage/>}/>
                                {/*<Route index element={<Home />} />*/}
                                <Route path='home' element={<Homepage/>}/>
                                {/*<SalesContextProvider>*/}
                                <Route path='sales'>
                                    <Route index element={<RedirectToSalesList/>}/>
                                    <Route
                                        path='list'
                                        element={
                                            <SalesContextProvider>
                                                <SalesPage card='list'/>
                                            </SalesContextProvider>
                                        }>
                                        <Route path=':id' element={<OpportunityInfoModal/>}/>
                                        <Route path=':id/edit' element={<EditOpportunityForm/>}/>
                                        {/*<Route path='compound/:id' element={<OpportunityInfoModal/>}/>*/}
                                        <Route path='advance/:id' element={<AdvanceOpportunityModal/>}/>
                                        <Route path='delete/:id' element={<DeleteOpportunityModal/>}/>
                                        <Route path='to_compound/:id' element={<ConvertOpportunityToCompoundModal/>}/>
                                        <Route path='change_customer/:id'
                                               element={<ChangeCustomerOfOpportunityModal/>}/>
                                        <Route path='link/:id' element={<LinkWithProjectModal/>}/>
                                        <Route path='new' element={
                                            // <SalesContextProvider>
                                            <NewOpportunityForm/>
                                            // </SalesContextProvider>
                                        }/>
                                    </Route>
                                    <Route path='advance/:id' element={<AdvanceOpportunityModal/>}/>
                                    <Route path='delete/:id' element={<DeleteOpportunityModal/>}/>
                                    <Route path='to_compound/:id' element={<ConvertOpportunityToCompoundModal/>}/>
                                    <Route path='change_customer/:id' element={<ChangeCustomerOfOpportunityModal/>}/>
                                    <Route
                                        path='funnel'
                                        element={
                                            <SalesContextProvider>
                                                <SalesPage card='funnel'/>
                                            </SalesContextProvider>
                                        }
                                    />
                                    <Route
                                        path='summary'
                                        element={
                                            <SalesContextProvider>
                                                <SalesPage card='summary'/>
                                            </SalesContextProvider>
                                        }
                                    />
                                    <Route
                                        path='validation'
                                        element={
                                            <SalesContextProvider>
                                                <SalesPage card='validation'/>
                                            </SalesContextProvider>
                                        }
                                    />
                                    <Route
                                        path='progress'
                                        element={
                                            <SalesContextProvider>
                                                <SalesPage card='progress'/>
                                            </SalesContextProvider>
                                        }
                                    />

                                    <Route
                                        path=':id'
                                        element={
                                            <SalesContextProvider>
                                                <SaleInfoPage/>
                                                {/*<OpportunityInfoModal/>*/}
                                            </SalesContextProvider>
                                        }
                                    />
                                    <Route
                                        path=':id/edit'
                                        element={
                                            <SalesContextProvider>
                                                <EditOpportunityForm/>
                                                {/*<OpportunityInfoModal/>*/}
                                            </SalesContextProvider>
                                        }
                                    />

                                    {/* <Route path='sub-page1' element={<DummyPage />} /> */}
                                    {/*<Route path="sub-page2" element={<TableTest/>}/>*/}
                                    {/* <Route path='sub-page2' element={<DummyPage />} /> */}
                                </Route>
                                {/*</SalesContextProvider>*/}

                                <Route path='projects'>
                                    {/*<Route index element={*/}
                                    {/*    <Access accessible={false} fallback={<></>}>*/}
                                    {/*        <TableTest/>*/}
                                    {/*    </Access>*/}
                                    {/*}/>*/}
                                    <Route index element={<ProjectsPage/>}/>
                                    <Route path='new' element={<NewProject/>}/>
                                    {/*<Route path=':projectId' element={<ProjectPage/>}/>*/}
                                    <Route path=':projectId' element={<ProjectPage/>}>
                                        {/*<Route index element={<ProjectPage/>}/>*/}

                                        <Route index element={<RedirectProjectIndexToProjectInfo/>}/>
                                        <Route path='info' element={<ProjectGeneralInfo/>}/>
                                        <Route path='proposals' element={<ProposedWBSVersions/>}/>
                                        <Route path='executions' element={<ExecutionWBSVersions/>}/>
                                        <Route path='progress' element={<ExecutionProgress/>}/>
                                        <Route path='tracker' element={<TimeTracker/>}/>
                                        <Route path='materials' element={<MaterialsUtilization/>}/>
                                        {/*<Route path="invoices" element={<Invoices/>}>*/}
                                        {/*    <Route path='new' element={<NewInvoice/>}/>*/}
                                        {/*    <Route path=':invoiceId' element={<Invoice editable={false}/>}/>*/}
                                        {/*    <Route path=':invoiceId/edit' element={<Invoice editable={true}/>}/>*/}
                                        {/*</Route>*/}
                                        <Route path='invoices' element={<RedirectProjectInvoicesToIncoming/>}/>
                                        <Route path='invoices/incoming' element={<InvoicesIncoming/>}>
                                            <Route path='new' element={<NewInvoiceIncoming/>}/>
                                            <Route
                                                path=':invoiceId'
                                                element={<InvoiceIncoming editable={false}/>}
                                            />
                                            <Route
                                                path=':invoiceId/edit'
                                                element={<InvoiceIncoming editable={true}/>}
                                            />
                                        </Route>
                                        <Route path='invoices/outgoing' element={<InvoicesOutgoing/>}>
                                            <Route path='new' element={<NewInvoiceOutgoing/>}/>
                                            <Route
                                                path=':invoiceId'
                                                element={<InvoiceOutgoing editable={false}/>}
                                            />
                                            <Route
                                                path=':invoiceId/edit'
                                                element={<InvoiceOutgoing editable={true}/>}
                                            />
                                        </Route>
                                        <Route path='payments' element={<LoggedPayments/>}>
                                            <Route path='new' element={<NewLoggedPayment/>}/>
                                            <Route
                                                path=':paymentId'
                                                element={<LoggedPayment editable={false}/>}
                                            />
                                            <Route
                                                path=':paymentId/edit'
                                                element={<LoggedPayment editable={true}/>}
                                            />
                                        </Route>
                                        <Route
                                            path='accounting'
                                            element={<RedirectProjectAccountingToSuggested/>}
                                        />
                                        <Route path='accounting/suggested' element={<SuggestedTransactions/>}/>
                                        <Route path='accounting/committed' element={<CommittedTransactions/>}/>
                                        <Route path='documents' element={<ProjectDocuments/>}/>
                                        <Route path='financials' element={<>Placeholder</>}/>
                                    </Route>
                                    {/*<Route path=":projectId/proposals/:proposedWbsVersionId"*/}
                                    {/*       element={<ProposedWBSVersion/>}/>*/}
                                    <Route
                                        path=':projectId/proposals/:proposedWbsVersionId'
                                        element={<ProposedWBSVersion/>}>
                                        <Route path='newdeliverable' element={<NewProposedDeliverable/>}/>
                                        <Route
                                            path='newdeliverable/fromtemplate'
                                            element={<ProposedDeliverableTemplates/>}
                                        />
                                        <Route
                                            path='newdeliverable/fromtemplate/new'
                                            element={<NewProposedDeliverable isNewTemplate={true}/>}
                                        />
                                        <Route
                                            path='newdeliverable/fromtemplate/:templateId'
                                            element={<NewProposedDeliverable/>}
                                        />
                                        <Route
                                            path='newdeliverable/fromtemplate/:templateId/edit'
                                            element={<ProposedDeliverable editable={true}/>}
                                        />
                                        <Route path='deliverables'>
                                            <Route index element={<RedirectDeliverablesToProposal/>}/>
                                            <Route
                                                path=':deliverableId'
                                                element={<ProposedDeliverable editable={false}/>}
                                            />
                                            <Route
                                                path=':deliverableId/edit'
                                                element={<ProposedDeliverable editable={true}/>}
                                            />
                                        </Route>
                                    </Route>

                                    <Route
                                        path=':projectId/executions/:executionWbsVersionId'
                                        element={<ExecutionWBSVersion/>}>
                                        <Route path='newdeliverable' element={<NewExecutionDeliverable/>}/>
                                        <Route path='deliverables'>
                                            <Route index element={<RedirectDeliverablesToExecution/>}/>
                                            <Route
                                                path=':deliverableId'
                                                element={<ExecutionDeliverable editable={false}/>}
                                            />
                                            <Route
                                                path=':deliverableId/edit'
                                                element={<ExecutionDeliverable editable={true}/>}
                                            />
                                        </Route>

                                        <Route path='newinvoice' element={<NewPlannedInvoice/>}/>
                                    </Route>

                                    <Route path='by_pretty_id/:pretty_id' element={<ViewCaseByPrettyId/>}/>
                                </Route>

                                <Route path='reporting' element={<ReportingPage/>}/>
                                <Route path='tracker' element={<TimeTrackerGlobal/>}/>
                                <Route path='materials' element={<MaterialsUtilizationGlobal/>}/>
                                <Route path='invoices' element={<InvoicesGlobal/>}>
                                    <Route index element={<Navigate to={'/invoices/incoming'}/>}/>
                                    {/*<Route path='incoming' element={<InvoicesIncomingGlobal/>}>*/}
                                    <Route path='incoming' element={
                                        <Suspense fallback={<Skeleton.Button active block/>}>
                                            <InvoicesIncomingGlobalTable/>
                                        </Suspense>
                                    }>
                                        <Route path='new' element={<NewInvoiceIncomingGlobal/>}/>
                                        <Route
                                            path=':invoiceId'
                                            element={<InvoiceIncomingGlobal editable={false}/>}
                                        />
                                        <Route
                                            path=':invoiceId/edit'
                                            element={<InvoiceIncomingGlobal editable={true}/>}
                                        />
                                    </Route>
                                    {/*<Route path='outgoing' element={<InvoicesOutgoingGlobal/>}>*/}
                                    <Route path='outgoing' element={
                                        <Suspense fallback={<Skeleton.Button active block/>}>
                                            <InvoicesOutgoingGlobalTable/>
                                        </Suspense>
                                    }>
                                        <Route path='new' element={<NewInvoiceOutgoingGlobal/>}/>
                                        <Route
                                            path=':invoiceId'
                                            element={<InvoiceOutgoingGlobal editable={false}/>}
                                        />
                                        <Route
                                            path=':invoiceId/edit'
                                            element={<InvoiceOutgoingGlobal editable={true}/>}
                                        />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path='/login' element={<UserLogin/>}/>
                        <Route path='*' element={<NoFoundPage/>}/>
                    </Routes>

                    {background && (
                        <Routes>
                            <Route element={<PrivateRoutes/>}>
                                {/*New Deliverable Modal*/}
                                <Route
                                    path='projects/:projectId/proposals/:proposedWbsVersionId/newdeliverable'
                                    element={<NewProposedDeliverable/>}
                                />
                                <Route
                                    path='projects/:projectId/proposals/:proposedWbsVersionId/newdeliverable/fromtemplate'
                                    element={<ProposedDeliverableTemplates/>}
                                />
                                <Route
                                    path='projects/:projectId/proposals/:proposedWbsVersionId/newdeliverable/fromtemplate/new'
                                    element={<NewProposedDeliverable isNewTemplate={true}/>}
                                />
                                <Route
                                    path='projects/:projectId/proposals/:proposedWbsVersionId/newdeliverable/fromtemplate/:templateId'
                                    element={<NewProposedDeliverable/>}
                                />
                                <Route
                                    path='projects/:projectId/proposals/:proposedWbsVersionId/newdeliverable/fromtemplate/:templateId/edit'
                                    element={<ProposedDeliverable editable={true}/>}
                                />

                                <Route
                                    path='projects/:projectId/executions/:executionWbsVersionId/newdeliverable'
                                    element={<NewExecutionDeliverable/>}
                                />
                                {/*View Deliverable Modal*/}
                                <Route
                                    path='projects/:projectId/proposals/:proposedWbsVersionId/deliverables/:deliverableId'
                                    element={<ProposedDeliverable editable={false}/>}
                                />
                                <Route
                                    path='projects/:projectId/proposals/:proposedWbsVersionId/deliverables/:deliverableId/edit'
                                    element={<ProposedDeliverable editable={true}/>}
                                />
                                <Route
                                    path='projects/:projectId/executions/:executionWbsVersionId/deliverables/:deliverableId'
                                    element={<ExecutionDeliverable editable={false}/>}
                                />
                                <Route
                                    path='projects/:projectId/executions/:executionWbsVersionId/deliverables/:deliverableId/edit'
                                    element={<ExecutionDeliverable editable={true}/>}
                                />

                                <Route path='projects/new' element={<NewProject/>}/>

                                {/*New Invoice Modal*/}
                                <Route
                                    path='projects/:projectId/invoices/incoming/new'
                                    element={<NewInvoiceIncoming/>}
                                />
                                <Route
                                    path='projects/:projectId/invoices/outgoing/new'
                                    element={<NewInvoiceOutgoing/>}
                                />
                                <Route path='/invoices/incoming/new' element={<NewInvoiceIncomingGlobal/>}/>
                                <Route path='/invoices/outgoing/new' element={<NewInvoiceOutgoingGlobal/>}/>

                                {/*View Invoice Modal*/}
                                <Route
                                    path='/invoices/incoming/:invoiceId'
                                    element={<InvoiceIncomingGlobal editable={false}/>}
                                />
                                <Route
                                    path='/invoices/incoming/:invoiceId/edit'
                                    element={<InvoiceIncomingGlobal editable={true}/>}
                                />
                                <Route
                                    path='/invoices/outgoing/:invoiceId'
                                    element={<InvoiceOutgoingGlobal editable={false}/>}
                                />
                                <Route
                                    path='/invoices/outgoing/:invoiceId/edit'
                                    element={<InvoiceOutgoingGlobal editable={true}/>}
                                />
                                {/*New Planned Invoice Modal*/}
                                <Route
                                    path='projects/:projectId/executions/:executionWbsVersionId/newinvoice'
                                    element={<NewPlannedInvoice/>}
                                />

                                {/*New Payment Modal*/}
                                <Route path='projects/:projectId/payments/new' element={<NewLoggedPayment/>}/>
                            </Route>

                            <Route path='sales/list/new' element={<NewOpportunityForm/>}/>
                            <Route path='sales/list/advance/:id' element={<AdvanceOpportunityModal/>}/>
                            <Route path='sales/list/delete/:id' element={<DeleteOpportunityModal/>}/>
                            <Route path='sales/advance/:id' element={<AdvanceOpportunityModal/>}/>
                            <Route path='sales/delete/:id'
                                   element={<DeleteOpportunityModal callback={() => {
                                       // navigate({pathname: "-1", search: "?update-true"}, {relative: 'path'})
                                       // navigate(-1, { search: '?update=true', replace: true });
                                       navigate(-1);
                                       setSearchParams({update: 'true'})
                                   }}/>}/>
                            <Route path='sales/link/:id' element={<LinkWithProjectModal/>}/>
                            {/*<Route path='sales/list/compound/:id' element={<OpportunityInfoModal/>}/>*/}
                            <Route path='sales/list/:id' element={<OpportunityInfoModal/>}/>
                            <Route path='sales/list/:id/edit' element={<EditOpportunityForm/>}/>
                            <Route path='sales/:id/edit' element={
                                <SalesContextProvider>
                                    <EditOpportunityForm/>
                                </SalesContextProvider>
                                // <EditOpportunityForm/>
                            }/>

                        </Routes>
                    )}
                </div>
            </ConfigProvider>
            <ReactQueryDevtools/>
        </QueryClientProvider>
    );
}

export default App;

import {Button, Space, Tabs, Tooltip, Typography} from "antd";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import axiosApiInstance from "../../api/axiosClient";
import {
    CloseCircleFilled,
    DeleteOutlined,
    DollarCircleOutlined,
    DollarOutlined,
    InfoCircleFilled,
    ReloadOutlined,MailOutlined,
    SyncOutlined,
    WarningFilled
} from "@ant-design/icons";
import {ProList} from "@ant-design/pro-components";
import {blue, gold, red} from '@ant-design/colors';
import {GlobalStateContext} from "../../context/GlobalContext";
import {useNavigate} from "react-router-dom";
import './NotificationsDropdownMenu.css'
import getStorageValue from "../../util/get_local_storage_value";

const TabPane = Tabs.TabPane;

const CATEGORY_ICON_MAP: any = {
    upcoming_invoices: DollarOutlined,
    neglected_invoices: DollarCircleOutlined
}

const TYPE_COLOR_MAP: any = {
    info: blue[5],
    warning: gold[5],
    error: red[5]
}

const TYPE_ICON_MAP: any = {
    info: InfoCircleFilled,
    // warning: ExclamationCircleFilled,
    warning: WarningFilled,
    error: CloseCircleFilled
}


export const NotificationsDropdownMenu = () => {
    const [activeTabKey, setActiveTabKey] = useState("1");
    const {notificationsListRef, notifications, setNotifications} = useContext(GlobalStateContext)
    const [notificationsInfo, setNotificationsInfo] = useState<API.NotificationVolatile[] | undefined>(undefined)
    const [notificationsWarning, setNotificationsWarning] = useState<API.NotificationVolatile[] | undefined>(undefined)
    const navigate = useNavigate();

    const tabItemClick = (key: any) => {
        setActiveTabKey(key);
    };


    const markAsRead = (notification: API.NotificationVolatile) => {
        // console.log(notification)
        // console.log(getStorageValue('notifications', []))
        const prevNotifications = getStorageValue('notifications', []);
        if (prevNotifications.includes(notification.message)) {
            return
        }
        localStorage.setItem('notifications',
            JSON.stringify([
                notification.message,
                ...prevNotifications
            ]))
        notificationsListRef?.current?.reload()
    }

    const resetLocalNotificationsAndReload = () => {
        localStorage.setItem('notifications', JSON.stringify([]))
        notificationsListRef?.current?.reload()
    }

    const markAllAsRead = () => {
        // console.log(notification)
        // console.log(getStorageValue('notifications', []))
        const prevNotifications = getStorageValue('notifications', []);
        const messages = notifications?.map(value => value.message) || []
        localStorage.setItem('notifications',
            JSON.stringify([
                ...prevNotifications,
                ...messages,
            ]))
        notificationsListRef?.current?.reload()
    }

    useEffect(() => {
        setNotificationsInfo(
            notifications?.filter((i) => i.type == 'info')
        )
        setNotificationsWarning(
            notifications?.filter((i) => i.type == 'warning')
        )
    }, [notifications])

    return <div className={'NotificationsDropdownMenu'} style={{
        width: 336,
        maxHeight: 508,
        // overflowY: 'auto',
        marginTop: 8,
        backgroundColor: '#ffffff',
        boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        listStyleType: 'none',
        backgroundClip: 'padding-box',
        borderRadius: '4px',
        outline: 'none',
        padding: '0px 4px 4px'
    }}>

        <Tabs centered defaultActiveKey="1" activeKey={activeTabKey} onChange={tabItemClick}
              tabBarStyle={{margin: '0 0 4px 0'}}
              tabBarExtraContent={
                  <Space size={0}>
                      <Tooltip title={'Mark all as read'}>
                          <Button icon={<MailOutlined />} type={"text"}
                                  onClick={() => markAllAsRead()}/>
                      </Tooltip>
                      <Tooltip title={'Sync (restores read notifications)'}>
                          <Button icon={<SyncOutlined/>} type={"text"}
                                  onClick={() => resetLocalNotificationsAndReload()}/>
                      </Tooltip>
                      <Tooltip title={'Refresh'}>
                          <Button icon={<ReloadOutlined/>} type={"text"}
                                  onClick={() => notificationsListRef?.current?.reload()}/>
                      </Tooltip>

                  </Space>
              }>
            <TabPane tab={`All (${notifications?.length || 0})`} key="1"
                     style={{
                         overflowY: 'auto',
                         maxHeight: 458,
                     }}
            >
                <ProList<API.NotificationVolatile>
                    // locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No new notifications'}/>}}
                    locale={{emptyText: 'No new notifications'}}
                    // cardBordered
                    actionRef={notificationsListRef}
                    onDataSourceChange={setNotifications}
                    // tableStyle={{padding:0}}
                    cardProps={{bodyStyle: {paddingInline: 0, paddingBlock: 0}}}
                    // itemCardProps={{bodyStyle: {paddingInline: 0, paddingBlock: 0, padding:0},style:{padding: 0} }}
                    // itemCardProps={{bodyStyle: {paddingInline: 0, paddingBlock: 0,padding:0,margin:0}}}
                    rowKey="message"
                    request={async (params = {}) =>
                        // axiosApiInstance.post<API.Project[]>('/api/projects/', params)
                    {
                        const res = await axiosApiInstance.get<API.NotificationVolatile[]>(`/api/notifications/volatile`)
                        // console.log(res.data || [])
                        // res.data.push(...res.data)
                        // res.data.push(...res.data)
                        // res.data.push(...res.data)
                        const prevNotifications = getStorageValue('notifications', []);
                        let arr = res.data?.filter((i) => !prevNotifications.includes(i.message))

                        return Promise.resolve({
                            data: arr || [],
                            total: arr?.length || 0,
                            success: true,
                        })
                    }
                    }
                    // pagination={{
                    //     pageSize: 5,
                    //     size: 'small',
                    //     showTotal: total => false
                    //     // hideOnSinglePage: true,
                    // }}
                    split={false}
                    metas={{
                        title: {
                            // dataIndex: 'title',
                            render: (_, record) => (
                                // <Badge count={record.count} overflowCount={99} size="small">
                                //     <BellOutlined />
                                // <span style={{marginLeft: 8}}>{record.message}</span>
                                <Typography.Text onClick={() => navigate(record.url)}
                                                 style={{
                                                     fontWeight: "normal",
                                                     fontSize: 13
                                                 }}>{record.message}</Typography.Text>
                                // </Badge>
                            ),
                        },
                        description: {
                            // dataIndex: 'description',
                            render: (_, record) => <Typography.Link type={'secondary'}
                                                                    onClick={() => markAsRead(record)}
                                                                    style={{fontSize: 12}}>Mark as
                                read</Typography.Link>,
                        },
                        avatar: {
                            // dataIndex: 'avatar',
                            search: false,
                            render: (dom, entity) => {
                                // let Icon = CATEGORY_ICON_MAP[entity.category]
                                let Icon = TYPE_ICON_MAP[entity.type]
                                let color = TYPE_COLOR_MAP[entity.type]
                                return <Icon style={{fontSize: 26, color: color, padding: '6px 2px 0 4px'}}/>

                            }
                        }
                    }}
                />
            </TabPane>

            <TabPane tab={`Info (${notificationsInfo?.length || 0})`} key="2"
                     style={{overflowY: 'auto', maxHeight: 458}}
            >
                <ProList<API.NotificationVolatile>
                    locale={{emptyText: 'No new notifications'}}
                    dataSource={notificationsInfo}
                    cardProps={{bodyStyle: {paddingInline: 0, paddingBlock: 0}}}
                    rowKey="message"
                    split={false}
                    metas={{
                        title: {
                            render: (_, record) => (
                                <Typography.Text onClick={() => navigate(record.url)}
                                                 style={{fontWeight: "normal", fontSize: 13}}>
                                    {record.message}
                                </Typography.Text>
                            ),
                        },
                        description: {
                            render: (_, record) => (
                                <Typography.Link type={'secondary'} onClick={() => markAsRead(record)}
                                                 style={{fontSize: 12}}>
                                    Mark as read
                                </Typography.Link>
                            ),
                        },
                        avatar: {
                            search: false,
                            render: (dom, entity) => {
                                // let Icon = CATEGORY_ICON_MAP[entity.category]
                                let Icon = TYPE_ICON_MAP[entity.type]
                                let color = TYPE_COLOR_MAP[entity.type]
                                return <Icon style={{fontSize: 26, color: color, padding: '6px 2px 0 4px'}}/>
                            }
                        }
                    }}
                />
            </TabPane>

            <TabPane tab={`Warning (${notificationsWarning?.length || 0})`} key="3"
                     style={{overflowY: 'auto', maxHeight: 458}}
            >
                <ProList<API.NotificationVolatile>
                    locale={{emptyText: 'No new notifications'}}
                    dataSource={notificationsWarning}
                    cardProps={{bodyStyle: {paddingInline: 0, paddingBlock: 0}}}
                    rowKey="message"
                    split={false}
                    metas={{
                        title: {
                            render: (_, record) => (
                                <Typography.Text onClick={() => navigate(record.url)}
                                                 style={{fontWeight: "normal", fontSize: 13}}>
                                    {record.message}
                                </Typography.Text>
                            ),
                        },
                        description: {
                            render: (_, record) => (
                                <Typography.Link type={'secondary'} onClick={() => markAsRead(record)}
                                                 style={{fontSize: 12}}>
                                    Mark as read
                                </Typography.Link>
                            ),
                        },
                        avatar: {
                            search: false,
                            render: (dom, entity) => {
                                // let Icon = CATEGORY_ICON_MAP[entity.category]
                                let Icon = TYPE_ICON_MAP[entity.type]
                                let color = TYPE_COLOR_MAP[entity.type]
                                return <Icon style={{fontSize: 26, color: color, padding: '6px 2px 0 4px'}}/>
                            }
                        }
                    }}
                />
            </TabPane>

        </Tabs>
    </div>
}

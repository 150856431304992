import * as React from 'react';
import {Suspense, useState} from 'react';
import './ProjectsPage.css'
import {Row, Skeleton, Spin, Tag} from 'antd';
import {PageContainer} from "@ant-design/pro-layout";
import {Access} from "../../components/Access";
import UnauthorizedPage from "../403";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import isBetween from 'dayjs/plugin/isBetween'
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";
import BrandsCashFlowStatus from "../../components/projects/BrandsCashFlowStatus";
import {useAtom} from "jotai";
import {layoutAtom} from "../../store/projects";
import ProjectsList from "../../components/projects/ProjectsList";
import ProjectsTable from "../../components/projects/ProjectsTable";

const {CheckableTag} = Tag;

dayjs.extend(utc)
dayjs.extend(isBetween)

type Layout = 'Grid' | 'List';

// enum


// type Option = { value: string; icon: any };
//
// const segmentedOpts: Array<Option> = [
//     {
//         value: LayoutEnum.Grid,
//         icon: <TableOutlined/>
//     },
//     {
//         value: LayoutEnum.List,
//         icon: <UnorderedListOutlined/>
//     }
// ];


export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    // const [radioValue, setRadioValue] = useState('all-time');
    // const {can} = UserAuth();
    // console.log(can('read', 'customers::data'), can('write', 'customers::data'))
    // const [searchParams, setSearchParams] = useSearchParams();
    const [renderList, setRenderList] = useState<boolean>(true);
    const {customers, externalBuyers, accountingCustomers, buyerTypes} = useSalesAuxDataContext()
    // const [orderBy, setOrderBy] = useState<string>('-expected_po_date');

    // const selectedProjectTagIds = useAtomValue(selectedProjectTagIdsAtom);
    const [layout, setLayout] = useAtom(layoutAtom);
    // const grid = useAtomValue(gridAtom);
    // const [fetchParams, setFetchParams] = useAtom(fetchParamsAtom);
    // const queryClient = useAtomValue(queryClientAtom);
    // const [orderBy, setOrderBy] = useAtom(orderByAtom);
    // const projectsAfterFilterSort = useAtomValue(projectsAfterFilterSortAtom)

    // useEffect(() => {
    //     setOrderBy(searchParams.get('order_by') || '-created_at')
    //     formSort.setFieldsValue({order_by: searchParams.get('order_by') || '-created_at'})
    // }, []);
    // const onCreate = () => {
    //     actionRef.current?.reload()
    // }

    // useEffect(() => {
    //     // console.log('grid', grid);
    //     setRenderList(false);
    //     // setRenderList(true)
    // }, [grid]);
    //
    // useEffect(() => {
    //     if (!renderList)
    //         setRenderList(true)
    // }, [renderList]);


    // useEffect(() => {
    //     console.log('MOUNT')
    // }, [])

    // useEffect(() => {
    //     // console.log('MOUNT')
    //     const entries = Object.fromEntries(searchParams.entries());
    //     formRef.current?.setFieldsValue(entries);
    // }, [])

    // useEffect(() => {
    //     // get the params from url and set them in the form
    //     // console.log('searchParams', searchParams)
    //     if (!projectsData) return
    //     // deserialize params
    //     let params = Object.fromEntries(searchParams.entries())
    //     // console.log(params)
    //     console.log('searchParams', params)
    //     formRef.current?.setFieldsValue(params)
    // }, [projectsData])


    return (
        <Access
            // accessible={can('read', 'customers::data') && can('write', 'customers::data')}
            accessible={true}
            // fallback={<div>Can not read foo content.</div>}
            fallback={<UnauthorizedPage message={"Sorry, you are not authorized to access this content."}/>}
        >

            <PageContainer>
                <Suspense fallback={<Skeleton.Button active={true} block style={{marginBottom: 8}}/>}>
                    <BrandsCashFlowStatus/>
                </Suspense>

                {/*{renderList &&*/}
                {/*    */}
                {/*}*/}
                {/*</ProCard>*/}

                <Suspense fallback={<Skeleton.Button active={true} block style={{marginBottom: 8}}/>}>
                {/*<Suspense fallback={*/}
                {/*    <Row justify={'center'} style={{width: '100%', marginTop: 24}}>*/}
                {/*        <Spin spinning={true} size={"default"}/>*/}
                {/*    </Row>*/}
                {/*}>*/}
                {/*    <ProjectsList/>*/}
                    <ProjectsTable/>
                </Suspense>

                <br/>
                {/*TEST*/}


            </PageContainer>

        </Access>

    );
};



import React, {useEffect, useState} from 'react';
import {ModalForm, ProForm, ProFormDependency, ProFormSelect, ProFormText} from '@ant-design/pro-components';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useSalesContext} from '../../context/SalesContextProvider';
import {InfoCircleOutlined, PlusOutlined, WarningOutlined} from "@ant-design/icons";
import {Button, Divider, Form, message, Space, Typography} from "antd";
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";
import createNewExternalBuyer from "../../util/sales/createNewExternalBuyer";
import changeOpportunityCustomer from "../../util/sales/changeOpportunityCustomer";

type T = API.Opportunity;

type Props = {
    // callback?: () => void;
};

const ChangeCustomerOfOpportunityModal = ({}: Props) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [target, setTarget] = useState<API.Opportunity | undefined>(undefined);
    const {updateOpportunities} = useSalesContext();
    const [form] = Form.useForm();
    const [fromPath, setFromPath] = React.useState<string | undefined>(undefined)
    const location = useLocation();
    const {
        opportunityTypes,
        customers,
        accountingCustomers,
        employees,
        isLoading,
        buyerTypes,
        externalBuyers,
        updateExternal
    } =
        useSalesAuxDataContext();

    useEffect(() => {
        const fromPath = location.state?.from_path
        setFromPath(fromPath)
        // console.log('fromPath', fromPath)
    }, [location])

    const addExternalCustomer = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        const new_external_customer = form.getFieldValue(['new_external_customer'])
        if (!new_external_customer) {
            message.error('Please enter a name for the new customer');
            return;
        }
        form.resetFields(['new_external_customer'])
        await createNewExternalBuyer({name: new_external_customer})
        updateExternal()
        // setExternal(await getExternalOptions())
    };

    return (
        <ModalForm<any>
            title={"Change Customer of Opportunity"}
            open={true}
            form={form}
            width={400}
            modalProps={{
                onCancel: () => navigate(-1), // go back
                // onCancel: () => navigate(`/sales/list`),
                destroyOnClose: true,
                okText: 'Submit',
                cancelText: 'No'
            }}
            // submitter={{submitButtonProps: {danger: true}}}
            onFinish={async (values) => {
                await changeOpportunityCustomer(+id!, values)
                if (fromPath)
                    navigate({
                        pathname: fromPath,
                        search: location.search + (location.search ? `&update=true` : '?update=true')
                    });
                else
                    // navigate(`/sales?update=true`);
                    navigate({
                        pathname: `/sales/list`,
                        search: location.search + (location.search ? `&update=true` : '?update=true')
                    });

            }}>
            <Typography.Text>
                Are you sure you want to change the customer of this opportunity?
            </Typography.Text>
            <br/>
            <Typography.Text>
                <WarningOutlined/> All properties of this opportunity will be delegated to the new customer you will select.
            </Typography.Text>
            <br/>
            <Typography.Text>
                <InfoCircleOutlined/> This will not change the already invoiced customers of the project.
            </Typography.Text>
            <br/>
            <ProForm.Group
                // extra={'Select new Customer'}
            >
                <ProFormSelect
                    // options={buyerTypeOpts}
                    options={buyerTypes.map((b) => ({label: b.name, value: b.id}))}
                    name="buyer_type_id"
                    label="Customer Type"
                    rules={[{required: true}]}
                    width={"sm"}
                    initialValue={1}
                    fieldProps={{
                        loading: isLoading,
                    }}
                />
                <ProFormDependency name={[['buyer_type_id']]}>
                    {({buyer_type_id}) => {
                        if (buyer_type_id === 1) {
                            form.setFieldValue('external_buyer_id', null)
                            form.setFieldValue('external_buyer', null)
                            form.setFieldValue('accounting_customer_id', null)
                            form.setFieldValue('accounting_customer', null)
                            form.setFieldValue('customer', null)
                            return <ProFormSelect
                                // options={registered}
                                options={customers.map((c) => ({
                                    label: `${c.name}: [${c.id}]`,
                                    value: c.id,
                                    employees: c.employees.map((e) => ({
                                        label: e.name,
                                        value: e.id
                                    }))
                                }))}
                                width="sm"
                                name="customer_id"
                                label="Existing Customer (LTD)"
                                rules={[{required: true}]}
                                fieldProps={{
                                    showSearch: true, filterOption: (input, option) =>
                                        (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                                    loading: isLoading,
                                }}
                            />
                        }
                        if (buyer_type_id === 2) {
                            form.setFieldValue('external_buyer_id', null)
                            form.setFieldValue('external_buyer', null)
                            form.setFieldValue('customer', null)
                            form.setFieldValue('customer_id', null)
                            form.setFieldValue('accounting_customer', null)
                            return <ProFormSelect
                                // options={registered}
                                options={accountingCustomers.map((c) => ({
                                    label: `${c.name}: [${c.id}]`,
                                    value: c.id,
                                    employees: c.employees?.map((e) => ({
                                        label: e.name,
                                        value: e.id
                                    }))
                                }))}
                                width="sm"
                                name="accounting_customer_id"
                                label="Existing Customer (IKE)"
                                rules={[{required: true}]}
                                fieldProps={{
                                    showSearch: true, filterOption: (input, option) =>
                                        (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                                    loading: isLoading,
                                }}
                            />
                        }
                        form.setFieldValue('customer_id', null)
                        form.setFieldValue('customer', null)
                        form.setFieldValue('external_buyer', null)
                        // form.setFieldValue('employee_id', null)
                        form.setFieldValue('employee', null)
                        return (
                            <ProFormSelect
                                // options={external}
                                options={externalBuyers.map((b) => ({
                                    label: `${b.name} [${b.id}]`,
                                    value: b.id,
                                    employees: b.employees.map((e) => ({
                                        label: e.name,
                                        value: e.id
                                    }))
                                }))}
                                width="sm"
                                name="external_buyer_id"
                                label="External Customer"
                                rules={[{required: true}]}
                                fieldProps={{
                                    dropdownRender: (menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{margin: '8px 0'}}/>
                                            <Space>
                                                <ProFormText
                                                    placeholder="Please enter"
                                                    name={'new_external_customer'}
                                                    allowClear
                                                    noStyle
                                                />
                                                <Button type="text" icon={<PlusOutlined/>}
                                                        onClick={addExternalCustomer}
                                                >
                                                    Add
                                                </Button>
                                            </Space>
                                        </>
                                    ),
                                    loading: isLoading,
                                }}
                            />
                        );
                    }}
                </ProFormDependency>


            </ProForm.Group>


        </ModalForm>
    );
};

export default ChangeCustomerOfOpportunityModal;

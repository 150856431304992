import axiosApiInstance from '../../api/axiosClient';

type Buyers = API.ExternalBuyer[];

const getExternalBuyers = async () => {
    return axiosApiInstance
        .get<Buyers>('/api/buyers/')
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};

export default getExternalBuyers;

import axiosApiInstance from '../../api/axiosClient';
import axios, { AxiosResponse } from 'axios';
import { notification } from 'antd';

const deleteOpportunity = async (id: number) => {
    return axiosApiInstance
        .delete<API.Opportunity>(`/api/sales/opportunities/${id}`)
        .then(() => true)
        .catch((e) => false);
};

export default deleteOpportunity;

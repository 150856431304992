// const shortenNameWithRegex = (fullName?: string) => {
//     if (!fullName) {
//         return '';
//     }
//     const shortenedName = fullName.replace(/(\w+)\s(\w)\w*$/, '$1 $2.');
//     console.log(fullName, shortenedName)
//     return shortenedName;
// }
//
// export default shortenNameWithRegex;


const shortenNameWithRegex = (fullName?: string) => {
    if (!fullName) {
        return '';
    }
    const nameParts = fullName.split(' ');

    if (nameParts.length < 2) {
        return fullName; // Not enough parts to create a shortened name
    }

    const firstName = nameParts[0];
    let shortenedLastNames = '';

    if (nameParts.length > 2) {
        shortenedLastNames = `${nameParts[1].charAt(0)}. ${nameParts[2].charAt(0)}.`;
    } else if (nameParts.length === 2) {
        shortenedLastNames = `${nameParts[1].charAt(0)}.`;
    }

    return `${firstName} ${shortenedLastNames}`;
}

export default shortenNameWithRegex;
type OppKey = keyof API.Opportunity;

/**
 * Βοηθητική συνάρτηση η οποία δέχεται ως παράμετρο ενα opportunity και ενα Record το οποίο το παράγει το
 * LightFilter component του antdesign. Σκοπός της είναι να περνάει ως παράμετρος στην array.filter συνάρτηση
 * ενός πίνακα opportunities.
 *
 * Το record έχει ενα σύνολο κλειδιών K και στο κάθε ένα αντιστοιχεί ενα σύνολο ακεραίων Ν_κ. Για ένα κλειδί k του K
 * ελέγχεται εαν η αντίστοιχη τιμή k του opportunity ισούται με κάθε n_k του k, και εφαρμόζεται το λογικό OR σε
 * κάθε αποτέλεσμα αυτής της ισότητας. Η διαδικασία αυτή επαναλαμβάνεται για κάθε κλειδί k του K, και σε αυτά τα αποτέσματα
 * η τελική τιμή του φίλτρου καθορίζεται απο το λογικό AND.
 *
 * Ως ένα πραγματικό παράδειγμα, θεωρουμε πως τα φίλτρα που επιλέχθηκαν είναι opportunity_phase = [1] και opportunity_type = [2,3].
 * Στην περίπτωση αυτή, το σύνολο Κ είναι {opportunity_phase, opportunity_type}.
 * Αρχικά η συνάρτηση θα ελέγξει τα κλειδιά που αντιστοιχούν στο opportunity_phase, δηλαδή το 1. Για την τιμή αυτή θα κάνει
 * έλεγχο εάν opportunity.opportunity_phase === 1, και κρατάει το αποτέλεσμα (r1). Μετά θα προχωρήσει στο κλειδι opportunity_type,
 * και για κάθε τιμή του θα κάνει opportunity.opportunity_type === 2 OR opportunity.opportunity_type === 3, κρατάει το αποτέλεσμα (r2).
 * τέλος, θα υπολογίσει την τιμή (r1 AND r2) και θα παράξει το τελικό αποτέλεσμα.
 *
 * Σε φυσική γλώσσα, το παραπάνω παράδειγμα θα είχε την περιγραφή "Το opportunity πρέπει να βρίσκεται σε φάση ένα και να είναι
 * τύπου 2 ή 3". Ουσιαστικά ένα opportunity για να περάσει τον έλεγχο πρέπει για κάθε κατηγορία (κλειδί) το αντίστοιχο της πεδίο
 * να ισούται με τουλάχιστον μία απο τις τιμές που αντιστοιχούν στο κλειδί αυτό.
 *
 * @param opp το opportunity που ελέγχεται
 * @param filters Record<string, number[]>, παράγεται απο το LightFilter του antd
 * @returns true αν το opportunity περνάει απο το φίλτρο
 */
const opportunityFilter = (opp: API.Opportunity, filters: Record<string, number[] | number | boolean>) => {
    const categories = Object.keys(filters); // extract selected categories from filterMap
    // console.log(opp, filters, categories)

    const opportunityPassed = categories.reduce<boolean>((pass, cat) => {
        const currentCategoryTags = filters[cat]; // selected filter values of specific category

        if (typeof currentCategoryTags === 'boolean') return pass && opp[cat as OppKey] === currentCategoryTags; // if a category has only one value, it's array of values becomes a single boolean and it needs checking

        if (typeof currentCategoryTags === 'number') return pass && opp[cat as OppKey] === currentCategoryTags; // if a category has only one value, it's array of values becomes a single number and it needs checking

        if (currentCategoryTags.length === 0) return true; // if a category was cleared, it's array of values becomes an empty array and it needs checking

        return (
            pass && // perform AND operation on every accumulated category value
            currentCategoryTags.reduce<boolean>((acc, value) => {
                return acc || opp[cat as OppKey] === value; // perform OR operation on every value under a given category
            }, false)
        );
    }, true);

    return opportunityPassed;
};

export default opportunityFilter;

import React from 'react';
import {Funnel, FunnelConfig} from '@ant-design/plots';
import {FunnelPlotOutlined} from '@ant-design/icons';
import {useSalesContext} from '../../context/SalesContextProvider';
import {Skeleton} from 'antd';
import {useSalesAuxDataContext} from "../../context/SalesAuxDataContextProvider";

type Props = {
    config: FunnelConfig;
};

const SalesFunnelComponent = ({config}: Props) => {
    const {isLoading} = useSalesContext();
    const {isLoading: isAuxDataLoading} = useSalesAuxDataContext();
    return (
        <Funnel
            loading={isLoading || isAuxDataLoading}
            loadingTemplate={
                <Skeleton.Node>
                    <FunnelPlotOutlined style={{fontSize: 40, color: '#bfbfbf'}}/>
                </Skeleton.Node>
            }
            legend={{
                position: 'right'
            }}
            {...config}
            padding={[0, 100, 0, 100]}
            appendPadding={[0, 250, 0, 0]}
            // dynamicHeight={true}
            shape={'funnel'}
            width={500}
        />
    );
};

export default SalesFunnelComponent;

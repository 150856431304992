import * as React from 'react';
import {Alert, Card, Divider, Row, Space, Statistic} from "antd";
import currencyFormatter from "../../util/currency_formatter";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {useAtom} from "jotai";
import {brandsCashFlowStatusAtom} from "../../store/projects";

type Props = {};

export const BrandsCashFlowStatus = (props: Props) => {
    const [{data: brandsCashFlowStatus}] = useAtom(brandsCashFlowStatusAtom);
    return (
        <Alert
            type={brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount > 0 ? 'success' : brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount == 0 ? 'info' : 'warning'}
            style={{marginBottom: 4}}
            // showIcon
            closable
            message={<>
                {brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount > 0 ? 'The brands are cash flow positive.' : brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount == 0 ? 'The brands are cash flow neutral.' : 'The brands are cash flow negative.'}

                <Row style={{marginTop: 4}}>
                    <Space>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Incoming Payments (Completed)"
                                value={currencyFormatter.format(brandsCashFlowStatus.incoming_payments_paid_amount)}
                                precision={2}
                                // style={{fontSize:1}}
                                valueStyle={{color: '#3f8600', fontSize: 14}}
                                prefix={<ArrowUpOutlined/>}
                            />
                        </Card>
                        {/*<Card bordered={false} size={"small"}>*/}
                        {/*    <Statistic*/}
                        {/*        title="Incoming Payments (To be paid)"*/}
                        {/*        value={currencyFormatter.format(brandsCashFlowStatus.incoming_payments_not_paid_amount)}*/}
                        {/*        precision={2}*/}
                        {/*        // style={{fontSize:1}}*/}
                        {/*        valueStyle={{color: '#3f8600', fontSize: 14}}*/}
                        {/*        prefix={<ArrowUpOutlined/>}*/}
                        {/*    />*/}
                        {/*</Card>*/}
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Outgoing Payments (Completed)"
                                value={currencyFormatter.format(brandsCashFlowStatus.outgoing_payments_paid_amount)}
                                precision={2}
                                valueStyle={{color: '#cf1322', fontSize: 14}}
                                prefix={<ArrowDownOutlined/>}
                            />
                        </Card>
                        {/*<Card bordered={false} size={"small"}>*/}
                        {/*    <Statistic*/}
                        {/*        title="Outgoing Payments (To be paid)"*/}
                        {/*        value={currencyFormatter.format(brandsCashFlowStatus.outgoing_payments_not_paid_amount)}*/}
                        {/*        precision={2}*/}
                        {/*        valueStyle={{color: '#cf1322', fontSize: 14}}*/}
                        {/*        prefix={<ArrowDownOutlined/>}*/}
                        {/*    />*/}
                        {/*</Card>*/}
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Payments (Completed) Total"
                                value={currencyFormatter.format(Math.abs(brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount))}
                                precision={2}
                                valueStyle={{
                                    color: brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount > 0 ? '#3f8600' :
                                        brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount == 0 ? '#000' : '#cf1322',
                                    fontSize: 14
                                }}
                                prefix={brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount > 0 ?
                                    <ArrowUpOutlined/> : brandsCashFlowStatus.incoming_payments_paid_amount - brandsCashFlowStatus.outgoing_payments_paid_amount == 0 ? null :
                                        <ArrowDownOutlined/>}
                            />
                        </Card>
                        <Divider type={"vertical"}/>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Account Receivables"
                                value={currencyFormatter.format(brandsCashFlowStatus.account_receivables)}
                                precision={2}
                                valueStyle={{color: '#3f8600', fontSize: 14}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Account Payables"
                                value={currencyFormatter.format(brandsCashFlowStatus.account_payables)}
                                precision={2}
                                valueStyle={{color: '#cf1322', fontSize: 14}}
                            />
                        </Card>
                    </Space>
                </Row>
            </>}
        />
    );
};

export default BrandsCashFlowStatus;
import axiosApiInstance from '../../api/axiosClient';

type Employees = API.Employee[];

const getEmployeeOptions = async () => {
    return axiosApiInstance
        .get<Employees>('/api/employees/', {params: {is_active: true}})
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};

export default getEmployeeOptions;

import axiosApiInstance from '../../api/axiosClient';
import axios from 'axios';
import {notification} from 'antd';
import dayjs from 'dayjs';

/**
 * Function to interface with backend
 *
 * @param values
 * @returns
 */
const assignManagerToExtBuyer = async (values: any) => {
    console.log('values passed to assignManagerToExtBuyer(): ', values);
    try {
        const {status} = await axiosApiInstance.post(`/api/buyers/${values.customer_id}/assign/${values.manager_id}`);
        return true;
    } catch (e: any) {
        if (!axios.isAxiosError(e)) {
            notification.error({message: e.message || 'Unexpected error'});
        }
        return false;
    }
};

export default assignManagerToExtBuyer;

import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ProCard} from "@ant-design/pro-components";
import {useParams} from "react-router-dom";
import {GlobalStateContext} from "../../context/GlobalContext";
import {Badge, Button, Empty, Select, Typography} from "antd";
import {ProTable, ProColumns} from "@ant-design/pro-table";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import axiosApiInstance from "../../api/axiosClient";
import LogDeliverableMaterialUtilization from "./LogDeliverableMaterialUtilization";
import dayjs, {Dayjs} from 'dayjs';
import utc from "dayjs/plugin/utc";
dayjs.extend(utc)

type Props = {};


const MaterialsUtilization = (props: Props) => {

    const [columns, setColumns] = useState<ProColumns<Record<string, any>>[] | undefined>([]);
    const [days, setDays] = useState<Dayjs[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const [data, setData] = useState<any>(undefined);
    const routerParams = useParams();
    const {materialsUtilizationTableRef} = useContext(GlobalStateContext);
    const [materialsOptions, setMaterialsOptions] = useState<{ label: any, value: number }[]>([]);
    const [selectedMaterialValue, setSelectedMaterialValue] = useState<number | undefined>(undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const fetchOwnMaterialsData = async () => {
        try {
            // setLoading(true)
            const response = await axiosApiInstance.get<API.OwnMaterial[]>('/api/deliverables/ownmaterials')
            // console.log(response.data)
            let options = []
            for (const material of response.data) {
                options.push({
                    value: material.id,
                    label: material.name
                })
            }
            setMaterialsOptions(options);
            if (options.length > 0) {
                setSelectedMaterialValue(options[0].value)
                materialsUtilizationTableRef?.current?.reload()
            }
            // setLoading(false)
        } catch (e) {
            // console.log('catch error')
            setMaterialsOptions([])
            // setLoading(false)
        }
    };

    const initDays = () => {
        let newDays = []
        // let curDay = dayjs().startOf('week').startOf('d')
        let curDay = dayjs.utc().startOf('week').startOf('d')
        for (let i = 0; i < 7; i++) {
            newDays.push(curDay.clone())
            curDay = curDay.add(1, 'd')
            // console.log(curDay.format('YYYY-W'))
        }
        // console.log(newDays)
        setDays(newDays)
    }

    // useEffect(() => {
    //     let newDays = []
    //     let curDay = dayjs().startOf('week').startOf('d')
    //     for (let i = 0; i < 7; i++) {
    //         newDays.push(curDay.clone())
    //         curDay = curDay.add(1, 'd')
    //         // console.log(curDay.format('YYYY-W'))
    //     }
    //     // console.log(newDays)
    //     setDays(newDays)
    // }, [])

    useEffect(() => {
        fetchOwnMaterialsData().then(initDays)
    }, [])

    // useEffect(() => {
    //     fetchOwnMaterialsData()
    // }, [])

    useEffect(() => {
        // console.log('cols')
        let newColumns: ProColumns<Record<string, any>>[] = []
        for (const daysKey of days) {
            // console.log(daysKey.format('YYYY-W'))
            let title;
            // let text = daysKey.format('YYYY-MM-DD')
            let text = daysKey.format('ddd D MMM')
            if (daysKey.isSame(dayjs().utc().startOf('d'))) {
                // title = <Tag color={'blue'} style={{fontSize:14}}>{daysKey.format('YYYY-W')}</Tag>
                title = <Badge status="processing" text={text}/>
            } else {
                title = text
            }
            newColumns.push({
                title,
                align: 'center',
                width: '12%',
                dataIndex: daysKey.format('YYYY-MM-DD'),
                render: (dom, entity) => {
                    if (entity.row_level == 'cost') {
                        return <LogDeliverableMaterialUtilization project_id={+routerParams.projectId!}
                                                                  day={daysKey}
                                                                  cost_id={entity.cost_id}
                                                                  cost_type={entity.cost_type}
                                                                  start_date={entity.start_date}
                                                                  own_material_id={selectedMaterialValue}
                                                                  hours={entity[daysKey.format('YYYY-MM-DD')]}
                        />
                    }
                    if (!daysKey.isBefore(dayjs.utc(entity.start_date))) {
                        // console.log(daysKey, moment(entity.start_date).startOf('isoWeek'))
                        if (entity[daysKey.format('YYYY-MM-DD')]) return `${entity[daysKey.format('YYYY-MM-DD')]}hr`
                        return `0hr`
                    }
                }
            })
        }
        newColumns = [
            {
                title: 'Deliverable', dataIndex: 'name', fixed: 'left', width: '15%',
                render: (dom, entity) => {
                    if (entity.row_level == 'deliverable') {
                        return <Typography.Text strong>{dom}</Typography.Text>
                    }
                    return <Typography.Text>Task: {dom}</Typography.Text>
                }
            },
            ...newColumns,
            {
                title: 'Total',
                dataIndex: 'total_hours',
                fixed: 'right',
                valueType: "digit",
                render: (dom, entity) => `${entity.total_hours || 0}hr`
            },
            // {title: 'Latest Progress', dataIndex: 'latest_progress', fixed: 'right', valueType: "progress"},
            // {
            //     title: 'Progress Chart',
            //     render: (dom, entity) => <ExecutionDeliverableProgressLineChart data={entity.series}/>,
            //     fixed: 'right'
            // }
        ]
        setColumns(newColumns)
        materialsUtilizationTableRef?.current?.reload()
    }, [days])

    const previousWeek = () => {
        let newDays = []
        let curDay = days[0].subtract(1, 'w')
        for (let i = 0; i < 7; i++) {
            newDays.push(curDay.clone())
            curDay = curDay.add(1, 'd')
        }
        setDays(newDays)
    }

    const nextWeek = () => {
        let newDays = []
        let curDay = days[days.length - 1].add(1, 'd')
        for (let i = 0; i < 7; i++) {
            newDays.push(curDay.clone())
            curDay = curDay.add(1, 'd')
        }
        setDays(newDays)
    }

    // if (selectedMaterialValue == undefined || columns == undefined) {
    if (selectedMaterialValue == undefined) {
        // console.log(selectedMaterialValue, columns)
        return <Empty description={'No materials found'}/>
    }

    return (
        <div>
            <ProCard bordered bodyStyle={{padding: 0}}>
                <ProTable
                    actionRef={materialsUtilizationTableRef}
                    columns={columns}
                    rowKey={"id"}
                    // dataSource={rows}
                    search={false}
                    // toolBarRender={false}
                    pagination={false}
                    toolBarRender={(action) => [
                        <Select options={materialsOptions} style={{width: 300}} placeholder={'Select Material'}
                                value={selectedMaterialValue}
                                onSelect={(value: number) => {
                                    setSelectedMaterialValue(value)
                                    materialsUtilizationTableRef?.current?.reload()
                                }}/>,
                        <Typography.Text
                            strong>{days[0]?.format('D MMM YYYY')} - {days[days.length - 1]?.format('D MMM YYYY')}</Typography.Text>,
                        <Button onClick={() => previousWeek()}><LeftOutlined/>Previous week</Button>,
                        <Button onClick={() => nextWeek()}>Next week<RightOutlined/></Button>
                    ]}
                    // toolbar={{actions:[]}}
                    request={() => {
                        // if (selectedEmployee == undefined) {
                        //     return Promise.reject()
                        // }
                        // console.log(weeks)
                        // console.log(selectedMaterialValue, columns)
                        let dates = []
                        for (let day of days) {
                            // let s = day.utcOffset(0, true).format()
                            let s = day.format()
                            dates.push(s)
                            // console.log(s, day.toISOString())
                            // dates.push(day.format())
                        }
                        return axiosApiInstance.get(`/api/projects/${routerParams.projectId}/material_utilization`, {
                            params: {
                                days: dates,
                                own_material_id: selectedMaterialValue,
                            }
                        })
                            .then(value => {
                                // console.log(value.data)
                                return {data: value.data, success: true, total: value.data.length}
                            })
                        // return axiosApiInstance.get(`/api/projects/${routerParams.projectId}/progress`, {params: {weeks}})
                    }}
                    // expandable={{expandedRowRender: record => expandedRowRender(record, data, days, selectedMaterialValue!, +routerParams.projectId!)}}
                    // onDataSourceChange={dataSource => setData(dataSource)}
                    onDataSourceChange={dataSource => {
                        setData(dataSource)
                        setExpandedRowKeys(dataSource?.map((i: any) => i.id))
                    }}
                    // defaultExpandAllRows={true}
                    expandedRowKeys={expandedRowKeys}

                />
            </ProCard>
        </div>
    );
};

export default MaterialsUtilization;
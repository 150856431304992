import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {UserAuth} from "../context/AuthContext";
import {PageLoading} from "@ant-design/pro-layout";
import UnauthorizedPage from "../pages/403";
import {useEffect, useState} from "react";

//Routes that require users to be authenticated
const PrivateRoutes = () => {
    const {user, permissions, can, authorizing} = UserAuth();
    const location = useLocation();
    // console.log({permissions})
    // console.log(can('read', 'brands::data'))
    const [canReadBrands, setCanReadBrands] = useState<boolean>(false)

    useEffect(() => {
        if (!user || authorizing)
            return
        setCanReadBrands(can('read', 'brands::data'))
        // console.log({canReadBrands})
        // console.log({user})
    }, [authorizing])

    // handle initial state of user (while in the process of authenticating...)
    if (user === undefined || authorizing) {
        return <PageLoading/>
    }

    return (
        user != null ?
            (canReadBrands ?
                <Outlet/>
                : <UnauthorizedPage/>)
            : <Navigate to="/login" replace state={{from: location}}/>
    )
}

export default PrivateRoutes
import {PageContainer} from '@ant-design/pro-layout';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import OpportunityInfoLayout from '../components/sales/OpportunityInfoLayout';
import {useSalesContext} from "../context/SalesContextProvider";
import {EditOutlined} from "@ant-design/icons";
import {Button, Skeleton} from "antd";

type T = API.Opportunity;

const SaleInfoPage = () => {
    const {id: opportunity_id} = useParams(); // route params
    // const [target, setTarget] = useState<API.Opportunity | undefined>(undefined);
    const {updateOpportunities, opportunities} = useSalesContext();
    const target = opportunities.find((opp) => opportunity_id == `${opp.id}`);
    const [loading, setLoading] = useState<boolean>(false);

    // const callback = (values: any) => {
    //     console.log({...target, ...values});
    //     setTarget({...target, ...values})
    //     // setTarget((t) => ({...t, ...values}));
    // }

    const getData = async () => {
        setLoading(true)
        await updateOpportunities();
        setLoading(false)
        // axiosApiInstance
        //     .get<T>(`/api/sales/opportunities/${opportunity_id}`)
        //     .then(({data}) => setTarget(data))
        //     .catch((e) => console.error(e));
    }

    // useEffect(() => {
    //     getData();
    // }, []);

    /**
     * check for search params and initialize states accordingly
     */
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (searchParams.has('update')) {
            searchParams.delete('update');
            // set params with navigation replace
            setSearchParams(searchParams, {replace: true})
            getData();
        }

    }, [searchParams]);

    return (
        <PageContainer
            // title={`${target?.title || 'Untitled'}`}
            title={
                <Skeleton loading={loading || !target} active paragraph={{rows: 1}}>
                    {target?.title}
                </Skeleton>
            }
            // subTitle={`Opportunity ${target?.project_pretty_id || '-'}`}
            subTitle={<Skeleton loading={loading || !target} active paragraph={{rows: 1}}>
                Opportunity {target?.project_pretty_id || '-'}
            </Skeleton>}
            loading={loading || !target}
            extra={
                // <EditOpportunityForm
                //     defaults={target}
                //     callback={callback}
                // />
                <Link to={`/sales/${opportunity_id}/edit`} state={{background: location}}>
                    <Button type={'text'} icon={<EditOutlined/>}>Edit</Button>
                </Link>
                // <Button key={'edit'} type={'text'} icon={<EditOutlined/>}
                //         onClick={() => navigate(`edit`)}>Edit</Button>
            }>
            {/*<div*/}
            {/*    style={{*/}
            {/*        height: '100%',*/}
            {/*        flex: 1,*/}
            {/*        padding: 32,*/}
            {/*        backgroundColor: 'white',*/}
            {/*        borderRadius: 6*/}
            {/*    }}>*/}
            {/*    <>{target && <OpportunityInfoLayout target={target} />}</>*/}
            {/*</div>*/}
            {/*{target && <OpportunityInfoLayout target={target}/>}*/}
            {target && <OpportunityInfoLayout/>}

            {/*<Outlet/>*/}

        </PageContainer>
    );
};

export default SaleInfoPage;

import {ModalForm, ProFormDigit} from '@ant-design/pro-components';
import * as React from 'react';
import {useContext} from 'react';
import {GlobalStateContext} from "../../context/GlobalContext";
import moment, {Moment} from "moment";
import {useParams} from "react-router-dom";
import {Button} from "antd";
import axiosApiInstance from "../../api/axiosClient";


type Props = {
    week: Moment
    // execution_deliverable_id: number
    // execution_deliverable_name: string
    cost_id: number
    cost_type: string
    start_date: string
    completion_percentage?: number
};

export const LogDeliverableProgress = (props: Props) => {
    const {executionProgressTableRef} = useContext(GlobalStateContext);
    const routerParams = useParams();

    // if (props.cost_id==325)
    // console.log(
    //     props.week,
    //     // props.week.utcOffset(0, true),
    //     props.start_date,
    //     moment(props.start_date),
    //     moment(props.start_date).startOf('isoWeek'),
    //     props.week.isBefore(moment(props.start_date).startOf('isoWeek'))
    // )

    return (
        <>
            <ModalForm<{
                progress_percentage: number
            }>
                width={400}
                title={`Log Progress - ${props.week.format('YYYY-W')} - ${props.cost_id}`}
                trigger={
                    <Button disabled={props.week.isBefore(moment(props.start_date).startOf('isoWeek'))}
                            type={"dashed"}>Log</Button>
                }
                autoFocusFirstInput={true}
                modalProps={{
                    destroyOnClose: true,
                    // okText: 'Delete',
                    // cancelText: 'No'
                }}
                onFinish={async (values) => {
                    let endpoint;
                    let params;
                    if (props.cost_type == 'own_time_cost') {
                        endpoint = 'otc'
                        params = {execution_own_time_cost_id: props.cost_id}
                    } else if (props.cost_type == 'own_material_cost') {
                        endpoint = 'omc'
                        params = {execution_own_material_cost_id: props.cost_id}
                    } else if (props.cost_type == 'third_party_time_cost') {
                        endpoint = '3ptc'
                        params = {execution_third_party_time_cost_id: props.cost_id}
                    } else if (props.cost_type == 'third_party_material_cost') {
                        endpoint = '3pmc'
                        params = {execution_third_party_material_cost_id: props.cost_id}
                    }
                    // console.log(endpoint, params)
                    return axiosApiInstance.post(`/api/projects/${routerParams.projectId}/progress/${endpoint}`, {
                        ...values,
                        ...params,
                        progress_date: props.week.utcOffset(0, true).format()
                    })
                        .then(res => {
                            executionProgressTableRef?.current?.reload()
                            return true
                        }).catch(reason => false)
                }}
                // onFinish={async (values) => {
                //     console.log(values)
                //     return true
                // }}
            >
                <ProFormDigit
                    // label="Progress Percentage"
                    name="progress_percentage"
                    min={1}
                    max={100}
                    width={"xs"}
                    fieldProps={{
                        precision: 0,
                        defaultValue: 0,
                        formatter: (value) => `${value}%`,
                        parser: (value) => +value!.replace('%', '')
                    }
                    }
                    rules={[{required: true}]}
                />

            </ModalForm>
            {/*{!props.week.isBefore(moment(props.start_date).startOf('isoWeek')) &&*/}
            {/*<ProDescriptions*/}
            {/*    dataSource={{*/}
            {/*        completion_percentage: props.completion_percentage,*/}
            {/*    }}*/}
            {/*    editable={{*/}
            {/*        onSave: async (key, record) => {*/}
            {/*            // console.log('saved', key, record, record.completion_percentage)*/}
            {/*            let endpoint;*/}
            {/*            let params;*/}
            {/*            if (props.cost_type == 'own_time_cost') {*/}
            {/*                endpoint = 'otc'*/}
            {/*                params = {execution_own_time_cost_id: props.cost_id}*/}
            {/*            } else if (props.cost_type == 'own_material_cost') {*/}
            {/*                endpoint = 'omc'*/}
            {/*                params = {execution_own_material_cost_id: props.cost_id}*/}
            {/*            } else if (props.cost_type == 'third_party_time_cost') {*/}
            {/*                endpoint = '3ptc'*/}
            {/*                params = {execution_third_party_time_cost_id: props.cost_id}*/}
            {/*            } else if (props.cost_type == 'third_party_material_cost') {*/}
            {/*                endpoint = '3pmc'*/}
            {/*                params = {execution_third_party_material_cost_id: props.cost_id}*/}
            {/*            }*/}
            {/*            // console.log(endpoint, params)*/}
            {/*            return axiosApiInstance.post(`/api/projects/${routerParams.projectId}/progress/${endpoint}`, {*/}
            {/*                progress_percentage: record.completion_percentage,*/}
            {/*                ...params,*/}
            {/*                progress_date: props.week.utcOffset(0, true).format()*/}
            {/*            })*/}
            {/*                .then(res => {*/}
            {/*                    executionProgressTableRef?.current?.reload()*/}
            {/*                    return true*/}
            {/*                }).catch(reason => false)*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    columns={[*/}
            {/*        {*/}
            {/*            valueType: 'digit',*/}
            {/*            fieldProps: {*/}
            {/*                min: 0,*/}
            {/*                max: 100,*/}
            {/*                precision: 0,*/}
            {/*                defaultValue: 0,*/}
            {/*                formatter: (value) => `${value}%`,*/}
            {/*                parser: (value) => value!.replace('%', ''),*/}
            {/*            },*/}
            {/*            dataIndex: 'completion_percentage',*/}
            {/*            render: (dom, entity) => {*/}
            {/*                return entity.completion_percentage !== undefined ?*/}
            {/*                    <Typography.Text*/}
            {/*                        style={{color: '#6395F9'}}>{entity.completion_percentage}%</Typography.Text>*/}
            {/*                    : '-'*/}
            {/*            }*/}
            {/*        },*/}

            {/*    ]}*/}
            {/*/>*/}
            {/*}*/}
        </>
    );
};

export default LogDeliverableProgress
import {
    ModalForm,
    ProForm,
    ProFormDependency,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components';
import {Button, Divider, Form, message, notification, Space, Tooltip} from 'antd';
import axiosApiInstance from "../../api/axiosClient";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {GlobalStateContext} from "../../context/GlobalContext";
import {useNavigate} from "react-router-dom";
import createNewExternalBuyer from "../../util/sales/createNewExternalBuyer";
import {LoginOutlined, PlusOutlined} from "@ant-design/icons";
import assignManagerToExtBuyer from "../../util/sales/assignManagerToExtBuyer";

type Props = {}

export const NewProject = (props: Props) => {
    const [form] = Form.useForm<API.Project>();
    const {projectsListRef} = useContext(GlobalStateContext);
    const navigate = useNavigate();
    const [customers, setCustomers] = useState<API.Customer[] | undefined>(undefined);
    const [accountingCustomers, setAccountingCustomers] = useState<API.AccountingCustomer[] | undefined>(undefined);
    const [employees, setEmployees] = useState<API.Employee[] | undefined>(undefined);
    const [buyers, setBuyers] = useState<API.ExternalBuyer[] | undefined>(undefined);
    const inBuyerType = Form.useWatch('buyer_type_id', form);
    const inExternalId = Form.useWatch('external_buyer_id', form);
    const inCustomerId = Form.useWatch('customer_id', form);
    const inEmployeeId = Form.useWatch('employee_id', form);

    const fetchCustomersData = async () => {
        try {
            const response = await axiosApiInstance.get<API.Customer[]>('/api/customers/')
            setCustomers(response.data);
        } catch (e) {
            setCustomers(undefined)
        }
    };
    const fetchAccountingCustomersData = async () => {
        try {
            const response = await axiosApiInstance.get<API.AccountingCustomer[]>('/api/accounting_customers/')
            setAccountingCustomers(response.data);
        } catch (e) {
            setAccountingCustomers(undefined)
        }
    };
    const fetchEmployeesData = async () => {
        try {
            const response = await axiosApiInstance.get<API.Employee[]>('/api/employees/')
            setEmployees(response.data);
        } catch (e) {
            setEmployees(undefined)
        }
    };
    const fetchBuyersData = async () => {
        try {
            const response = await axiosApiInstance.get<API.ExternalBuyer[]>('/api/buyers/')
            setBuyers(response.data);
        } catch (e) {
            setBuyers(undefined)
        }
    }

    const addExternalCustomer = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        const new_external_customer = form.getFieldValue(['new_external_customer'])
        if (!new_external_customer) {
            message.error('Please enter a name for the new customer');
            return;
        }
        form.resetFields(['new_external_customer'])
        await createNewExternalBuyer({name: new_external_customer})
        await fetchBuyersData()
    };


    useEffect(() => {
        fetchCustomersData()
        fetchEmployeesData()
        fetchBuyersData()
    }, []);


    return (
        <ModalForm<API.Project>
            title="Create a new Case"
            visible={true}
            form={form}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                onCancel: () => navigate(`/projects`),
                okText: 'Create'
            }}
            // submitTimeout={2000}
            onFinish={(values) => {
                return axiosApiInstance.post<API.Project>('/api/projects/', values)
                    .then(res => {
                        // console.log(res.data)
                        // console.log(props.listRef.current)
                        projectsListRef?.current?.reload()
                        navigate(`/projects`)
                        return true
                    }).catch(reason => false)
            }}
            validateMessages={{required: "${label} is required!"}}
        >
            <ProFormText
                width="xl"
                name="title"
                label="Title"
                rules={[{required: true}]}
            />
            <ProForm.Group>

                <ProFormSelect
                    request={() =>
                        axiosApiInstance.get<API.BuyerType[]>('/api/buyers/types')
                            .then(res => {
                                return res.data.map(i => ({label: i.name, value: i.id}))
                            })
                    }
                    name="buyer_type_id"
                    label="Customer Type"
                    rules={[{required: true}]}
                    width={"sm"}
                    initialValue={1}
                />

                {/*<ProFormSelect*/}
                {/*    options={customers?.map(value => ({value: value.id, label: `${value.name} [${value.id}]`}))}*/}
                {/*    width="md"*/}
                {/*    name="customer_id"*/}
                {/*    label="Customer"*/}
                {/*    rules={[{required: true}]}*/}
                {/*    fieldProps={{*/}
                {/*        showSearch: true, filterOption: (input, option) =>*/}
                {/*            (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())*/}
                {/*    }}*/}
                {/*/>*/}

                <ProFormDependency name={[['buyer_type_id']]}>
                    {({buyer_type_id}) => {
                        if (buyer_type_id === 1) {
                            form.setFieldValue('external_buyer_id', null)
                            form.setFieldValue('external_buyer', null)
                            form.setFieldValue('accounting_customer_id', null)
                            form.setFieldValue('accounting_customer', null)
                            form.setFieldValue('customer', null)
                            return <ProFormSelect
                                request={() =>
                                    axiosApiInstance.get<API.Customer[]>('/api/customers/')
                                        .then(res => {
                                            return res.data.map(i => ({
                                                label: `${i.name} [${i.id}]`,
                                                value: i.id
                                            }))
                                        })
                                }
                                width="sm"
                                name="customer_id"
                                label="Existing Customer (LTD)"
                                rules={[{required: true}]}
                                fieldProps={{
                                    showSearch: true, filterOption: (input, option) =>
                                        (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                                    onChange(value: any, option: any) {
                                        // console.log(value, option)
                                        const empl = customers?.filter(i => i.id === value)[0]?.employees[0] || undefined
                                        if (empl)
                                            form.setFieldValue('employee_id', empl.id);
                                    },
                                }}
                            />
                        }

                        if (buyer_type_id === 2) {
                            form.setFieldValue('external_buyer_id', null)
                            form.setFieldValue('external_buyer', null)
                            form.setFieldValue('customer_id', null)
                            form.setFieldValue('customer', null)
                            form.setFieldValue('accounting_customer', null)
                            return <ProFormSelect
                                request={() =>
                                    axiosApiInstance.get<API.AccountingCustomer[]>('/api/accounting_customers/')
                                        .then(res => {
                                            return res.data.map(i => ({
                                                label: `${i.name} [${i.id}]`,
                                                value: i.id
                                            }))
                                        })
                                }
                                width="sm"
                                name="accounting_customer_id"
                                label="Existing Customer (IKE)"
                                rules={[{required: true}]}
                                fieldProps={{
                                    showSearch: true, filterOption: (input, option) =>
                                        (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                                    onChange(value: any, option: any) {
                                        // console.log(value, option)
                                        const empl = accountingCustomers?.filter(i => i.id === value)[0]?.employees[0] || undefined
                                        if (empl)
                                            form.setFieldValue('employee_id', empl.id);
                                    },
                                }}
                            />
                        }


                        form.setFieldValue('customer_id', null)
                        form.setFieldValue('customer', null)
                        form.setFieldValue('accounting_customer_id', null)
                        form.setFieldValue('accounting_customer', null)
                        form.setFieldValue('external_buyer', null)
                        // form.setFieldValue('employee_id', null)
                        form.setFieldValue('employee', null)
                        return (
                            <ProFormSelect
                                // request={() =>
                                //     axiosApiInstance.get<API.ExternalBuyer[]>('/api/buyers/')
                                //         .then(res => {
                                //             // console.log(res.data)
                                //             return res.data.map(i => ({
                                //                 label: i.name,
                                //                 value: i.id
                                //             }))
                                //         })
                                // }
                                options={buyers?.map(value => ({
                                    value: value.id,
                                    label: `${value.name} [${value.id}]`
                                }))}
                                width="sm"
                                name="external_buyer_id"
                                label="External Customer"
                                rules={[{required: true}]}
                                fieldProps={{
                                    dropdownRender: (menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{margin: '8px 0'}}/>
                                            <Space>
                                                <ProFormText
                                                    placeholder="Please enter"
                                                    name={'new_external_customer'}
                                                    allowClear
                                                    noStyle
                                                />
                                                <Button type="text" icon={<PlusOutlined/>}
                                                        onClick={addExternalCustomer}
                                                >
                                                    Add
                                                </Button>
                                            </Space>
                                        </>
                                    ),
                                    onChange(value: any, option: any) {
                                        // console.log(value, option)
                                        const empl = buyers?.filter(i => i.id === value)[0]?.employees[0] || undefined
                                        if (empl)
                                            form.setFieldValue('employee_id', empl.id);
                                    },
                                }}
                            />
                        );
                    }}
                </ProFormDependency>


                <ProFormDependency
                    name={[['customer_id']]}>
                    {({customer_id}) => {
                        const c = customers?.find((c) => c.id == customer_id)
                        if (c)
                            form.setFieldValue(['employee_id'], c?.employee_id)
                        return (
                            <ProFormSelect
                                // request={() =>
                                //     axiosApiInstance.get<API.Employee[]>('/api/employees/')
                                //         .then(res => {
                                //             return res.data.map(i => ({label: i.name, value: i.id}))
                                //         })
                                // }
                                options={employees?.map(value => ({value: value.id, label: `${value.name}`}))}
                                width="sm"
                                name="employee_id"
                                label="Account Manager"
                                rules={[{required: true}]}
                                addonAfter={
                                    inBuyerType == 2 && inExternalId && inEmployeeId ?
                                        <Tooltip title={'Assign manager to the selected customer'}>
                                            <Button
                                                type="default"
                                                icon={<LoginOutlined/>}
                                                onClick={async () => {
                                                    try {
                                                        const res = await assignManagerToExtBuyer({
                                                            manager_id: inEmployeeId,
                                                            customer_id: inExternalId
                                                        })
                                                        notification.success({
                                                            message: 'Successfully assigned manager',
                                                            duration: 1
                                                        })
                                                        await fetchBuyersData()
                                                    } catch (error) {
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                        : null
                                }
                            />
                        );
                    }}
                </ProFormDependency>


            </ProForm.Group>
            <ProFormTextArea width="xl"
                             name="description"
                             label="Description"
            />
        </ModalForm>
    );
};

export default NewProject
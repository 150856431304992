import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ModalForm, ProList} from "@ant-design/pro-components";
import {Button, Col, Divider, Row, Space, Statistic, Typography} from "antd";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {EditOutlined} from "@ant-design/icons";
import {GlobalStateContext} from "../../../context/GlobalContext";
import axiosApiInstance from "../../../api/axiosClient";
import DeleteProposedDeliverableTemplate from "./DeleteProposedDeliverableTemplate";

type Props = {};
const ProposedDeliverableTemplates = (props: Props) => {
    const [wbsVersionData, setWbsVersionData] = useState<API.ProposedWBSVersion | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const routerParams = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {proposedDeliverableTemplatesListRef} = useContext(GlobalStateContext);
    // const {proposedWBSVersionRef} = useContext(GlobalStateContext);

    // const fetchWbsVersionData = async () => {
    //     try {
    //         setLoading(true)
    //         const response = await axiosApiInstance.get<API.ProposedWBSVersion>('/api/proposals/' + routerParams.proposedWbsVersionId)
    //         // console.log(response.data)
    //         setWbsVersionData(response.data);
    //         setLoading(false)
    //     } catch (e) {
    //         console.log('catch error')
    //         setWbsVersionData(undefined)
    //         setLoading(false)
    //     }
    // };

    useEffect(() => {
        // if (location.pathname.includes('newdeliverable'))
        //     return
        // fetchWbsVersionData();
    }, []);

    return (
        <ModalForm<{}>
            visible={true}
            width={"75%"}
            // title="Please select a template"
            submitter={false}
            modalProps={{
                destroyOnClose: true,
                onCancel: () => navigate(`/projects/${routerParams.projectId}/proposals/${routerParams.proposedWbsVersionId}`),
                // bodyStyle: {height: "calc(100vh - 300px)", overflowY: 'auto'},
                // style: {top: 70},
            }}
        >

            <ProList<API.ProposedDeliverableTemplate & { actions: any }>
                // toolBarRender={() => [
                //     <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                //         proposedDeliverableTemplatesListRef?.current?.reload()
                //     }}/>
                // ]}
                // toolBarRender={false}
                toolBarRender={() => []}
                ghost
                headerTitle={<Space size={0}>Please choose a template, or <Button type={"link"} onClick={() => {
                    navigate(`new`)
                }}>Create new template</Button> </Space>}
                itemCardProps={{
                    // bodyStyle:{padding:"16px 32px 16px 32px"},
                    bodyStyle: {paddingLeft: 32, width: 300},
                    // headStyle: {paddingLeft: 0}
                }}
                pagination={{
                    defaultPageSize: 4,
                    showSizeChanger: false,
                }}
                showActions="always"
                // rowSelection={{}}
                rowSelection={false}
                grid={{gutter: 16}}
                onItem={(record: any) => {
                    return {
                        onClick: () => {
                            navigate(`${record.id}`)
                            // console.log(record)
                        },
                    };
                }}
                metas={{
                    title: {
                        dataIndex: 'name',
                        render: (dom, entity) => {
                            return `Template ${entity.name}`
                            // return <Row justify={"start"}>Deliverable {entity.name}</Row>
                        },
                    },
                    subTitle: {
                        // dataIndex: 'description'
                        // dataIndex: 'description'
                        render: (dom, entity) => `${entity.estimated_duration_days} days`
                    },
                    // type: {},
                    // avatar: {},
                    content: {
                        render: (dom, entity) => {
                            let otc = 0
                            let omc = 0
                            let tptc = 0
                            let tpmc = 0
                            entity.own_time_costs?.map(value => {
                                otc += value.cost
                            })
                            entity.own_material_costs?.map(value => {
                                omc += value.cost
                            })
                            entity.third_party_time_costs?.map(value => {
                                tptc += value.cost
                            })
                            entity.third_party_material_costs?.map(value => {
                                tpmc += value.cost
                            })
                            return <>
                                {/*<Row>*/}
                                <Space direction={"vertical"} size={"small"}>

                                    <div style={{display: "flex", justifyContent: "space-between"}}><Typography.Text
                                        style={{marginRight: 10}}>Own time cost: €{otc}</Typography.Text>
                                        <Typography.Text
                                            type={"secondary"}>{entity.own_time_costs?.length || '0'} item(s)</Typography.Text>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}><Typography.Text
                                        style={{marginRight: 10}}>Own materials cost: €{omc} </Typography.Text>
                                        <Typography.Text
                                            type={"secondary"}>{entity.own_material_costs?.length || '0'} item(s)</Typography.Text>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}><Typography.Text
                                        style={{marginRight: 10}}>3P time cost: €{tptc} </Typography.Text>
                                        <Typography.Text
                                            type={"secondary"}>{entity.third_party_time_costs?.length || '0'} item(s)</Typography.Text>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}><Typography.Text
                                        style={{marginRight: 10}}>3P materials cost: €{tpmc} </Typography.Text>
                                        <Typography.Text
                                            type={"secondary"}>{entity.third_party_material_costs?.length || '0'} item(s)</Typography.Text>
                                    </div>

                                    <Divider style={{marginTop: 8, marginBottom: 8}}/>
                                    <Row gutter={8} justify={"space-evenly"}>
                                        <Col span={12}>
                                            <Statistic title="Total Cost" value={entity.total_cost} precision={2}
                                                       prefix={'€'}
                                                       valueStyle={{fontSize: 18}}/>
                                        </Col>
                                        {/*<Divider type={'vertical'}/>*/}
                                        <Col span={12}>
                                            <Statistic title="Total Price" value={entity.total_price} precision={2}
                                                       prefix={'€'}
                                                       valueStyle={{fontSize: 18}}/>
                                        </Col>
                                    </Row>
                                </Space>
                                {/*</Row>*/}
                            </>
                        }
                    },
                    type: {},
                    avatar: {},
                    actions: {cardActionProps: "actions"}
                }}
                // onDataSourceChange={dataSource => log}
                actionRef={proposedDeliverableTemplatesListRef}
                request={params => {
                    return axiosApiInstance.get<API.ProposedDeliverableTemplate[]>('/api/templates/')
                        .then(value => {
                            return {
                                data: value.data.map((i) => ({
                                    ...i,
                                    actions: [
                                        <DeleteProposedDeliverableTemplate id={i.id}/>,
                                        // <EditOutlined key="edit"/>,
                                        <Link to={`${i.id}/edit`}> <EditOutlined key="edit"/> </Link>,
                                        // <EllipsisOutlined key="ellipsis"/>,
                                    ],
                                })),
                                success: true,
                                total: value.data.length
                            }
                        })
                        .catch(reason => {
                            // console.log(reason)
                            return {success: false}
                        })
                }}
                onRequestError={e => console.log(e)}
                // onDataSourceChange={dataSource => console.log(dataSource)}
                // onLoad={dataSource => console.log(dataSource)}
            />
        </ModalForm>
    );
};

export default ProposedDeliverableTemplates
import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Button, Card, Row, Skeleton, Space, Statistic, Typography} from "antd";
import {EditOutlined, EyeOutlined, FileExcelOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Link, Outlet, useLocation} from "react-router-dom";
import {ProColumns, ProFormInstance, ProFormSelect} from "@ant-design/pro-components";
import dayjs from "dayjs";
import currencyFormatter from "../../util/currency_formatter";
import axiosApiInstance from "../../api/axiosClient";
import {GlobalStateContext} from "../../context/GlobalContext";
import XLSX from "sheetjs-style-v2";
import {useAtom, useAtomValue} from "jotai/index";
import {combinedSuppliersOptionsAtom, projectsOnExecutionUnfilteredAtom} from "../../store/projects";
import {fetchParamsAtom} from "../../store/invoices_in";
import {ProTable} from "@ant-design/pro-table";
import {RESET} from "jotai/utils";

type Props = {};

export const InvoicesIncomingGlobalTable = (props: Props) => {
    const {invoicesIncomingGlobalListRef, setTaxCategories} = useContext(GlobalStateContext);
    const [data, setData] = useState<API.LoggedInvoiceIncoming[] | undefined>(undefined);
    const [invoicedTotal, setInvoicedTotal] = useState<number>(0);
    const [paidTotal, setPaidTotal] = useState<number>(0);
    const [vatTotal, setVatTotal] = useState<number>(0);
    const {data: projectsData} = useAtomValue(projectsOnExecutionUnfilteredAtom)
    const combinedSuppliersOptions = useAtomValue(combinedSuppliersOptionsAtom)

    const location = useLocation();

    useEffect(() => {
        let invoicedTotal = 0
        let vatTotal = 0
        let paidTotal = 0
        data?.map((d) => {
            invoicedTotal += d.cost
            paidTotal += d.paid_amount
            vatTotal += d.cost_incl_vat - d.cost
        })

        setInvoicedTotal(invoicedTotal)
        setPaidTotal(paidTotal)
        setVatTotal(vatTotal)
    }, [data]);

    // based on the columns of the table export the data to excel
    const buildAndDownloadXLSX = () => {
        let title: string = 'Invoices_Incoming_' + dayjs().format('YYYYMMDD') + '.xlsx'
        let sheetData = data!.map((item, index) => {
            return {
                'Invoice ID': item.id,
                'Invoice Number': item.invoice_number,
                'Case ID': item.project_pretty_id,
                'Date Issued': dayjs.utc(item.date).format('YYYY-MM-DD'),
                'Due Date': dayjs.utc(item.due_date).format('YYYY-MM-DD'),
                'Cost': item.cost,
                'Cost Incl. VAT': item.cost_incl_vat,
                'Paid': item.paid,
                'Paid Amount': item.paid_amount,
            }
        })

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetData, {dateNF: 'dd/mm/yyyy', cellDates: true});
        const wb: XLSX.WorkBook = {Sheets: {Report: ws}, SheetNames: ['Report']};
        XLSX.writeFile(wb, title, {
            bookType: 'xlsx',
            type: 'array'
        });
    };

    const columns: ProColumns<API.LoggedInvoiceIncoming>[] = [
        {
            title: 'Invoice ID',
            dataIndex: 'id',
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            sorter: (a, b) => (a.invoice_number ?? '').localeCompare(b.invoice_number ?? ''),
            formItemProps: {label: 'Invoice No'},
        },
        {
            title: 'Case',
            dataIndex: 'project_pretty_id',
            sorter: (a, b) => (a.project_pretty_id ?? '').localeCompare(b.project_pretty_id ?? ''),
            valueType: 'select',
            fieldProps: {
                options: projectsData?.map(value => ({
                    label: `${value.title} (${value.project_pretty_id})`,
                    value: value.project_pretty_id
                })),
                multiple: true,
                showSearch: true,
                filterOption: (input: string, option: { label: any; }) =>
                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
            },
            renderFormItem: (item, config) => {
                return <ProFormSelect
                    {...config}
                    fieldProps={{
                        showSearch: true, filterOption: (input, option) =>
                            (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                        popupMatchSelectWidth: true,
                        // mode: "multiple",
                        mode: "tags",
                        maxTagCount: "responsive",
                    }}
                    wrapperCol={{span: 24}}
                />
            }
        },
        {
            title: 'Date Issued',
            dataIndex: 'date',
            valueType: 'dateRange',
            render: (text, record, index) => {
                return dayjs.utc(record.date).format('YYYY-MM-DD')
            },
            sorter: (a, b) => dayjs.utc(a.date).unix() - dayjs.utc(b.date).unix(),
            // fieldProps: {
            //     format: 'YYYY-MM-DD',
            // },
            search: {
                transform: (value) => {
                    return {
                        date_issued_start: value[0],
                        date_issued_end: value[1]
                    }
                }
            }
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            valueType: 'dateRange',
            render: (text, record, index) => {
                return dayjs.utc(record.due_date).format('YYYY-MM-DD')
            },
            sorter: (a, b) => dayjs.utc(a.due_date).unix() - dayjs.utc(b.due_date).unix(),
            search: {
                transform: (value) => {
                    return {
                        due_date_start: value[0],
                        due_date_end: value[1]
                    }
                }
            }
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            render: (text, record, index) => {
                return currencyFormatter.format(record.cost)
            },
            sorter: (a, b) => a.cost - b.cost,
            search: false,
        },
        {
            title: 'Cost Incl. VAT',
            dataIndex: 'cost_incl_vat',
            render: (text, record, index) => {
                return currencyFormatter.format(record.cost_incl_vat)
            },
            sorter: (a, b) => a.cost_incl_vat - b.cost_incl_vat,
            search: false,
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            valueType: 'select',
            valueEnum: {
                YES: {text: 'Yes'},
                NO: {text: 'No'},
                PARTIALLY: {text: 'Partially'},
            },
            sorter: (a, b) => (a.paid ?? '').localeCompare(b.paid ?? ''),
            fieldProps: {
                label: 'Paid Status',
                allowClear: true,
            }
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amount',
            render: (text, record, index) => {
                return currencyFormatter.format(record.paid_amount)
            },
            sorter: (a, b) => a.paid_amount - b.paid_amount,
            search: false,
        },
        {
            // title: 'Actions',
            valueType: 'option',
            width: 80,
            fixed: 'right',
            render: (text, record, _, action) => [
                <Link to={`${record.id}`}> <Button type="link" icon={<EyeOutlined/>}>View</Button> </Link>,
                <Link to={`${record.id}/edit`}> <EditOutlined key="edit"/> </Link>,
            ],
        },
        {
            title: 'Supplier', dataIndex: 'supplier', valueType: 'text',
            // hideInSearch: true,
            render: (_, record) => (
                <Typography.Text>
                    {record.supplier_customer_id != null ? record.customer.name : record.supplier_external_id != null ?
                        record.external_supplier.name : record.accounting_customer.name}
                </Typography.Text>
            ),
            // +Unified customer search implementation
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <ProFormSelect
                        placeholder="Select supplier"
                        options={combinedSuppliersOptions}
                        width={"lg"}
                        wrapperCol={{span: 24}}
                        mode={'multiple'}
                        showSearch
                    />
                )
            },
            // +Unified customer search implementation
            search: {
                transform: (value) => {
                    let parsedVals = value.map((v: string) => JSON.parse(v))
                    return {
                        supplier_customer_id: parsedVals.filter((v: any) => v.supplier_type_id === 1).map((v: any) => v.supplier_customer_id),
                        supplier_accounting_customer_id: parsedVals.filter((v: any) => v.supplier_type_id === 2).map((v: any) => v.supplier_accounting_customer_id),
                        supplier_external_id: parsedVals.filter((v: any) => v.supplier_type_id === 3).map((v: any) => v.supplier_external_id),
                    }
                }
            }
        },
    ];

    const [fetchParams, setFetchParams] = useAtom(fetchParamsAtom);
    const form = useRef<ProFormInstance>();

    useEffect(() => {
        // if fetchParams exist, set the form values
        if (Object.keys(fetchParams).length > 0) {
            let params = JSON.parse(JSON.stringify(fetchParams))
            // console.log('fetchParams', fetchParams)
            form.current?.setFieldsValue(fetchParams)
            // console.log('form.current?.getFieldsValue()', form.current?.getFieldsValue())

            let date_obj: any = {}
            if (params.date_issued_start) {
                date_obj['date_issued_start'] = dayjs.utc(params.date_issued_start)
            }
            if (params.date_issued_end) {
                date_obj['date_issued_end'] = dayjs.utc(params.date_issued_end)
            }
            if (params.due_date_start) {
                date_obj['due_date_start'] = dayjs.utc(params.due_date_start)
            }
            if (params.due_date_end) {
                date_obj['due_date_end'] = dayjs.utc(params.due_date_end)
            }
            form.current?.setFieldsValue({
                date: [date_obj.date_issued_start, date_obj.date_issued_end],
                due_date: [date_obj.due_date_start, date_obj.due_date_end],
            })

            // +Unified customer search implementation
            let supplier_search_obj: any = {}
            if (params.supplier_customer_id) {
                supplier_search_obj['supplier_customer_id'] = params.supplier_customer_id?.map((id: number) => JSON.stringify({
                    supplier_type_id: 1,
                    supplier_customer_id: id
                }))
            }
            if (params.supplier_accounting_customer_id) {
                supplier_search_obj['supplier_accounting_customer_id'] = params.supplier_accounting_customer_id?.map((id: number) => JSON.stringify({
                    supplier_type_id: 2,
                    supplier_accounting_customer_id: id
                }))
            }
            if (params.supplier_external_id) {
                supplier_search_obj['supplier_external_id'] = params.supplier_external_id?.map((id: number) => JSON.stringify({
                    supplier_type_id: 3,
                    supplier_external_id: id
                }))
            }
            let arr = []
            if (supplier_search_obj.supplier_customer_id) {
                arr.push(...supplier_search_obj.supplier_customer_id)
            }
            if (supplier_search_obj.supplier_accounting_customer_id) {
                arr.push(...supplier_search_obj.supplier_accounting_customer_id)
            }
            if (supplier_search_obj.supplier_external_id) {
                arr.push(...supplier_search_obj.supplier_external_id)
            }
            form.current?.setFieldsValue({supplier: arr})
        }
    }, [fetchParams]);

    useEffect(() => {
        // console.log({fetchParams})
        return () => {
            setFetchParams(RESET)
        }
    }, []);

    return (
        <>
            <Row style={{marginTop: 4, marginBottom: 16,}}>
                {data === undefined ? <Skeleton.Button block active/> :
                    <Space>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Amount Invoiced"
                                value={currencyFormatter.format(invoicedTotal)}
                                precision={2}
                                valueStyle={{fontSize: 16}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Amount VAT"
                                value={currencyFormatter.format(vatTotal)}
                                precision={2}
                                valueStyle={{fontSize: 16}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Amount Paid"
                                value={currencyFormatter.format(paidTotal)}
                                precision={2}
                                valueStyle={{fontSize: 16}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Amount to be Paid"
                                value={currencyFormatter.format(invoicedTotal + vatTotal - paidTotal)}
                                precision={2}
                                valueStyle={{fontSize: 16}}
                            />
                        </Card>
                        <Card bordered={false} size={"small"}>
                            <Statistic
                                title="Account Payables"
                                value={currencyFormatter.format(invoicedTotal)}
                                precision={2}
                                valueStyle={{color: '#cf1322', fontSize: 16}}
                            />
                        </Card>
                    </Space>
                }
            </Row>

            <ProTable
                columns={columns}
                request={params => {
                    // console.log(params)
                    return axiosApiInstance.get<API.LoggedInvoiceIncoming[]>(`/api/invoices/incoming/`, {params})
                }}
                onSubmit={async (params) => {
                    console.log({params})
                    setFetchParams(params)
                }}
                onReset={() => {
                    setFetchParams(RESET)
                }}
                actionRef={invoicesIncomingGlobalListRef}
                formRef={form}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false,
                    setting: false,
                    search: false,
                    // search: {
                    //     placeholder: "Search...",
                    //     onChange: (e) => {
                    //         setFilterSearch(e.target.value)
                    //     },
                    //     value: filterSearch,
                    //     allowClear: true,
                    //     enterButton: <SearchOutlined/>
                    // }
                }}
                search={{
                    searchText: 'Search',
                    defaultCollapsed: false,
                    defaultColsNumber: 3,
                }}
                pagination={{
                    pageSize: 10,
                }}
                // onRow={(record) => {
                //     return {
                //         onClick: () => {
                //             navigate(`${record.id}`)
                //         },
                //     };
                // }}
                toolBarRender={() => [
                    <Link to="new" state={{background: location}}>
                        <Button
                            icon={<PlusOutlined/>}
                            key="new"
                            type="primary"
                        >
                            New
                        </Button>
                    </Link>,
                    <Button
                        size={'large'}
                        type={'dashed'}
                        icon={<FileExcelOutlined style={{color: '#575757'}}/>}
                        onClick={() => {
                            buildAndDownloadXLSX();
                        }}
                        disabled={data === undefined}
                    />,
                    <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                        invoicesIncomingGlobalListRef?.current?.reload()
                    }}/>
                ]}
                params={fetchParams}
                onDataSourceChange={dataSource => setData(dataSource)}
                // onRequestError={e => console.log(e)}
            />


            <Outlet/>

        </>
    );
};

export default InvoicesIncomingGlobalTable;
import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Form, Space, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormMoney,
    ProFormRadio,
    ProFormSelect,
    ProFormText
} from "@ant-design/pro-components";
import {ProFormInstance} from "@ant-design/pro-form/es/BaseForm/BaseForm";
import {PageLoading} from "@ant-design/pro-layout";
import axiosApiInstance from "../../../../api/axiosClient";
import {GlobalStateContext} from "../../../../context/GlobalContext";
// import moment from "moment";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import currencyFormatter from "../../../../util/currency_formatter";

dayjs.extend(utc)

type Props = {
    editable: boolean
};

const DEFAULT_GROSS_MARGIN_P_OTC = 38

const InvoiceIncomingGlobal = (props: Props) => {
    const [form] = Form.useForm<API.LoggedInvoiceIncoming>();
    const navigate = useNavigate();
    const routerParams = useParams();
    // const [editable, setEditable] = useState<boolean>(false);
    // const [editable, setEditable] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const formRef = useRef<ProFormInstance>();
    const [invoiceData, setInvoiceData] = useState<API.LoggedInvoiceIncoming | undefined>(undefined);
    const {invoicesIncomingGlobalListRef, taxCategories} = useContext(GlobalStateContext);
    const [projectsData, setProjectsData] = useState<API.Project[] | undefined>(undefined);

    const fetchInvoiceData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.LoggedInvoiceIncoming>(`/api/invoices/incoming/${routerParams.invoiceId}`)
            // console.log(response.data)
            setInvoiceData(response.data);
            form.setFieldsValue(response.data)
            setLoading(false)
        } catch (e) {
            // console.log('catch error')
            setInvoiceData(undefined)
            setLoading(false)
        }

    }

    // const fetchInvoiceData = () => {
    //     setLoading(true)
    //     return axiosApiInstance.get<API.LoggedInvoiceIncoming>(`/api/invoices/incoming/${routerParams.invoiceId}`).then(response => {
    //         console.log(response.data)
    //         setInvoiceData(response.data);
    //         form.setFieldsValue(response.data)
    //         setLoading(false)
    //     }).catch((e) => {
    //         // console.log('catch error')
    //         setInvoiceData(undefined)
    //         setLoading(false)
    //     })
    //
    // }

    const fetchProjectsData = async () => {
        try {
            // setLoading(true)
            const response = await axiosApiInstance.get<API.Project[]>(`/api/projects/onexecution`)
            // console.log(response.data)
            setProjectsData(response.data);
            // setLoading(false)
            //
            // if (response.data.length > 0) {
            //     // setSelectedProjectId(response.data[0].id)
            //     form.setFieldsValue({project_id: response.data[0].id})
            // }
        } catch (e) {
            console.log('catch error')
            setProjectsData(undefined)
            // setLoading(false)
        }
    };

    useEffect(() => {
        // fetchProjectsData().then(fetchWbsVersionData);
        fetchProjectsData();
    }, []);

    const fetchWbsVersionData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.ExecutionWBSVersion>(`/api/projects/${invoiceData?.project_id}/structures/executions/active`)
            // console.log(response.data)
            setWbsVersionData(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setWbsVersionData(undefined)
            setLoading(false)
        }
    };

    useEffect(() => {
        if (invoiceData)
            fetchWbsVersionData()
    }, [invoiceData]);

    useEffect(() => {
        // fetchInvoiceData().then(fetchWbsVersionData);
        fetchInvoiceData();
    }, []);

    // if (loading) {
    //     return <PageLoading/>
    // }

    // console.log(taxCategories)

    return (
        <ModalForm<API.LoggedInvoiceIncoming>
            // title={`Deliverable ${routerParams.deliverableId}`}
            title={<Space>
                {invoiceData && <Typography.Text>Invoice #{routerParams.invoiceId}</Typography.Text>}
            </Space>}
            open={true}
            form={form}
            formRef={formRef}
            autoFocusFirstInput
            // width={"100%"}
            // width={"80%"}
            submitter={props.editable ? {} : false}
            modalProps={{
                destroyOnClose: true,
                okText: 'Save',
                okButtonProps: {hidden: !props.editable, loading: loading},
                onCancel: () => navigate(`/invoices/incoming`),
                bodyStyle: {height: props.editable ? "calc(100vh - 270px)" : "calc(100vh - 370px)", overflowY: 'auto'},
                style: {top: props.editable ? 40 : 90},
                // style: {top: 20},
            }}
            onFinish={async formData => {
                console.log(formData)
                // return
                return axiosApiInstance.put('/api/invoices/incoming/' + routerParams.invoiceId, formData)
                    .then(() => {
                        fetchInvoiceData();
                        invoicesIncomingGlobalListRef?.current?.reload()
                        return false
                    })
                // .then(()=>form.resetFields())
            }}
            validateMessages={{required: "${label} is required!"}}
            // request={() => {
            //     return axiosApiInstance.get<API.Deliverable>(`/api/deliverables/${routerParams.deliverableId}`)
            //         .then(value => {
            //             // console.log(value.data)
            //             return value.data
            //         })
            //     // return Promise.resolve({})
            // }}
            // initialValues={deliverableData}
            readonly={!props.editable}
            requiredMark={props.editable}
            // colon={true}
            // onValuesChange={(changedValues, values) => console.log(changedValues, values)}
        >

            {loading ? <PageLoading/> :
                <>

                    <ProFormDigit
                        name="id"
                        hidden={true}
                    />

                    <ProFormGroup label={'General Information'}>
                        <ProFormSelect
                            disabled
                            label={'Project'}
                            name={'project_id'}
                            width={'lg'}
                            rules={[{required: true}]}
                            placeholder={'Select Project'}
                            options={projectsData?.map(value => ({
                                label: `${value.title} (${value.project_pretty_id})`,
                                value: value.id
                            }))}

                        />
                        <ProFormGroup>
                            <ProFormText
                                width="sm"
                                name="invoice_number"
                                label="Invoice Number"
                                rules={[{required: true}]}
                            />
                            <ProFormDatePicker name={"date"} label={"Date Issued"}
                                               rules={[{required: true}]}
                                // transform={(value) => {
                                //     // console.log(value)
                                //     const d1 = moment(value).startOf('d').toISOString()
                                //     return {date: d1}
                                // }}
                                               normalize={(value) => {
                                                   // console.log(value.toISOString())
                                                   // console.log(value,value.toISOString(),new Date(value).toISOString(), dayjs.utc(value).toISOString())
                                                   // return new Date(value).toISOString()
                                                   return value.toISOString()
                                                   // return value
                                               }}
                            />
                            <ProFormDatePicker name={"due_date"} label={"Due Date"}
                                // rules={[{required: true}]}
                                // transform={(value) => {
                                //     // console.log(value)
                                //     const d1 = moment(value).startOf('d').toISOString()
                                //     return {date: d1}
                                // }}
                                               rules={[{required: true}]}
                                               normalize={(value) => {
                                                   // console.log(value)
                                                   // return new Date(value).toISOString()
                                                   // console.log(value.toISOString())
                                                   // console.log(value,value.toISOString(),new Date(value).toISOString(), dayjs.utc(value).toISOString())
                                                   return value.toISOString()
                                                   // return value
                                               }}
                                               fieldProps={{
                                                   presets: [
                                                       {
                                                           label: 'In 14 days',
                                                           value: dayjs.utc().add(14, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 30 days',
                                                           value: dayjs.utc().add(30, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 60 days',
                                                           value: dayjs.utc().add(60, 'd').startOf('d')
                                                       },
                                                       {
                                                           label: 'In 90 days',
                                                           value: dayjs.utc().add(90, 'd').startOf('d')
                                                       },
                                                   ]
                                               }}
                            />

                        </ProFormGroup>
                        <ProFormGroup>


                            {/*<ProFormDependency name={[['cost_id']]}>*/}
                            {/*    {({cost_id}) => {*/}
                            {/*        return */}
                            <ProFormMoney
                                // shouldUpdate={(prevValues, nextValues) => prevValues.cost_id!=nextValues.cost_id}
                                name="cost"
                                label={'Cost'}
                                customSymbol={'€'}
                                fieldProps={{
                                    numberFormatOptions: {currency: 'EUR'}
                                }}
                                rules={[{required: true},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            // console.log(value)

                                            const cost_id = getFieldValue('cost_id')
                                            let cost_type = getFieldValue('cost_type');
                                            if (cost_id == undefined || cost_type == undefined) {
                                                return Promise.resolve();
                                            }

                                            let costMeta: API.ThirdPartyCost | undefined = undefined
                                            // console.log(form.getFieldValue('cost_type'))
                                            if (cost_type === '_execution_third_party_time_costs') {
                                                wbsVersionData?.execution_deliverables?.map((d) => {
                                                    d.third_party_time_costs?.map((c) => {
                                                        if (c.id == cost_id) {
                                                            costMeta = c
                                                            return
                                                        }
                                                    })
                                                })
                                            } else {
                                                wbsVersionData?.execution_deliverables?.map((d) => {
                                                    d.third_party_material_costs?.map((c) => {
                                                        if (c.id == cost_id) {
                                                            costMeta = c
                                                            return
                                                        }
                                                    })
                                                })
                                            }
                                            // console.log(costMeta)
                                            if (!value || costMeta!.cost >= value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Cost exceeds the task budget'));
                                        },
                                        warningOnly: true,
                                    }),
                                ]}
                                width={"xs"}
                            />
                            {/*    }}*/}
                            {/*</ProFormDependency>*/}

                            <ProFormSelect
                                options={taxCategories?.map(i => ({label: i.label, value: i.id}))}
                                width="xs"
                                name="tax_category_id"
                                label="Tax category"
                                // formItemProps={{}}
                                fieldProps={{
                                    onSelect: (tax_category_id: number | undefined) => {
                                        // console.log('SELECT')
                                        form.setFieldValue(['tax_percentage'], taxCategories?.find(value => value.id == tax_category_id)?.tax_percentage)
                                        form.setFieldValue(['tax_category'], taxCategories?.find(value => value.id == tax_category_id))
                                    }
                                }}
                                rules={[{required: true}]}
                            />

                            <ProFormItem
                                name="tax_percentage"
                                hidden={true}
                            />

                            <ProFormItem
                                name="tax_category"
                                hidden={true}
                            />


                            {/*<ProFormMoney*/}
                            {/*    name="cost_incl_vat"*/}
                            {/*    label={'Cost Incl. VAT'}*/}
                            {/*    customSymbol={'€'}*/}
                            {/*    fieldProps={{*/}
                            {/*        numberFormatOptions: {currency: 'EUR'}*/}
                            {/*    }}*/}
                            {/*    rules={[{required: true}]}*/}
                            {/*    width={"xs"}*/}
                            {/*/>*/}


                            <ProFormDependency
                                name={[['cost'], ['tax_percentage']]}>
                                {({cost, tax_percentage}) => {
                                    // console.log("cost", effort, hourly_rate)
                                    form.setFieldValue('cost_incl_vat', (cost || 0) + (cost || 0) * (tax_percentage || 0))

                                    return (
                                        <ProFormMoney
                                            name="cost_incl_vat"
                                            label={'Cost Incl. VAT'}
                                            customSymbol={'€'}
                                            readonly
                                            fieldProps={{
                                                numberFormatOptions: {currency: 'EUR'}
                                            }}
                                            width={"xs"}
                                        />
                                    );
                                }}
                            </ProFormDependency>

                            <ProFormRadio.Group
                                valueEnum={{
                                    YES: {text: 'Yes', status: 'success'},
                                    NO: {text: 'No', status: 'error'},
                                    PARTIALLY: {text: 'Partially', status: 'warning'},
                                }}
                                radioType={"button"}
                                width="xl"
                                name="paid"
                                label="Paid"
                                tooltip={`Amount paid: ${currencyFormatter.format(invoiceData?.paid_amount!)}`}
                                readonly
                                // rules={[{required: true}]}
                            />

                        </ProFormGroup>
                    </ProFormGroup>

                    <ProFormGroup label={'Related Cost Center'}>
                        <ProFormSelect
                            valueEnum={{
                                _execution_third_party_time_costs: 'Time',
                                _execution_third_party_material_costs: 'Material',
                            }}
                            width={"sm"}
                            name="cost_type"
                            label="Cost Type"
                            rules={[{required: true}]}
                            fieldProps={{onSelect: () => form.setFieldValue(['cost_id'], undefined)}}
                        />

                        <ProFormDependency name={[['cost_type']]}>
                            {({cost_type}) => {
                                if (cost_type === '_execution_third_party_time_costs') {
                                    let options: any = []
                                    wbsVersionData?.execution_deliverables?.map((d) => {
                                        d.third_party_time_costs?.map((c) => {
                                            options.push({
                                                value: c.id,
                                                // value: JSON.stringify({deliverable_id: d.id, cost_id: c.id}),
                                                label: `${d.name} → ${c.description}`
                                            })
                                        })
                                    })
                                    // console.log(wbsVersionData?.execution_deliverables)
                                    return <ProFormSelect
                                        options={options}
                                        width={"md"}
                                        name="cost_id"
                                        label="Cost Center" // task
                                        rules={[{required: true}]}
                                        fieldProps={{
                                            onSelect: (cost_id: number) => {
                                                let costMeta: API.ThirdPartyCost
                                                // console.log(form.getFieldValue('cost_type'))
                                                if (form.getFieldValue('cost_type') === '_execution_third_party_time_costs') {
                                                    wbsVersionData?.execution_deliverables?.map((d) => {
                                                        d.third_party_time_costs?.map((c) => {
                                                            if (c.id == cost_id) {
                                                                costMeta = c
                                                                return
                                                            }
                                                        })
                                                    })
                                                } else {
                                                    wbsVersionData?.execution_deliverables?.map((d) => {
                                                        d.third_party_material_costs?.map((c) => {
                                                            if (c.id == cost_id) {
                                                                costMeta = c
                                                                return
                                                            }
                                                        })
                                                    })
                                                }
                                                // console.log(costMeta!)
                                                form.setFieldValue(['supplier_type_id'], costMeta!.supplier_type_id)
                                                if (costMeta!.supplier_type_id === 1) {
                                                    form.setFieldValue(['supplier_customer_id'], costMeta!.supplier_customer_id)

                                                    form.setFieldValue(['supplier_external_id'], null)
                                                    form.setFieldValue(['external_supplier'], null)
                                                } else {
                                                    form.setFieldValue(['supplier_external_id'], costMeta!.supplier_external_id)

                                                    form.setFieldValue(['supplier_customer_id'], null)
                                                    form.setFieldValue(['customer'], null)
                                                }
                                            }
                                        }}
                                    />
                                }
                                let options: any = []
                                wbsVersionData?.execution_deliverables?.map((d) => {
                                    d.third_party_material_costs?.map((c) => {
                                        options.push({
                                            value: c.id,
                                            // value: JSON.stringify({deliverable_id: d.id, cost_id: c.id}),
                                            label: `${d.name} → ${c.description}`
                                        })
                                    })
                                })
                                return <ProFormSelect
                                    options={options}
                                    width={"sm"}
                                    name="cost_id"
                                    label="Task" // task
                                    rules={[{required: true}]}
                                    fieldProps={{
                                        onSelect: (cost_id: number) => {
                                            let costMeta: API.ThirdPartyCost
                                            // console.log(form.getFieldValue('cost_type'))
                                            if (form.getFieldValue('cost_type') === '_execution_third_party_time_costs') {
                                                wbsVersionData?.execution_deliverables?.map((d) => {
                                                    d.third_party_time_costs?.map((c) => {
                                                        if (c.id == cost_id) {
                                                            costMeta = c
                                                            return
                                                        }
                                                    })
                                                })
                                            } else {
                                                wbsVersionData?.execution_deliverables?.map((d) => {
                                                    d.third_party_material_costs?.map((c) => {
                                                        if (c.id == cost_id) {
                                                            costMeta = c
                                                            return
                                                        }
                                                    })
                                                })
                                            }
                                            // console.log(costMeta!)
                                            if (costMeta!.supplier_type_id === 1) {
                                                form.setFieldValue(['supplier_customer_id'], costMeta!.supplier_customer_id)

                                                form.setFieldValue(['supplier_external_id'], null)
                                                form.setFieldValue(['external_supplier'], null)
                                            } else {
                                                form.setFieldValue(['supplier_external_id'], costMeta!.supplier_external_id)

                                                form.setFieldValue(['supplier_customer_id'], null)
                                                form.setFieldValue(['customer'], null)
                                            }
                                        }
                                    }}
                                />
                            }}
                        </ProFormDependency>

                    </ProFormGroup>

                    <ProFormGroup label={'Supplier'}>

                        <ProFormSelect
                            request={() =>
                                axiosApiInstance.get<API.SupplierType[]>('/api/suppliers/types')
                                    .then(res => {
                                        return res.data.map(i => ({label: i.name, value: i.id}))
                                    })
                            }
                            width={"sm"}
                            name="supplier_type_id"
                            label="Supplier Type"
                            rules={[{required: true}]}
                        />

                        <ProFormDependency name={[['supplier_type_id']]}>
                            {({supplier_type_id}) => {

                                // let costMeta: API.ThirdPartyCost
                                // if (form.getFieldValue('cost_type') === '_execution_third_party_time_costs') {
                                //     wbsVersionData?.execution_deliverables?.map((d) => {
                                //         d.third_party_time_costs?.map((c) => {
                                //             if (c.id == cost_id) {
                                //                 costMeta = c
                                //                 return
                                //             }
                                //         })
                                //     })
                                // } else {
                                //     wbsVersionData?.execution_deliverables?.map((d) => {
                                //         d.third_party_material_costs?.map((c) => {
                                //             if (c.id == cost_id) {
                                //                 costMeta = c
                                //                 return
                                //             }
                                //         })
                                //     })
                                // }

                                if (supplier_type_id === 1) {
                                    form.setFieldValue(['supplier_external_id'], null)
                                    form.setFieldValue(['external_supplier'], null)
                                    return <ProFormSelect
                                        request={() =>
                                            axiosApiInstance.get<API.Customer[]>('/api/customers/')
                                                .then(res => {
                                                    return res.data.map(i => ({
                                                        label: `${i.name} [${i.id}]`,
                                                        value: i.id
                                                    }))
                                                })
                                        }
                                        width={"md"}
                                        name="supplier_customer_id"
                                        label="Customer"
                                        rules={[{required: true}]}
                                        fieldProps={{
                                            showSearch: true, filterOption: (input, option) =>
                                                (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
                                        }}
                                    />
                                }
                                form.setFieldValue(['supplier_customer_id'], null)
                                form.setFieldValue(['customer'], null)
                                return (
                                    <ProFormSelect
                                        request={() =>
                                            axiosApiInstance.get<API.ExternalSupplier[]>('/api/suppliers/')
                                                .then(res => {
                                                    // console.log(res.data)
                                                    return res.data.map(i => ({label: i.name, value: i.id}))
                                                })
                                        }
                                        width="sm"
                                        name="supplier_external_id"
                                        label="External Supplier"
                                        rules={[{required: true}]}
                                    />
                                );
                            }}
                        </ProFormDependency>

                    </ProFormGroup>

                    {/*<ProFormText*/}
                    {/*    name="description"*/}
                    {/*    label={'Description'}*/}
                    {/*/>*/}

                    <ProFormGroup label={'Other'}>
                        <ProFormText
                            width="xl"
                            name="file_url"
                            label="File URL"
                            // rules={[{required: true}]}
                        />
                    </ProFormGroup>
                    {/*</ProFormGroup>*/}
                    {/*</ProCard>*/}


                </>
            }

        </ModalForm>
    );
};

export default InvoiceIncomingGlobal;

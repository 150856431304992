import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ProLayout} from "@ant-design/pro-layout";
import {
    BarChartOutlined,
    BellFilled,
    BookOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    DollarOutlined,
    HomeOutlined,
    InfoCircleFilled, LineChartOutlined,
    SearchOutlined,
    StockOutlined
} from "@ant-design/icons";
import {ProSettings} from "@ant-design/pro-components";
// import enUS from "antd/locale/en_US";
import {Avatar, Badge, Dropdown, Input} from "antd";
import {Link, Outlet, useLocation} from "react-router-dom";
// import {ReactComponent as LogoCompactTrans} from '../assets/dm_logo_compact_trans.svg';
import {ReactComponent as Logo} from '../assets/dm_logo_long.svg';
import {UserAuth} from "../context/AuthContext";
import {AvatarDropdownMenu} from "../components/topbar/AvatarDropdownMenu";
import {NotificationsDropdownMenu} from "../components/topbar/NotificationsDropdownMenu";
import {GlobalStateContext} from "../context/GlobalContext";
import axiosApiInstance from "../api/axiosClient";
import getStorageValue from "../util/get_local_storage_value";

type Props = {};

export const MyLayout = (props: Props) => {
    const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: true,
    });

    // const [pathname, setPathname] = useState('/list/sub-page/sub-sub-page1');
    const [num, setNum] = useState(40);
    const location = useLocation();
    const {notifications, setNotifications} = useContext(GlobalStateContext)

    const {user} = UserAuth();

    const fetchNotificationsData = async () => {
        try {
            const response = await axiosApiInstance.get<API.NotificationVolatile[]>(`/api/notifications/volatile`)
            // console.log(response.data)
            const prevNotifications = getStorageValue('notifications', []);
            let arr = response.data?.filter((i) => !prevNotifications.includes(i.message))
            if (setNotifications) {
                setNotifications(arr);
            }
        } catch (e) {
            console.log('catch error')
        }
    };

    useEffect(() => {
        fetchNotificationsData()
    }, [])


    return (
        <div id="test-pro-layout"
             style={{
                 // height: '100vh',
                 // backgroundColor: "#F5F5F5"
                 // color: "#F5F5F5"
             }}>

            <ProLayout
                // loading={true}
                // theme={"dark"}
                // menuProps={{style: {backgroundColor:"#001529"}}}
                location={location}
                // contentStyle={{backgroundColor: "#F0F2F5", minHeight: "94vh"}}
                // contentStyle={{backgroundColor: "#F6F7F9", minHeight: "94vh"}}
                // contentStyle={{backgroundColor: "#F4F5F8", minHeight: "94vh"}}
                // contentStyle={{backgroundColor: "#F5F5F5", minHeight: "94vh"}} // works
                // contentStyle={{backgroundColor: "#F5F5F5", padding:0}}
                // contentStyle={{backgroundColor: "#F5F5F5"}}
                // token={{bgLayout: "#F5F5F5",}}
                // style={{backgroundColor: "#F5F5F5", padding:0}}
                // style={{padding:0}}
                // contentStyle={{backgroundColor: "#FBFCFC", minHeight: "94vh"}}
                // style={{backgroundColor: "#F0F2F5"}} // todo
                menuItemRender={(item: any, defaultDom: any) => {
                    // to handle permissions : define another ROUTE object for each possible 'view' of the menu
                    // console.log({item})
                    // if (item.path == '/proposals') {
                    //     return <Access accessible={false} fallback={<></>}>
                    //         <Link to={item.path}> {defaultDom} </Link>
                    //     </Access>
                    // }
                    //
                    return <Link to={item.path}> {defaultDom} </Link>

                }}
                subMenuItemRender={(item: any, defaultDom: any) => <Link to={item.path}> {defaultDom} </Link>}

                // location={{
                //     pathname,
                // }}
                route={{
                    path: '/',
                    // name: 'Home',
                    routes: [
                        {
                            path: '/home',
                            name: 'Home',
                            icon: <HomeOutlined/>,
                            // component: './Welcome',
                        },
                        {
                            path: '/sales',
                            name: 'Sales',
                            icon: <StockOutlined/>,
                        },
                        // {
                        //     path: '/structures',
                        //     name: 'Work Breakdown Structures',
                        //     icon: <BookOutlined/>,
                        //     hideInMenu: true,
                        //     routes: [
                        //         {
                        //             path: '/structures/:wbsVersionId',
                        //             hideInMenu: true,
                        //             name: 'Work Breakdown Structure',
                        //         },
                        //
                        //     ],
                        // },
                        {
                            path: '/projects',
                            name: 'Cases',
                            icon: <CalendarOutlined/>,
                            routes: [
                                {
                                    path: '/projects/:projectId', hideInMenu: true, name: 'Case',
                                    routes: [
                                        // {
                                        //     path: '/projects/:projectId/structures',
                                        //     name: 'Work Breakdown Structures',
                                        //     icon: <BookOutlined/>,
                                        //     hideInMenu: true,
                                        //     routes: [
                                        //         {
                                        //             path: '/projects/:projectId/structures/:wbsVersionId',
                                        //             hideInMenu: true,
                                        //             name: 'Work Breakdown Structure',
                                        //         },
                                        //
                                        //     ],
                                        // },
                                        {
                                            path: '/projects/:projectId/proposals',
                                            name: 'Proposals',
                                            icon: <BookOutlined/>,
                                            routes: [
                                                {
                                                    path: '/projects/:projectId/proposals/:proposedWbsVersionId',
                                                    hideInMenu: true,
                                                    name: 'Proposal',
                                                }
                                            ]
                                        },
                                        {
                                            path: '/projects/:projectId/executions',
                                            name: 'Executions',
                                            icon: <BookOutlined/>,
                                            routes: [
                                                {
                                                    path: '/projects/:projectId/executions/:executionWbsVersionId',
                                                    hideInMenu: true,
                                                    name: 'Execution',
                                                }
                                            ]
                                        }
                                    ]
                                },
                            ],
                        },
                        {
                            path: '/invoices',
                            name: 'Invoices',
                            icon: <DollarOutlined/>,
                            // component: './Welcome',
                        },
                        {
                            path: '/reporting',
                            name: 'Reporting',
                            icon: <LineChartOutlined/>,
                            // component: './Welcome',
                        },
                        {
                            path: '/tracker',
                            name: 'Time Tracker',
                            icon: <ClockCircleOutlined/>,
                            // component: './Welcome',
                        },
                        {
                            path: '/materials',
                            name: 'Materials Ulitilization',
                            icon: <BarChartOutlined/>,
                            // component: './Welcome',
                        },
                    ]
                }}
                appList={
                    [
                        {
                            // icon: 'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
                            icon: <></>,
                            // icon: <LinkOutlined />,
                            title: 'Main Platform',
                            desc: 'CRM, ERP and reporting modules',
                            url: 'http://app.digitalminds-dashboard.com',
                            target: '_blank',
                        },
                        {
                            // icon: <LinkOutlined />,
                            icon: <></>,
                            title: 'Portal',
                            desc: 'Customer and Internal Portal',
                            url: 'http://portal.digitalminds-dashboard.com',
                            target: '_blank',
                        },
                        {
                            // icon: <LinkOutlined />,
                            icon: <></>,
                            title: 'RBAC',
                            desc: 'Role based access management module',
                            url: 'http://rbac.digitalminds-dashboard.com',
                            target: '_blank',
                        },
                        {
                            // icon: <LinkOutlined />,
                            icon: <></>,
                            title: 'Bulk Payments',
                            desc: 'Paypal bulk payments management module',
                            url: 'http://bulk.digitalminds-dashboard.com',
                            target: '_blank',
                        }
                    ]
                }
                // {...settings}
                // bgLayoutImgList={[
                //     {
                //         src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
                //         left: 85,
                //         bottom: 100,
                //         height: '303px',
                //     },
                //     {
                //         src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
                //         bottom: -68,
                //         right: -45,
                //         height: '303px',
                //     },
                //     {
                //         src: 'https://img.alicdn.com/imgextra/i3/O1CN018NxReL1shX85Yz6Cx_!!6000000005798-2-tps-884-496.png',
                //         bottom: 0,
                //         left: 0,
                //         width: '331px',
                //     },
                // ]}
                siderMenuType="group"
                menu={{
                    collapsedShowGroupTitle: true,
                }}

                // avatarProps={{
                //     // src: 'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
                //     src: user?.photoURL,
                //     size: 'small',
                //     // title: user?.displayName,
                //     children: <>
                //         <Dropdown overlay={<AvatarDropdownMenu/>}>
                //             <Button>bottomLeft</Button>
                //         </Dropdown>
                //     </>
                //
                // }}
                actionsRender={(props) => {
                    if (props.isMobile) return [];
                    return [
                        props.layout !== 'side' && document.body.clientWidth > 1400 ? (
                            <div
                                key="SearchOutlined"
                                aria-hidden
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginInlineEnd: 24,
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <Input
                                    style={{
                                        borderRadius: 4,
                                        marginInlineEnd: 0,
                                        backgroundColor: 'rgba(0,0,0,0.03)',
                                    }}
                                    prefix={
                                        <SearchOutlined
                                            style={{
                                                color: 'rgba(0, 0, 0, 0.15)',
                                            }}
                                        />
                                    }
                                    placeholder="search"
                                    bordered={false}
                                />
                            </div>
                        ) : undefined,
                        <InfoCircleFilled key="InfoCircleFilled" style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginInlineEnd: 16,
                        }}/>,
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginInlineEnd: 24,
                        }}>
                            <Dropdown overlay={<NotificationsDropdownMenu/>} placement={"bottomRight"}
                                      trigger={['click']}
                                // open={true}
                            >
                                <Badge count={notifications?.length || 0} offset={[4, -4]}>
                                    <BellFilled key="BellFilled"/>
                                </Badge>
                            </Dropdown>
                        </div>,
                        // <BellFilled key="BellFilled" style={{
                        //     display: 'flex',
                        //     alignItems: 'center',
                        //     marginInlineEnd: 24,
                        // }}/>,
                        // <Space>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginInlineEnd: 32,
                        }}>
                            <Dropdown overlay={<AvatarDropdownMenu/>}>
                                <Avatar
                                    src={user?.photoURL}
                                    // size={'small'}
                                />
                            </Dropdown>
                        </div>

                        // </Space>
                        // <QuestionCircleFilled key="QuestionCircleFilled"/>,
                        // <GithubFilled key="GithubFilled"/>,
                    ];
                }}
                menuFooterRender={(props) => {
                    if (props?.collapsed) return undefined;
                    return (
                        <div
                            style={{
                                textAlign: 'center',
                                paddingBlockStart: 12,
                            }}
                        >
                            <div>© 2022 Digital Minds</div>
                            {/*<div></div>*/}
                        </div>
                    );
                }}
                // onMenuHeaderClick={(e) => console.log('onMenuHeaderClick', e)}
                // menuItemRender={(item, dom) => (
                //     <div
                //         onClick={() => {
                //             setPathname(item.path || '/welcome');
                //         }}
                //     >
                //         {dom}
                //     </div>
                // )}
                logo={
                    <Link to="/home">
                        <Logo fill='#0E3748' width={170}
                            // style={{marginBottom: -20, marginLeft: -8, marginRight: -32}}
                              style={{marginBottom: -20, marginLeft: -20, marginRight: -4}}
                        />
                        {/*<LogoCompactTrans fill='#0E3748' width={30} style={{marginBottom: -20, marginRight: 8}}/>*/}
                    </Link>
                }
                // title={false}
                title={'Brands'}

                {...settings}
            >

                {/*<PageContainer*/}
                {/*    // token={{*/}
                {/*    //     paddingInlinePageContainerContent: num,*/}
                {/*    // }}*/}
                {/*    // extra={[*/}
                {/*    //     // <Button key="3">3</Button>,*/}
                {/*    //     // <Button key="2">2</Button>,*/}
                {/*    //     <Button*/}
                {/*    //         key="1"*/}
                {/*    //         type="primary"*/}
                {/*    //         onClick={() => {*/}
                {/*    //             setNum(num > 0 ? 0 : 40);*/}
                {/*    //         }}*/}
                {/*    //         // icon={<ColumnWidthOutlined />}*/}
                {/*    //         // icon={<SmallDashOutlined />}*/}
                {/*    //     >*/}
                {/*    //         Action*/}
                {/*    //     </Button>,*/}
                {/*    // ]}*/}
                {/*    // subTitle="Sub Title"*/}
                {/*    // footer={[*/}
                {/*    //     <Button key="3">3</Button>,*/}
                {/*    //     <Button key="2" type="primary">*/}
                {/*    //         2*/}
                {/*    //     </Button>,*/}
                {/*    // ]}*/}
                {/*>*/}
                {/*<ProCard*/}
                {/*    style={{*/}
                {/*        height: '200vh',*/}
                {/*        minHeight: 800,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div />*/}
                {/*</ProCard>*/}

                {/*<TableTest/>*/}

                <Outlet/>

                {/*<SettingDrawer*/}
                {/*    // pathname={location.pathname}*/}
                {/*    enableDarkTheme*/}
                {/*    getContainer={() => document.getElementById('test-pro-layout')}*/}
                {/*    settings={settings}*/}
                {/*    onSettingChange={(changeSetting) => {*/}
                {/*        setSetting(changeSetting);*/}
                {/*    }}*/}
                {/*    // disableUrlParams={false}*/}
                {/*/>*/}

                {/*</PageContainer>*/}

            </ProLayout>


        </div>
    );
};


import * as React from 'react';
import {PageContainer} from "@ant-design/pro-layout";
import CumulativeNetChart from "../components/reporting/CumulativeNetChart";
import {Skeleton} from "antd";
import InvoicesIncomingGlobalTable from "./tracking/InvoicesIncomingGlobalTable";
import {Suspense} from "react";

type Props = {};

export const ReportingPage = (props: Props) => {
    return (
        <PageContainer>
            <Suspense fallback={<Skeleton.Button active block/>}>
                <CumulativeNetChart/>
            </Suspense>

            {/*    other reporting features  */}

        </PageContainer>
    );
};

export default ReportingPage;
import {LightFilter, ProFormSelect, ProList} from '@ant-design/pro-components';
import {Button, Form, Grid, Progress, Segmented, Space, Tag, Tooltip, Typography} from 'antd';
import {
    DeleteOutlined,
    FolderOutlined,
    ForwardOutlined,
    GroupOutlined,
    PieChartOutlined,
    TableOutlined,
    TagOutlined
} from '@ant-design/icons';
import React, {useCallback, useEffect, useState} from 'react';
import {useSalesContext} from '../../context/SalesContextProvider';
import OpportunityBoxCard from './OpportunityBoxCard';
import OpportunityCard, {DelayedTooltip} from './OpportunityCard';
import ExportExcel from './ExportExcel';
import {Link, Outlet, useLocation, useSearchParams} from 'react-router-dom';
import NewOpportunityButton from "./NewOpportunityButton";
import {ProTable} from "@ant-design/pro-table";
import {ListGridType} from "antd/es/list";
import currencyFormatter from "../../util/currency_formatter";
import dayjs from "dayjs";

const {useBreakpoint} = Grid;

type RenderItem = ({target}: { target: API.Opportunity }) => JSX.Element;
type Layout = 'Scrolls' | 'Grid' | 'Table';

// enum
enum LayoutEnum {
    Scrolls = 'Scrolls',
    Grid = 'Grid',
    Table = 'Table'
}

type Option = { value: string; icon: any };

const segmentedOpts: Array<Option> = [
    {
        value: LayoutEnum.Table,
        icon: <TableOutlined/>
    },
    {
        value: LayoutEnum.Scrolls,
        icon: <GroupOutlined/>
    },
    {
        value: LayoutEnum.Grid,
        icon: <PieChartOutlined/>
    }
    // {
    //     value: 'List',
    //     icon: <UnorderedListOutlined />
    // }
];

const orderByEnum = {
    '-expected_po_date': 'Expected PO Date (Latest First)',
    'expected_po_date': 'Expected PO Date (Oldest First)',
    '-created_at': 'Created (Newest First)',
    'created_at': 'Created (Oldest First)',
    '-proposed_value_in_euro': 'Deal Size/Unweighted (Highest First)',
    'proposed_value_in_euro': 'Deal Size/Unweighted (Lowest First)',
    '-po_value_in_euro': 'Weighted (Highest First)',
    'po_value_in_euro': 'Weighted (Lowest First)',
    '-opportunity_phase_id': 'Phase (Highest First)',
    'opportunity_phase_id': 'Phase (Lowest First)',
};

/**
 * Object που περιέχει react components που δέχονται ως είσοδο ενα opportunity, λειτουργεί ως map για να καθορίσει
 * πως θα γινονται render τα opportunities μεσα στην λίστα
 */
const renderItems: { [k: string]: RenderItem } = {
    [LayoutEnum.Scrolls]: OpportunityCard,
    // Scrolls: ({ target }: { target: API.Opportunity }) => <>Scrolls {target.id}</>,
    [LayoutEnum.Grid]: OpportunityBoxCard,
    [LayoutEnum.Table]: () => <></>,
};

/**
 * Το frame της λιστας των opportunities. Περιέχει το export to excel και την επιλογή layout στο toolbar.
 * Τα columns στα οποία είναι χωρισμένη αλλάζουν δυναμικά ανάλογα με το μέγεθος του παραθύρου και του layout
 * Ο τρόπος που εμφανίζονται τα opportunities καθορίζεται μέσω του renderItem prop
 * @returns
 */
const OppListNew = () => {
    const {opportunities, searchFilter: search} = useSalesContext();
    const [opportunityList, setOpportunityList] = useState<API.Opportunity[]>(opportunities);
    const [searchParams, setSearchParams] = useSearchParams();
    const [layout, setLayout] = useState<Layout>((searchParams.get('layout') as Layout) || LayoutEnum.Scrolls);
    const [grid, setGrid] = useState<ListGridType>({gutter: 16});
    const [renderList, setRenderList] = useState<boolean>(true);
    const [orderBy, setOrderBy] = useState<string>('-expected_po_date');
    const [form] = Form.useForm<{ order_by: string }>();

    // Αποθηκεύει το layout της σελίδας στο search param
    const setParams = useCallback(async (v: string) => {
        searchParams.set('layout', v);
        setSearchParams((p) => p);
    }, []);

    const getOpportunitiesSortedBy = (orderBy: string) => {
        return [...opportunities]
            .sort((prev, next) => {
                // sort by expected_po_date by default, newest first, unless otherwise specified in the orderBy state
                // should also sory according to the orderBy property type
                // console.log('prev', prev, 'next', next)
                const descending = orderBy[0] === '-';
                const orderProperty = orderBy.replace('-', '') as keyof API.Opportunity
                const prevValue = prev[orderProperty]!;
                const nextValue = next[orderProperty]!;
                // console.log('prevValue', prevValue, 'nextValue', nextValue)
                if (typeof prevValue === 'string') { // if string, sort by date
                    if (descending)
                        return dayjs(nextValue as string).valueOf() - dayjs(prevValue as string).valueOf();
                    else
                        return dayjs(prevValue as string).valueOf() - dayjs(nextValue as string).valueOf();
                } else if (typeof prevValue === 'number') {
                    if (descending)
                        return nextValue as number - prevValue as number;
                    else
                        return prevValue as number - +nextValue;
                } else
                    return 0;
            })
    }

    // const sortOpportunitiesBy = (orderBy: string) => {
    //     // console.log('orderBy updated', opportunityList)
    //     setOpportunityList(
    //         getOpportunitiesSortedBy(orderBy)
    //     )
    // };

    useEffect(() => {
        setOpportunityList(
            getOpportunitiesSortedBy(orderBy)
                .filter((opp) => !opp.parent_id || opp.is_compound)
            // opportunities
        );
        // sortOpportunitiesBy('-expected_po_date')
    }, [search, orderBy, opportunities]); // filter opportunities when search

    // useEffect(() => {
    //     setOpportunityList(
    //         getOpportunitiesSortedBy('-expected_po_date')
    //     );
    // }, [opportunities]); // filter opportunities when search

    // useEffect(() => {
    //     console.log('opportunityList updated', opportunityList)
    //     if (so
    // }, [opportunityList]); // refresh local copy of list every time opportunities is updated in context

    useEffect(() => {
        // const isWindowSmall = window.innerWidth < LIST_MIN_WIDTH;
        const layout = searchParams.get('layout') || LayoutEnum.Scrolls;
        if (layout === LayoutEnum.Scrolls) {
            // setGrid({xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1, gutter: 16})
            setGrid({xxl: 5, xl: 4, lg: 3, md: 2, sm: 1, xs: 1, gutter: 16})
        }
        if (layout === LayoutEnum.Grid) {
            setGrid({xxl: 4, xl: 3, lg: 2, md: 2, sm: 1, xs: 1, gutter: 16})
        }
        setLayout(layout as Layout);
    }, [searchParams]);


    useEffect(() => {
        // console.log('grid', grid);
        setRenderList(false);
        // setRenderList(true)
    }, [grid]);

    useEffect(() => {
        if (!renderList)
            setRenderList(true)
    }, [renderList]);

    useEffect(() => {
        setOrderBy(searchParams.get('order_by') || '-expected_po_date');
        form.setFieldsValue({order_by: searchParams.get('order_by') || '-expected_po_date'})
    }, []);
    const location = useLocation();

    const onSortFinish = async ({order_by}: any) => {
        // console.log(order_by);
        // sortOpportunitiesBy(order_by);
        setOrderBy(order_by);
        searchParams.set('order_by', order_by);
        setSearchParams(searchParams);
        return true;
    }

    return (
        <>
            {layout === LayoutEnum.Table ? (
                <>
                    {/*<Empty/>*/}
                    <ProTable<API.Opportunity>
                        columns={[
                            {
                                title: 'Opportunity',
                                dataIndex: 'title',
                                key: 'title',
                                render: (text, record) => (
                                    <Space size={6}>
                                        <TagOutlined/>
                                        <Link to={{pathname: `${record.id}`, search: location.search}}>
                                            <b style={{color: '#1d1d1b'}}>{text}</b>
                                        </Link>
                                        {record.is_compound && <Tag color='volcano'> Compound</Tag>}
                                    </Space>
                                ),
                            },
                            {
                                title: 'Opportunity Type',
                                dataIndex: 'opportunity_type',
                                key: 'opportunity_type',
                                render: (text, record) => (
                                    <DelayedTooltip title='Opportunity Type'>
                                        <Tag color='green'>{record.opportunity_type.name}</Tag>
                                    </DelayedTooltip>
                                ),
                            },
                            {
                                title: 'Opportunity ID',
                                dataIndex: 'opportunity_id',
                                key: 'opportunity_id',
                                render: (text, record) => (
                                    <DelayedTooltip title='Opportunity Pretty ID'>
                                        <Tag color='blue'>
                                            {record.project_pretty_id || record.opportunity_pretty_id}
                                        </Tag>
                                    </DelayedTooltip>
                                ),
                            },
                            {
                                title: 'Expected PO Date',
                                dataIndex: 'expected_po_date',
                                key: 'expected_po_date',
                                render: (text, record) => (
                                    <Typography.Text>{dayjs(record.expected_po_date).format('DD/MM/YYYY')}</Typography.Text>
                                ),
                            },
                            {
                                title: 'Customer',
                                dataIndex: 'customer',
                                key: 'customer',
                                align: 'center',
                                render: (text, record) => (
                                    <Typography.Paragraph italic style={{textAlign: 'center'}}
                                                          ellipsis={{rows: 3, tooltip: true}}>
                                        {record.customer?.name || record.external_buyer?.name || record.accounting_customer?.name}
                                    </Typography.Paragraph>
                                ),
                            },
                            {
                                title: 'Manager',
                                dataIndex: 'employee',
                                key: 'employee',
                                render: (text, record) => (
                                    <Typography.Text italic style={{display: 'flex', textAlign: 'center'}}>
                                        {record.employee.name}
                                    </Typography.Text>
                                ),
                            },
                            {
                                title: 'Deal Size',
                                dataIndex: 'proposed_value_in_euro',
                                key: 'proposed_value_in_euro',
                                render: (text, record) => <Typography.Text
                                    type='success'>{currencyFormatter.format(record.proposed_value_in_euro!)}</Typography.Text>,
                                // sorter: (a, b) => a.proposed_value_in_euro! - b.proposed_value_in_euro!,
                                //     always present the column in full width
                                width: 100,
                            },
                            {
                                title: 'Weighted',
                                dataIndex: 'po_value_in_euro',
                                key: 'po_value_in_euro',
                                render: (text, record) => <Typography.Text
                                    type='success'>{currencyFormatter.format(record.po_value_in_euro!)}</Typography.Text>,
                                // sorter: (a, b) => a.po_value_in_euro! - b.po_value_in_euro!,
                                width: 100,
                            },
                            {
                                title: 'Completion',
                                dataIndex: 'opportunity_phase',
                                key: 'opportunity_phase',
                                // width: 60,
                                render: (text, record) => {
                                    if (record.is_compound) {
                                        // return null
                                        // return 'N/A'
                                        return <Typography.Text italic style={{display: 'flex', textAlign: 'center'}}>
                                            N/A
                                        </Typography.Text>
                                    }
                                    return <Progress
                                        status={record.opportunity_phase.completion_percentage === 0 ? 'exception' : undefined}
                                        percent={record.opportunity_phase.completion_percentage * 100}
                                    />
                                },
                                // sorter: (a, b) => a.opportunity_phase.completion_percentage - b.opportunity_phase.completion_percentage,
                            },
                            {
                                title: 'Actions',
                                dataIndex: 'actions',
                                key: 'actions',
                                render: (text, record) => (
                                    <Space direction='horizontal' size={0}>
                                        {!record.is_compound && <>
                                            <Link to={{pathname: `advance/${record.id}`, search: location.search}}
                                                  state={{background: location}}>
                                                <Tooltip title={'Advance'} placement={'top'}>
                                                    <Button
                                                        type="link"
                                                        disabled={record.opportunity_phase.completion_percentage === 0}
                                                        icon={<ForwardOutlined/>}
                                                    />
                                                </Tooltip>
                                            </Link>
                                            <Link
                                                to={`/projects/by_pretty_id/${record.project_pretty_id?.replaceAll('/', '_')}`}>
                                                <Tooltip title={'Advance'} placement={'top'}>
                                                    <Button type="link" icon={<FolderOutlined/>}/>
                                                </Tooltip>
                                            </Link>
                                        </>}
                                        <Link to={{pathname: `delete/${record.id}`, search: location.search}}
                                              state={{background: location}}>
                                            <Tooltip title={'Delete'} placement={'top'}>
                                                <Button type="link" danger icon={<DeleteOutlined/>}/>
                                            </Tooltip>
                                        </Link>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={opportunityList}
                        headerTitle={
                            <Space><span>Sales Opportunities</span>
                                <Segmented
                                    options={segmentedOpts}
                                    value={LayoutEnum.Table}
                                    onChange={(v) => {
                                        // setLayout(v as Layout);
                                        setParams(v as string);
                                    }}
                                    // onClick={() => console.log('on click')}
                                />
                            </Space>
                        }
                        rowKey='id'
                        search={false}
                        pagination={{
                            defaultPageSize: 8,
                            pageSizeOptions: [4, 8, 12, 16, 24],
                            // onChange: (page, pageSize) => {
                            //     console.log(page, pageSize);
                            // }

                        }}
                        options={{
                            density: false,
                            fullScreen: false,
                            reload: false,
                            setting: false,
                        }}
                        toolBarRender={() => {

                            return [
                                <LightFilter<{
                                    order_by: string;
                                }>
                                    form={form}
                                    onFinish={onSortFinish}
                                >
                                    <ProFormSelect
                                        name='order_by'
                                        label='Sort by'
                                        valueEnum={orderByEnum}
                                        allowClear={false}
                                    />
                                </LightFilter>,
                                <NewOpportunityButton/>,
                                <ExportExcel opportunities={opportunities}/>,
                                // <Segmented
                                //     options={segmentedOpts}
                                //     value={LayoutEnum.Table}
                                //     onChange={(v) => {
                                //         // setLayout(v as Layout);
                                //         setParams(v as string);
                                //     }}
                                //     // onClick={() => console.log('on click')}
                                // />
                            ];
                        }}
                    />
                </>
            ) : <>
                {renderList &&
                    <ProList<API.Opportunity>
                        rowKey='id'
                        headerTitle={
                            <Space><span>Sales Opportunities</span>
                                <Segmented
                                    options={segmentedOpts}
                                    defaultValue={LayoutEnum.Scrolls}
                                    value={(searchParams.get('layout') as Layout) || LayoutEnum.Scrolls}
                                    onChange={(v) => {
                                        // setLayout(v as Layout);
                                        setParams(v as string);
                                    }}
                                    // onClick={() => console.log('on click')}
                                />
                            </Space>
                        }
                        cardBordered
                        pagination={{
                            defaultPageSize: 10,
                            pageSizeOptions: [5, 10, 15, 20, 30],
                            // onChange: (page, pageSize) => {
                            //     console.log(page, pageSize);
                            // }

                        }}
                        toolBarRender={() => [
                            <LightFilter<{
                                order_by: string;
                            }>
                                form={form}
                                onFinish={onSortFinish}
                            >
                                <ProFormSelect
                                    name='order_by'
                                    label='Sort by'
                                    valueEnum={orderByEnum}
                                    allowClear={false}
                                />
                            </LightFilter>,
                            <NewOpportunityButton/>,
                            <ExportExcel opportunities={opportunities}/>,
                            // <Segmented
                            //     options={segmentedOpts}
                            //     defaultValue={LayoutEnum.Scrolls}
                            //     value={(searchParams.get('layout') as Layout) || LayoutEnum.Scrolls}
                            //     onChange={(v) => {
                            //         // setLayout(v as Layout);
                            //         setParams(v as string);
                            //     }}
                            //     // onClick={() => console.log('on click')}
                            // />
                        ]}
                        dataSource={opportunityList}
                        // renderItem={(opp) => renderItems[layout]({
                        //     target: opp,
                        // })}
                        renderItem={(opp) => {
                            if (layout === LayoutEnum.Scrolls) {
                                return <OpportunityCard target={opp} key={opp.id}/>
                            } else if (layout === LayoutEnum.Grid) {
                                return <OpportunityBoxCard target={opp} key={opp.id}/>
                            }
                            return <OpportunityCard target={opp} key={opp.id}/>
                        }}
                        // grid={{column: column}}
                        grid={{...grid}}
                        // showActions={'hover'}
                    />
                }
            </>
            }
            <Outlet/>
        </>
    );
};

export default OppListNew;

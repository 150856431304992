import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormMoney,
    ProFormRadio,
    ProFormSelect,
    ProFormText
} from "@ant-design/pro-components";
import {Form} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import {GlobalStateContext} from "../../context/GlobalContext";
import axiosApiInstance from "../../api/axiosClient";
dayjs.extend(utc)

type Props = {};

const NewLoggedPayment = (props: Props) => {
    const [form] = Form.useForm<API.LoggedPayment>();
    const navigate = useNavigate();
    const routerParams = useParams();
    // const {executionDeliverablesListRef, executionWBSVersionRef} = useContext(GlobalStateContext);
    const {loggedPaymentsListRef} = useContext(GlobalStateContext);
    const [incomingInvoices, setIncomingInvoices] = useState<API.LoggedInvoiceIncoming[] | undefined>(undefined);
    const [outgoingInvoices, setOutgoingInvoices] = useState<API.LoggedInvoiceOutgoing[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchIncomingInvoices = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.LoggedInvoiceIncoming[]>(`/api/projects/${routerParams.projectId}/invoices/incoming`)
            // console.log(response.data)
            setIncomingInvoices(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setIncomingInvoices(undefined)
            setLoading(false)
        }
    };

    const fetchOutgoingInvoices = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.LoggedInvoiceOutgoing[]>(`/api/projects/${routerParams.projectId}/invoices/outgoing`)
            // console.log(response.data)
            setOutgoingInvoices(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setOutgoingInvoices(undefined)
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchIncomingInvoices()
        fetchOutgoingInvoices()
    }, []);

    return (
        <ModalForm<API.LoggedPayment>
            title="Create a new Payment"
            visible={true}
            form={form}
            autoFocusFirstInput
            // width={"100%"}
            modalProps={{
                destroyOnClose: true,
                okText: 'Create',
                onCancel: () => navigate(`/projects/${routerParams.projectId}/payments`),
                style: {top: 70},
            }}
            // submitTimeout={2000}
            onFinish={(values) => {
                // values.execution_wbs_version_id = +routerParams.executionWbsVersionId!
                console.log({values});
                // return Promise.resolve()
                return axiosApiInstance.post<API.LoggedPayment>('/api/payments/', values)
                    .then(res => {
                        loggedPaymentsListRef?.current?.reload()
                        navigate(`/projects/${routerParams.projectId}/payments`)
                        return true
                    }).catch(reason => false)
            }}
            validateMessages={{required: "${label} is required!"}}
            // initialValues={{
            //     dateRange: []
            // }}
        >
            <ProFormDigit
                name="id"
                hidden={true}
            />

            <ProFormGroup label={'General Information'}>
                <ProFormGroup>
                    <ProFormMoney
                        name="amount"
                        label={'Amount'}
                        customSymbol={'€'}
                        fieldProps={{
                            numberFormatOptions: {currency: 'EUR'}
                        }}
                        width={"xs"}
                        rules={[{required: true}]}
                    />
                    <ProFormRadio.Group
                        options={[
                            {value: 1, label: 'Bank Account'},
                            {value: 2, label: 'Paypal'},
                        ]}
                        radioType={"button"}
                        width="xl"
                        name="payment_method_id"
                        label="Payment method"
                        rules={[{required: true}]}
                    />
                    <ProFormText
                        width="sm"
                        name="transaction_id"
                        label="Transaction ID"
                        // rules={[{required: true}]}
                    />
                </ProFormGroup>
                <ProFormGroup>
                    <ProFormRadio.Group
                        options={[
                            {value: 1, label: 'Pending'},
                            {value: 2, label: 'Completed'},
                            {value: 3, label: 'Refunded'},
                            {value: 4, label: 'Failed'},
                            {value: 5, label: 'Cancelled'},
                        ]}
                        radioType={"button"}
                        width="xl"
                        name="payment_status_id"
                        label="Payment status"
                        rules={[{required: true}]}
                    />
                    <ProFormDatePicker name={"date_effective"} label={"Date Effective"}
                                       rules={[{required: true}]}
                                       normalize={(value) => {
                                           return value.toISOString()
                                       }}
                    />
                </ProFormGroup>


            </ProFormGroup>

            <ProFormGroup label={'Relative Invoice'}>
                <ProFormSelect
                    valueEnum={{
                        incoming: 'Incoming',
                        outgoing: 'Outgoing',
                    }}
                    width={"sm"}
                    name="invoice_type"
                    label="Invoice Type"
                    rules={[{required: true}]}
                    fieldProps={{onSelect: () => form.setFieldValue(['invoice_id'], undefined)}}
                />

                <ProFormDependency name={[['invoice_type']]}>
                    {({invoice_type}) => {
                        if (invoice_type === 'incoming') {
                            let options: any = []
                            incomingInvoices?.map((c) => {
                                options.push({
                                    value: c.id,
                                    label: `${c.description}`
                                })
                            })
                            // console.log(wbsVersionData?.execution_deliverables)
                            return <ProFormSelect
                                options={options}
                                width={"md"}
                                name="invoice_id"
                                label="Invoice"
                                rules={[{required: true}]}
                            />
                        }
                        let options: any = []
                        outgoingInvoices?.map((c) => {
                            options.push({
                                value: c.id,
                                label: `${c.description}`
                            })
                        })
                        return <ProFormSelect
                            options={options}
                            width={"md"}
                            name="invoice_id"
                            label="Invoice"
                            rules={[{required: true}]}
                        />
                    }}
                </ProFormDependency>

            </ProFormGroup>

        </ModalForm>
    );
};

export default NewLoggedPayment;
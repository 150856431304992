/**
 * Take in an object generated by ant design LightFilter component and parse it into a string of values, delimited by ':'
 * paired with their corresponding keys delimited by '::', and finally each key-values pair delimited by '|'. for example:
 * the object {"key1": [1,2], "key2": [3,4]} would yield the result key1::1:2|key2::3:4
 *
 * @param values object generated by ant design LightFilter component
 * @returns a string of delimited values
 */
const filterObjToString = (values: { [k: string]: Array<number> | number | string | boolean }) => {
    return Object.entries(values) // transform object of key: value form into [key, value] array
        .map(([k, vs]) => [k, typeof vs == 'number' || typeof vs == 'string' || typeof vs == 'boolean' ? vs : vs.join(':')].join('::')) // for each [key, values] pair, join values with '-' and then join key-value pair with ':'
        .join('|'); // join all the joined-by-':' keyvalue pairs with '|'
};

// write the inverse function of the above, so that a string can be unmarsheled back into an object, respecting the data types of the values
export const stringToFilterObj = (str: string) => {
    return str
        .split('|') // split the string into key-value pairs
        .map((kv) => kv.split('::')) // split each key-value pair into [key, value] array
        .reduce<{ [k: string]: Array<number> | number | boolean }>((acc, [k, v]) => {
            // for each [key, value] pair
            const vs = v.split(':'); // split the value into an array of values
            console.log({vs})
            // if there is no value, return the accumulator as is
            if (vs.length == 1 && vs[0] == '') return acc;
            // handle the case where the value is a single boolean as a string
            if (vs.length == 1 && vs[0] == 'true') {
                acc[k] = true;
                return acc;
            }
            if (vs.length == 1 && vs[0] == 'false') {
                acc[k] = false;
                return acc;
            }
            acc[k] = vs.length == 1 ? Number(vs[0]) : vs.map(Number); // if the value is a single number, parse it to number, otherwise parse all the values to numbers
            return acc;
        }, {});
};

export default filterObjToString;

import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ProTable} from "@ant-design/pro-table";
import {ProCard} from "@ant-design/pro-components";
import {ProColumns} from "@ant-design/pro-table";
import moment, {Moment} from "moment";
import {Badge, Button, Space, Typography} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import axiosApiInstance from "../../api/axiosClient";
import {useParams} from "react-router-dom";
import LogDeliverableProgress from "./LogDeliverableProgress";
import {GlobalStateContext} from "../../context/GlobalContext";
import ExecutionDeliverableProgressTinyChart from "./ExecutionDeliverableProgressTinyChart";
import ExecutionDeliverableProgressColumnChart from "./ExecutionDeliverableProgressColumnChart";

type Props = {};

const weekUnit = 'isoWeek'

const expandedRowRender = (record: Record<string, any>, data: any, weeks: any) => {

    let columns: ProColumns<Record<string, any>, any>[] = []
    for (const weeksKey of weeks) {
        // console.log(weeksKey.format('YYYY-W'))
        let title;
        if (weeksKey.isSame(moment().startOf(weekUnit))) {
            // title = <Tag color={'blue'} style={{fontSize:14}}>{weeksKey.format('YYYY-W')}</Tag>
            title = <Badge status="processing" text={weeksKey.format('YYYY-[W]W')}/>
        } else {
            title = weeksKey.format('YYYY-[W]W')
        }
        columns.push({
            title,
            align: 'center',
            dataIndex: weeksKey.format('YYYY-W'),
            render: (dom, entity) => {
                // console.log(record, data, entity)
                // return entity[weeksKey.format('YYYY-W')] !== undefined ? `${dom}%` :
                //     <LogDeliverableProgress week={weeksKey} cost_id={entity.cost_id} cost_type={entity.cost_type}
                //                             start_date={record.start_date}/>
                // if (entity.cost_type == 'own_time_cost' || entity.cost_type == 'own_material_cost') {
                    // console.log(weeksKey, moment(entity.start_date).startOf(weekUnit).utcOffset(0, true), weeksKey.format('YYYY-W'))
                    // if (!weeksKey.isBefore(moment(entity.start_date).startOf(weekUnit).utcOffset(0, true)))
                    //     console.log(weeksKey, moment(entity.start_date).startOf(weekUnit).utcOffset(0, true))
                    // if (weeksKey.format('W')=='2' )
                    //     console.log(weeksKey,entity.start_date, moment(entity.start_date).startOf(weekUnit).utcOffset(0, true), entity)
                    return <Space direction={"vertical"}>
                        {(!weeksKey.isBefore(moment(record.start_date).startOf(weekUnit).utcOffset(0, true))) &&
                        <Typography.Text
                            type={"danger"}> {entity[weeksKey.format('YYYY-W') + '_cost']}% </Typography.Text>}

                        {entity[weeksKey.format('YYYY-W')] !== undefined ?
                            // <Typography.Text>{dom}%</Typography.Text>
                            <Typography.Text
                                style={{color: '#6395F9'}}>{dom}%</Typography.Text>
                            :
                            <LogDeliverableProgress week={weeksKey} cost_id={entity.cost_id}
                                                    cost_type={entity.cost_type}
                                                    start_date={record.start_date}
                                                    completion_percentage={entity[weeksKey.format('YYYY-W')]}
                            />}

                        {/*<LogDeliverableProgress week={weeksKey} cost_id={entity.cost_id}*/}
                        {/*                        cost_type={entity.cost_type}*/}
                        {/*                        start_date={record.start_date}*/}
                        {/*                        completion_percentage={entity[weeksKey.format('YYYY-W')]}*/}
                        {/*/>*/}
                    </Space>

                // } else {
                //     return entity[weeksKey.format('YYYY-W')] !== undefined ? `${dom}%` :
                //         <Space direction={"vertical"}> <LogDeliverableProgress week={weeksKey} cost_id={entity.cost_id}
                //                                                                cost_type={entity.cost_type}
                //                                                                start_date={record.start_date}
                //                                                                completion_percentage={entity[weeksKey.format('YYYY-W')]}
                //         /></Space>
                // }
            }
        })
    }

    columns = [
        // {title: 'Cost Id', dataIndex: 'cost_id', fixed: 'left', width: 90},
        {title: 'Cost', dataIndex: 'description', fixed: 'left', width: 90},
        {title: 'Contr. Factor', dataIndex: 'contribution_factor', fixed: 'left', width: 100},
        ...columns,
        {title: 'Latest Progress', dataIndex: 'latest_progress', fixed: 'right', valueType: "progress"},
        {
            title: 'Progress Chart',
            // render: (dom, entity) => <ExecutionDeliverableProgressTinyChart data={entity.series}/>,
            render: (dom, entity) => {
                // console.log(entity)
                // if (entity.cost_type == 'own_time_cost' || entity.cost_type == 'own_material_cost') {
                    return <ExecutionDeliverableProgressColumnChart data={entity.time_series}/>
                // } else {
                //     return <ExecutionDeliverableProgressTinyChart data={entity.series}/>
                // }
            },
            fixed: 'right'
        }
    ]


    // console.log(data)
    const myData = data.find((d: { deliverable_id: any; }) => d.deliverable_id == record.deliverable_id).cost_progresses
    // console.log(myData)

    // columns = columns.filter(value => value.dataIndex != 'deliverable_name' && value.dataIndex != 'cost_id')
    // columns.push({dataIndex: 'cost_id', fixed: 'left', title: 'Cost Id'})
    // console.log(columns)

    return (
        <>
            <ProTable
                style={{marginTop: 12}}
                columns={columns}
                headerTitle={false}
                search={false}
                options={false}
                dataSource={myData}
                pagination={false}
            />
        </>
    );
};


// const rows = [
//     {deliverable_name: 'Test Deliverable 1', latest_progress: 50, '2022-48': 50},
//     {deliverable_name: 'Test Deliverable 2', latest_progress: 20, '2022-48': 50},
//     {deliverable_name: 'Test Deliverable 3', latest_progress: 50, '2022-48': 50},
//     {deliverable_name: 'Test Deliverable 4', latest_progress: 40, '2022-48': 50},
//     {deliverable_name: 'Test Deliverable 5', latest_progress: 80, '2022-48': 50},
// ]

const ExecutionProgress = (props: Props) => {
    const [columns, setColumns] = useState<ProColumns<Record<string, any>>[] | undefined>([]);
    const [weeks, setWeeks] = useState<Moment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    const [data, setData] = useState<any>(undefined);
    const routerParams = useParams();
    const {executionProgressTableRef} = useContext(GlobalStateContext);

    useEffect(() => {
        let newWeeks = []
        let curWeek = moment().startOf(weekUnit).subtract(2, 'w')
        for (let i = 0; i < 5; i++) {
            newWeeks.push(curWeek.clone())
            curWeek = curWeek.add(1, 'w')
            // console.log(curWeek.format('YYYY-W'))
        }
        // console.log(newWeeks)
        setWeeks(newWeeks)
    }, [])

    useEffect(() => {
        fetchWbsVersionData()
    }, [])

    useEffect(() => {
        // console.log('cols')
        let newColumns: ProColumns<Record<string, any>>[] = []
        for (const weeksKey of weeks) {
            // console.log(weeksKey.format('YYYY-W'))
            let title;
            if (weeksKey.isSame(moment().startOf(weekUnit))) {
                // title = <Tag color={'blue'} style={{fontSize:14}}>{weeksKey.format('YYYY-W')}</Tag>
                title = <Badge status="processing" text={weeksKey.format('YYYY-[W]W')}/>
            } else {
                title = weeksKey.format('YYYY-[W]W')
            }
            newColumns.push({
                title,
                align: 'center',
                dataIndex: weeksKey.format('YYYY-W'),
                render: (dom, entity) => {
                    if (entity[weeksKey.format('YYYY-W')]) {
                        // return `${entity[weeksKey.format('YYYY-W')]}%`
                        return <Typography.Text
                            style={{color: '#6395F9'}}>{entity[weeksKey.format('YYYY-W')]}%</Typography.Text>
                    }
                    // return `0%`
                    return <Typography.Text style={{color: '#6395F9'}}>0%</Typography.Text>
                }
            })
        }
        newColumns = [
            {title: 'Deliverable', dataIndex: 'deliverable_name', fixed: 'left'},
            ...newColumns,
            {title: 'Latest Progress', dataIndex: 'latest_progress', fixed: 'right', valueType: "progress"},
            // {
            //     title: 'Progress Chart',
            //     render: (dom, entity) => <ExecutionDeliverableProgressLineChart data={entity.series}/>,
            //     fixed: 'right'
            // }
        ]
        setColumns(newColumns)
        executionProgressTableRef?.current?.reload()
    }, [weeks])

    const previous5Weeks = () => {
        let newWeeks = []
        let curWeek = weeks[0].subtract(5, 'w')
        for (let i = 0; i < 5; i++) {
            newWeeks.push(curWeek.clone())
            curWeek = curWeek.add(1, 'w')
        }
        setWeeks(newWeeks)
    }

    const next5Weeks = () => {
        let newWeeks = []
        let curWeek = weeks[weeks.length - 1].add(1, 'w')
        for (let i = 0; i < 5; i++) {
            newWeeks.push(curWeek.clone())
            curWeek = curWeek.add(1, 'w')
        }
        setWeeks(newWeeks)
    }

    const fetchWbsVersionData = async () => {
        try {
            setLoading(true)
            const response = await axiosApiInstance.get<API.ExecutionWBSVersion>(`/api/projects/${routerParams.projectId}/structures/executions/active`)
            // console.log(response.data)
            setWbsVersionData(response.data);
            setLoading(false)
        } catch (e) {
            console.log('catch error')
            setWbsVersionData(undefined)
            setLoading(false)
        }
    };

    return (
        <div>
            <ProCard bordered bodyStyle={{padding: 0}}>
                <ProTable
                    actionRef={executionProgressTableRef}
                    columns={columns}
                    rowKey={"deliverable_id"}
                    // dataSource={rows}
                    search={false}
                    // toolBarRender={false}
                    pagination={false}
                    // tableStyle={{paddingLeft: 20, paddingRight: 20}}
                    // tableStyle={{padding:20}}
                    // headerTitle={loading ? '' : `Progress of ${wbsVersionData?.pretty_id}`}
                    // headerTitle={loading ? '' : `Progress of ${wbsVersionData?.pretty_id}`}
                    headerTitle={wbsVersionData ? `Progress of ${wbsVersionData?.pretty_id}` : ''}
                    toolBarRender={(action) => [
                        <Space style={{marginRight: 12}}>
                            <Badge color={'#6395F9'} text="Completion"/>
                            <Badge color={'#FF6B6D'} text="Utilization"/>
                        </Space>,
                        <Button onClick={() => previous5Weeks()}><LeftOutlined/>Previous 5 weeks</Button>,
                        <Button onClick={() => next5Weeks()}>Next 5 weeks<RightOutlined/></Button>
                    ]}
                    // toolbar={{actions:[]}}
                    request={() => {
                        // console.log(weeks)
                        let weeksDates = []
                        for (let week of weeks) {
                            let s = week.utcOffset(0, true).format()
                            weeksDates.push(s)
                            // console.log(s, week.toISOString())
                            // weeksDates.push(week.format())
                        }
                        return axiosApiInstance.get(`/api/projects/${routerParams.projectId}/progress`, {params: {weeks: weeksDates}})
                            .then(value => {
                                console.log(value.data)
                                return {data: value.data, success: true, total: value.data.length}
                            })
                        // return axiosApiInstance.get(`/api/projects/${routerParams.projectId}/progress`, {params: {weeks}})
                    }}
                    expandable={{expandedRowRender: record => expandedRowRender(record, data, weeks)}}
                    // onDataSourceChange={dataSource => {
                    //     console.log(dataSource)
                    // }}
                    onDataSourceChange={dataSource => setData(dataSource)}

                />
            </ProCard>
        </div>
    );
};


export default ExecutionProgress;
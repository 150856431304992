import axios from "axios";
import qs_stringify from "qs-stringify";
import {auth} from '../config/firebase';

const postRequestRBAC = async (endpoint: string, data?: any) => {

    if (data === undefined) data = null
    else data = qs_stringify(data)

    const session_url = process.env.REACT_APP_RBAC_API + endpoint

    const token = await auth.currentUser?.getIdToken()
    // console.log({token})

    return axios.post(session_url, data, {
        headers: {
            Accept: '*/*',
            'Access-Control-Allow-Origin': process.env.REACT_APP_RBAC_API!,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            Authorization: `Bearer ${token}`
        }

    });
};

export default postRequestRBAC
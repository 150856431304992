import {Column} from '@ant-design/plots';
import {ColumnConfig} from "@ant-design/plots/es/components/column";
import moment from "moment";

type Props = {
    data: any[]
};

const ExecutionDeliverableProgressColumnChart = (props: Props) => {
    // const data = [
    //     {
    //         name: 'London',
    //         month: 'Jan.',
    //         percentage: 18.9,
    //     },
    //     {
    //         name: 'London',
    //         month: 'Feb.',
    //         percentage: 28.8,
    //     },
    //     {
    //         name: 'London',
    //         month: 'Mar.',
    //         percentage: 39.3,
    //     },
    //     {
    //         name: 'London',
    //         month: 'Apr.',
    //         percentage: 81.4,
    //     },
    //     {
    //         name: 'London',
    //         month: 'May',
    //         percentage: 47,
    //     },
    //     {
    //         name: 'London',
    //         month: 'Jun.',
    //         percentage: 20.3,
    //     },
    //     {
    //         name: 'London',
    //         month: 'Jul.',
    //         percentage: 24,
    //     },
    //     {
    //         name: 'London',
    //         month: 'Aug.',
    //         percentage: 35.6,
    //     },
    //     {
    //         name: 'Berlin',
    //         month: 'Jan.',
    //         percentage: 12.4,
    //     },
    //     {
    //         name: 'Berlin',
    //         month: 'Feb.',
    //         percentage: 23.2,
    //     },
    //     {
    //         name: 'Berlin',
    //         month: 'Mar.',
    //         percentage: 34.5,
    //     },
    //     {
    //         name: 'Berlin',
    //         month: 'Apr.',
    //         percentage: 99.7,
    //     },
    //     {
    //         name: 'Berlin',
    //         month: 'May',
    //         percentage: 52.6,
    //     },
    //     {
    //         name: 'Berlin',
    //         month: 'Jun.',
    //         percentage: 35.5,
    //     },
    //     {
    //         name: 'Berlin',
    //         month: 'Jul.',
    //         percentage: 37.4,
    //     },
    //     {
    //         name: 'Berlin',
    //         month: 'Aug.',
    //         percentage: 42.4,
    //     },
    // ];
    // console.log(props.data)
    const config: ColumnConfig = {
        height: 70,
        autoFit: true,
        data: props.data,
        // data,
        maxColumnWidth: 30,
        // columnWidthRatio: 0.15,
        // smooth: true,
        isGroup: true,
        // xField: 'month',
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        tooltip: {
            customContent: function (x: any, data: any) {
                // console.log(x, data)
                return `<div>${moment(data[0]?.data?.date).format('YYYY-[W]W')}</div><div>Completion: ${data[1]?.data?.value}%</div> <div>Budget%: ${data[0]?.data?.value}%</div><div>Budget: ${data[0]?.data?.actual}€</div>`;
            },
        },
        legend: false,
        xAxis: false,
        yAxis: false,
        padding: [12,0,0,0],
        color: ['#FF6B6D', '#6395F9',],
        annotations: [
            {
                type: 'line',
                // start: ['min', 'mean'],
                start: ['min', 100],
                end: ['max', 100],
                text: {
                    content: '100%',
                    offsetY: -2,
                    style: {
                        textAlign: 'left',
                        fontSize: 10,
                        fill: 'rgba(44, 53, 66, 0.45)',
                        textBaseline: 'bottom',
                    },
                },
                style: {
                    stroke: 'rgba(0, 0, 0, 0.25)',
                },
            },
        ],
    };
    // return <TinyLine {...config} />;
    return <Column {...config} />;
};


export default ExecutionDeliverableProgressColumnChart
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {GlobalStateContext} from "../../../../context/GlobalContext";
import axiosApiInstance from "../../../../api/axiosClient";
import {Button, Card, Empty, Row, Space, Statistic, Typography} from "antd";
import {ProCard, ProList} from "@ant-design/pro-components";
import {EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
// import moment from "moment";
import currencyFormatter from "../../../../util/currency_formatter";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

type Props = {};
const InvoicesOutgoing = (props: Props) => {
    // const [wbsVersionData, setWbsVersionData] = useState<API.ExecutionWBSVersion | undefined>(undefined);
    // const [loading, setLoading] = useState<boolean>(false);
    const routerParams = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {invoicesOutgoingListRef, setTaxCategories} = useContext(GlobalStateContext);
    const [data, setData] = useState<API.LoggedInvoiceOutgoing[] | undefined>(undefined);
    const [invoicedTotal, setInvoicedTotal] = useState<number>(0);
    const [paidTotal, setPaidTotal] = useState<number>(0);
    const [vatTotal, setVatTotal] = useState<number>(0);

    const fetchTaxCategoriesData = async () => {
        try {
            // setLoading(true)
            const response = await axiosApiInstance.get<API.TaxCategory[]>('/api/invoices/tax_categories/')
            // console.log(response.data)
            if (setTaxCategories) {
                setTaxCategories(response.data);
            }
            // setLoading(false)
        } catch (e) {
            // console.log('catch error')
            if (setTaxCategories) {
                setTaxCategories(undefined)
            }
            // setLoading(false)
        }
    };

    useEffect(() => {
        fetchTaxCategoriesData()
    }, []);

    useEffect(() => {
        let invoicedTotal = 0
        let paidTotal = 0
        let vatTotal = 0
        data?.map((d) => {
            invoicedTotal += d.cost
            paidTotal += d.paid_amount
            vatTotal += d.cost_incl_vat - d.cost
        })

        setInvoicedTotal(invoicedTotal)
        setPaidTotal(paidTotal)
        setVatTotal(vatTotal)
    }, [data]);


    return (
        <>

            <Row style={{marginTop: 4, marginBottom: 4,}}>
                <Space>
                    <Card bordered={false} size={"small"}>
                        <Statistic
                            title="Amount Invoiced"
                            value={currencyFormatter.format(invoicedTotal)}
                            precision={2}
                            // style={{fontSize:1}}
                            // valueStyle={{color: '#cf1322', fontSize: 14}}
                            valueStyle={{fontSize: 16}}
                            // prefix={<ArrowUpOutlined/>}
                        />
                    </Card>
                    <Card bordered={false} size={"small"}>
                        <Statistic
                            title="Amount VAT"
                            value={currencyFormatter.format(vatTotal)}
                            precision={2}
                            // style={{fontSize:1}}
                            // valueStyle={{color: '#cf1322', fontSize: 14}}
                            valueStyle={{fontSize: 16}}
                            // prefix={<ArrowUpOutlined/>}
                        />
                    </Card>
                    <Card bordered={false} size={"small"}>
                        <Statistic
                            title="Amount Paid"
                            value={currencyFormatter.format(paidTotal)}
                            precision={2}
                            // style={{fontSize:1}}
                            // valueStyle={{color: '#cf1322', fontSize: 14}}
                            valueStyle={{fontSize: 16}}
                            // prefix={<ArrowUpOutlined/>}
                        />
                    </Card>
                    <Card bordered={false} size={"small"}>
                        <Statistic
                            title="Amount to be Paid"
                            value={currencyFormatter.format(invoicedTotal + vatTotal - paidTotal)}
                            precision={2}
                            // style={{fontSize:1}}
                            // valueStyle={{color: '#cf1322', fontSize: 14}}
                            valueStyle={{fontSize: 16}}
                            // prefix={<ArrowUpOutlined/>}
                        />
                    </Card>
                    <Card bordered={false} size={"small"}>
                        <Statistic
                            title="Account Receivables"
                            value={currencyFormatter.format(invoicedTotal)}
                            precision={2}
                            // style={{fontSize:1}}
                            // valueStyle={{color: '#cf1322', fontSize: 14}}
                            valueStyle={{color: '#3f8600', fontSize: 16}}
                            // prefix={<ArrowUpOutlined/>}
                        />
                    </Card>
                </Space>
            </Row>


            <ProCard bordered
                // bodyStyle={{padding: 0}}
            >

                <ProList<API.LoggedInvoiceOutgoing & { actions: any }>
                    locale={{
                        emptyText: <Empty
                            // image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={'No outgoing invoices found for project'}/>
                    }}
                    toolBarRender={() => [
                        <Link to="new" state={{background: location}}>
                            <Button
                                icon={<PlusOutlined/>}
                                key="new"
                                type="primary"
                            >
                                New
                            </Button>
                        </Link>,
                        <Button icon={<ReloadOutlined/>} type={"text"} onClick={() => {
                            invoicesOutgoingListRef?.current?.reload()
                        }}/>
                    ]}
                    // ghost
                    itemCardProps={{
                        // bodyStyle:{padding:"16px 32px 16px 32px"},
                        bodyStyle: {paddingLeft: 32, width: 250},
                        // headStyle: {paddingLeft: 0},
                        style: {borderColor: '#3f8600'}
                    }}
                    pagination={{
                        defaultPageSize: 8,
                        showSizeChanger: false,
                    }}
                    showActions="always"
                    // rowSelection={{}}
                    rowSelection={false}
                    grid={{gutter: 16}}
                    onItem={(record: any) => {
                        return {
                            onClick: () => {
                                navigate(`${record.id}`)
                            },
                        };
                    }}
                    search={{filterType: "light"}}
                    metas={{
                        title: {
                            dataIndex: 'name',
                            search: false,
                            render: (dom, entity) => {
                                return `Invoice #${entity.id}`
                                // return <Row justify={"start"}>Invoice {entity.name}</Row>
                            },
                        },
                        subTitle: {
                            search: false,
                            // dataIndex: 'description'
                            // dataIndex: 'date'
                            // dataIndex: 'id'
                            // render: (dom, entity) => {
                            //     const estimated_duration_days = moment(entity.end_date).diff(moment(entity.start_date), 'days') + 1
                            //     return `${estimated_duration_days} days`
                            // }
                        },
                        // type: {},
                        // avatar: {},
                        content: {
                            search: false,
                            render: (dom, entity) => {
                                return <>
                                    <Space direction={"vertical"} size={"small"}>
                                        <Typography.Text type={"secondary"} style={{marginRight: 10}}>Invoice
                                            No: {entity.invoice_number}</Typography.Text>
                                        <Typography.Text type={"secondary"}
                                                         style={{marginRight: 10}}>Date
                                            Issued: {dayjs.utc(entity.date).format('YYYY-MM-DD')}</Typography.Text>
                                        <Typography.Text type={"secondary"}
                                                         style={{marginRight: 10}}>Cost: {currencyFormatter.format(entity.cost)}</Typography.Text>
                                        <Typography.Text type={"secondary"}
                                                         style={{marginRight: 10}}>Cost Incl.
                                            VAT: {currencyFormatter.format(entity.cost_incl_vat)}</Typography.Text>
                                        <Typography.Text type={"secondary"}
                                                         style={{marginRight: 10}}>Paid: {entity.paid}</Typography.Text>
                                        <Typography.Text type={"secondary"}
                                                         style={{marginRight: 10}}>Paid
                                            Amount: {currencyFormatter.format(entity.paid_amount)}</Typography.Text>
                                    </Space>
                                </>
                            }
                        },
                        invoice_number: {
                            dataIndex: 'invoice_number',
                            title: 'Invoice Number',
                        },
                        paid: {
                            title: 'Paid',
                            valueType: 'radioButton',
                            valueEnum: {
                                YES: {text: 'Yes'},
                                NO: {text: 'No'},
                                PARTIALLY: {text: 'Partially'},
                            },
                        },
                        min_cost: {
                            title: 'Min. Cost',
                            valueType: 'digit',
                        },
                        max_cost: {
                            title: 'Max. Cost',
                            valueType: 'digit',
                        },
                        range: {
                            title: 'Range',
                            // formItemProps: {light:false},
                            data_index: 'range',
                            valueType: 'radioButton',
                            valueEnum: {
                                'last-week': {text: 'Last week'},
                                'last-month': {text: 'Last month'},
                            },
                            // fieldProps: {
                            //     options: [
                            //         // {label: 'All-time', value: 'all-time'},
                            //         {label: 'Last week', value: 'last-week'},
                            //         {label: 'Last month', value: 'last-month'},
                            //     ]
                            // }
                            // search: false,
                        },
                        type: {
                            search: false,
                        },
                        avatar: {
                            search: false,
                        },
                        actions: {cardActionProps: "actions"}
                    }}
                    onDataSourceChange={dataSource => setData(dataSource)}
                    actionRef={invoicesOutgoingListRef}
                    headerTitle="Outgoing Invoices"
                    request={params => {
                        // console.log(params)
                        return axiosApiInstance.get<API.LoggedInvoiceOutgoing[]>(`/api/projects/${routerParams.projectId}/invoices/outgoing`, {params})
                            .then(value => {
                                // console.log(value.data)
                                return {
                                    data: value.data.map((i) => ({
                                        ...i,
                                        actions: [
                                            // <DeleteInvoiceOutgoing id={i.id}/>,
                                            // <EditOutlined key="edit"/>,
                                            <Link to={`${i.id}/edit`}> <EditOutlined key="edit"/> </Link>,
                                            // <EllipsisOutlined key="ellipsis"/>,
                                        ],
                                    })),
                                    success: true,
                                    total: value.data.length
                                }
                            })
                            .catch(reason => {
                                // console.log(reason)
                                return {success: false}
                            })
                    }}
                    onRequestError={e => console.log(e)}
                    // onDataSourceChange={dataSource => console.log(dataSource)}
                    // onLoad={dataSource => console.log(dataSource)}
                />

                <Outlet/>

            </ProCard>
        </>
    );
};

export default InvoicesOutgoing;
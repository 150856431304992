import * as React from 'react';
import {useContext, useEffect} from 'react';
import {Alert, Card, Row, Segmented, Space} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import axiosApiInstance from "../../api/axiosClient";
import {GlobalStateContext} from "../../context/GlobalContext";

type Props = {};

export const InvoicesGlobal = (props: Props) => {
    const location = useLocation();
    const routerParams = useParams();
    const navigate = useNavigate();
    const {setTaxCategories, taxCategories} = useContext(GlobalStateContext);


    const fetchTaxCategoriesData = async () => {
        try {
            // setLoading(true)
            const response = await axiosApiInstance.get<API.TaxCategory[]>('/api/invoices/tax_categories/')
            // console.log(response.data)
            if (setTaxCategories) {
                setTaxCategories(response.data);
            }
            // setLoading(false)
        } catch (e) {
            // console.log('catch error')
            if (setTaxCategories) {
                setTaxCategories(undefined)
            }
            // setLoading(false)
        }
    };

    useEffect(() => {
        fetchTaxCategoriesData()
    }, []);

    return (
        <>
            <Row justify={"space-between"} style={{display: "flex"}}>
                {location.pathname.includes("incoming") ?
                    <Alert type={"info"}
                        // style={{marginBottom: 4}}
                           style={{flex: 1}}
                           showIcon
                        // closable
                           message={'Invoices being sent to the company by third parties (service providers, vendors, retailers, etc.)  on the basis of a purchased service.'}/>

                    :
                    <Alert type={"info"}
                        // style={{marginBottom: 4}}
                           style={{flex: 1}}
                           showIcon
                        // closable
                           message={'Invoices being sent from the side of the company to its customers, counterparties, partners, etc.'}/>
                }

                <Space style={{marginBottom: 4}}>
                    <Card bodyStyle={{padding: 0}} bordered>
                        <Segmented size="large"
                                   options={[
                                       {label: 'Incoming', value: 'incoming', icon: <ArrowDownOutlined/>,},
                                       {label: 'Outgoing', value: 'outgoing', icon: <ArrowUpOutlined/>}
                                   ]}
                                   value={location.pathname.includes("incoming") ? 'incoming' : 'outgoing'}
                                   onChange={value => navigate(`${value}`, {relative: 'path'})}
                            // style={{ borderWidth: 1, borderStyle:"solid"}}
                        />
                    </Card>
                </Space>
            </Row>
            <br/>
            {/*<Row style={{width: '100%'}}>*/}
            <Outlet/>
            {/*</Row>*/}
        </>
    );
};

export default InvoicesGlobal;
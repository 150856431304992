import * as React from 'react';
import {Key, Suspense, useEffect, useRef, useState} from 'react';
import {Button, Space, Tag, Typography} from 'antd';
import {Link, useLocation} from "react-router-dom";
import {
    DeleteOutlined,
    EyeOutlined,
    PlusOutlined,
    ReloadOutlined,
    SearchOutlined,
    TableOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import isBetween from 'dayjs/plugin/isBetween'
import {useAtom, useAtomValue} from "jotai";
import {
    allProjectTagsAtom,
    combinedCustomersOptionsAtom,
    employeesAtom,
    fetchParamsAtom,
    filterSearchAtom,
    LayoutEnum,
    projectsAfterFilterSortSearchAtom,
    projectsUnfilteredAtom
} from "../../store/projects";
import {ProFormInstance, ProFormSelect} from '@ant-design/pro-components';
import currencyFormatter from '../../util/currency_formatter';
import {ProColumns, ProTable} from "@ant-design/pro-table";
import DeleteProjects from "./DeleteProjects";
import {queryClientAtom} from "jotai-tanstack-query";
import ExportToExcel from "./ExportToExcel";
import {RESET} from "jotai/utils";

const {CheckableTag} = Tag;

dayjs.extend(utc)
dayjs.extend(isBetween)

type Layout = 'Grid' | 'List';

// enum


type Option = { value: string; icon: any };

const segmentedOpts: Array<Option> = [
    {
        value: LayoutEnum.Grid,
        icon: <TableOutlined/>
    },
    {
        value: LayoutEnum.List,
        icon: <UnorderedListOutlined/>
    }
];


type Props = {};

export const ProjectsTable = (props: Props) => {
        const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
        const location = useLocation();
        const [{data: employees}] = useAtom(employeesAtom)
        const [{data: allProjectTags}] = useAtom(allProjectTagsAtom);
        const form = useRef<ProFormInstance>();
        const [fetchParams, setFetchParams] = useAtom(fetchParamsAtom);
        const projectsAfterFilterSortSearch = useAtomValue(projectsAfterFilterSortSearchAtom)
        // const {data: projectsAfterFilterSort} = useAtomValue(projectsAtom)
        // const {data: projects} = useAtomValue(projectsAtom)
        const {data: projectsUnfiltered} = useAtomValue(projectsUnfilteredAtom)
        const queryClient = useAtomValue(queryClientAtom);
        const combinedCustomersOptions = useAtomValue(combinedCustomersOptionsAtom)

        const columns: ProColumns<API.Project>[] = [
            {
                title: 'Case', dataIndex: 'pretty_ids', valueType: 'text', width: 140,
                render: (_, record) => (
                    <Typography.Text>
                        {record.project_pretty_id}
                    </Typography.Text>
                ),
                renderFormItem: (_, {value, onChange}) => {
                    // form.current?.setFieldValue('project_pretty_id', null)
                    return (
                        <ProFormSelect
                            placeholder="Select case"
                            options={projectsUnfiltered?.map((p) => ({
                                label: `${p.title} (${p.project_pretty_id})`,
                                value: p.project_pretty_id
                            }))}
                            width="xl"
                            // name="pretty_ids"
                            fieldProps={{
                                showSearch: true, filterOption: (input, option) =>
                                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                                // loading: isLoading,
                                popupMatchSelectWidth: true,
                                mode: "tags",
                                maxTagCount: "responsive",
                                onClear: () => {
                                    form.current?.setFieldValue('pretty_ids', null)
                                }
                            }}
                            wrapperCol={{span: 24}}
                            mode={'multiple'}
                        />
                    )
                },
                // sorter: (a, b) => {
                //     return a.project_pretty_id!.localeCompare(b.project_pretty_id!)
                // }
                sorter: (a, b) => {
                    //
                    //Sorting column
                    //
                    //Compare two strings to sort pro table
                    var first_project_pretty_id = a.project_pretty_id != undefined ? a.project_pretty_id : ""
                    var second_project_pretty_id = b.project_pretty_id != undefined ? b.project_pretty_id : ""

                    if (first_project_pretty_id > second_project_pretty_id) {
                        return 1
                    } else if (first_project_pretty_id < second_project_pretty_id) {
                        return -1
                    } else {
                        return 0
                    }
                }
            },
            {
                title: 'Title', dataIndex: 'title', valueType: 'text', hideInSearch: true,
                sorter: (a, b) => {
                    //
                    //Sorting column
                    //
                    //Compare two strings to sort pro table
                    var first_title = a.title != undefined ? a.title : ""
                    var second_title = b.title != undefined ? b.title : ""

                    if (first_title > second_title) {
                        return 1
                    } else if (first_title < second_title) {
                        return -1
                    } else {
                        return 0
                    }
                }
            },
            // { title: 'Description', dataIndex: 'description', valueType: 'text', hideInSearch: true },
            // { title: 'Buyer Type', dataIndex: 'buyer_type', valueType: 'text', hideInSearch: true },
            {
                title: 'Customer', dataIndex: 'customer', valueType: 'text',
                // hideInSearch: true,
                render: (_, record) => (
                    <Typography.Text>
                        {record.customer_id != null ? record.customer.name : record.external_buyer_id != null ?
                            record.external_buyer.name : record.accounting_customer.name}
                    </Typography.Text>
                ),
                // +Unified customer search implementation
                renderFormItem: (_, {value, onChange}) => {
                    return (
                        <ProFormSelect
                            placeholder="Select customer"
                            options={combinedCustomersOptions}
                            width={"lg"}
                            wrapperCol={{span: 24}}
                            mode={'multiple'}
                            showSearch
                        />
                    )
                },
                // +Unified customer search implementation
                search: {
                    transform: (value) => {
                        let parsedVals = value.map((v: string) => JSON.parse(v))
                        return {
                            customer_id: parsedVals.filter((v: any) => v.buyer_type_id === 1).map((v: any) => v.customer_id),
                            accounting_customer_id: parsedVals.filter((v: any) => v.buyer_type_id === 2).map((v: any) => v.accounting_customer_id),
                            external_buyer_id: parsedVals.filter((v: any) => v.buyer_type_id === 3).map((v: any) => v.external_buyer_id),
                        }
                    }
                },
                sorter: (a, b) => {
                    //
                    //Sorting column
                    //
                    //Compare two strings to sort antd's pro table
                    var first_customer_name = a.customer_id != null ? a.customer.name : a.external_buyer_id != null ?
                        a.external_buyer.name : a.accounting_customer.name
                    var second_customer_name = b.customer_id != null ? b.customer.name : b.external_buyer_id != null ?
                        b.external_buyer.name : b.accounting_customer.name

                    if (first_customer_name > second_customer_name) {
                        return 1
                    } else if (first_customer_name < second_customer_name) {
                        return -1
                    } else {
                        return 0
                    }
                }
            },


            // { title: 'Accounting Customer', dataIndex: 'accounting_customer', valueType: 'text', hideInSearch: true },
            // { title: 'External Buyer', dataIndex: 'external_buyer', valueType: 'text', hideInSearch: true },
            // { title: 'Customer Year Project Count', dataIndex: 'customer_year_project_count', valueType: 'text', hideInSearch: true },
            // { title: 'Proposed WBS', dataIndex: 'proposed_wbs', valueType: 'text', hideInSearch: true },
            // { title: 'Execution WBS', dataIndex: 'execution_wbs', valueType: 'text', hideInSearch: true },
            {
                title: 'Manager', dataIndex: 'employee_id', valueType: 'select',
                render: (_, record) => (
                    <Typography.Text>
                        {record.employee.name}
                    </Typography.Text>
                ),
                fieldProps: {
                    options: employees.map((e) => ({label: e.name, value: e.id})),
                    showSearch: true, filterOption: (input: string, option: { label: any; }) =>
                        (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase()),
                    // loading: isLoading,
                    popupMatchSelectWidth: true,
                },
                proFieldProps: {width: 'sm'},
                sorter: (a, b) => {
                    return a.employee.name.localeCompare(b.employee.name)
                }
            },
            {
                title: 'Status', dataIndex: 'status', valueType: 'select',
                fieldProps: {
                    options: [
                        {value: 'On Proposal', label: 'On Proposal'},
                        {value: 'On Execution', label: 'On Execution'},
                        {value: 'Initiated', label: 'Initiated'},
                    ]
                },
                render: (_, record) => (
                    <Tag
                        color={record.status == 'On Proposal' ? 'gold' : record.status == 'On Execution' ? 'volcano' : 'blue'}
                        key={record.id}>
                        {record.status}
                    </Tag>
                ),
                proFieldProps: {width: 'sm'},
                sorter: (a, b) => {
                    return a.status.localeCompare(b.status)
                }
            },
            // { title: 'Active Proposal Cost', dataIndex: 'active_proposal_cost', valueType: 'text', hideInSearch: true },
            // { title: 'Active Proposal Price', dataIndex: 'active_proposal_price', valueType: 'text', hideInSearch: true },
            {
                title: 'Exec. Cost', dataIndex: 'active_execution_cost', valueType: 'text', hideInSearch: true,
                render: (_, record) => (
                    <Typography.Text>
                        {currencyFormatter.format(record.active_execution_cost)}
                    </Typography.Text>
                ), width: 100, sorter: (a, b) => a.active_execution_cost - b.active_execution_cost
            },
            {
                title: 'Exec. Price', dataIndex: 'active_execution_price', valueType: 'text', hideInSearch: true,
                render: (_, record) => (
                    <Typography.Text>
                        {currencyFormatter.format(record.active_execution_price)}
                    </Typography.Text>
                ), width: 100, sorter: (a, b) => a.active_execution_price - b.active_execution_price
            },
            {
                title: 'Exec. Margin',
                dataIndex: 'active_execution_margin',
                valueType: 'text',
                hideInSearch: true,
                render: (_, record) => (
                    <Typography.Text>
                        {currencyFormatter.format(record.active_execution_price - record.active_execution_cost)}
                    </Typography.Text>
                ),
                width: 100,
                sorter: (a, b) => (a.active_execution_price - a.active_execution_cost) - (b.active_execution_price - b.active_execution_cost)
            },
            {
                title: 'Created At', dataIndex: 'created_at', valueType: 'dateRange',
                render: (_, record) => (
                    <Typography.Text>
                        {new Date(record.created_at).toLocaleDateString('el')}
                    </Typography.Text>
                ), sorter: (a, b) => dayjs.utc(a.created_at).diff(dayjs.utc(b.created_at)), width: 100,
                fieldProps: {
                    format: 'YYYY-MM-DD',
                    presets: [ { label: 'Previous Year', text: 'Previous Year', value: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')] },
                        { label: 'This Year', text: 'This Year', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
                        { label: 'Previous Month', text: 'Previous Month', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                        { label: 'This Month', text: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                    ],
                },
                search: {
                    transform: (value) => {
                        return {
                            created_at_start: value[0],
                            created_at_end: value[1]
                        }
                    }
                }
            },
            {
                title: 'Tags', dataIndex: 'tag_ids', valueType: 'select',
                render: (_, record) => (
                    <Space>
                        {record.project_tags.map(({id, tag}) => (
                            <Tag color={'cyan'} key={id}>
                                {tag}
                            </Tag>
                        ))}
                    </Space>
                ),
                fieldProps: {
                    options: allProjectTags.map((t) => ({label: t.tag, value: t.id})),
                    mode: 'tags', maxTagCount: 'responsive'
                }
            },
            {
                title: '', valueType: 'option', render: (_, record) => {
                    return [
                        <Link to={`${record.id}`}>
                            <Button type="link" icon={<EyeOutlined/>}>View</Button>
                        </Link>,
                        <DeleteProjects projectIds={[record.id!]} label={<DeleteOutlined/>}/>,
                    ];
                }, width: 130, hideInSearch: true
            },
        ]

        const [filterSearch, setFilterSearch] = useAtom(filterSearchAtom)

        useEffect(() => {
            // if fetchParams exist, set the form values
            if (Object.keys(fetchParams).length > 0) {
                let params = JSON.parse(JSON.stringify(fetchParams))
                console.log('fetchParams', fetchParams)
                form.current?.setFieldsValue(fetchParams)
                console.log('form.current?.getFieldsValue()', form.current?.getFieldsValue())

                let created_at_search_obj: any = {}
                if (params.created_at_start) {
                    created_at_search_obj['created_at_start'] = dayjs.utc(params.created_at_start)
                }
                if (params.created_at_end) {
                    created_at_search_obj['created_at_end'] = dayjs.utc(params.created_at_end)
                }
                form.current?.setFieldsValue({created_at: [created_at_search_obj.created_at_start, created_at_search_obj.created_at_end]})

                // +Unified customer search implementation
                let customers_search_obj: any = {}
                if (params.customer_id) {
                    customers_search_obj['customer_id'] = params.customer_id?.map((id: number) => JSON.stringify({
                        buyer_type_id: 1,
                        customer_id: id
                    }))
                }
                if (params.accounting_customer_id) {
                    customers_search_obj['accounting_customer_id'] = params.accounting_customer_id?.map((id: number) => JSON.stringify({
                        buyer_type_id: 2,
                        accounting_customer_id: id
                    }))
                }
                if (params.external_buyer_id) {
                    customers_search_obj['external_buyer_id'] = params.external_buyer_id?.map((id: number) => JSON.stringify({
                        buyer_type_id: 3,
                        external_buyer_id: id
                    }))
                }
                let arr = []
                if (customers_search_obj.customer_id) {
                    arr.push(...customers_search_obj.customer_id)
                }
                if (customers_search_obj.accounting_customer_id) {
                    arr.push(...customers_search_obj.accounting_customer_id)
                }
                if (customers_search_obj.external_buyer_id) {
                    arr.push(...customers_search_obj.external_buyer_id)
                }
                form.current?.setFieldsValue({customer: arr})
            }
        }, [fetchParams]);

        useEffect(() => {
            // console.log({fetchParams})
            return () => {
                setFetchParams(RESET)
            }
        }, []);

        return (
            <ProTable
                columns={columns}
                dataSource={projectsAfterFilterSortSearch}
                // search={false}
                onSubmit={async (params) => {
                    console.log({params})
                    //deep copy of fetchParams
                    let newParams = JSON.parse(JSON.stringify(fetchParams))
                    newParams.pretty_ids = params.pretty_ids
                    newParams.customer_id = params.customer_id
                    newParams.external_buyer_id = params.external_buyer_id
                    newParams.accounting_customer_id = params.accounting_customer_id
                    newParams.employee_id = params.employee_id
                    newParams.status = params.status
                    newParams.tag_ids = params.tag_ids
                    newParams.created_at_start = params.created_at_start
                    newParams.created_at_end = params.created_at_end
                    // setFetchParams({...fetchParams, ...params})
                    setFetchParams(newParams)
                }}
                onReset={() => {
                    setFetchParams({})
                }}
                // onChange={(pagination, filters, sorter, extra) => {
                //     console.log('fetchParams', extra)
                // }}
                formRef={form}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false,
                    setting: false,
                    search: {
                        placeholder: "Search...",
                        onChange: (e) => {
                            setFilterSearch(e.target.value)
                        },
                        value: filterSearch,
                        allowClear: true,
                        enterButton: <SearchOutlined/>
                    }
                }}
                search={{
                    searchText: 'Search',
                    defaultCollapsed: false,
                    defaultColsNumber: 3,
                }}
                toolBarRender={() => [
                    <Link to="new" state={{background: location}}>
                        <Button
                            icon={<PlusOutlined/>}
                            key="new"
                            type="primary"
                        >
                            New
                        </Button>
                    </Link>,
                    <Suspense fallback={null}>
                        <ExportToExcel/>
                    </Suspense>,
                    <Button
                        key="reload"
                        type={'text'}
                        icon={<ReloadOutlined/>}
                        onClick={() => {
                            queryClient.refetchQueries({queryKey: ['projects']})
                        }}
                    />,
                ]}
                // pagination={false}
                pagination={{
                    pageSize: 10,
                }}
                rowKey="id"
                rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys: Key[], selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys as number[]);
                    },
                }}
                // tableStyle={{
                //     padding: 0, margin: 0, paddingBlock: 0, paddingInline: 0,
                // }}
                style={{
                    overflowY: 'auto',
                    maxHeight: 'inherit'
                }}

            />
        );
    }
;

export default ProjectsTable;

